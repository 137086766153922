import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import process from 'process'
import base64 from 'base-64'
import {
  getAPIBaseUrl,
  logout,
  showBanModal,
  showLogoutModal,
  showModalOnHold,
  showDownTimeModal,
  getLocationPermission,
} from 'utils'
import {ServiceResponses, UserData} from 'types'
import {API_OAUTH_REFRESH_TOKEN} from 'consts'
import {reduxUpdateDispatcher} from 'lib/redux'
import {REDUX_STORE} from '../lib/redux/ReduxStore'

function handleBanModal(status: number, message: string) {
  showBanModal(status, message)
}

function handleLogoutModal() {
  logout()
  showLogoutModal()
}

function handleDownTimeModal() {
  showDownTimeModal(true)
}

let retryQueue: Function[] = []

export function handleRequestOnFulfilled(request: AxiosRequestConfig) {
  const token = REDUX_STORE.getState().user?.access_token
  let latitude = REDUX_STORE.getState().user?.latitude
  let longitude = REDUX_STORE.getState().user?.longitude

  if (!(latitude && longitude))
    getLocationPermission(
      () => {},
      () =>
        navigator.geolocation.getCurrentPosition(
          (position) => {
            longitude = position.coords.longitude
            latitude = position.coords.latitude
          },
          () => {},
          {enableHighAccuracy: true},
        ),
    )

  if (token && request.headers) {
    request.headers.Authorization = `Bearer ${token}`
  }

  return request
}

export async function handleRefreshAccessToken() {
  const user = 'web'
  const password = process.env.AUTH_CLIENT_PASSWORD
  const refreshToken =
    REDUX_STORE.getState().user?.refresh_token ||
    REDUX_STORE.getState().lastUserState.refreshToken

  if (refreshToken) {
    await axios
      .post(
        getAPIBaseUrl() + API_OAUTH_REFRESH_TOKEN,
        {
          code_verifier: process.env.AUTH_CODE_VERIFIER,
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
        },
        {
          headers: {
            Authorization: `Basic ${base64.encode(`${user}:${password}`)}`,
          },
        },
      )
      .then((value) => {
        if (value.status === 200) {
          const {result} = value.data as ServiceResponses<UserData>

          reduxUpdateDispatcher('user', result)
        }
      })
      .catch((value) => {
        if (
          typeof value === 'object' &&
          value.response.status === 401 &&
          (value.response.data.detail.refresh_token ===
            'expired refresh token' ||
            value.response.data.detail.refresh_token ===
              'invalid refresh token')
        ) {
          reduxUpdateDispatcher('lastUserState', {isTokenExpired: true})
          handleLogoutModal()
        }
      })
  }
}

export function handleServiceResponseInterceptor(response: AxiosResponse) {
  const accToken = REDUX_STORE.getState().user?.access_token
  const {data, status, config} = response

  if (
    status === 403 &&
    (data.message === 'Banned' || data.message === 'Underage')
  ) {
    handleBanModal(status, data.message)
  } else if (status === 403 && data.message === 'Duplicate Account') {
    accToken
      ? window.location.replace('/similar-identity')
      : showModalOnHold(true)
  } else if (status === 403 && data.message === 'Feature Is Disabled') {
    handleDownTimeModal()
  } else if (
    status === 423 &&
    (data.message === 'Suspended' ||
      data.message === 'Attempt Login Suspended' ||
      data.message === 'Locked')
  ) {
    handleBanModal(status, data.message)
  } else if (status === 401 && data.message !== 'unverified') {
    const configAccessToken = config.headers?.Authorization.toString().slice(7)
    const isAccessTokenUpdated = () =>
      !!configAccessToken &&
      !!REDUX_STORE.getState().user?.access_token &&
      configAccessToken !== REDUX_STORE.getState().user?.access_token

    if (config.headers && isAccessTokenUpdated()) {
      config.headers.Authorization = `Bearer ${
        REDUX_STORE.getState().user?.access_token
      }`
      return axios(config)
    }

    const needRefresh = retryQueue.length === 0

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const onRetry = () => {
        if (config.headers && isAccessTokenUpdated()) {
          config.headers.Authorization = `Bearer ${
            REDUX_STORE.getState().user?.access_token
          }`
          resolve(axios(config))
        } else {
          resolve(response)
        }
      }

      retryQueue.push(onRetry)
      if (needRefresh) {
        await handleRefreshAccessToken()
        retryQueue.forEach((retry) => retry())
        retryQueue = []
      }
    })
  }

  return response
}
