import {resetStateExplorePagination} from 'utils'
import history from 'lib/history'
import {reduxResetDispatcher} from 'lib/redux'
import {clearUserCache} from '../cache'

export function logout() {
  clearUserCache()
  resetStateExplorePagination()
  reduxResetDispatcher('forkygramHomefeedsState')
  reduxResetDispatcher('user')
  reduxResetDispatcher('bannerClosed')
  reduxResetDispatcher('hostState')
  reduxResetDispatcher('giftshopState')
  reduxResetDispatcher('hostRegistrationState')
  reduxResetDispatcher('yuserActivationState')
  reduxResetDispatcher('exploreFilter')
  reduxResetDispatcher('userNewEmail')
  reduxResetDispatcher('userNewPhone')
  reduxResetDispatcher('verificationStatus')
  reduxResetDispatcher('treeQrState')
  reduxResetDispatcher('giftShopTooltipState')
  reduxResetDispatcher('parentalConsentState')
  reduxResetDispatcher('exploreMonthPaginationState')
  localStorage.removeItem('used')
  localStorage.removeItem('bonus')
  localStorage.removeItem('bonusGb')
  localStorage.removeItem('limit')
  history.push('/')
}
