import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {ObjectState, UserSimilarAccountData} from 'types'
import {logout, showSnackbar, useDidMount, useHistory} from 'utils'
import {requestData} from 'services'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {Button, Icon, List, ModalLoading, Paragraph} from 'common/components'
import {
  GiftShopTemplateModalMultiAccountConfirmation,
  GiftShopTemplateModalOtherAccount,
  GiftShopTemplateSimilarAccountItem,
} from '../../template'

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-self: center;
  background-color: ${({theme}) => theme.white_3};
`
const StyledContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: ${convertUnit(560)};
  padding: 0 ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    max-width: 100%;
    height: 100%;
  }
  background-color: ${({theme}) => theme.white_1};
`
const StyledHeader = styled(Paragraph)`
  padding-top: ${convertUnit(20)};
  white-space: pre-line;
`
const StyledButton = styled(Button)`
  width: fit-content;
`
const StyledSelfSelectContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  gap: ${convertUnit(20)};
  flex-direction: column;
  justify-content: center;
  align-self: center;
  text-align: center;
`
const StyledSelfSelectContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
`

interface GiftShopSimilarChooseAccountMultiProps {
  stateFinished: ObjectState<boolean>
}

export default function GiftShopSimilarChooseAccountMulti({
  stateFinished,
}: GiftShopSimilarChooseAccountMultiProps) {
  const {translate} = useTranslation()
  const {id} = useSelector('user') || {}
  const [finished, setFinished] = stateFinished
  const [data, setData] = useState<ReadonlyArray<UserSimilarAccountData>>([])
  const [selectedData, setSelectedData] = useState<UserSimilarAccountData>()
  const {update} = useDispatch()
  const stateErrorMessage = useState('')
  const setErrorMessage = stateErrorMessage[1]
  const stateLoading = useState(false)
  const setLoading = stateLoading[1]
  const history = useHistory()
  const [isSelectSelf, setIsSelectSelf] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  type MultiAccountModalType = 'confirm' | 'verify'
  const [modalStatus, setModalStatus] = useState<MultiAccountModalType>()
  const [correctCredential, setCorrectCredential] = useState('')

  const handleContinue = useCallback(() => {
    if (selectedData) {
      setModalLoading(true)
      requestData('giftshop_similar_patch_account', {
        data: {
          id: selectedData.id,
          identifier: isSelectSelf ? selectedData.username : correctCredential,
        },
        onRequestReceived: () => {
          setModalLoading(false)
        },
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            update('similarAccountStatus', {
              status: isSelectSelf ? 'CHOOSE_SELF' : 'CHOOSE_OTHER',
            })
            setFinished(true)
          } else {
            showSnackbar(
              translate('global:messageError', {context: `code-${status}`}),
            )
          }
        },
        onRequestFailed: (reason) => {
          showSnackbar(translate('global:messageError', {context: reason}))
        },
      })
    }
  }, [
    correctCredential,
    isSelectSelf,
    selectedData,
    setFinished,
    translate,
    update,
  ])

  const handleLoadData = useCallback(async () => {
    const response = await requestData('giftshop_similar_get_account')
    if (typeof response !== 'string' && response.status === 200) {
      const getSelfData = response.data.result.filter((x) => x.id === id)[0]
      response.data.result.unshift(getSelfData)
      response.data.result.splice(
        response.data.result.lastIndexOf(getSelfData),
        1,
      )
      setData(response.data.result)
      return response.data.result
    }
    return null
  }, [id])

  const handleSetSelectedData = useCallback(
    (item: UserSimilarAccountData) => {
      setSelectedData(item)
      setModalStatus(item.id === id ? 'confirm' : 'verify')
    },
    [id],
  )

  const handleRenderConfirmationModal = useMemo(
    () =>
      selectedData !== undefined && (
        <GiftShopTemplateModalMultiAccountConfirmation
          data={selectedData}
          onCancel={() => {
            setModalStatus(undefined)
            setCorrectCredential('')
          }}
          onConfirm={() => {
            setModalStatus(undefined)
            handleContinue()
          }}
        />
      ),
    [handleContinue, selectedData],
  )

  const handleRenderItem = useCallback(
    (item: UserSimilarAccountData) => (
      <GiftShopTemplateSimilarAccountItem
        data={item}
        showCurrentLabel={item.id === id}
        rightComponent={
          <StyledButton
            label={translate('global:iChooseThis')}
            type="small"
            fontSize="s"
            onClick={() => {
              setIsSelectSelf(item.id === id)
              handleSetSelectedData(item)
            }}
          />
        }
      />
    ),
    [handleSetSelectedData, id, translate],
  )

  const handleBack = useCallback(() => {
    if (isSelectSelf) {
      update('yuserActivationState', {kycStatus: 'verified'})
      history.push('giftshop_explore', {})
    } else {
      logout()
    }
  }, [history, isSelectSelf, update])

  const handleFinishModal = useMemo(
    () =>
      finished ? (
        <StyledSelfSelectContainer>
          <StyledSelfSelectContentContainer>
            <Icon type="check" color="success_5" size={48} />
            <Paragraph fontWeight="bold" fontSize="xl">
              {translate('tree:hostRegistrationProcessModalTitle')}
            </Paragraph>
            <Paragraph fontSize="l">
              {translate(
                isSelectSelf
                  ? 'giftShop:similarMultiAccountFinishedSelf'
                  : 'giftShop:similarMultiAccountFinishedOther',
              )}
            </Paragraph>
          </StyledSelfSelectContentContainer>
          <Button onClick={handleBack}>{translate('global:ok')}</Button>
        </StyledSelfSelectContainer>
      ) : (
        <></>
      ),
    [finished, handleBack, isSelectSelf, translate],
  )

  const handleRenderContent = useMemo(
    () =>
      !finished && (
        <StyledContainer>
          <StyledContentContainer>
            <List
              data={data}
              keyExtractor={(item) => item.id}
              renderItem={handleRenderItem}
              scrollbar={false}
              listHeaderElement={
                <StyledHeader fontSize="l" fontWeight="medium">
                  {translate('giftShop:similarMultiAccountChooseDesc')}
                </StyledHeader>
              }
            />
          </StyledContentContainer>
        </StyledContainer>
      ),
    [data, finished, handleRenderItem, translate],
  )

  const handleVerifyCredential = useCallback(
    (chosenId: string, identifier: string, method?: string) => {
      setLoading(true)
      requestData('giftshop_similar_post_account', {
        data: {id: chosenId, identifier},
        onRequestReceived: () => {
          setLoading(false)
        },
        onRequestSuccess: ({status, data: result}) => {
          if (status === 200) {
            setCorrectCredential(identifier)
            const {username} = result.result
            setSelectedData(
              selectedData ? {...selectedData, username} : undefined,
            )
            setModalStatus('confirm')
          } else if (status === 400 && result.detail !== undefined) {
            if (result.detail.identifier === 'invalid') {
              setErrorMessage(
                translate('giftShop:similarOtherAccountFailedMessage', {
                  context: method,
                }),
              )
            } else {
              showSnackbar(
                translate('global:messageError', {context: `code-${status}`}),
              )
            }
          } else {
            showSnackbar(
              translate('global:messageError', {context: `code-${status}`}),
            )
          }
        },
        onRequestFailed: (reason) => {
          showSnackbar(translate('global:messageError', {context: reason}))
        },
      })
    },
    [selectedData, setErrorMessage, setLoading, translate],
  )

  const handleRenderVerifModal = useMemo(
    () =>
      selectedData !== undefined && (
        <GiftShopTemplateModalOtherAccount
          data={selectedData}
          onCancel={() => {
            setSelectedData(undefined)
            setErrorMessage('')
          }}
          stateError={stateErrorMessage}
          stateLoading={stateLoading}
          onConfirm={({id: chosenId, identifier, method}) =>
            handleVerifyCredential(chosenId, identifier, method)
          }
        />
      ),
    [
      handleVerifyCredential,
      selectedData,
      setErrorMessage,
      stateErrorMessage,
      stateLoading,
    ],
  )
  const handleRenderModalLoading = useMemo(
    () => <ModalLoading visible={modalLoading} />,
    [modalLoading],
  )

  const handleRenderMultiAccountModal = useMemo(() => {
    switch (modalStatus) {
      case 'verify':
        return handleRenderVerifModal
      case 'confirm':
        return handleRenderConfirmationModal
      default:
        return <></>
    }
  }, [handleRenderConfirmationModal, handleRenderVerifModal, modalStatus])

  useDidMount(() => {
    handleLoadData()
  })

  return (
    <>
      {handleRenderModalLoading}
      {handleRenderMultiAccountModal}
      {handleFinishModal}
      {handleRenderContent}
    </>
  )
}
