import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  FirebaseMessageRoomItemPayload,
  ForkygramPostData,
  parseShape,
  UserInfoBase,
} from 'types'
import {useHistory} from 'utils'
import {Paragraph, ParsedText, Image} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'

export interface GiftShopMessageRoomContentItemNotificationCommentMentionProps {
  payload: FirebaseMessageRoomItemPayload['notification_comment_mention']
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledMessageContiner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledParagraph = styled(Paragraph)`
  margin-left: ${convertUnit(4)};
`

const StyledImageContainer = styled.div`
  margin-left: ${convertUnit(16)};
  cursor: pointer;
`

const StyledImage = styled(Image)`
  width: ${convertUnit(56)};
  height: ${convertUnit(56)};
`

export default function GiftShopMessageRoomContentItemNotificationCommentMention({
  payload: {
    comment,
    member_params,
    commented_member_id,
    post_id,
    post_image_url,
    post_member_id,
    post_member_username,
    username,
  },
}: GiftShopMessageRoomContentItemNotificationCommentMentionProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const stateData = useState<ReadonlyArray<ForkygramPostData>>([])
  const data = stateData[0]
  const {id} = useSelector('user') || {}

  const handleNavigateProfile = useCallback(
    (member: UserInfoBase) => {
      history.push(
        'giftshop_profile',
        {
          self: member.id === id,
          postData: {
            member_id: member.id,
            username: member.username,
            photo: member.photo,
          },
        },
        member.username,
      )
    },
    [history, id],
  )

  const patternsMention: parseShape[] = useMemo(
    () =>
      member_params &&
      member_params.map((mention) => {
        const regex = new RegExp(`{{${mention.id}}}`)
        return {
          pattern: regex,
          style: {fontFamily: 'roboto-bold', cursor: 'pointer'},
          renderText: (text) => text.replace(regex, `@${mention.username}`),
          onClick: () => handleNavigateProfile(mention),
        }
      }),
    [handleNavigateProfile, member_params],
  )

  const patternsUsername: parseShape[] = useMemo(
    () =>
      member_params &&
      member_params.map((member) => {
        const regex = new RegExp(`${member.id}`)
        return {
          pattern: regex,
          style: {fontFamily: 'roboto-bold', cursor: 'pointer'},
          renderText: (text) => text.replace(regex, `@${member.username}`),
          onClick: () => handleNavigateProfile(member),
        }
      }),
    [handleNavigateProfile, member_params],
  )

  const handleRenderMessage = useMemo(
    () => (
      <StyledMessageContiner>
        <StyledParagraph fontSize="m">
          <ParsedText parse={patternsUsername}>
            {commented_member_id}
          </ParsedText>
          {translate('messages:notificationCommentMention')}
          <ParsedText parse={patternsMention}>{comment}</ParsedText>
        </StyledParagraph>
      </StyledMessageContiner>
    ),
    [
      patternsUsername,
      commented_member_id,
      translate,
      patternsMention,
      comment,
    ],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledImageContainer
        onClick={() => {
          history.pushQuery({
            path: 'giftshop_profile_post_detail',
            state: {
              data,
              self: false,
              selectedItemId: post_id,
              memberId: post_member_id,
              loadSpecificData: true,
            },
            queryObject: {id: post_id},
            /**
             * Based on the response changes made on May 26th, 2023,
             * please note that the `post_member_username` doesn't always exist.
             */
            recursivePath: post_member_username || username,
          })
        }}>
        <StyledImage src={post_image_url} alt="image" />
      </StyledImageContainer>
    ),
    [
      data,
      history,
      post_id,
      post_image_url,
      post_member_id,
      post_member_username,
      username,
    ],
  )

  return (
    <StyledContainer>
      {handleRenderMessage}
      {handleRenderContent}
    </StyledContainer>
  )
}
