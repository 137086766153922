import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {ForkygramPostData} from 'types'
import {
  clearUserCache,
  showModalLimitedAccess,
  showSnackbar,
  useDefaultState,
  useHistory,
} from 'utils'
import {ListLazy} from 'common/components'
import {useSelector} from 'lib/redux'
import {TemplateProfileDetailItem} from '../ProfileDetailItem'
import {TemplateProfileDetailContentProps} from './TemplateProfileDetailContentProps'
import TemplateProfileDetailContentSkeleton from './TemplateProfileDetailContentSkeleton'

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`

export default function TemplateProfileDetailContent({
  selectedItemId,
  stateData,
  stateEdit: stateEditBase,
  initialPage,
  isFeed,
  isLive,
  self,
  loadData,
  stateVideoPlaying,
  stateMute,
  stateVideoPause,
}: TemplateProfileDetailContentProps) {
  const {translate} = useTranslation()
  const {username} = useSelector('user') || {}
  const {status: childStatus} = useSelector('parentalConsentState')
  const [data, setData] = stateData
  const stateEdit = useDefaultState(undefined, stateEditBase)
  const [scrollPos, setScrollPos] = useState(0)
  const setEdit = stateEdit[1]
  const [dataLen, setDataLen] = useState(data?.length)
  const history = useHistory()
  const setVideoPlaying = stateVideoPlaying[1]
  const stateFollowButton = useState(true)

  const handleNavigateProfile = useCallback(() => {
    history.push('giftshop_profile', {self: true}, username)
  }, [history, username])

  const handleDelete = useCallback(
    (item: ForkygramPostData) => {
      requestData('forkygram_delete_post', {
        actionType: 'execute',
        useDefaultMessage: true,
        data: {id: item.id},
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setData((prev) => prev.filter((post) => post.id !== item.id))
            clearUserCache('post')
            if (dataLen && dataLen > 1) {
              setData((prev) => prev.filter((val) => val.id !== item.id))
              setDataLen((prev) => prev && prev - 1)
            } else {
              handleNavigateProfile()
            }
          }
        },
      })
    },
    [dataLen, handleNavigateProfile, setData],
  )

  const handlePin = useCallback(
    (id: string, isPinned: boolean) => {
      isPinned
        ? requestData('forkygram_delete_pin_posts', {
            actionType: 'execute',
            useDefaultMessage: true,
            data: {id},
            onRequestSuccess: ({status}) => {
              if (status === 200) {
                showSnackbar(translate('forkygram:postUnpinSuccess'))
                handleNavigateProfile()
              }
            },
          })
        : requestData('forkygram_post_pin_posts', {
            actionType: 'execute',
            useDefaultMessage: true,
            data: {id},
            onRequestSuccess: ({status}) => {
              if (status === 200) {
                showSnackbar(translate('forkygram:postPinSuccess'))
                handleNavigateProfile()
              } else if (status === 400) {
                showSnackbar(translate('forkygram:postPinFailed'))
              }
            },
          })
    },
    [handleNavigateProfile, translate],
  )

  const selectedComponentRef = useCallback((node: HTMLDivElement) => {
    node &&
      node.scrollIntoView({
        block: 'start',
        inline: 'nearest',
      })
  }, [])

  const handleFollow = useCallback(
    (item: ForkygramPostData) => {
      if (childStatus === 'UNDERAGE') {
        showModalLimitedAccess()
        return
      }
      stateFollowButton[1](false)
      requestData('giftshop_post_follow', {
        data: {member_id: item.member_id},
        useDefaultMessage: true,
        actionType: 'execute',
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setData((prev) =>
              prev.map((x) =>
                x.member_id === item.member_id ? {...x, is_followed: true} : x,
              ),
            )
          } else {
            stateFollowButton[1](true)
            showSnackbar(translate('global:messageError', {context: status}))
          }
        },
      })
    },
    [childStatus, setData, stateFollowButton, translate],
  )

  const handleRenderItem = useCallback(
    (item: ForkygramPostData) => (
      <TemplateProfileDetailItem
        componentRef={
          selectedItemId && selectedItemId === item.id
            ? selectedComponentRef
            : null
        }
        data={item}
        onDelete={() => handleDelete(item)}
        onEdit={() => setEdit(item)}
        onPin={(pinnedId, isPinned) => handlePin(pinnedId, isPinned)}
        isFeed={isFeed}
        isLive={isLive}
        scrollPosition={scrollPos}
        index={data.indexOf(item)}
        self={self}
        stateVideoPause={stateVideoPause}
        stateMute={stateMute}
        setVideoPlaying={setVideoPlaying}
        onReported={(reportedId) =>
          setData((prev) => prev.filter((post) => post.id !== reportedId))
        }
        onFollow={() => handleFollow(item)}
        stateFollowButton={stateFollowButton}
      />
    ),
    [
      data,
      handleDelete,
      handleFollow,
      handlePin,
      isFeed,
      isLive,
      scrollPos,
      selectedComponentRef,
      selectedItemId,
      self,
      setData,
      setEdit,
      setVideoPlaying,
      stateFollowButton,
      stateMute,
      stateVideoPause,
    ],
  )

  const handleRenderList = useMemo(
    () => (
      <ListLazy
        cache={self ? 'post' : undefined}
        onScrollList={(e) => setScrollPos(e.currentTarget.scrollTop)}
        stateData={stateData}
        keyExtractor={(item) => item.id}
        listInitializeElement={<TemplateProfileDetailContentSkeleton />}
        renderItem={handleRenderItem}
        loadData={loadData}
        scrollbar={false}
        initialPage={initialPage}
      />
    ),
    [handleRenderItem, initialPage, loadData, self, stateData],
  )

  return <StyledContainer>{handleRenderList}</StyledContainer>
}
