import {useCallback} from 'react'
import {FirestoreRoboyuStatusPayload} from 'types'
import {useDidMount, useHistory} from 'utils'
import firebase from 'lib/firebase'
import {useDispatch, useSelector} from 'lib/redux'

export function useFirestoreRoboyuStatus() {
  const {update} = useDispatch()
  const user = useSelector('user')
  const {kycStatus} = useSelector('yuserActivationState')
  const history = useHistory()

  const handleFirestore = useCallback(
    () =>
      firebase
        .firestore()
        .collection(process.env.FIREBASE_COLLECTION_USER!)
        .doc(user?.id)
        .onSnapshot((snapshot) => {
          const data = snapshot.data() as FirestoreRoboyuStatusPayload
          const {kyc_status, similar_status, verified_at} = data || {}
          verified_at &&
            update('yuserActivationState', {
              verifiedAt: verified_at * 1000,
            })
          if (kyc_status && kyc_status !== kycStatus) {
            update('yuserActivationState', {
              kycStatus: data.kyc_status,
            })
            update('similarAccountStatus', {status: similar_status})
            if (kyc_status === 'onhold')
              history.push('giftshop_similar_identity', {fromRoboyu: true})
          }
        }),
    [history, kycStatus, update, user?.id],
  )

  useDidMount(handleFirestore)
}
