import React, {CSSProperties} from 'react'
import styled from 'styled-components'
import {Icon} from 'common/components'
import convertUnit from 'lib/unit'
import {ForkygramTemplatePostAudioPlayPauseProps} from './ForkygramTemplatePostAudioProps'

interface StyledPlayPauseContainerProps {
  visibility: CSSProperties['visibility']
}

const StyledPlayPauseContainer = styled.div<StyledPlayPauseContainerProps>`
  ${({visibility}) => ({visibility})}
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  height: ${convertUnit(72)};
  width: ${convertUnit(72)};
`

export default function ForkygramTemplatePostAudioPlayPause({
  pause,
  showButton,
}: ForkygramTemplatePostAudioPlayPauseProps) {
  return (
    <StyledPlayPauseContainer visibility={showButton ? 'visible' : 'hidden'}>
      <Icon
        color="white_1"
        type={pause ? 'pause' : 'play'}
        size={convertUnit(56)}
      />
    </StyledPlayPauseContainer>
  )
}
