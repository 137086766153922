import React from 'react'
import styled from 'styled-components'
import {IconVerifiedBadge, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {formatDateRange, getBorder} from 'utils'
import {IMAGE_ASSET} from 'consts'
import {TreeFototreeTemplateListTreeProps} from './TreeFototreeTemplateListTreeProps'

interface StyledItemContainerProps {
  isFindSimilar?: boolean
}

const StyledItemContainer = styled.div<StyledItemContainerProps>`
  ${({theme, isFindSimilar}) => ({
    borderBottom: isFindSimilar ? getBorder(1, 'solid', theme.white_4) : 'none',
    gridTemplateColumns: isFindSimilar ? '1fr' : `auto 1fr`,
    cursor: isFindSimilar ? 'default' : 'pointer',
    '&:hover': {
      backgroundColor: isFindSimilar ? 'transparent' : theme.white_4,
    },
  })}
  display: grid;
  align-items: center;
  padding: ${convertUnit(6)} ${convertUnit(16)};
  gap: ${convertUnit(12)};
`

const StyledItemContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledItemContentNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${convertUnit(6)};
`

const StyledItemContentBio = styled(Paragraph)<StyledItemContainerProps>`
  display: -webkit-box;
  -webkit-line-clamp: ${({isFindSimilar}) => (isFindSimilar ? 1 : 2)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const StyledImage = styled(Image)`
  border-radius: ${convertUnit(3.2)};
  width: ${convertUnit(34)};
  aspect-ratio: 0.75;
  object-fit: contain;
`

export default function TreeFototreeTemplateListTree({
  data,
  isFindSimilar = false,
  onClick,
}: TreeFototreeTemplateListTreeProps) {
  const defaultImage = IMAGE_ASSET('tree', 'default.png')

  return (
    <StyledItemContainer isFindSimilar={isFindSimilar} onClick={onClick}>
      {!isFindSimilar && (
        <StyledImage
          alt={data.name}
          src={data.url}
          fallbackSrc={defaultImage}
        />
      )}
      <StyledItemContentContainer>
        <StyledItemContentNameContainer>
          <Paragraph fontWeight="bold">{data.name}</Paragraph>
          {data.status === 'VERIFIED' && (
            <IconVerifiedBadge width={12} height={12} />
          )}
        </StyledItemContentNameContainer>
        <StyledItemContentBio
          fontSize="xs"
          color="gray_5"
          isFindSimilar={isFindSimilar}>
          {data.location.name !== '-' && `${data.location.name} | `}
          {data.event_start_date &&
            data.event_end_date &&
            `${formatDateRange(data.event_start_date, data.event_end_date)} | `}
          {data.bio}
        </StyledItemContentBio>
      </StyledItemContentContainer>
    </StyledItemContainer>
  )
}
