import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  GIFT_SHOP_UPLOAD_MAX_PRICE,
  GIFT_SHOP_UPLOAD_MIN_PRICE,
  GIFT_SHOP_UPLOAD_YUSER_MAX_PRICE,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestDataPayload} from 'services'
import {
  AuthCredentialType,
  GiftShopEditBulkForm,
  TransactionCurrencyData,
} from 'types'
import {useDidMount, useHistory} from 'utils'
import {Button, Modal, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopTemplateModalCredential} from '../../../template'
import GiftShopProfileEditBulkModalFormPrice from './GiftShopProfileEditBulkModalFormPrice'
import {GiftShopProfileEditBulkModalProps} from './GiftShopProfileEditBulkModalProps'
import GiftShopProfileEditBulkModalFormTags from './GiftShopProfileEditBulkModalFormTags'
import GiftShopProfileEditBulkModalFormDescription from './GiftShopProfileEditBulkModalFormDescription'
import GiftShopProfileEditBulkModalFormDateTime from './GiftShopProfileEditBulkModalFormDateTime'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(295)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(20)};
`

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(8)};
  text-align: center;
  width: 100%;
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopProfileEditBulkModal({
  visible,
  defaultValues,
  isLimitReached,
  onSubmit,
  toggleModal,
  onClickLearnMore,
}: GiftShopProfileEditBulkModalProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const [currencyData, setCurrencyData] = useState<TransactionCurrencyData[]>(
    [],
  )
  const form = useForm<GiftShopEditBulkForm>({defaultValues})
  const {watch, register, setValue} = form
  const values = watch()
  const {creatorStatus, kycStatus} = useSelector('yuserActivationState')
  const {maximumPrice, minimumPrice} = useSelector('hostState')
  const user = useSelector('user')
  const credential = useMemo<AuthCredentialType | undefined>(
    () => (!user?.email ? 'email' : !user?.phone_number ? 'phone' : undefined),
    [user?.email, user?.phone_number],
  )
  const stateModalCredential = useState(false)
  const notVerifiedCreator = useMemo(
    () =>
      creatorStatus !== 'verified' &&
      (kycStatus === 'verified' || kycStatus === 'verified_check'),
    [creatorStatus, kycStatus],
  )

  const maxPrice = useMemo(
    () =>
      notVerifiedCreator
        ? Infinity
        : maximumPrice || GIFT_SHOP_UPLOAD_MAX_PRICE,
    [maximumPrice, notVerifiedCreator],
  )
  const minPrice = minimumPrice || GIFT_SHOP_UPLOAD_MIN_PRICE

  const disabledBtn = useMemo(
    () =>
      isLimitReached
        ? values.price.length <= 0 && values.description.length === 0
        : (values.description.length === 0 &&
            values.tags.length === 0 &&
            values.price.length <= 0 &&
            (values.date.length === 0 || values.time.length === 0)) ||
          parseInt(values.price, 10) < minPrice ||
          parseInt(values.price, 10) > maxPrice ||
          (notVerifiedCreator &&
            parseInt(values.price, 10) > GIFT_SHOP_UPLOAD_YUSER_MAX_PRICE),
    [
      isLimitReached,
      maxPrice,
      minPrice,
      notVerifiedCreator,
      values.date.length,
      values.description.length,
      values.price,
      values.tags.length,
      values.time.length,
    ],
  )

  const handleGetCurrencyData = useCallback(
    () =>
      requestDataPayload('giftshop_get_currency', {
        useDefaultMessage: true,
        actionType: 'fetch',
        onRequestSuccess: ({data: response, status}) => {
          const {result} = response
          if (status === 200) {
            const idx = result.findIndex((item) => item.currency === 'IDR')
            const resultIdx = idx === -1 ? 0 : idx
            setValue('currencyName', result[resultIdx].currency)
            setValue('currencyId', result[resultIdx].id)
            setCurrencyData(result)
          }
        },
      }),
    [setValue],
  )

  const handleRenderDescription = useMemo(
    () => (
      <StyledInfo>
        <Paragraph fontSize="l" fontWeight="bold">
          {translate('giftShop:editContentBulkHeader')}
        </Paragraph>
        <Paragraph fontSize="m">
          {translate('giftShop:editContentBulkDescription')}
        </Paragraph>
      </StyledInfo>
    ),
    [translate],
  )

  const handleRenderFormPrice = useMemo(
    () => (
      <GiftShopProfileEditBulkModalFormPrice
        form={form}
        currencyData={currencyData}
        maxPrice={maxPrice}
        minPrice={minPrice}
        notVerifiedCreator={notVerifiedCreator}
        onClickLearnMore={onClickLearnMore}
      />
    ),
    [
      currencyData,
      form,
      maxPrice,
      minPrice,
      notVerifiedCreator,
      onClickLearnMore,
    ],
  )

  const handleRenderFormTags = useMemo(
    () => (
      <GiftShopProfileEditBulkModalFormTags
        form={form}
        disabled={isLimitReached}
      />
    ),
    [form, isLimitReached],
  )

  const handleRenderFormDateTime = useMemo(
    () => (
      <GiftShopProfileEditBulkModalFormDateTime
        form={form}
        disabled={isLimitReached}
      />
    ),
    [form, isLimitReached],
  )

  const handleRenderFormDescription = useMemo(
    () => <GiftShopProfileEditBulkModalFormDescription form={form} />,
    [form],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:cancel')}
          color="primary_5"
          backgroundColor="white_2"
          onClick={toggleModal}
        />
        <StyledButton
          label={translate('global:save')}
          disabled={disabledBtn}
          onClick={() => onSubmit(values)}
        />
      </StyledButtonContainer>
    ),
    [disabledBtn, onSubmit, toggleModal, translate, values],
  )

  const handleNavigateAddCredential = useCallback(() => {
    stateModalCredential[1](false)
    history.push('giftshop_edit_profile', {
      menu: credential === 'email' ? 'email' : 'phone',
    })
  }, [credential, history, stateModalCredential])

  const handleRenderCredentialModal = useMemo(
    () => (
      <GiftShopTemplateModalCredential
        stateModal={stateModalCredential}
        credential={credential}
        onAgree={handleNavigateAddCredential}
      />
    ),
    [credential, handleNavigateAddCredential, stateModalCredential],
  )

  useDidMount(() => {
    handleGetCurrencyData()
    register('currencyId')
    register('currencyName')
    register('tags')
    register('date')
    register('time')
    register('datetime')
  })

  return (
    <>
      {handleRenderCredentialModal}
      <StyledModal visible={visible}>
        {handleRenderDescription}
        {handleRenderFormPrice}
        {handleRenderFormTags}
        {handleRenderFormDateTime}
        {handleRenderFormDescription}
        {handleRenderButton}
      </StyledModal>
    </>
  )
}
