import React, {useCallback, useEffect, useMemo, useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {
  downloadImages,
  getCurrencyValue,
  getFileSize,
  getThemeColor,
  parseDate,
  useHistory,
  useLocation,
  showSnackbar,
  showModalReprocessKyc,
  showModalLimitedAccess,
  getBorder,
  isDiscountPrice,
  getDefaultAvatar,
} from 'utils'
import {requestData} from 'services'
import {
  FileImageUrlOption,
  GiftShopGetReportResponse,
  GiftShopContentDetailModalStates,
  GiftshopResendModalState,
  WindowModeType,
  GiftShopContentData,
} from 'types'
import {ThemeColor, useTheme} from 'themes'
import {useWindowMode} from 'windows'
import {
  GiftShopReportCancelConfirmation,
  GiftShopReportContent,
  GiftShopShareContentModal,
  GiftShopTemplateContentDetailBadge,
  GiftShopTemplateWholesaleDiscountModal,
} from 'pages'
import {
  Avatar,
  Button,
  ButtonIcon,
  ConfirmationModal,
  Icon,
  Paragraph,
  Rating,
} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {
  FoundComponentProps,
  GiftShopTemplateDetailItemProps,
} from './GiftShopTemplateDetailContentItemProps'
import GiftShopTemplateDetailLocation from './GiftShopTemplateDetailLocation'
import {GiftShopTemplateDetailContentItemImage} from '../DetailContentItemImage'
import {GiftshopTemplateUploadResendModal} from '../ModalResendStatus'
import {GiftShopTemplateRevisionModal} from '../RevisionModal'

interface StyledContainerProps {
  mode: WindowModeType
}

interface StyledDescriptionContainerProps {
  toggle: boolean
}

interface StyledButtonIconProps {
  backgroundColorOpacity?: number
  hasBackground?: boolean
  hasDefaultMargin?: boolean
}

interface StyledButtonProps {
  disabled: boolean
}

interface StyledSectionDescriptionProps {
  darkBackground?: boolean
}

interface StyledPreviewInfoContainerProps {
  darkBackground?: boolean
}

interface StyledSectionDescriptionCreatorProps {
  clickable?: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: column;
`

const StyledSectionDescription = styled.div<StyledSectionDescriptionProps>`
  ${({theme, darkBackground}) => ({
    backgroundColor: darkBackground ? theme.black : theme.white_1,
  })}
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledSectionDescriptionMain = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StyledSectionDescriptionCreator = styled.div<StyledSectionDescriptionCreatorProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  cursor: ${({clickable}) => (clickable ? 'pointer' : 'default')};
`

const StyledSectionDescriptionInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledDescriptionContainer = styled.div<StyledDescriptionContainerProps>`
  ${({toggle}) => ({display: toggle ? 'flex' : 'none'})}
  flex-direction: column;
  padding: ${convertUnit(20)};
`

const StyledResendButton = styled(Button)`
  margin: ${convertUnit(20)};
  margin-bottom: 0;
`

const StyledDescriptionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledDescriptionToggleContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: ${convertUnit(12)};
  padding-top: ${convertUnit(6)};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: ${convertUnit(8)};
`

const StyledButtonIcon = styled(ButtonIcon)<StyledButtonIconProps>`
  ${({
    theme,
    backgroundColor = 'black',
    backgroundColorOpacity = 0.25,
    backgroundHoverColor = 'primary_5',
    hasBackground = true,
  }) => ({
    backgroundColor: hasBackground
      ? getThemeColor(theme, backgroundColor, backgroundColorOpacity)
      : 'transparent',
    ':hover': {
      backgroundColor: getThemeColor(theme, backgroundHoverColor, 0.25),
    },
  })}
  margin-left: ${({hasDefaultMargin = true}) =>
    hasDefaultMargin ? convertUnit(8) : undefined};
`

const StyledPriceIconContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.info_1,
    border: `${convertUnit(1)} solid ${theme.primary_1}`,
  })}
  display: flex;
  border-radius: ${convertUnit(28)};
  padding: ${convertUnit(2)};
  height: ${convertUnit(28)};
  width: ${convertUnit(28)};
  justify-content: center;
  align-items: center;
`

const StyledPriceTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const StyledSeparator = styled.div`
  height: 100%;
  align-self: stretch;
  margin: 0 ${convertUnit(12)};
  width: ${convertUnit(2)};
  background-color: ${({theme}) => theme.gray_1};
`

const StyledPriceDiscountContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(8)};
  justify-content: flex-start;
  align-items: center;
  margin-top: ${convertUnit(15)};
  width: fit-content;
`

const StyledDescriptionPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: ${convertUnit(34)};
  width: fit-content;
  border-radius: ${convertUnit(17)};
  align-items: center;
  justify-content: center;
`

const StyledDiscountParagraph = styled(Paragraph)`
  cursor: pointer;
  text-decoration: underline;
`

const StyledDescriptionLocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(8)};
  margin-bottom: ${convertUnit(8)};
  cursor: pointer;
  width: fit-content;
`

const StyledDescriptionPrice = styled(Paragraph)`
  margin-left: ${convertUnit(8)};
`

const StyledCrossPrice = styled(Paragraph)`
  margin-left: ${convertUnit(8)};
  text-decoration: line-through;
`

const StyledNameAndRatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: ${convertUnit(8)};
`

const StyledDescriptionCreatorName = styled(Paragraph)`
  display: flex;
  flex: 1;
  width: fit-content;
  margin-left: ${convertUnit(5)};
`

const StyledDescriptionInfoLabel = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
  width: fit-content;
`

const StyledIcon = styled(Icon)`
  margin-top: ${convertUnit(10)};
`

const StyledDescriptionMessage = styled(Paragraph)`
  margin-top: ${convertUnit(30)};
  white-space: normal;
  word-wrap: break-word;
`

const StyledDescriptionReport = styled(Paragraph)`
  margin-top: ${convertUnit(15)};
  cursor: pointer;
  width: fit-content;
`

const StyledParagraph = styled(Paragraph)`
  margin-left: ${convertUnit(8)};
  line-height: 15px;
  width: fit-content;
`

const StyledHiResBadgeContainer = styled.div`
  padding: ${convertUnit(2)} ${convertUnit(8)};
  border-radius: ${convertUnit(6)};
  background-color: ${({theme}) => theme.primary_5};
  width: fit-content;
  display: inline-block;
  margin-right: ${convertUnit(4)};
`

const StyledPreviewBadgeContainer = styled.div`
  padding: ${convertUnit(2)} ${convertUnit(8)};
  border-radius: ${convertUnit(6)};
  background-color: ${({theme}) => getThemeColor(theme, 'white_5', 0.6)};
  width: fit-content;
  display: inline-block;
  margin-right: ${convertUnit(4)};
`

const StyledFoundConfirmedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${convertUnit(12)};
`

const StyledFoundConfirmedItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledFoundConfirmedItem = styled(StyledFoundConfirmedItemsContainer)`
  margin-right: ${convertUnit(12)};
`

const StyledDivider = styled.div`
  height: ${convertUnit(40)};
  border: ${({theme}) => getBorder(0.5, 'solid', theme.gray_1)};
`

const StyledFoundConfirmedIcon = styled(Icon)`
  margin-right: ${convertUnit(4)};
`

const StyledFoundConfirmedParagraph = styled(Paragraph)`
  margin-left: ${convertUnit(4)};
`

const StyledPreviewInfoContainer = styled.div<StyledPreviewInfoContainerProps>`
  background-color: ${({theme, darkBackground}) =>
    darkBackground ? theme.black : getThemeColor(theme, 'white_3', 0.5)};
  border-radius: ${convertUnit(8)};
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin-top: ${convertUnit(10)};
  height: ${convertUnit(42)};
  padding: ${convertUnit(8)} ${convertUnit(16)};
  justify-content: left;
  text-align: left;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    height: ${convertUnit(37)};
  }
`

const StyledIconInfo = styled(Icon)`
  align-self: 'left';
`

const StyledPreviewInfoParagraph = styled(Paragraph)`
  text-align: left;
  align-self: center;
  margin-left: ${convertUnit(16)};
`

const StyledMakeItFree = styled.a<StyledButtonProps>`
  ${({theme, disabled}) => ({
    color: disabled ? theme.gray_3 : theme.primary_5,
  })}
  font-family: Roboto-Bold;
  cursor: pointer;
  font-size: ${convertUnit(12)};
  margin-left: ${convertUnit(4)};
  display: inline-block;
`

const StyledMakeItFreeContainer = styled.div`
  margin-left: ${convertUnit(8)};
  display: inline-block;
`

const StyledResolutionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
`

const StyledRevisionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${convertUnit(8)};
  margin-left: ${convertUnit(8)};
`

const StyledRevision = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${convertUnit(4)};
  cursor: pointer;
`

const StyledArchiveButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(8)};
  align-items: center;
  cursor: pointer;
`

const StyledDateAndArchiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export default function GiftShopTemplateDetailContentItem({
  componentRef,
  showActionButton,
  showReportButton = true,
  showResBadge = true,
  showLowResBadge = false,
  showPreviewInfo = true,
  showImageBadge = true,
  data,
  isHost = false,
  layout,
  hideOverlayWhenExpand = false,
  hideOverlay,
  showPreviewBadge,
  showSkipButton,
  showPrice = true,
  showWishlistButton = true,
  stateData,
  isWishlistPage,
  showPurchaseStatusBadge,
  showFoundConfirmed = false,
  showUnwishlistBadge,
  showDownloadButton,
  showResBadgeOverlay,
  showBuyNowButton,
  showSaveButton,
  resendSource,
  isRemoved,
  showFullOverlay,
  showOverlayPrice,
  isEditable,
  darkBackground,
  darkBackgroundImage,
  removeAfterUnfavorite,
  showFavorite,
  isArchived,
  onSendDM,
  onDelete,
  onEdit,
  onBuyNow,
  onResendpologyDelete,
  fetchOnToggle,
  hideMakeItFree = true,
  showDownscaledImage,
  showDescriptionButton,
  showTextShadow,
  showArchiveButton = false,
  redirectCreator = false,
  fromCreation,
  redirectFototree = true,
}: GiftShopTemplateDetailItemProps) {
  const mode = useWindowMode()
  const {translate} = useTranslation()
  const {
    location,
    member_id,
    is_valid = true,
    is_cart,
    is_reported,
    content_id,
    original_at,
    profile_url,
    price,
    initial_price,
    currency_code,
    title,
    size,
    description,
    resolution,
    creator_name,
    purchased_at,
    purchase_status,
    quality,
    is_deletable,
    found_count,
    found_count_low,
    confirmed_count,
    is_wholesale_active,
    tags,
    type,
    content_type,
    creator_id,
    is_robofind_done,
    creator_rating,
    creator_rating_count,
    wholesale_tier,
    upload_source,
  } = data
  const [toggle, setToggle] = useState(false)
  const [fetchingDetails, setFetchingDetails] = useState(fetchOnToggle)
  const {id, photo: profilePhoto} = useSelector('user') || {}
  const {cart_count} = useSelector('cart')
  const {kycStatus} = useSelector('yuserActivationState')
  const {status: similarStatus} = useSelector('similarAccountStatus')
  const {status: childStatus} = useSelector('parentalConsentState')
  const isSelf = id === member_id
  const stateModalIndex = useState<GiftShopContentDetailModalStates>('hidden')
  const [modalVisible, setModalVisible] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [modalIndex, setModalIndex] = stateModalIndex
  const {update} = useDispatch()
  const history = useHistory()
  const stateSelected = useState<GiftShopGetReportResponse>()
  const [select, setSelect] = stateSelected
  const isLoading = useState(false)
  const nowLoading = isLoading[0]
  const [clicked, setClicked] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const stateBroken = useState(false)
  const broken = stateBroken[0]
  const theme = useTheme()
  const setData = stateData[1]
  const hostState = useSelector('hostState')
  const resendModalState = useState<GiftshopResendModalState>('none')
  const fontColor: ThemeColor = useMemo(
    () => (darkBackground ? 'white_1' : 'black'),
    [darkBackground],
  )
  const stateTypeContent = useState(type)
  const fototreeContent = useMemo(
    () => tags?.find((tag) => tag.type === 'tree'),
    [tags],
  )

  const isPriceDiscount = useMemo(() => isDiscountPrice(price, initial_price), [
    initial_price,
    price,
  ])
  const discountDescToggle = useMemo(
    () =>
      wholesale_tier &&
      wholesale_tier.length > 1 &&
      wholesale_tier[wholesale_tier.length - 1],
    [wholesale_tier],
  )

  const date = useMemo(
    () =>
      typeof original_at === 'string'
        ? original_at
        : new Date(original_at * 1000).toISOString(),
    [original_at],
  )
  const time = useMemo(() => parseDate(date, 'dddd, D MMMM YYYY, HH:mm:ss'), [
    date,
  ])
  const locationState = useLocation(
    'giftshop_transaction_pending_content_detail',
  )
  const parsedLocation = useMemo(() => {
    if (!location) return null

    const fototree = tags?.find((tag) => tag.type === 'tree')

    return {
      isFototree: fototree?.name,
      content: fototree?.name
        ? fototree?.name
        : /^lat: [0-9.-]+ lng: [0-9.-]+$/.test(location.name)
        ? `lat: ${location.latitude} lng: ${location.longitude}`
        : location.name,
    }
  }, [location, tags])
  const statusNow =
    locationState && locationState.state
      ? locationState.state.statusNow
      : undefined

  const handleGetDetailedData = useCallback(async () => {
    if (!fetchOnToggle) return

    setFetchingDetails(true)
    requestData('giftshop_get_creation_detail_new', {
      params: {
        content_id,
      },
      onRequestSuccess: ({data: dataResponse, status}) => {
        if (status === 200) {
          const result = (dataResponse.result as unknown) as GiftShopContentData

          setData((prev) =>
            prev.map((datum) =>
              datum.content_id === result.content_id
                ? {
                    ...datum,
                    ...result,
                  }
                : datum,
            ),
          )
          setFetchingDetails(false)
        }
      },
    })
  }, [content_id, fetchOnToggle, setData])

  const handleResend = useCallback(async () => {
    history.push(
      content_type === 'photo'
        ? 'giftshop_profile_resend_single'
        : 'giftshop_profile_resend_video',
      {
        data,
        source: resendSource,
      },
      data.creator_name,
    )
  }, [content_type, data, history, resendSource])

  const handleToggleDescription = useCallback(() => {
    setToggle((previous) => !previous)
  }, [])

  useEffect(() => {
    if (toggle) handleGetDetailedData()
  }, [handleGetDetailedData, toggle])

  const handleLoading = useCallback(() => {
    if (is_reported === true) {
      setLoadingData(false)
    } else if (nowLoading === true) {
      setLoadingData(true)
    }
  }, [is_reported, nowLoading])

  const handleFeatureDisabled = useCallback(() => {
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    if (kycStatus === 'onhold') {
      if (similarStatus === 'CHOOSE_SELF') {
        return showModalReprocessKyc()
      }
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [childStatus, history, kycStatus, similarStatus])

  const handleDownload = useCallback(() => {
    const hi_res_ids = quality === 'hi-res' ? content_id : undefined
    const low_res_ids = quality !== 'hi-res' ? content_id : undefined
    requestData('giftshop_get_download_collection', {
      useDefaultMessage: true,
      actionType: 'fetch',
      params: {hi_res_ids, low_res_ids},
      onRequestSuccess: ({data: dataResponse, status}) => {
        if (status === 200) {
          const urls = dataResponse.result.map<FileImageUrlOption>((item) => ({
            name: item.title,
            url: item.url,
          }))
          downloadImages(urls)
          if (quality === 'hi-res')
            showSnackbar(translate('giftShop:successDownloadMessageHires'))
          else
            showSnackbar(translate('giftShop:successDownloadMessageNotHires'))
        } else if (status === 400) {
          showSnackbar(translate('giftShop:snackbarFailedDownloadMyCollection'))
        }
      },
    })
  }, [content_id, quality, translate])

  const handleArchiveContent = useCallback(() => {
    requestData('giftshop_create_archives', {
      actionType: 'execute',
      useDefaultMessage: true,
      data: {
        content_ids: [content_id],
      },
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          setData((prev) =>
            prev.filter((item) => item.content_id !== content_id),
          )
          showSnackbar(translate('giftShop:archiveSuccessMessage'))
        }
      },
    })
  }, [content_id, setData, translate])

  const handleReportCartContent = useCallback(() => {
    if (is_cart) {
      update('cart', {
        cart_count: cart_count - 1,
      })
    }
  }, [cart_count, is_cart, update])

  const handleRedirectCreator = useCallback(() => {
    redirectCreator
      ? history.pushQuery({
          path: 'giftshop_search_by_creator_creation_content',
          state: {
            photo: profile_url,
            bio: '',
            shouldFetchData: true,
          },
          queryObject: {
            creator_id,
            username: creator_name,
          },
        })
      : undefined
  }, [creator_id, creator_name, history, profile_url, redirectCreator])

  const handleRenderDescriptionButtonSelf = useMemo(
    () =>
      !broken && is_valid ? (
        !is_deletable &&
        !fetchingDetails && (
          <StyledButtonIcon
            hasShadow={false}
            hasBackground={false}
            iconType="pencil"
            iconColor="black"
            onClick={onEdit}
          />
        )
      ) : (
        <StyledButtonIcon
          hasShadow={false}
          hasBackground={false}
          iconType="trash"
          iconColor="black"
          onClick={onDelete}
        />
      ),
    [broken, fetchingDetails, is_deletable, is_valid, onDelete, onEdit],
  )

  const handleMessageButton = useCallback(() => {
    if (
      childStatus === 'UNDERAGE' ||
      !(
        kycStatus === 'verified' ||
        kycStatus === 'verified_check' ||
        kycStatus === 'onhold'
      )
    ) {
      handleFeatureDisabled()
      return
    }
    onSendDM
      ? onSendDM()
      : history.push('giftshop_message_room', {
          participantId: member_id,
          roomType: 'giftshop',
        })
  }, [
    childStatus,
    handleFeatureDisabled,
    history,
    kycStatus,
    member_id,
    onSendDM,
  ])

  const handleRenderDescriptionButtonOther = useMemo(() => {
    if (fetchingDetails) return <></>
    if (
      !(isHost && !purchased_at && (isEditable || isSelf)) &&
      purchase_status !== 'available' &&
      purchase_status !== 'all'
    ) {
      return (
        <StyledButtonIcon
          hasShadow={false}
          hasBackground={darkBackground}
          iconType="message"
          iconColor={darkBackground ? 'white_1' : 'primary_5'}
          backgroundColorOpacity={darkBackground ? 1 : undefined}
          backgroundColor={darkBackground ? 'primary_5' : undefined}
          onClick={handleMessageButton}
        />
      )
    }
    if (isHost && isSelf && isEditable && purchase_status === 'available') {
      return (
        <StyledButtonIcon
          hasShadow={false}
          hasBackground={false}
          iconType="pencil"
          iconColor="black"
          onClick={onEdit}
        />
      )
    }
    return <></>
  }, [
    darkBackground,
    handleMessageButton,
    isEditable,
    isHost,
    fetchingDetails,
    isSelf,
    onEdit,
    purchase_status,
    purchased_at,
  ])

  const handleRenderDescriptionButton = useMemo(
    () => (
      <StyledDescriptionButtonContainer>
        {!showDescriptionButton || !!isRemoved ? (
          <></>
        ) : (purchase_status === 'all' || purchase_status === 'available') &&
          isSelf &&
          !purchased_at &&
          isEditable ? (
          handleRenderDescriptionButtonSelf
        ) : (
          handleRenderDescriptionButtonOther
        )}
      </StyledDescriptionButtonContainer>
    ),
    [
      showDescriptionButton,
      isRemoved,
      purchase_status,
      isSelf,
      purchased_at,
      isEditable,
      handleRenderDescriptionButtonSelf,
      handleRenderDescriptionButtonOther,
    ],
  )

  const handleRenderDescriptionContentMain = useMemo(
    () => (
      <StyledSectionDescriptionMain>
        <StyledSectionDescriptionCreator
          onClick={handleRedirectCreator}
          clickable={redirectCreator}>
          <Avatar
            size={32}
            src={
              fromCreation
                ? profilePhoto || getDefaultAvatar()
                : profile_url || getDefaultAvatar()
            }
            alt={creator_name}
          />
          <StyledNameAndRatingContainer>
            <StyledDescriptionCreatorName
              fontSize="m"
              fontWeight="medium"
              color={fontColor}>
              {creator_name}
            </StyledDescriptionCreatorName>
            <Rating value={creator_rating} ratingCount={creator_rating_count} />
          </StyledNameAndRatingContainer>
        </StyledSectionDescriptionCreator>
        {statusNow !== 'PROCESSED' &&
          !is_reported &&
          !loadingData &&
          handleRenderDescriptionButton}
      </StyledSectionDescriptionMain>
    ),
    [
      handleRedirectCreator,
      redirectCreator,
      fromCreation,
      profilePhoto,
      profile_url,
      creator_name,
      fontColor,
      creator_rating,
      creator_rating_count,
      statusNow,
      is_reported,
      loadingData,
      handleRenderDescriptionButton,
    ],
  )

  const handleRenderDescriptionInfoPrice = useMemo(
    () =>
      showPrice &&
      (typeof price !== 'undefined' || fetchingDetails) && (
        <StyledPriceDiscountContainer>
          <StyledDescriptionPriceContainer>
            <StyledPriceIconContainer>
              <Icon type="price-tags" color="primary_5" size={16} />
            </StyledPriceIconContainer>
            <StyledPriceTextContainer>
              {!fetchingDetails && typeof price !== 'undefined' ? (
                <StyledDescriptionPrice
                  fontWeight="bold"
                  color={darkBackground ? 'white_1' : 'primary_5'}>
                  {getCurrencyValue(price, currency_code || undefined)}
                </StyledDescriptionPrice>
              ) : (
                <Skeleton
                  width={100}
                  height={16}
                  baseColor={theme.gray_2}
                  highlightColor={theme.white_1}
                />
              )}
              {isPriceDiscount && initial_price && (
                <StyledCrossPrice fontSize="xs" color="gray_4">
                  {getCurrencyValue(initial_price, currency_code || undefined)}
                </StyledCrossPrice>
              )}
            </StyledPriceTextContainer>
          </StyledDescriptionPriceContainer>
          {is_wholesale_active &&
            wholesale_tier &&
            wholesale_tier.length > 1 &&
            type !== 'collection' &&
            !isRemoved &&
            !isArchived && (
              <>
                <StyledSeparator />
                <StyledPriceIconContainer>
                  <Icon type="percentage" color="primary_5" size={16} />
                </StyledPriceIconContainer>
                <StyledPriceTextContainer>
                  <Paragraph
                    fontSize="xs"
                    color={darkBackground ? 'white_1' : 'primary_5'}>
                    {translate('giftShop:discountText', {
                      count: wholesale_tier[1].min_contents || 0,
                      username: data.creator_name,
                    })}
                  </Paragraph>
                  <StyledDiscountParagraph
                    fontSize="xs"
                    fontWeight="bold"
                    color={darkBackground ? 'white_1' : 'primary_5'}
                    onClick={() => setModalIndex('wholesale')}>
                    {translate('giftShop:discountPercent', {
                      count: wholesale_tier[1].discount || 0,
                    })}
                  </StyledDiscountParagraph>
                </StyledPriceTextContainer>
              </>
            )}
        </StyledPriceDiscountContainer>
      ),
    [
      currency_code,
      darkBackground,
      data.creator_name,
      fetchingDetails,
      initial_price,
      isArchived,
      isPriceDiscount,
      isRemoved,
      is_wholesale_active,
      price,
      setModalIndex,
      showPrice,
      theme.gray_2,
      theme.white_1,
      translate,
      type,
      wholesale_tier,
    ],
  )

  const handleRenderDescriptionInfoTitle = useMemo(
    () =>
      title && (
        <StyledDescriptionInfoLabel
          fontSize="l"
          fontWeight="bold"
          color={fontColor}>
          {title}
        </StyledDescriptionInfoLabel>
      ),
    [fontColor, title],
  )

  const handleRenderDescriptionInfoLocation = useMemo(
    () =>
      parsedLocation && (
        <StyledDescriptionLocationContainer
          onClick={() =>
            parsedLocation.isFototree
              ? redirectFototree
                ? history.pushQuery({
                    path: 'tree_events_bib_masonry',
                    state: {
                      tree_name: fototreeContent?.name || '',
                      tree_url: '',
                      fromScan: false,
                    },
                    queryObject: {
                      search: '',
                      tree_id: '',
                      tag_id: fototreeContent?.id || '',
                    },
                  })
                : history.goBack()
              : setClicked(true)
          }>
          <StyledIcon
            type={parsedLocation.isFototree ? 'tree-feature' : 'mark'}
            size={15}
            color={darkBackground ? 'white_1' : 'primary_5'}
          />
          <StyledParagraph
            fontSize="s"
            fontWeight="medium"
            color={darkBackground ? 'white_1' : 'primary_5'}>
            {parsedLocation.content}
          </StyledParagraph>
        </StyledDescriptionLocationContainer>
      ),
    [
      darkBackground,
      fototreeContent?.id,
      fototreeContent?.name,
      history,
      parsedLocation,
      redirectFototree,
    ],
  )

  const handleRenderArchiveButton = useMemo(
    () =>
      showArchiveButton && type !== 'collection' ? (
        <StyledArchiveButtonContainer onClick={handleArchiveContent}>
          <Icon type="archive-in" size={16} color="primary_5" />
          <Paragraph fontWeight="bold" color="primary_5">
            {translate('giftShop:archive')}
          </Paragraph>
        </StyledArchiveButtonContainer>
      ) : (
        <></>
      ),
    [handleArchiveContent, showArchiveButton, translate, type],
  )

  const handleRenderDescriptionInfoTime = useMemo(
    () => (
      <Paragraph fontSize="s" color={fontColor}>
        {time}
      </Paragraph>
    ),
    [fontColor, time],
  )

  const handleRenderDescriptionInfoTimeAndArchiveBtn = useMemo(
    () => (
      <StyledDateAndArchiveContainer>
        {handleRenderDescriptionInfoTime}
        {handleRenderArchiveButton}
      </StyledDateAndArchiveContainer>
    ),
    [handleRenderArchiveButton, handleRenderDescriptionInfoTime],
  )

  const handleRenderUnwishlistBadge = useMemo(
    () =>
      showUnwishlistBadge &&
      is_deletable && (
        <GiftShopTemplateContentDetailBadge
          isDeletable={is_deletable}
          type={undefined}
          badgeStyle={{
            backgroundColor: `${getThemeColor(theme, 'black', 0.5)}`,
          }}
        />
      ),
    [showUnwishlistBadge, is_deletable, theme],
  )

  const handleRenderResBadge = useMemo(() => {
    if (showResBadge && upload_source === 'sell') {
      if (quality === 'hi-res') {
        return (
          <StyledHiResBadgeContainer>
            <Paragraph
              fontSize={mode === 'website' ? 'm' : 's'}
              fontWeight="medium"
              color="white_1">
              {translate('giftShop:collectionHiResBadge')}
            </Paragraph>
          </StyledHiResBadgeContainer>
        )
      }
      if (showLowResBadge && quality === 'low-res') {
        return (
          <StyledPreviewBadgeContainer>
            <Paragraph
              fontSize={mode === 'website' ? 's' : 'xs'}
              fontWeight="medium"
              color="black">
              {translate('giftShop:collectionPreviewBadge')}
            </Paragraph>
          </StyledPreviewBadgeContainer>
        )
      }
      return <></>
    }
    return <></>
  }, [showResBadge, upload_source, quality, showLowResBadge, mode, translate])

  const handleRenderPreviewInfo = useMemo(
    () =>
      showPreviewInfo &&
      upload_source === 'sell' && (
        <>
          {quality === 'not-hi-res' ? (
            <StyledPreviewInfoContainer darkBackground={darkBackground}>
              <StyledIconInfo
                type="info"
                size={mode === 'mobile' ? 's' : 'm'}
                color={fontColor}
              />
              <StyledPreviewInfoParagraph
                fontSize={mode === 'mobile' ? 'xs' : 's'}
                fontWeight="medium"
                color={fontColor}>
                {translate('giftShop:previewInfoLabel', {
                  context: content_type,
                })}
              </StyledPreviewInfoParagraph>
            </StyledPreviewInfoContainer>
          ) : undefined}
        </>
      ),
    [
      content_type,
      darkBackground,
      fontColor,
      mode,
      quality,
      showPreviewInfo,
      translate,
      upload_source,
    ],
  )

  const handleRenderFoundComponent = useCallback(
    ({topIcon, topText, bottomIcon, bottomText}: FoundComponentProps) => (
      <div>
        <StyledFoundConfirmedItem>
          <StyledFoundConfirmedIcon
            type={topIcon}
            size={convertUnit(16)}
            color={bottomIcon ? 'primary_5' : 'success_5'}
          />
          <StyledFoundConfirmedParagraph
            fontSize="s"
            fontWeight="medium"
            color={bottomIcon ? 'primary_5' : 'success_5'}>
            {topText}
          </StyledFoundConfirmedParagraph>
        </StyledFoundConfirmedItem>
        <StyledFoundConfirmedItem>
          {bottomIcon && (
            <StyledFoundConfirmedIcon
              type={bottomIcon}
              size={convertUnit(16)}
              color="primary_4"
            />
          )}
          <StyledFoundConfirmedParagraph
            fontSize="s"
            fontWeight="medium"
            color={bottomIcon ? 'primary_4' : 'success_5'}>
            {bottomText}
          </StyledFoundConfirmedParagraph>
        </StyledFoundConfirmedItem>
      </div>
    ),
    [],
  )

  const handleRenderFoundConfirmed = useMemo(
    () =>
      showFoundConfirmed ? (
        <StyledFoundConfirmedContainer>
          <Paragraph fontSize="s" fontWeight="bold">
            {translate('giftShop:foundByLabel')}
          </Paragraph>
          <StyledFoundConfirmedItemsContainer>
            {handleRenderFoundComponent({
              topIcon: 'found-up',
              topText: `${is_robofind_done ? found_count : '-'} ${translate(
                'global:yuser',
                {
                  count: found_count,
                },
              )}`,
              bottomIcon: 'robopet',
              bottomText: translate('giftShop:roboyuAccuracyPlus'),
            })}
            <StyledFoundConfirmedItem>
              <StyledDivider />
            </StyledFoundConfirmedItem>
            {handleRenderFoundComponent({
              topIcon: 'found-down',
              topText: `${is_robofind_done ? found_count_low : '-'} ${translate(
                'global:yuser',
                {
                  count: found_count_low,
                },
              )}`,
              bottomIcon: 'robopet',
              bottomText: translate('giftShop:roboyuAccuracyMinus'),
            })}
            <StyledFoundConfirmedItem>
              <StyledDivider />
            </StyledFoundConfirmedItem>
            {handleRenderFoundComponent({
              topIcon: 'fotoyu',
              topText: `${is_robofind_done ? confirmed_count : '-'} ${translate(
                'global:confirmed',
              )}`,
              bottomText: translate('global:confirmed'),
            })}
          </StyledFoundConfirmedItemsContainer>
        </StyledFoundConfirmedContainer>
      ) : (
        <></>
      ),
    [
      confirmed_count,
      found_count,
      found_count_low,
      handleRenderFoundComponent,
      is_robofind_done,
      showFoundConfirmed,
      translate,
    ],
  )

  const handleMakeItFreeButton = useCallback(() => {
    setModalVisible(true)
  }, [])

  const handleMakeItFree = useCallback(() => {
    requestData('giftshop_make_it_free', {
      data: {
        content_id: data.content_id,
      },
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          setDisabled(true)
          setData((prev) =>
            prev.filter((content) => content.content_id !== content_id),
          )
          history.goBack()
          showSnackbar(translate('giftShop:makeItFreeSnackBarSuccess'))
        } else {
          showSnackbar(translate('giftShop:snackbarFailedExecute'))
        }
      },
    })
  }, [content_id, data.content_id, history, setData, translate])

  const handleRenderConfirmation = useMemo(
    () => (
      <ConfirmationModal
        visible={modalVisible}
        title={translate('giftShop:makeItFree')}
        text={translate('giftShop:makeItFreeDescription')}
        onConfirm={handleMakeItFree}
        toggleModal={() => setModalVisible((previous) => !previous)}
        cancelText={translate('global:cancel')}
        confirmText={translate('global:yesImSure')}
      />
    ),
    [handleMakeItFree, modalVisible, translate],
  )

  const handleRenderMakeItFree = useMemo(
    () =>
      purchase_status === 'resend' &&
      hideMakeItFree === false && (
        <>
          <StyledMakeItFreeContainer>
            <Icon type="dot" size={convertUnit(5)} color="black" />
            <StyledMakeItFree
              onClick={disabled ? undefined : handleMakeItFreeButton}
              disabled={disabled}>
              <Icon
                type="giftshop"
                size={convertUnit(13.5)}
                color={disabled ? 'gray_3' : 'primary_5'}
              />
              {translate('giftShop:makeItFree')}
            </StyledMakeItFree>
          </StyledMakeItFreeContainer>
        </>
      ),
    [
      disabled,
      handleMakeItFreeButton,
      hideMakeItFree,
      purchase_status,
      translate,
    ],
  )

  const handleRenderRevision = useMemo(
    () =>
      purchase_status === 'sold' && !showDownloadButton ? (
        <StyledRevisionContainer>
          <Icon type="dot" size={convertUnit(5)} />
          <StyledRevision onClick={() => setModalIndex('resend_revision')}>
            <Icon
              type="compose-message"
              size={convertUnit(16)}
              color="primary_5"
            />
            <Paragraph fontWeight="medium" color="primary_5">
              {translate('global:revision')}
            </Paragraph>
          </StyledRevision>
        </StyledRevisionContainer>
      ) : (
        <></>
      ),
    [purchase_status, setModalIndex, showDownloadButton, translate],
  )

  const handleRenderDescriptionInfoResolution = useMemo(
    () =>
      resolution && (
        <>
          <StyledDescriptionInfoLabel
            fontSize="s"
            fontWeight="bold"
            color={fontColor}>
            {translate('giftShop:resolution')}
          </StyledDescriptionInfoLabel>
          <StyledResolutionContainer>
            <Paragraph fontSize="s" color={fontColor}>
              {handleRenderResBadge} {resolution.width} x {resolution.height},{' '}
              {getFileSize(size)}
              {handleRenderMakeItFree}
            </Paragraph>
            {handleRenderRevision}
          </StyledResolutionContainer>
          {handleRenderPreviewInfo}
        </>
      ),
    [
      fontColor,
      handleRenderMakeItFree,
      handleRenderPreviewInfo,
      handleRenderResBadge,
      handleRenderRevision,
      resolution,
      size,
      translate,
    ],
  )

  const handleRenderDescriptionMessage = useMemo(
    () =>
      description && (
        <StyledDescriptionMessage fontSize="s" color={fontColor}>
          {description}
        </StyledDescriptionMessage>
      ),
    [description, fontColor],
  )

  const handleRenderDescriptionReportButton = useMemo(
    () =>
      showDescriptionButton &&
      showReportButton &&
      type !== 'collection' && (
        <StyledDescriptionReport
          fontSize="m"
          fontWeight="medium"
          color="gray_3"
          onClick={() =>
            !(kycStatus === 'verified' || kycStatus === 'verified_check')
              ? handleFeatureDisabled()
              : setModalIndex('report')
          }>
          {translate('giftShop:contentDetailReportContent')}
        </StyledDescriptionReport>
      ),
    [
      showDescriptionButton,
      showReportButton,
      type,
      translate,
      kycStatus,
      handleFeatureDisabled,
      setModalIndex,
    ],
  )

  const handleRenderDescriptionContent = useMemo(
    () => (
      <StyledDescriptionContainer toggle={toggle}>
        {handleRenderDescriptionContentMain}
        <StyledSectionDescriptionInfo>
          {handleRenderDescriptionInfoPrice}
          {handleRenderDescriptionInfoTitle}
          {handleRenderDescriptionInfoLocation}
          {handleRenderDescriptionInfoTimeAndArchiveBtn}
          {handleRenderUnwishlistBadge}
          {handleRenderFoundConfirmed}
          {handleRenderDescriptionInfoResolution}
        </StyledSectionDescriptionInfo>
        {handleRenderDescriptionMessage}
        {!is_reported && handleRenderDescriptionReportButton}
      </StyledDescriptionContainer>
    ),
    [
      toggle,
      handleRenderDescriptionContentMain,
      handleRenderDescriptionInfoPrice,
      handleRenderDescriptionInfoTitle,
      handleRenderDescriptionInfoLocation,
      handleRenderDescriptionInfoTimeAndArchiveBtn,
      handleRenderUnwishlistBadge,
      handleRenderFoundConfirmed,
      handleRenderDescriptionInfoResolution,
      handleRenderDescriptionMessage,
      is_reported,
      handleRenderDescriptionReportButton,
    ],
  )

  const handleLoadingReportedDescriptionContent = useMemo(
    () => (
      <StyledDescriptionContainer toggle={toggle}>
        {handleRenderDescriptionContentMain}
        <StyledSectionDescriptionInfo>
          {handleRenderDescriptionInfoPrice}
          {handleRenderDescriptionInfoTitle}
          {handleRenderDescriptionInfoLocation}
          {handleRenderDescriptionInfoTime}
          {handleRenderDescriptionInfoResolution}
        </StyledSectionDescriptionInfo>
        {handleRenderDescriptionMessage}
      </StyledDescriptionContainer>
    ),
    [
      handleRenderDescriptionContentMain,
      handleRenderDescriptionInfoLocation,
      handleRenderDescriptionInfoPrice,
      handleRenderDescriptionInfoResolution,
      handleRenderDescriptionInfoTime,
      handleRenderDescriptionInfoTitle,
      handleRenderDescriptionMessage,
      toggle,
    ],
  )

  const handleRenderDescriptionToogle = useMemo(
    () => (
      <StyledDescriptionToggleContainer onClick={handleToggleDescription}>
        {wholesale_tier &&
          type !== 'collection' &&
          is_wholesale_active &&
          discountDescToggle && (
            <Paragraph
              fontSize="xs"
              color={darkBackground ? 'white_1' : 'primary_5'}>
              {translate('giftShop:contentDetailDiscountInformation', {
                content_count: discountDescToggle.min_contents,
                creator_username: creator_name,
                discount: discountDescToggle.discount,
              })}
            </Paragraph>
          )}
        <Icon
          type={toggle ? 'drop-up' : 'drop-down'}
          color={darkBackground ? 'white_1' : 'primary_5'}
          size={16}
        />
      </StyledDescriptionToggleContainer>
    ),
    [
      creator_name,
      darkBackground,
      discountDescToggle,
      handleToggleDescription,
      is_wholesale_active,
      toggle,
      translate,
      type,
      wholesale_tier,
    ],
  )

  const handleRenderDescription = useMemo(
    () =>
      !is_reported && (
        <StyledSectionDescription darkBackground={darkBackground}>
          {handleRenderDescriptionContent}
          {handleRenderDescriptionToogle}
        </StyledSectionDescription>
      ),
    [
      darkBackground,
      handleRenderDescriptionContent,
      handleRenderDescriptionToogle,
      is_reported,
    ],
  )

  const handleRenderImage = useMemo(
    () =>
      !is_reported && (
        <GiftShopTemplateDetailContentItemImage
          isLoading={loadingData}
          showResBadge={showImageBadge}
          showPreviewBadge={showPreviewBadge}
          data={data}
          stateData={stateData}
          isHost={isHost}
          layout={layout}
          showActionButton={showActionButton}
          hideOverlayWhenExpand={hideOverlayWhenExpand}
          hideOverlay={hideOverlay}
          showSkipButton={showSkipButton}
          toggle={toggle}
          stateBroken={stateBroken}
          showWishlistButton={showWishlistButton}
          isWishlistPage={isWishlistPage}
          showPurchaseStatusBadge={showPurchaseStatusBadge}
          showUnwishlistBadge={showUnwishlistBadge}
          showDownloadButton={showDownloadButton}
          showResBadgeOverlay={showResBadgeOverlay}
          showBuyNowButton={showBuyNowButton}
          showSaveButton={showSaveButton}
          isRemoved={isRemoved}
          showFullOverlay={showFullOverlay}
          showOverlayPrice={showOverlayPrice}
          showDownscaledImage={showDownscaledImage}
          isEditable={isEditable}
          darkBackground={darkBackground || darkBackgroundImage}
          removeAfterUnfavorite={removeAfterUnfavorite}
          showFavorite={showFavorite}
          isArchived={isArchived}
          showTextShadow={showTextShadow}
          redirectCreator={redirectCreator}
          fromCreation={fromCreation}
          onRedirectCreator={handleRedirectCreator}
          handleResend={handleResend}
          onToggle={handleToggleDescription}
          onDelete={onDelete}
          onEdit={onEdit}
          onBuyNow={onBuyNow}
          onDownload={handleDownload}
          onResendpologyDelete={onResendpologyDelete}
          showEdit={false}
          stateTypeContent={stateTypeContent}
        />
      ),
    [
      is_reported,
      loadingData,
      showImageBadge,
      showPreviewBadge,
      data,
      stateData,
      isHost,
      layout,
      showActionButton,
      hideOverlayWhenExpand,
      hideOverlay,
      showSkipButton,
      toggle,
      stateBroken,
      showWishlistButton,
      isWishlistPage,
      showPurchaseStatusBadge,
      showUnwishlistBadge,
      showDownloadButton,
      showResBadgeOverlay,
      showBuyNowButton,
      showSaveButton,
      isRemoved,
      showFullOverlay,
      showOverlayPrice,
      showDownscaledImage,
      isEditable,
      darkBackground,
      darkBackgroundImage,
      removeAfterUnfavorite,
      showFavorite,
      isArchived,
      showTextShadow,
      redirectCreator,
      fromCreation,
      handleRedirectCreator,
      handleResend,
      handleToggleDescription,
      onDelete,
      onEdit,
      onBuyNow,
      handleDownload,
      onResendpologyDelete,
      stateTypeContent,
    ],
  )

  const handleLoadingReportedImage = useMemo(
    () => (
      <GiftShopTemplateDetailContentItemImage
        isLoading={loadingData}
        data={data}
        layout={layout}
        hideOverlayWhenExpand
        hideOverlay
        toggle={false}
        stateBroken={stateBroken}
        onToggle={handleToggleDescription}
        stateData={stateData}
        stateTypeContent={stateTypeContent}
      />
    ),
    [
      loadingData,
      data,
      layout,
      stateBroken,
      handleToggleDescription,
      stateData,
      stateTypeContent,
    ],
  )

  const handleLoadingReportedDescription = useMemo(
    () => (
      <StyledSectionDescription darkBackground={darkBackground}>
        {handleLoadingReportedDescriptionContent}
        {handleRenderDescriptionToogle}
      </StyledSectionDescription>
    ),
    [
      darkBackground,
      handleLoadingReportedDescriptionContent,
      handleRenderDescriptionToogle,
    ],
  )

  const handleLoadingReportedContent = useMemo(
    () => (
      <>
        {handleLoadingReportedImage}
        {handleLoadingReportedDescription}
      </>
    ),
    [handleLoadingReportedDescription, handleLoadingReportedImage],
  )

  const handleResendHiRes = useMemo(
    () =>
      toggle &&
      !showDownloadButton &&
      ((hostState &&
        (hostState.role === 'admin' || hostState.role === 'leader')) ||
        (hostState && isSelf)) &&
      (purchase_status === 'resend' || purchase_status === 'resendpology') && (
        <StyledResendButton
          fontSize={mode === 'website' ? 'm' : 's'}
          fontWeight="medium"
          color="white_1"
          onClick={handleResend}>
          {translate('giftShop:resendToYuser')}
        </StyledResendButton>
      ),
    [
      toggle,
      showDownloadButton,
      hostState,
      isSelf,
      purchase_status,
      mode,
      handleResend,
      translate,
    ],
  )

  const handleRenderModalResendStatus = useMemo(
    () => (
      <GiftshopTemplateUploadResendModal resendModalState={resendModalState} />
    ),
    [resendModalState],
  )

  const handleRenderContent = useMemo(
    () => (
      <>
        {handleRenderImage}
        {handleResendHiRes}
        {handleRenderDescription}
        {handleRenderModalResendStatus}
      </>
    ),
    [
      handleRenderDescription,
      handleRenderImage,
      handleRenderModalResendStatus,
      handleResendHiRes,
    ],
  )

  const handleRenderWholesaleDiscountModal = useMemo(
    () => (
      <GiftShopTemplateWholesaleDiscountModal
        visible
        creatorData={{
          memberId: member_id,
          photo: profile_url,
          username: creator_name,
        }}
        toggleModal={() => setModalIndex('hidden')}
      />
    ),
    [creator_name, member_id, profile_url, setModalIndex],
  )

  const handleRenderResendRevisionModal = useMemo(
    () => (
      <GiftShopTemplateRevisionModal
        contentId={content_id}
        onClose={() => setModalIndex('hidden')}
        onRevision={(revData) => {
          const {
            collectorId,
            contentId,
            originalUrl,
            hiresUrl,
            nickname,
            invoiceId,
            contentType,
            originalSize,
            hiresSize,
            originalDuration,
            hiresDuration,
            originalResolution,
            hiresResolution,
          } = revData

          return history.push('giftshop_resend_revision', {
            collectorId,
            contentId,
            originalUrl,
            hiresUrl,
            collectorNickname: nickname,
            title,
            originalAt: original_at,
            invoiceId,
            contentType,
            originalSize,
            hiresSize,
            originalDuration,
            hiresDuration,
            originalResolution,
            hiresResolution,
          })
        }}
      />
    ),
    [content_id, history, original_at, setModalIndex, title],
  )

  const handleRenderModal = useMemo(() => {
    switch (modalIndex) {
      case 'share':
        return (
          <GiftShopShareContentModal
            onClickBackDrop={() => setModalIndex('hidden')}
            onCancel={() => setModalIndex('hidden')}
            dataContent={data}
          />
        )
      case 'confirmation':
        return (
          <GiftShopReportCancelConfirmation
            stateModalIndex={stateModalIndex}
            onCancel={() => setSelect(undefined)}
          />
        )
      case 'report':
        return (
          <GiftShopReportContent
            memberId={member_id}
            contentId={content_id}
            stateLoading={isLoading}
            stateSelected={stateSelected}
            onConfirm={() => {
              handleReportCartContent()
              history.goBack()
            }}
            onCancel={() =>
              select !== undefined
                ? setModalIndex('confirmation')
                : setModalIndex('hidden')
            }
          />
        )
      case 'wholesale':
        return handleRenderWholesaleDiscountModal
      case 'resend_revision':
        return handleRenderResendRevisionModal
      case 'hidden':
        return null
    }
  }, [
    modalIndex,
    data,
    stateModalIndex,
    member_id,
    content_id,
    isLoading,
    stateSelected,
    handleRenderWholesaleDiscountModal,
    handleRenderResendRevisionModal,
    setModalIndex,
    setSelect,
    handleReportCartContent,
    history,
    select,
  ])

  const handleRenderLocationMap = useMemo(
    () => (
      <GiftShopTemplateDetailLocation
        visible={clicked}
        location={location}
        handleClick={() => setClicked((prev) => !prev)}
      />
    ),
    [clicked, location],
  )

  useEffect(() => {
    handleLoading()
  }, [handleLoading])

  return (
    <>
      {handleRenderConfirmation}
      <StyledContainer mode={mode} ref={componentRef}>
        {loadingData ? handleLoadingReportedContent : handleRenderContent}
        {handleRenderModal}
        {handleRenderLocationMap}
      </StyledContainer>
    </>
  )
}
