import React, {useState, useCallback, useMemo, useEffect, useRef} from 'react'
import styled from 'styled-components'
import ReactHlsPlayer from '@gumlet/react-hls-player'
import {
  FORKYGRAM_UPLOAD_MAX_VIDEO_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {getThemeColor} from 'utils'
import {useWindowLayout, useWindowMode} from 'windows'
import {Icon, ModalFullscreen, ProgressBar} from 'common/components'
import convertUnit from 'lib/unit'
import {ForkygramTemplatePostAudioPlayPause} from 'pages/forkygram'
import {FullscreenVideoIntroModalProps} from './FototreeEditProfileModalProps'

interface StyledRelativeProps {
  width: number
}

interface StyledContentProps {
  height: number
}

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledContainer = styled(StyledRowContainer)`
  height: 100vh;
  width: 100%;

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    height: 100%;
  }
`

const StyledRelative = styled.div<StyledRelativeProps>`
  position: relative;
  height: fit-content;
  overflow-x: hidden;
  width: ${({width}) => convertUnit(width)};
  background-color: ${({theme}) => theme.gray_9};
`

const StyledContentContainer = styled.div<StyledContentProps>`
  position: relative;
  display: flex;
  justify-content: center;
  height: ${({height}) => convertUnit(height)};
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  max-width: ${convertUnit(FORKYGRAM_UPLOAD_MAX_VIDEO_WIDTH)};
  background-color: ${({theme}) => theme.black};
  margin: auto;
`

const StyledAbsoluteContainer = styled.div`
  top: ${convertUnit(16)};
  position: absolute;
  cursor: pointer;
  z-index: 2;
`

const StyledBackIconContainer = styled(StyledAbsoluteContainer)`
  left: ${convertUnit(16)};
`

const StyledVideo = styled(ReactHlsPlayer)<StyledContentProps>`
  max-width: 100%;
  object-fit: scale-down;
  max-height: ${({height}) => convertUnit(height)};
`

const StyledLocalVideo = styled.video`
  max-width: 100%;
  object-fit: scale-down;
`

const StyledRoundedIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.4)};
  width: ${convertUnit(42)};
  height: ${convertUnit(42)};
  border-radius: ${convertUnit(28)};
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => getThemeColor(theme, 'gray_1', 0.4)};
  }
`

const StyledProgressBarContainer = styled.div`
  right: ${convertUnit(0)};
  bottom: ${convertUnit(0)};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  max-width: ${convertUnit(FORKYGRAM_UPLOAD_MAX_VIDEO_WIDTH)};
`

export default function FullscreenVideoIntroModal({
  stateModal,
  src,
  isLocal = false,
}: FullscreenVideoIntroModalProps) {
  const statePause = useState(false)
  const {width, height} = useWindowLayout()
  const mode = useWindowMode()
  const videoRef = useRef<HTMLVideoElement>(null)
  const [progress, setProgress] = useState(0)
  const [pause, setPause] = statePause
  const timestamp = useRef(new Date().getTime())
  const [showButton, setShowButton] = useState(false)
  const toggleTimeout = 500
  const [modal, setModal] = stateModal

  const handleHideButton = useCallback(() => {
    const now = new Date().getTime()
    const dif = now - timestamp.current

    if (dif >= toggleTimeout) {
      setShowButton(false)
    }
  }, [toggleTimeout])

  const handleToggleVideo = useCallback(() => {
    timestamp.current = new Date().getTime()

    setPause((previous) => !previous)
    setShowButton(true)

    setTimeout(handleHideButton, toggleTimeout)
  }, [toggleTimeout, setPause, handleHideButton])

  const handleVideoTimeUpdate = useCallback<
    React.ReactEventHandler<HTMLVideoElement>
  >((event) => {
    const {currentTime, duration} = event.currentTarget
    const extra = (currentTime / duration) * 1

    setProgress(Math.min(100, (currentTime + extra) / duration) * 100)
  }, [])

  const handleBlur = useCallback(() => {
    videoRef.current && videoRef.current.pause()
  }, [])

  const handleFocus = useCallback(() => {
    videoRef.current && videoRef.current.play()
  }, [])

  const handleFocusEvent = useCallback(() => {
    window.addEventListener('blur', handleBlur)
    window.addEventListener('focus', handleFocus)

    return () => {
      window.removeEventListener('blur', handleBlur)
      window.removeEventListener('focus', handleFocus)
    }
  }, [handleBlur, handleFocus])

  const handleRenderBackIcon = useMemo(
    () => (
      <StyledBackIconContainer>
        <StyledRoundedIcon>
          <Icon
            type="back"
            size={convertUnit(20)}
            color="white_1"
            onClick={() => setModal(false)}
          />
        </StyledRoundedIcon>
      </StyledBackIconContainer>
    ),
    [setModal],
  )

  const handleRenderContent = useMemo(
    () =>
      isLocal ? (
        <StyledLocalVideo autoPlay={false} controls>
          <source src={src} type="video/mp4" />
          <source src={src} type="video/mov" />
          <source src={src} type="video/heiv" />
        </StyledLocalVideo>
      ) : (
        <>
          <StyledVideo
            autoPlay
            key={src}
            src={src}
            height={mode === 'website' ? height - 5 : height - 69}
            playerRef={videoRef}
            onClick={handleToggleVideo}
            onTimeUpdate={handleVideoTimeUpdate}
          />
          <ForkygramTemplatePostAudioPlayPause
            showButton={showButton}
            pause={pause}
          />
        </>
      ),
    [
      isLocal,
      src,
      mode,
      height,
      handleToggleVideo,
      handleVideoTimeUpdate,
      showButton,
      pause,
    ],
  )

  useEffect(handleFocusEvent, [handleFocusEvent])

  useEffect(() => {
    if (videoRef.current) {
      if (!pause) {
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }, [pause])

  return (
    <ModalFullscreen visible={modal}>
      <StyledContainer>
        <StyledRelative width={width}>
          <StyledContentContainer height={height}>
            {handleRenderBackIcon}
            {handleRenderContent}
            <StyledProgressBarContainer>
              <ProgressBar progress={progress} />
            </StyledProgressBarContainer>
          </StyledContentContainer>
        </StyledRelative>
      </StyledContainer>
    </ModalFullscreen>
  )
}
