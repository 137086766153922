import React, {useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  GIFTSHOP_SEARCH_DEBOUNCE_TIMEOUT,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useDebounce} from 'utils'
import {Icon, Input} from 'common/components'
import convertUnit from 'lib/unit'
import {TemplateTransactionHistoryCoverProps} from './TemplateTransactionHistoryCoverProps'

const StyledSearchMenuContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: ${convertUnit(64)};
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 ${convertUnit(45)} 0 ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    flex-direction: column;
    align-items: initial;
    margin-top: ${convertUnit(12)};
    margin-bottom: ${convertUnit(12)};
    padding: 0 ${convertUnit(25)};
  }
`

const StyledSearchContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
`

const StyledInput = styled(Input)`
  width: ${convertUnit(244)};
  height: ${convertUnit(26)};
  padding-right: ${convertUnit(16)};
`

export default function TemplateTransactionHistoryCover({
  stateSearchQuery,
}: TemplateTransactionHistoryCoverProps) {
  const [searchQuery, setSearchQuery] = stateSearchQuery
  const debounce = useDebounce()
  const {translate} = useTranslation()
  const [input, setInput] = useState(searchQuery)

  return (
    <StyledSearchMenuContainer>
      <StyledSearchContainer>
        <StyledInput
          value={input}
          containerStyle={{width: '100%'}}
          leftIcon={<Icon type="search" color="gray_3" />}
          placeholder={translate('giftShop:searchTransactionInputPlaceholder')}
          onChangeText={(text) => {
            setInput(text)
            debounce(
              () => setSearchQuery(text),
              GIFTSHOP_SEARCH_DEBOUNCE_TIMEOUT,
            )
          }}
          rightIcon={
            input.length ? (
              <Icon
                size={16}
                type="close"
                onClick={() => {
                  setInput('')
                  setSearchQuery('')
                }}
              />
            ) : (
              <></>
            )
          }
        />
      </StyledSearchContainer>
    </StyledSearchMenuContainer>
  )
}
