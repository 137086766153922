import {useCallback, useEffect, useMemo, useRef} from 'react'
import {
  LayoutDimension,
  LayoutView,
  ListIndicatorStatus,
  ListLazyAction,
  ListLazyActionLoad,
  ListNotLazyActionParam,
} from 'types'
import {LIST_LOAD_LIMIT} from 'consts'
import {useDefaultState, useDidUpdate, useMapRef} from '../../objects'

export function useListNotLazyAction<ItemT, S>({
  data,
  stateData,
  search,
  refresh,
  refreshing,
  refreshDefaultEnabled = true,
  limitPerPage = LIST_LOAD_LIMIT,
  assertPage,
  defaultPage,
  pageCount,
  limitContent,
  loadData,
  primaryExtractor,
  onEndReached,
  onContentSizeChange,
  onRefresh,
  onLayout,
  onLayoutDimension,
}: ListNotLazyActionParam<ItemT, S>) {
  const emptyData = useRef([]).current
  const stateItems = useDefaultState(data || emptyData, stateData)
  const [items, setItems] = stateItems
  const {map, setMapValue, updateMap} = useMapRef({
    initialize: false,
    loading: true,
    endPage: false,
    page: defaultPage,
    lastFetchedPage: 0,
    refreshDefault: false,
  })
  const {
    initialize,
    loading,
    page,
    lastFetchedPage,
    refreshDefault,
  } = map.current

  const status = useMemo((): ListIndicatorStatus => {
    if (loading) {
      return 'loading'
    }

    return 'normal'
  }, [loading])

  const pageRefresh = useMemo(
    () =>
      refreshing !== undefined || !refreshDefaultEnabled
        ? undefined
        : refreshDefault,
    [refreshing, refreshDefaultEnabled, refreshDefault],
  )

  const handleLoadData = useCallback(
    async ({page: newPage = page}: ListLazyActionLoad) => {
      if (lastFetchedPage && newPage === lastFetchedPage) return

      const safePage = Math.max(Math.min(newPage ?? 1, pageCount), 1)

      setMapValue({loading: true})
      initialize && updateMap()
      setMapValue({page: safePage, lastFetchedPage: safePage})

      if (limitContent) {
        setMapValue({
          initialize: true,
          refreshDefault: false,
          loading: false,
        })
        return
      }

      const responses = await loadData(safePage, limitPerPage, search)
      const primaryData = primaryExtractor
        ? responses?.filter(primaryExtractor)
        : responses

      if (responses === undefined) return

      setMapValue({
        initialize: true,
        refreshDefault: false,
        loading: false,
        endPage: !primaryData || primaryData.length < limitPerPage,
      })

      if (Array.isArray(responses)) {
        setItems(responses)
      } else {
        updateMap()
      }
    },
    [
      initialize,
      lastFetchedPage,
      limitContent,
      limitPerPage,
      loadData,
      page,
      pageCount,
      primaryExtractor,
      search,
      setItems,
      setMapValue,
      updateMap,
    ],
  )

  const handleSearch = useCallback(
    () => handleLoadData({merge: false, page: 1}),
    [handleLoadData],
  )

  const handleRefresh = useCallback(() => {
    onRefresh && onRefresh()
    setMapValue({refreshDefault: true})
    handleLoadData({merge: false, page: 1})
  }, [onRefresh, setMapValue, handleLoadData])

  const handleEndReached = useCallback(
    (info: {distanceFromEnd: number}) => {
      onEndReached && onEndReached(info)
    },
    [onEndReached],
  )

  const handleLayout = useCallback(
    (layout: LayoutView) => {
      onLayout && onLayout(layout)
    },
    [onLayout],
  )

  const handleLayoutDimension = useCallback(
    (layout: LayoutDimension) => {
      onLayoutDimension && onLayoutDimension(layout)
    },
    [onLayoutDimension],
  )

  const handleContentSizeChange = useCallback(
    (width: number, height: number) => {
      onContentSizeChange && onContentSizeChange(width, height)
    },
    [onContentSizeChange],
  )

  useEffect(() => {
    handleLoadData({page: assertPage})
  }, [assertPage, handleLoadData])

  useDidUpdate(() => setItems(data || emptyData), [data])

  useDidUpdate(() => {
    initialize && handleSearch()
  }, [search])

  useDidUpdate(() => {
    initialize && handleRefresh()
  }, [refresh])

  return useMemo<ListLazyAction<ItemT>>(
    () => ({
      items,
      status,
      pageRefresh,
      handleRefresh,
      handleEndReached,
      handleContentSizeChange,
      handleLayout,
      handleLayoutDimension,
    }),
    [
      items,
      status,
      pageRefresh,
      handleRefresh,
      handleEndReached,
      handleContentSizeChange,
      handleLayout,
      handleLayoutDimension,
    ],
  )
}
