import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import ReactHlsPlayer from '@gumlet/react-hls-player'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {ObjectState} from 'types'
import {useWindowLayout} from 'windows'
import {ForkygramTemplatePostAudioPlayPause} from '../../../../forkygram'

interface StyledContentProps {
  height: number
}

const StyledVideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: inherit;
`

const StyledVideo = styled(ReactHlsPlayer)<StyledContentProps>`
  width: 100%;
  height: 100%;
  max-height: ${({height}) => convertUnit(height - 5)};
  object-fit: scale-down;
`

const StyledOverlay = styled.div<StyledContentProps>`
  position: absolute;
  width: 100%;
  height: ${({height}) => convertUnit(height)};
`

export interface FototreeMemoryContentItemVideoProps {
  src: string
  active: boolean
  focused: boolean
  mute: boolean
  statePause: ObjectState<boolean>
  stateProgress: ObjectState<number>
}

export default function FototreeMemoryContentItemVideo({
  src,
  active,
  focused,
  mute,
  statePause,
  stateProgress,
}: FototreeMemoryContentItemVideoProps) {
  const {height} = useWindowLayout()
  const videoRef = useRef<HTMLVideoElement>(null)
  const timestamp = useRef(new Date().getTime())
  const [showButton, setShowButton] = useState(false)
  const [pause, setPause] = statePause
  const setProgress = stateProgress[1]

  const handleVideoTimeUpdate = useCallback<
    React.ReactEventHandler<HTMLVideoElement>
  >(
    (event) => {
      const {currentTime, duration} = event.currentTarget
      const extra = (currentTime / duration) * 1

      setProgress(Math.min(100, (currentTime + extra) / duration) * 100)
    },
    [setProgress],
  )

  const handleBlur = useCallback(() => {
    videoRef.current && videoRef.current.pause()
  }, [])

  const handleFocus = useCallback(() => {
    videoRef.current && videoRef.current.play()
  }, [])

  const handleFocusEvent = useCallback(() => {
    if (videoRef.current && active) {
      window.addEventListener('blur', handleBlur)
      window.addEventListener('focus', handleFocus)

      return () => {
        window.removeEventListener('blur', handleBlur)
        window.removeEventListener('focus', handleFocus)
      }
    }

    return undefined
  }, [active, handleBlur, handleFocus])

  const handleHideButton = useCallback(() => {
    const now = new Date().getTime()
    const dif = now - timestamp.current

    if (dif >= 500) {
      setShowButton(false)
    }
  }, [])

  const handleToggleVideo = useCallback(() => {
    timestamp.current = new Date().getTime()

    setPause((previous) => !previous)
    setShowButton(true)

    setTimeout(handleHideButton, 500)
  }, [setPause, handleHideButton])

  const handleRenderContentVideo = useMemo(
    () => (
      <StyledVideoContainer>
        <StyledVideo
          loop
          playerRef={videoRef}
          src={src}
          muted={mute}
          height={height}
          onTimeUpdate={handleVideoTimeUpdate}
        />
      </StyledVideoContainer>
    ),
    [handleVideoTimeUpdate, height, mute, src],
  )

  const handleRenderOverlayPlayPause = useMemo(
    () => (
      <ForkygramTemplatePostAudioPlayPause
        showButton={showButton}
        pause={pause}
      />
    ),
    [pause, showButton],
  )

  const handleRenderOverlay = useMemo(
    () => <StyledOverlay height={height} onClick={handleToggleVideo} />,
    [handleToggleVideo, height],
  )

  useEffect(() => handleFocusEvent(), [handleFocusEvent])

  useEffect(() => {
    if (videoRef.current) {
      if (active) {
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }, [active])

  useEffect(() => {
    if (videoRef.current && !focused) {
      videoRef.current.currentTime = 0
    }
  }, [focused])

  return (
    <>
      {handleRenderContentVideo}
      {handleRenderOverlayPlayPause}
      {handleRenderOverlay}
    </>
  )
}
