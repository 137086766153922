import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {
  GiftShopExploreTabView,
  GiftShopFavoriteContentProps,
  GiftShopRootEmpty,
  GiftShopTemplateMasonry,
} from 'pages'
import {requestData} from 'services'
import {GiftShopContentData} from 'types'
import {showSnackbar, useHistory} from 'utils'
import convertUnit from 'lib/unit'
import {Button, Icon, ModalLoading, Paragraph} from 'common/components'

const StyledMasonryContainer = styled.div`
  position: relative;
  height: 100%;
  flex: '1 1 auto';
`

const StyledUnfavoriteSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${convertUnit(10)} ${convertUnit(20)};
  gap: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    justify-content: space-between;
  }
`

export default function GiftShopFavoriteContent({
  stateSelect,
  stateToggleSelect,
  stateLoading,
}: GiftShopFavoriteContentProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const stateData = useState<ReadonlyArray<GiftShopContentData>>([])
  const [data, setData] = stateData
  const favEmptyImage = IMAGE_ASSET('giftshop', 'empty.png')
  const [select, setSelect] = stateSelect
  const selectIds = useMemo(() => select.map((item) => item.content_id), [
    select,
  ])
  const [toggleSelect, setToggleSelect] = stateToggleSelect
  const [loading, setLoading] = stateLoading

  const handleLoadFavoriteData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('giftshop_get_favorite', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {page, limit},
      })

      return typeof response !== 'string' && response.status === 200
        ? response.data.result.map((item) => ({...item, is_favorite: true}))
        : []
    },
    [],
  )

  const handleDeleteFavorite = useCallback(() => {
    setLoading(true)
    requestData('giftshop_delete_favorite', {
      data: {content_ids: selectIds},
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          showSnackbar(
            translate('giftShop:favoriteSuccessRemoveCount', {
              count: select.length,
            }),
          )
          setToggleSelect(false)
          setSelect([])
          setData((prev) =>
            prev.filter((item) => !selectIds.includes(item.content_id)),
          )
        }
      },
    })
  }, [
    select.length,
    selectIds,
    setData,
    setLoading,
    setSelect,
    setToggleSelect,
    translate,
  ])

  const handleClickFavoriteItem = useCallback(
    (item: GiftShopContentData) =>
      loading
        ? undefined
        : toggleSelect
        ? setSelect((prev) =>
            prev.includes(item)
              ? prev.filter((val) => val.content_id !== item.content_id)
              : [...prev, item],
          )
        : history.push('giftshop_favorite_detail', {
            data: [...data],
            selectedItemId: item.content_id,
          }),
    [data, history, loading, setSelect, toggleSelect],
  )

  const handleRenderBulkUnfavoriteSection = useMemo(
    () => (
      <StyledUnfavoriteSection>
        <Paragraph fontWeight="bold">{`${translate(
          'global:menu',
        )}:`}</Paragraph>
        <Button
          label={translate('giftShop:favoriteButtonRemoveBulk')}
          leftIcon={
            <Icon
              type="heart-ol"
              color={select.length ? 'white_1' : 'gray_3'}
              size={14}
            />
          }
          disabled={select.length === 0}
          fontSize="s"
          fontWeight="bold"
          style={{height: convertUnit(28)}}
          onClick={handleDeleteFavorite}
        />
      </StyledUnfavoriteSection>
    ),
    [handleDeleteFavorite, select.length, translate],
  )

  const handleTabView = useMemo(
    () =>
      toggleSelect ? (
        handleRenderBulkUnfavoriteSection
      ) : (
        <GiftShopExploreTabView selectedTab="favorite" />
      ),
    [handleRenderBulkUnfavoriteSection, toggleSelect],
  )

  const handleRenderFavoriteEmpty = useMemo(
    () => (
      <GiftShopRootEmpty
        image={favEmptyImage}
        imageAlt={favEmptyImage}
        title={translate('giftShop:favoriteEmptyTitle')}
        message={translate('giftShop:favoriteEmptySubtitle')}
      />
    ),
    [favEmptyImage, translate],
  )

  const handleRenderList = useMemo(
    () => (
      <GiftShopTemplateMasonry
        loadData={handleLoadFavoriteData}
        stateData={stateData}
        showResBadge
        listEmptyElement={handleRenderFavoriteEmpty}
        onClickItem={handleClickFavoriteItem}
        toggleSelect={toggleSelect}
        selected={select}
        onSelectRule={(item) => !item.is_reported}
      />
    ),
    [
      handleClickFavoriteItem,
      handleLoadFavoriteData,
      handleRenderFavoriteEmpty,
      select,
      stateData,
      toggleSelect,
    ],
  )

  return (
    <>
      {handleTabView}
      <ModalLoading visible={loading} />
      <StyledMasonryContainer>{handleRenderList}</StyledMasonryContainer>
    </>
  )
}
