import {useMemo} from 'react'
import {
  GiftShopUploadFormData,
  GiftShopUploadSeriesData,
  GiftShopUploadType,
} from 'types'
import {getGiftshopUploadDefaultValues} from './GiftShopUploadContentPropertiesHelper'

export function useGiftshopUploadProperties({
  data,
  uploadType,
  initialValues,
  selectId,
}: {
  data: GiftShopUploadSeriesData
  uploadType: GiftShopUploadType
  initialValues?: GiftShopUploadFormData
  selectId?: string
}) {
  const {images} = data

  const select = useMemo(
    () => images.find((item) => item.id === selectId) || images[0],
    [images, selectId],
  )

  const defaultValues = useMemo(() => {
    const defaultData = getGiftshopUploadDefaultValues({
      data,
      uploadType,
      selectId,
    })
    return initialValues || defaultData
  }, [data, initialValues, selectId, uploadType])

  return useMemo(() => ({select, defaultValues}), [defaultValues, select])
}
