import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {checkFutureTime, checkLaterThanToday, useDidUpdate} from 'utils'
import {Input, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopProfileEditBulkModalFormProps} from './GiftShopProfileEditBulkModalProps'

const StyledOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    justify-content: space-between;
  }
`

export default function GiftShopProfileEditBulkModalFormDateTime({
  form,
  disabled,
}: GiftShopProfileEditBulkModalFormProps) {
  const {translate} = useTranslation()
  const [fileDate, setFileDate] = useState('')
  const [fileTime, setFileTime] = useState('')
  const {setValue, setError} = form
  const [errMessage, setErrMessage] = useState('')
  const timezoneOffset = useMemo(() => new Date().getTimezoneOffset() / 60, [])
  const today = useMemo(
    () =>
      new Date(
        new Date().setHours(new Date().getHours() - timezoneOffset),
      ).toISOString(),
    [timezoneOffset],
  )
  const todayDate = useMemo(() => today.substring(0, 10), [today])
  const todayTime = useMemo(() => today.substring(11, 16), [today])

  const dateTime = useMemo(
    () =>
      fileDate && fileTime
        ? new Date(`${fileDate}T${fileTime}:00.000+07:00`)
        : undefined,
    [fileDate, fileTime],
  )

  useDidUpdate(() => {
    const compare = checkLaterThanToday(fileDate)
    if (compare) {
      setFileDate(todayDate)
      setValue('date', todayDate)
    }
    const compareTime = checkFutureTime(fileTime)
    if (compareTime && todayDate === fileDate) {
      setFileTime(todayTime)
      setValue('time', todayTime)
    }
  }, [fileDate, todayDate, setValue, fileTime])

  useDidUpdate(() => {
    if (fileDate && !fileTime) {
      setError('time', {
        type: 'manual',
        message: translate('giftShop:fillTheTimeError'),
      })
    } else if (fileTime && !fileDate) {
      setError('date', {
        type: 'manual',
        message: translate('giftShop:fillTheDateError'),
      })
    }
  }, [fileDate, fileTime, setError])

  useDidUpdate(() => {
    if (disabled) {
      setError('date', {})
      setError('time', {})
      setErrMessage(translate('giftShop:editContentBulkLimitMessage'))
    }
  }, [disabled, setError, translate])

  useDidUpdate(() => setValue('datetime', dateTime), [dateTime])

  return (
    <StyledOuterContainer>
      <StyledContainer>
        <Input
          name="date"
          label={translate('giftShop:uploadInputDateLabel')}
          defaultValue={fileDate}
          onChangeText={(text) => {
            if (text.length < 10 || text.length > 10) {
              setFileDate(todayDate)
              setValue('date', todayDate)
            } else {
              setFileDate(text)
              setValue('date', text)
            }
          }}
          form={form}
          autoComplete={false}
          type="date"
          containerStyle={{
            marginRight: convertUnit(25),
            width: '100%',
            height: 'fit-content',
          }}
          placeholderColor="gray_5"
          disabled={disabled}
          borderColor={errMessage ? 'danger_5' : undefined}
          hoverBorderColor={errMessage ? 'danger_5' : undefined}
        />
        <Input
          name="time"
          defaultValue={fileTime}
          onChangeText={(text) => {
            if (text.length < 5 || text.length > 5) {
              setFileTime(todayTime)
              setValue('time', todayTime)
            } else {
              setFileTime(text)
              setValue('time', text)
            }
          }}
          label={translate('giftShop:uploadInputTimeLabel')}
          form={form}
          autoComplete={false}
          type="time"
          containerStyle={{
            width: '100%',
            height: 'fit-content',
          }}
          disabled={disabled}
          borderColor={errMessage ? 'danger_5' : undefined}
          hoverBorderColor={errMessage ? 'danger_5' : undefined}
        />
      </StyledContainer>
      {errMessage ? (
        <Paragraph color="danger_5" fontWeight="medium">
          {errMessage}
        </Paragraph>
      ) : (
        <></>
      )}
    </StyledOuterContainer>
  )
}
