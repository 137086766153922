import React from 'react'
import styled from 'styled-components'
import {ButtonIcon} from 'common/components'
import {getThemeColor} from 'utils'
import convertUnit from 'lib/unit'
import {GiftShopTemplateDetailContentItemImageIconProps} from './GiftShopTemplateDetailContentItemImageIconProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledIcon = styled(
  ButtonIcon,
)<GiftShopTemplateDetailContentItemImageIconProps>`
  ${({
    theme,
    backgroundColor = 'black',
    backgroundColorOpacity = 0.4,
    backgroundHoverColorOpacity = 0.25,
    backgroundHoverColor = 'primary_5',
    hasBackground = true,
  }) => ({
    backgroundColor: hasBackground
      ? getThemeColor(theme, backgroundColor, backgroundColorOpacity)
      : 'transparent',
    ':hover': {
      backgroundColor: getThemeColor(
        theme,
        backgroundHoverColor,
        backgroundHoverColorOpacity,
      ),
    },
  })}
  height: ${convertUnit(34)};
  radius: ${convertUnit(28)};
`

export default function GiftShopTemplateDetailContentItemImageIcon({
  label,
  ...props
}: GiftShopTemplateDetailContentItemImageIconProps) {
  return (
    <StyledContainer>
      <StyledIcon {...props} label={label} />
    </StyledContainer>
  )
}
