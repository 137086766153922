import React from 'react'
import styled from 'styled-components'
import {ProgressBar} from 'common/components'
import convertUnit from 'lib/unit'
import {ForkygramTemplatePostProgressBarProps} from './ForkygramTemplatePostProgressBarProps'

const StyledContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  bottom: ${convertUnit(4)};
  padding: 0 ${convertUnit(8)};
`

export default function ForkygramTemplatePostProgressBar({
  progress,
}: ForkygramTemplatePostProgressBarProps) {
  return (
    <StyledContainer>
      <ProgressBar progress={progress} />
    </StyledContainer>
  )
}
