import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {Button, Image, Paragraph} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {getBorder, getContentURL, getTextShadow, useHistory} from 'utils'
import {useWindowLayout} from 'windows'
import {ForkygramTemplatePostProgressBar} from '../../../../forkygram'
import {GiftShopTemplateDetailContentItemImageIcon} from '../../../../gift-shop'
import {FototreWishlistContentItemProps} from './FototreeContentItemProps'
import FototreeWishlistContentItemDescription from './FototreeWishlistContentItemDescription'
import FototreeMemoryContentItemVideo from './FototreeMemoryContentItemVideo'

interface StyledContainerProps {
  height: number
}

const StyledContainer = styled.div<StyledContainerProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({height}) => convertUnit(height)};
  width: fill-available;
  max-width: ${convertUnit(600)};
  margin: auto;
`

const StyledExtraContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: ${convertUnit(18)};
  left: ${convertUnit(18)};
  bottom: ${convertUnit(18)};
  gap: ${convertUnit(4)};
`

const StyledBuyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${convertUnit(8)};
`

const StyledBuyButton = styled(Button)`
  width: 100%;
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  box-sizing: border-box;
`

const StyledProfileContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${convertUnit(8)};
`

const StyledAvatarContainer = styled.div`
  position: relative;
  cursor: pointer;
`

const StyledAvatar = styled(Image)`
  ${({theme}) => ({
    border: getBorder(1, 'solid', theme.white_1),
    backgroundColor: theme.black,
  })}
  aspect-ratio: 0.75;
  width: ${convertUnit(36)};
  border-radius: ${convertUnit(4)};
  object-fit: contain;
`

const StyledParagraph = styled(Paragraph)`
  text-shadow: ${({theme}) => getTextShadow(theme)};
`

const StyledUsername = styled(StyledParagraph)`
  display: inline;
  cursor: pointer;
  word-break: break-word;
`

export default function FototreeWishlistContentItem({
  active,
  data,
  focused,
  isCart,
  mute,
  statePause,
  onBuyNow,
  onCart,
}: FototreWishlistContentItemProps) {
  const history = useHistory()
  const {translate} = useTranslation()
  const {height} = useWindowLayout()
  const stateProgress = useState(0)

  const handleNavigateProfile = useCallback(() => {
    history.pushQuery({
      path: 'giftshop_search_by_creator_creation_content',
      state: {
        photo: data.profile_url,
        creatorRating: data.creator_rating,
        ratingCount: data.creator_rating_count,
      },
      queryObject: {
        creator_id: data.creator_id,
        username: data.creator_name,
      },
    })
  }, [data, history])

  const handleRenderContent = useMemo(
    () =>
      data.content_type === 'photo' ? (
        <StyledImage
          src={getContentURL({url: data.url, type: data.type})}
          alt={data.title}
          height="100%"
          width="100%"
        />
      ) : (
        <FototreeMemoryContentItemVideo
          src={getContentURL({url: data.video_url, type: data.type})}
          active={active}
          focused={focused}
          mute={mute}
          statePause={statePause}
          stateProgress={stateProgress}
        />
      ),
    [active, data, focused, mute, statePause, stateProgress],
  )

  const handleRenderProfile = useMemo(
    () => (
      <StyledProfileContainer>
        <StyledAvatarContainer onClick={handleNavigateProfile}>
          <StyledAvatar src={data.profile_url} alt={data.creator_name} />
        </StyledAvatarContainer>
        <StyledUsername
          color="white_1"
          fontWeight="bold"
          onClick={handleNavigateProfile}>
          @{data.creator_name}
        </StyledUsername>
      </StyledProfileContainer>
    ),
    [data.creator_name, data.profile_url, handleNavigateProfile],
  )

  const handleRenderDescription = useMemo(
    () => <FototreeWishlistContentItemDescription data={data} />,
    [data],
  )

  const handleRenderBuyButton = useMemo(
    () => (
      <StyledBuyContainer>
        <StyledBuyButton
          label={translate('giftShop:buyNow')}
          type="small"
          backgroundColor="white_3"
          backgroundHoverColor="white_1"
          color="primary_5"
          onClick={() => onBuyNow(data)}
        />
        <GiftShopTemplateDetailContentItemImageIcon
          iconType={isCart ? 'cart' : 'cart-plus-outlined'}
          iconColor="primary_5"
          backgroundColor="white_3"
          backgroundHoverColor="white_1"
          backgroundColorOpacity={1}
          backgroundHoverColorOpacity={1}
          onClick={() => onCart(data)}
        />
      </StyledBuyContainer>
    ),
    [data, isCart, onBuyNow, onCart, translate],
  )

  const handleRenderExtra = useMemo(
    () => (
      <StyledExtraContainer>
        {handleRenderProfile}
        {handleRenderDescription}
        {handleRenderBuyButton}
      </StyledExtraContainer>
    ),
    [handleRenderBuyButton, handleRenderDescription, handleRenderProfile],
  )

  const handleRenderProgressBar = useMemo(
    () => <ForkygramTemplatePostProgressBar progress={stateProgress[0]} />,
    [stateProgress],
  )

  return (
    <StyledContainer height={height}>
      {handleRenderContent}
      {handleRenderExtra}
      {handleRenderProgressBar}
    </StyledContainer>
  )
}
