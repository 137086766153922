import React from 'react'
import styled from 'styled-components'
import {Avatar, Badge, Icon, Paragraph} from 'common/components'
import {GIFT_SHOP_HEADER_HEIGHT, GIFT_SHOP_ROOT_HEADER_PADDING} from 'consts'
import {translate} from 'i18n'
import convertUnit from 'lib/unit'
import {WindowModeType} from 'types'
import {useHistory, useLocation} from 'utils'
import {useWindowMode} from 'windows'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledSectionHeader = styled.div`
  ${({theme}) => ({borderColor: theme.gray_1})}
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: ${convertUnit(GIFT_SHOP_HEADER_HEIGHT)};
  width: 100%;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: ${convertUnit(1)};
`
const StyledUsername = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledIconContainer = styled.div<StyledContainerProps>`
  padding: ${({mode}) =>
    mode === 'website' ? convertUnit(12) : convertUnit(20)};
  margin-right: ${convertUnit(GIFT_SHOP_ROOT_HEADER_PADDING / 2)};
  cursor: pointer;
`
const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: ${convertUnit(8)};
`
export interface GiftShopMessageRoomHeaderSystemProps {
  participantId: string
  roomId: string
  onBackButtonClicked?(): void
}
export default function GiftShopMessageRoomHeaderSystem({
  onBackButtonClicked,
}: GiftShopMessageRoomHeaderSystemProps) {
  const mode = useWindowMode()
  const history = useHistory()
  const {roomName = '-', roomPhoto = ''} = useLocation(
    'giftshop_message_room',
  ).state
  return (
    <StyledSectionHeader>
      <StyledIconContainer
        mode={mode}
        onClick={onBackButtonClicked || history.goBack}>
        <Icon
          type="back"
          color="primary_5"
          size={mode === 'mobile' ? convertUnit(20) : convertUnit(24)}
        />
      </StyledIconContainer>
      <Avatar src={roomPhoto} alt="Avatar" />
      <StyledHeader>
        <StyledUsername>
          <Paragraph fontSize="l" fontWeight="bold" color="primary_5">
            {roomName}
          </Paragraph>
          <Badge
            text={translate('messages:badgeBot')}
            isMessageBadge
            color="white_1"
            backgroundColor="success_5"
          />
        </StyledUsername>
      </StyledHeader>
    </StyledSectionHeader>
  )
}
