import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {ButtonHeader, Paragraph} from 'common/components'
import {GIFT_SHOP_HEADER_HEIGHT} from 'consts'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {getBorder} from 'utils'
import {ForkygramUploadTagErrorType} from 'types'
import {ForkygramTemplateUploadForm} from '../../../../forkygram'
import {FototreeMemoryEditContentProps} from './FototreeContentProps'

const StyledSectionHeader = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: ${convertUnit(GIFT_SHOP_HEADER_HEIGHT)};
  border: ${({theme}) => getBorder(1, 'solid', theme.gray_1)};
`

const StyledButtonHeader = styled(ButtonHeader)`
  margin-left: auto;
`

export default function FototreeMemoryEditContent({
  stateEdit,
  dataTag,
  formRef,
  onPatchPost,
}: FototreeMemoryEditContentProps) {
  const {translate} = useTranslation()
  const stateFormError = useState<ForkygramUploadTagErrorType>('none')
  const [edit, setEdit] = stateEdit
  const formError = stateFormError[0]

  const handleRenderHeader = useMemo(
    () => (
      <StyledSectionHeader>
        <ButtonHeader
          size={20}
          icon="back"
          color="primary_5"
          label={translate('global:back')}
          onClick={() => setEdit(undefined)}
        />
        <Paragraph fontSize="l" fontWeight="medium">
          {translate('forkygram:uploadHeader')}
        </Paragraph>
        <StyledButtonHeader
          size={20}
          icon="check"
          label={translate('global:save')}
          color={formError === 'none' ? 'primary_5' : 'gray_3'}
          onClick={formError === 'none' ? onPatchPost : undefined}
        />
      </StyledSectionHeader>
    ),
    [translate, formError, onPatchPost, setEdit],
  )

  const handleRenderContent = useMemo(
    () => (
      <ForkygramTemplateUploadForm
        stateFormError={stateFormError}
        editContent
        tag={dataTag}
        thumbnail={edit?.thumbnail}
        caption={edit?.caption}
        contentType={edit?.content_type}
        src={edit?.url}
        onFormUpdate={(item) => {
          formRef.current = item
        }}
      />
    ),
    [
      dataTag,
      edit?.caption,
      edit?.content_type,
      edit?.thumbnail,
      edit?.url,
      formRef,
      stateFormError,
    ],
  )

  return (
    <>
      {handleRenderHeader}
      {handleRenderContent}
    </>
  )
}
