import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
  LayoutDimension,
  LayoutView,
  ListIndicatorStatus,
  ListLazyAction,
  ListLazyActionLoad,
  ListLazyActionParam,
} from 'types'
import {LIST_LOAD_LIMIT} from 'consts'
import {getCacheData, setCacheData} from 'utils'
import {
  useDefaultState,
  useDidUpdate,
  useDidMount,
  useMapRef,
} from '../../objects'

export function useListLazyAction<ItemT, S>({
  data,
  stateData,
  search,
  refresh,
  refreshing,
  cache,
  refreshDefaultEnabled = true,
  initialPage = 1,
  limitPerPage = LIST_LOAD_LIMIT,
  loadData,
  keyExtractor,
  anchorExtractor,
  primaryExtractor,
  onEndReached,
  onContentSizeChange,
  onRefresh,
  onLayout,
  onLayoutDimension,
}: ListLazyActionParam<ItemT, S>) {
  const emptyData = useRef([]).current
  const stateItems = useDefaultState(data || emptyData, stateData)
  const [layoutSize, setLayoutSize] = useState<number>()
  const [contentSize, setContentSize] = useState<number>()
  const [items, setItems] = stateItems
  const {map, setMapValue, updateMap} = useMapRef({
    initialize: false,
    loading: true,
    endPage: false,
    page: initialPage,
    refreshDefault: false,
    anchor: '',
  })
  const {
    endPage,
    initialize,
    loading,
    page,
    refreshDefault,
    anchor,
  } = map.current

  const status = useMemo((): ListIndicatorStatus => {
    if (items.length === 0) {
      return initialize ? 'empty' : 'initialize'
    }
    if (endPage) {
      return 'end-page'
    }
    if (loading) {
      return 'loading'
    }

    return 'normal'
  }, [items, initialize, endPage, loading])

  const pageRefresh = useMemo(
    () =>
      refreshing !== undefined || !refreshDefaultEnabled
        ? undefined
        : refreshDefault,
    [refreshing, refreshDefaultEnabled, refreshDefault],
  )

  const mergeData = useCallback(
    (responses: ItemT[] | null) => {
      const keys: string[] = []

      function addItem(record: Record<string, ItemT>, item: ItemT) {
        const id = keyExtractor(item)
        const previous = record[id]

        if (previous) {
          record[id] = {...previous, ...item}
        } else {
          keys.push(id)
          record[id] = item
        }

        return record
      }

      const record = items.reduce(addItem, {})

      if (responses) {
        for (const item of responses) {
          addItem(record, item)
        }
      }

      const merge = keys.map((key) => record[key])

      return merge
    },
    [items, keyExtractor],
  )

  const handleLoadCachedData = useCallback(
    () => (!initialize && cache ? getCacheData<ItemT[]>(cache) : undefined),
    [cache, initialize],
  )

  const handleUpdateCachedData = useCallback(
    (cachedData: ItemT[]) => {
      if (cache && cachedData.length > 0) {
        setCacheData(cache, cachedData)
      }
    },
    [cache],
  )

  const handleLoadData = useCallback(
    async ({merge = true, page: newPage = page}: ListLazyActionLoad) => {
      setMapValue({loading: true})
      initialize && updateMap()
      setMapValue({page: newPage})
      if (anchorExtractor && anchor && newPage === 1) {
        setMapValue({anchor: ''})
      }

      const cachedData = await handleLoadCachedData()
      const responses = await loadData(
        newPage,
        limitPerPage,
        search,
        newPage === 1 ? undefined : anchor,
      )
      const updateData = merge && responses ? mergeData(responses) : responses
      const primaryData = primaryExtractor
        ? responses?.filter(primaryExtractor)
        : responses

      if (responses === undefined) return

      if (
        anchorExtractor &&
        responses !== null &&
        responses.length >= limitPerPage
      ) {
        const lastResponse = responses[limitPerPage - 1] as any
        const lastResponseAnchor = anchorExtractor(lastResponse)
        setMapValue({anchor: lastResponseAnchor})
      }

      setMapValue({
        initialize: true,
        refreshDefault: false,
        loading: false,
        endPage: !primaryData || primaryData.length < limitPerPage,
      })

      if (Array.isArray(updateData)) {
        handleUpdateCachedData(updateData)
        setItems(!updateData.length && cachedData ? cachedData : updateData)
      } else {
        updateMap()
      }
    },
    [
      anchor,
      anchorExtractor,
      handleLoadCachedData,
      handleUpdateCachedData,
      initialize,
      limitPerPage,
      loadData,
      mergeData,
      page,
      primaryExtractor,
      search,
      setItems,
      setMapValue,
      updateMap,
    ],
  )

  const handleSearch = useCallback(
    () => handleLoadData({merge: false, page: 1}),
    [handleLoadData],
  )

  const handleRefresh = useCallback(() => {
    onRefresh && onRefresh()
    setMapValue({refreshDefault: true, loading: true})
    handleLoadData({merge: false, page: initialPage})
  }, [onRefresh, setMapValue, handleLoadData, initialPage])

  const handleEndReached = useCallback(
    (info: {distanceFromEnd: number}) => {
      onEndReached && onEndReached(info)

      if (!loading && !endPage) {
        handleLoadData({page: page + 1})
      }
    },
    [endPage, loading, page, onEndReached, handleLoadData],
  )

  const handleLayout = useCallback(
    (layout: LayoutView) => {
      const {clientHeight} = layout

      onLayout && onLayout(layout)
      setLayoutSize(clientHeight)
    },
    [setLayoutSize, onLayout],
  )

  const handleLayoutDimension = useCallback(
    (layout: LayoutDimension) => {
      const {height: clientHeight} = layout

      onLayoutDimension && onLayoutDimension(layout)
      setLayoutSize(clientHeight)
    },
    [setLayoutSize, onLayoutDimension],
  )

  const handleContentSizeChange = useCallback(
    (width: number, height: number) => {
      onContentSizeChange && onContentSizeChange(width, height)
      setContentSize(height)
    },
    [setContentSize, onContentSizeChange],
  )

  const handleChangeStatus = useCallback(() => {
    if (
      layoutSize &&
      contentSize &&
      contentSize <= layoutSize &&
      status === 'normal' &&
      !loading
    ) {
      handleLoadData({page: page + 1})
    }
  }, [layoutSize, contentSize, status, loading, handleLoadData, page])

  useDidMount(() => {
    handleLoadData({})
  })

  useEffect(handleChangeStatus, [handleChangeStatus])

  useDidUpdate(() => setItems(data || emptyData), [data])

  useDidUpdate(() => {
    initialize && handleSearch()
  }, [search])

  useDidUpdate(() => {
    initialize && handleRefresh()
  }, [refresh])

  return useMemo<ListLazyAction<ItemT>>(
    () => ({
      items,
      status,
      pageRefresh,
      handleRefresh,
      handleEndReached,
      handleContentSizeChange,
      handleLayout,
      handleLayoutDimension,
    }),
    [
      items,
      status,
      pageRefresh,
      handleRefresh,
      handleEndReached,
      handleContentSizeChange,
      handleLayout,
      handleLayoutDimension,
    ],
  )
}
