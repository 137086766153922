import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_TRANSACTION_BUILT_IN_KEY_WORD,
  GIFT_SHOP_TRANSACTION_STATUS_COLORS,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {useWindowMode} from 'windows'
import {
  getCurrencyValue,
  getThemeColor,
  getWithdrawInfoDate,
  parseDate,
  showSnackbar,
  useHistory,
} from 'utils'
import {requestData} from 'services'
import {GiftShopContentData} from 'types'
import {
  Button,
  ButtonIcon,
  ButtonOutline,
  ConfirmationModal,
  Icon,
  IconFirstBuy,
  Paragraph,
} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopTemplatePaymentProofModal} from 'pages'
import {TransactionDetailContentProps} from './TransactionDetailContentProps'
import {TransactionDetailContentItem} from '../ContentItem'
import {TransactionDetailWaitingHiresInfo} from '../WaitingHiresInfo'

interface StyledButtonIconProps {
  backgroundColorOpacity?: number
  hasBackground?: boolean
}

const StyledContainer = styled.div<CSSProperties>`
  display: flex;
  max-width: ${({maxWidth}) => maxWidth};
  margin: ${convertUnit(25)};
  padding: ${convertUnit(25)} 0;
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_1};
  align-self: center;
  overflow: auto;

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    flex-direction: column;
    height: 100%;
  }
`

const StyledContentSection = styled.div<CSSProperties>`
  display: flex;
  width: ${({width}) => width};
  flex-direction: column;
  margin: 0 ${convertUnit(25)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
    margin: 0;
    margin-top: ${({marginTop}) => marginTop};
  }
`

const StyledHeaderContainer = styled.div<CSSProperties>`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(8)};
  margin-bottom: ${convertUnit(20)};
  justify-content: space-between;
  align-items: center;
`

const StyledTitle = styled(Paragraph)`
  margin: ${convertUnit(10)} 0;
`

const StyledParagraph = styled(Paragraph)`
  text-transform: capitalize;
  text-align: right;
`

const StyledInfoContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: ${convertUnit(25)};
`

const StyledInfoItem = styled.div`
  width: 50%;
  margin-bottom: ${convertUnit(16)};
`

const StyledBr = styled.div`
  width: 100%;
  margin-top: ${convertUnit(4)};
  margin-bottom: ${convertUnit(20)};
  border: ${convertUnit(1)} solid ${({theme}) => theme.gray_1};
`

const StyledDetailText = styled(Paragraph)`
  text-align: right;
`

const StyledPricingContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const StyledPricing = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledSeparatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const StyledSeparator = styled.div`
  background-color: ${({theme}) => theme.gray_3};
  display: flex;
  flex: 1;
  height: ${convertUnit(1)};
  margin-top: ${convertUnit(10)};
`

const StyledSeparatorText = styled(Paragraph)`
  margin-left: ${convertUnit(5)};
  margin-bottom: ${convertUnit(10)};
`

const StyledTextHeader = styled(Paragraph)`
  margin: ${convertUnit(20)} 0;
`

const StyledText = styled(Paragraph)`
  white-space: pre-wrap;
  margin-bottom: ${convertUnit(16)};
`

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(8)};
  align-items: center;
`

const StyledRefund = styled(StyledPricing)`
  margin-top: ${convertUnit(26)};
`

const StyledWithdrawMessageContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_4,
  })}
  border-radius: ${convertUnit(8)};
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${convertUnit(8)} ${convertUnit(16)};
`

const StyledButtonIcon = styled(ButtonIcon)<StyledButtonIconProps>`
  ${({
    theme,
    backgroundColor = 'black',
    backgroundColorOpacity = 0.25,
    backgroundHoverColor = 'primary_5',
    hasBackground = true,
  }) => ({
    backgroundColor: hasBackground
      ? getThemeColor(theme, backgroundColor, backgroundColorOpacity)
      : 'transparent',
    ':hover': {
      backgroundColor: getThemeColor(theme, backgroundHoverColor, 0.25),
    },
  })}
  justify-content: right;
  margin-left: ${convertUnit(25)};
`

const StyledMessageContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_4,
  })}
  flex-direction: 'column';
  border-radius: ${convertUnit(8)};
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: ${convertUnit(8)} ${convertUnit(16)};
  margin-top: ${convertUnit(4)};
  margin-bottom: ${convertUnit(20)};
`

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  display: flex;
  flex: 1;
  width: 100%;
`

const StyledCopyIcon = styled(Icon)`
  margin-right: ${convertUnit(8)};
`

const StyledButtonOutline = styled(ButtonOutline)`
  display: flex;
  flex: 1;
  width: 100%;
`

export default function TransactionDetailContent({
  stateData,
  status,
  dataInvoice,
  isHostTransaction,
}: TransactionDetailContentProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const history = useHistory()
  const userData = useSelector('user')
  const statusState = status
  const [title, setTitle] = useState('')
  const [sendProofModal, setSendProofModal] = useState(false)
  const [sendProofLoading, setSendProofLoading] = useState(false)
  const [sendProofSent, setSendProofSent] = useState<boolean>()

  const {
    data,
    invoice_id,
    payment_invoice,
    payment_type,
    price_detail,
    status: stateDataStatus,
    checkout_at,
    payment_at,
    transaction_method,
    transaction_total,
    transaction_type,
    amount,
    bank_account_name,
    bank_account_number,
    bank_name,
    card_last4,
  } = stateData
  const {discount, refund} = price_detail
  const isFirstBuy =
    transaction_type !== 'Withdraw' &&
    data &&
    data.findIndex((item) => item.is_free_first_buy) !== -1
  const findHost =
    transaction_type !== 'Withdraw' &&
    data &&
    data.map((item) => item.tags.findIndex((tag) => tag.type === 'host') !== -1)
  const isHost = findHost && findHost.findIndex((item) => item === true) !== -1
  const {id: selfId, nickname} = useSelector('user')!
  const isSelf =
    transaction_type !== 'Withdraw' &&
    data &&
    data.map((dt) => dt.member_id === selfId).includes(true)
  const userBuysTheirOwnContent =
    isSelf &&
    data &&
    data.map((dt) => dt.collector_nickname === nickname).includes(true)
  const [cancelModal, setCancelModal] = useState(false)

  const enableHowToPayButton = useMemo(
    () =>
      transaction_type === 'Purchase' &&
      stateDataStatus === 'PROCESSED' &&
      (payment_type === 'Virtual Account' ||
        payment_type === 'Retail' ||
        (payment_type === 'Ewallet' &&
          (transaction_method === 'GoPay' ||
            transaction_method === 'ShopeePay'))),
    [payment_type, stateDataStatus, transaction_method, transaction_type],
  )

  const handleClickPendingItem = useCallback(
    (item: GiftShopContentData) => {
      history.push('giftshop_transaction_pending_content_detail', {
        data: [item],
        transaction_date: checkout_at,
        statusNow: status,
        invoiceId: dataInvoice,
      })
    },
    [history, checkout_at, status, dataInvoice],
  )

  const handleClickCollectionItem = useCallback(
    (item: GiftShopContentData) => {
      if (transaction_type === 'Received' || transaction_type === 'Refund_to') {
        history.push(
          'giftshop_profile_creation_detail',
          {
            data,
            selectedItemId: item.content_id,
            limitContent: true,
          },
          item.creator_name,
        )
      } else
        history.push('giftshop_collection_detail', {
          data: data.map((val) => ({
            ...val,
            upload_source: 'sell',
          })),
          showLowResBadge: true,
          selectedItemId: item.content_id,
          limitContent: true,
        })
    },
    [transaction_type, history, data],
  )

  const handleHowToPay = useCallback(() => {
    transaction_method === 'GoPay' || transaction_method === 'ShopeePay'
      ? history.push('giftshop_ewallet_payment_history', {
          invoiceId: payment_invoice,
          paymentMethod: transaction_method,
          transactionDate: new Date(checkout_at),
        })
      : history.push('giftshop_payment_history', {
          invoiceId: payment_invoice,
          paymentMethod: transaction_method,
          paymentType: payment_type,
          transactionDate: new Date(checkout_at),
        })
  }, [checkout_at, history, payment_invoice, payment_type, transaction_method])

  const handleCheckPaymentProofIsSent = useCallback(() => {
    if (invoice_id === '') return

    requestData('giftshop_get_transaction_report', {
      params: {
        invoice_id,
      },
      onRequestSuccess: ({status: stat}) => {
        setSendProofSent(stat === 200)
      },
    })
  }, [invoice_id])

  const handleCancelTransaction = useCallback(() => {
    requestData('giftshop_cancel_transaction', {
      actionType: 'execute',
      useDefaultMessage: true,
      data: {payment_invoice},
      onRequestSuccess: ({status: reqStatus}) => {
        reqStatus === 200 && history.replace('giftshop_balance', {})
      },
    })
  }, [history, payment_invoice])

  const date = useMemo(() => payment_at || new Date().toISOString(), [
    payment_at,
  ])

  const {checkDate, transferDate} = getWithdrawInfoDate(date)

  const withdrawInfoDateFormat = useMemo(
    () =>
      userData?.lang === 'en'
        ? 'dddd, MMMM D, YYYY, hh:mm A'
        : 'dddd, D MMMM YYYY, HH:mm',
    [userData?.lang],
  )

  const handleWithdrawInfo = useMemo(
    () => (
      <StyledWithdrawMessageContainer>
        <Paragraph
          fontSize="s"
          fontWeight="bold"
          style={{lineHeight: convertUnit(21)}}>
          {translate('giftShop:withdrawConfirmationExplanationFirst')}
        </Paragraph>
        <Paragraph
          fontSize="s"
          fontWeight="regular"
          style={{lineHeight: convertUnit(21)}}>
          {parseDate(checkDate, withdrawInfoDateFormat)}
        </Paragraph>
        <Paragraph
          fontSize="s"
          fontWeight="bold"
          style={{lineHeight: convertUnit(21)}}>
          {translate('giftShop:withdrawConfirmationExplanationSecond')}
        </Paragraph>
        <Paragraph
          fontSize="s"
          fontWeight="regular"
          style={{lineHeight: convertUnit(21)}}>
          {parseDate(transferDate, withdrawInfoDateFormat)}
        </Paragraph>
      </StyledWithdrawMessageContainer>
    ),
    [checkDate, transferDate, translate, withdrawInfoDateFormat],
  )

  const renderItemPurchased = useMemo(
    () =>
      data && (
        <StyledContentSection
          marginTop={mode !== 'website' ? convertUnit(50) : 0}
          width="50%">
          <StyledTitle fontSize="xl" fontWeight="bold">
            {translate('giftShop:contentDetail')}
          </StyledTitle>
          {data.map((item: GiftShopContentData) => (
            <TransactionDetailContentItem
              item={item}
              showDiscount={transaction_type === 'Purchase'}
              onClickItem={
                statusState === 'COMPLETED'
                  ? handleClickCollectionItem
                  : handleClickPendingItem
              }
              discount={discount}
            />
          ))}
        </StyledContentSection>
      ),
    [
      data,
      mode,
      translate,
      transaction_type,
      statusState,
      handleClickCollectionItem,
      handleClickPendingItem,
      discount,
    ],
  )

  const handleRenderCancelTransactionModal = useMemo(
    () => (
      <ConfirmationModal
        visible={cancelModal}
        title={translate('global:cancel')}
        text={translate('transaction:cancelTransactionModalMessage')}
        cancelText={translate('global:stay')}
        confirmText={translate('global:yesImSure')}
        onConfirm={handleCancelTransaction}
        toggleModal={() => setCancelModal(false)}
      />
    ),
    [cancelModal, handleCancelTransaction, translate],
  )

  const renderTransactionStatus = useMemo(
    () => (
      <StyledParagraph
        fontSize="m"
        color={GIFT_SHOP_TRANSACTION_STATUS_COLORS[stateDataStatus]}
        fontWeight="bold">
        {transaction_type === 'Withdraw' && stateDataStatus !== 'COMPLETED'
          ? translate('global:processed')
          : translate('giftShop:transactionStatus', {context: stateDataStatus})}
      </StyledParagraph>
    ),
    [stateDataStatus, transaction_type, translate],
  )

  const renderDetailWithdraw = useMemo(
    () => (
      <>
        <StyledInfoItem>
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('giftShop:accountLabel')}
          </Paragraph>
        </StyledInfoItem>
        <StyledInfoItem>
          <StyledDetailText fontSize="m">
            {bank_account_name
              ? `${translate('giftShop:bankAccountDetail', {
                  bankName: bank_name,
                  name: bank_account_name.toUpperCase(),
                })}`
              : ''}
          </StyledDetailText>
        </StyledInfoItem>

        <StyledInfoItem>
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('giftShop:addBankInputAccountNumberLabel')}
          </Paragraph>
        </StyledInfoItem>
        <StyledInfoItem>
          <StyledDetailText fontSize="m">
            {bank_account_number ? `${bank_account_number}` : ''}
          </StyledDetailText>
        </StyledInfoItem>

        <StyledInfoItem>
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('giftShop:nominal')}
          </Paragraph>
        </StyledInfoItem>
        <StyledInfoItem>
          <StyledDetailText fontSize="m">
            {(amount && getCurrencyValue(amount)) || ''}
          </StyledDetailText>
        </StyledInfoItem>
        <StyledInfoItem>
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('giftShop:withdrawFee')}
          </Paragraph>
        </StyledInfoItem>
        <StyledInfoItem>
          <StyledDetailText fontSize="m" color="danger_5">
            {amount && `-${getCurrencyValue(amount - transaction_total)}`}
          </StyledDetailText>
        </StyledInfoItem>
        <StyledBr />
        <StyledInfoItem>
          <Paragraph fontWeight="medium" fontSize="m">
            {translate('giftShop:totalWithdraw')}
          </Paragraph>
        </StyledInfoItem>
        <StyledInfoItem>
          <StyledDetailText color="primary_5" fontSize="m" fontWeight="bold">
            {getCurrencyValue(transaction_total)}
          </StyledDetailText>
        </StyledInfoItem>
        {transaction_type === 'Withdraw' && handleWithdrawInfo}
      </>
    ),
    [
      translate,
      bank_account_name,
      bank_name,
      bank_account_number,
      amount,
      transaction_total,
      transaction_type,
      handleWithdrawInfo,
    ],
  )

  const handleSeparator = useMemo(
    () => (
      <StyledSeparatorContainer>
        <StyledSeparator />
        <StyledSeparatorText fontWeight="bold" color="gray_3">
          {'+'}
        </StyledSeparatorText>
      </StyledSeparatorContainer>
    ),
    [],
  )

  const renderPriceBreakdown = useMemo(
    () => (
      <StyledPricingContainer>
        <StyledBr />
        <StyledTextHeader fontWeight="bold" fontSize="l">
          {translate('giftShop:priceBreakdown')}
        </StyledTextHeader>
        <StyledPricing>
          <StyledText fontSize="m" fontWeight="medium">
            {translate('giftShop:priceNet', {
              count: price_detail?.net_creator.percentage,
            })}
          </StyledText>
          <StyledText
            fontSize="m"
            fontWeight={isHostTransaction ? 'regular' : 'bold'}
            color={isHostTransaction ? 'black' : 'primary_5'}>
            {getCurrencyValue(price_detail?.net_creator.nominal)}
          </StyledText>
        </StyledPricing>
        <StyledPricing>
          <StyledText fontSize="m" fontWeight="medium">
            {translate('giftShop:pricefee_forky', {
              count: price_detail?.fotoyu_fee.percentage,
            })}
          </StyledText>
          <StyledText fontSize="m">
            {getCurrencyValue(price_detail?.fotoyu_fee.nominal)}
          </StyledText>
        </StyledPricing>

        {isHost && (
          <StyledPricing>
            <StyledText fontSize="m" fontWeight="medium">
              {translate('giftShop:pricefee_host', {
                count: price_detail?.host_fee.percentage,
              })}
            </StyledText>
            <StyledText
              fontSize="m"
              fontWeight={isHostTransaction ? 'bold' : 'regular'}
              color={isHostTransaction ? 'primary_5' : 'black'}>
              {getCurrencyValue(price_detail?.host_fee.nominal)}
            </StyledText>
          </StyledPricing>
        )}
        {handleSeparator}
        <StyledPricing>
          <StyledText fontSize="m" fontWeight="medium">
            {translate('giftShop:pricefee', {
              count: price_detail?.creator.percentage,
            })}
          </StyledText>
          <StyledText fontSize="m">
            {getCurrencyValue(price_detail?.creator.nominal)}
          </StyledText>
        </StyledPricing>
        <StyledPricing>
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('giftShop:pricefee_paymentservice')}
          </Paragraph>
          <Paragraph fontSize="m">
            {getCurrencyValue(price_detail?.service_fee.nominal)}
          </Paragraph>
        </StyledPricing>
        <StyledText fontSize="m" fontWeight="medium">
          {translate('giftShop:pricefee_paymentservicepercentage', {
            count: price_detail?.service_fee.percentage,
          })}
        </StyledText>
        {handleSeparator}
        <StyledPricing>
          <StyledText fontSize="m" fontWeight="medium">
            {translate('giftShop:collectorPay')}
          </StyledText>
          <StyledText fontSize="m" fontWeight="bold" color="primary_5">
            {getCurrencyValue(price_detail?.final.nominal)}
          </StyledText>
        </StyledPricing>
      </StyledPricingContainer>
    ),
    [handleSeparator, isHost, isHostTransaction, price_detail, translate],
  )

  const handlePaymentMethod = useMemo(() => {
    if (transaction_method !== GIFT_SHOP_TRANSACTION_BUILT_IN_KEY_WORD) {
      if (
        transaction_method === 'Credit Card' &&
        transaction_type === 'Purchase'
      ) {
        return `${transaction_method} ${translate(
          'transaction:creditCardLastFour',
          {number: card_last4},
        )}`
      }
      return transaction_method || 'OVO'
    }
    return translate('global:free')
  }, [card_last4, transaction_method, transaction_type, translate])

  const renderDetailPurchase = useMemo(
    () => (
      <>
        <StyledInfoItem>
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('giftShop:paymentMethod')}
          </Paragraph>
        </StyledInfoItem>
        <StyledInfoItem>
          <StyledDetailText fontSize="m">
            {handlePaymentMethod}
          </StyledDetailText>
        </StyledInfoItem>
        <StyledBr />
        {discount && discount.nominal > 0 && (
          <>
            <StyledInfoItem>
              <Paragraph fontWeight="medium" fontSize="m">
                {translate('giftShop:discount')}
              </Paragraph>
            </StyledInfoItem>
            <StyledInfoItem>
              <StyledDetailText fontSize="m">
                {getCurrencyValue(discount.nominal)}
              </StyledDetailText>
            </StyledInfoItem>
          </>
        )}
        <StyledInfoItem>
          <Paragraph fontWeight="medium" fontSize="m">
            {translate('giftShop:totalPayment')}
          </Paragraph>
        </StyledInfoItem>
        <StyledInfoItem>
          <StyledDetailText fontSize="m" fontWeight="bold" color="primary_5">
            {transaction_total && getCurrencyValue(transaction_total)}
          </StyledDetailText>
        </StyledInfoItem>
        {stateDataStatus === 'WAITING_HIRES' ? (
          <TransactionDetailWaitingHiresInfo />
        ) : (
          <></>
        )}
        {stateDataStatus === 'HALF_COMPLETED' && (
          <>
            <StyledInfoItem>
              <Paragraph fontWeight="medium" fontSize="m">
                {translate('giftShop:totalRefund')}
              </Paragraph>
            </StyledInfoItem>
            <StyledInfoItem>
              <StyledDetailText
                color="primary_5"
                fontSize="m"
                fontWeight="bold">
                {transaction_total &&
                  translate('giftShop:totalRefundAmount', {
                    price: getCurrencyValue(refund.nominal),
                    percent: refund.percentage,
                  })}
              </StyledDetailText>
            </StyledInfoItem>
          </>
        )}
      </>
    ),
    [
      translate,
      handlePaymentMethod,
      discount,
      transaction_total,
      stateDataStatus,
      refund.nominal,
      refund.percentage,
    ],
  )

  const handleRenderRefundPriceBreakdown = useMemo(
    () => (
      <StyledPricingContainer>
        <StyledBr />
        <StyledTextHeader fontWeight="bold" fontSize="l">
          {translate('giftShop:priceBreakdown')}
        </StyledTextHeader>
        <StyledPricing>
          <StyledText fontSize="m" fontWeight="medium">
            {translate('giftShop:pricefee', {
              count: price_detail?.creator.percentage,
            })}
          </StyledText>
          <StyledText fontSize="m">
            {getCurrencyValue(price_detail?.creator.nominal)}
          </StyledText>
        </StyledPricing>
        <StyledSeparatorContainer>
          <StyledSeparator />
        </StyledSeparatorContainer>
        <StyledRefund>
          <Paragraph fontSize="m" fontWeight="medium">
            {translate('giftShop:priceBreakdownRefund')}
          </Paragraph>
          <Paragraph fontSize="m">
            {getCurrencyValue(
              stateDataStatus === 'HALF_COMPLETED'
                ? price_detail?.refund.nominal
                : price_detail?.refund_collector.nominal,
            )}
          </Paragraph>
        </StyledRefund>
        <StyledText fontSize="m" fontWeight="medium">
          {translate('giftShop:priceBreakdownRefund_percentage', {
            percentage:
              stateDataStatus === 'HALF_COMPLETED'
                ? price_detail?.refund.percentage
                : price_detail?.refund_collector.percentage,
          })}
        </StyledText>
        <StyledPricing>
          <StyledText fontSize="m" fontWeight="medium">
            {translate('giftShop:priceBreakdownRefundFotoyu', {
              percentage: price_detail?.refund_fotoyu.percentage,
            })}
          </StyledText>
          <StyledText fontSize="m">
            {getCurrencyValue(price_detail?.refund_fotoyu.nominal)}
          </StyledText>
        </StyledPricing>
        {isHost && (
          <StyledPricing>
            <StyledText fontSize="m" fontWeight="medium">
              {translate('giftShop:priceBreakdownRefundHost', {
                percentage: price_detail?.refund_host.percentage,
              })}
            </StyledText>
            <StyledText
              fontSize="m"
              fontWeight={isHostTransaction ? 'bold' : 'regular'}
              color={isHostTransaction ? 'primary_5' : 'black'}>
              {getCurrencyValue(price_detail?.refund_host.nominal)}
            </StyledText>
          </StyledPricing>
        )}
        <StyledPricing>
          <StyledText fontSize="m" fontWeight="medium">
            {translate('giftShop:priceBreakdownRefundCreator', {
              percentage: price_detail?.refund_creator.percentage,
            })}
          </StyledText>
          <StyledText
            fontSize="m"
            fontWeight={isHostTransaction ? 'regular' : 'bold'}
            color={isHostTransaction ? 'black' : 'primary_5'}>
            {getCurrencyValue(price_detail?.refund_creator.nominal)}
          </StyledText>
        </StyledPricing>
      </StyledPricingContainer>
    ),
    [isHost, isHostTransaction, price_detail, stateDataStatus, translate],
  )

  const handleRenderPricingDetails = useMemo(() => {
    switch (transaction_type) {
      case 'Withdraw': {
        setTitle(translate('giftShop:withdrawDetailLabel'))
        return renderDetailWithdraw
      }
      case 'Purchase': {
        setTitle(
          translate('giftShop:transactionType', {
            context: transaction_type.toUpperCase(),
          }),
        )
        return renderDetailPurchase
      }
      case 'Received': {
        setTitle(
          translate('giftShop:transactionType', {
            context: transaction_type.toUpperCase(),
          }),
        )
        return renderPriceBreakdown
      }
      case 'Refund': {
        setTitle(
          translate('giftShop:transactionType', {
            context: transaction_type.toUpperCase(),
          }),
        )
        return
      }
      case 'Refund_to': {
        setTitle(
          translate('giftShop:transactionType', {
            context: transaction_type.toUpperCase(),
          }),
        )
        return handleRenderRefundPriceBreakdown
      }
      case 'Incentive': {
        setTitle(
          translate('giftShop:transactionType', {
            context: transaction_type.toUpperCase(),
          }),
        )
        return
      }
      case 'Make_it_free': {
        setTitle(
          translate('giftShop:transactionType', {
            context: transaction_type.toUpperCase(),
          }),
        )
        return
      }
    }
    return <></>
  }, [
    handleRenderRefundPriceBreakdown,
    renderDetailPurchase,
    renderDetailWithdraw,
    renderPriceBreakdown,
    transaction_type,
    translate,
  ])

  const handleRenderTotalRefund = useMemo(
    () =>
      (transaction_type === 'Refund' ||
        transaction_type === 'Make_it_free') && (
        <>
          <StyledBr />
          <StyledInfoItem>
            <Paragraph fontWeight="medium" fontSize="m">
              {translate('giftShop:totalRefund')}
            </Paragraph>
          </StyledInfoItem>
          <StyledInfoItem>
            <StyledDetailText fontSize="m" fontWeight="bold" color="primary_5">
              {getCurrencyValue(
                stateDataStatus === 'HALF_COMPLETED'
                  ? price_detail?.refund.nominal
                  : price_detail?.refund_collector.nominal,
              )}
            </StyledDetailText>
          </StyledInfoItem>
        </>
      ),
    [transaction_type, translate, stateDataStatus, price_detail],
  )

  const handleOnClickMessage = useCallback(() => {
    if (transaction_type === 'Purchase') {
      history.push('giftshop_message_room', {
        participantId: data[0].member_id,
        roomType: 'giftshop',
        chatType: 'request_paid_content',
        isContentClickable: true,
        content: {
          contentIds: data.map((val) => val.content_id),
          invoiceId: invoice_id,
          title: data[0].title,
          url: data[0].url,
        },
      })
    } else if (transaction_type === 'Refund') {
      history.push('giftshop_message_room', {
        participantId: data[0].member_id,
        roomType: 'giftshop',
      })
    } else {
      requestData('giftshop_get_buyers', {
        params: {
          content_id: data[0].content_id,
        },
        onRequestSuccess: ({status: stat, data: {result}}) => {
          if (stat === 200) {
            result.find((value) => {
              value.collector_nickname === data[0].collector_nickname &&
                history.push('giftshop_message_room', {
                  participantId: value.collector_member_id,
                  selfRole: 'seller',
                  roomType: 'giftshop',
                })
            })
          }
        },
      })
    }
  }, [data, history, invoice_id, transaction_type])

  const handleRenderMessage = useMemo(
    () =>
      transaction_type !== 'Withdraw' &&
      transaction_type !== 'Incentive' &&
      !userBuysTheirOwnContent &&
      (!isSelf ||
        transaction_type === 'Received' ||
        transaction_type === 'Refund_to') && (
        <StyledButtonIcon
          hasShadow={false}
          hasBackground={false}
          iconType="message"
          iconColor="primary_5"
          onClick={handleOnClickMessage}
        />
      ),
    [handleOnClickMessage, isSelf, transaction_type, userBuysTheirOwnContent],
  )

  const handleSubmitPaymentProof = useCallback(
    (file: File, notes?: string) => {
      setSendProofLoading(true)
      requestData('giftshop_post_transaction_report', {
        useDefaultMessage: true,
        actionType: 'execute',
        headerContentType: 'multipart/form-data',
        dataType: 'form-data',
        data: {
          payment_invoice,
          notes,
          receipt_photo: file,
        },
        onRequestSuccess: ({status: paymentProofStatus}) => {
          setSendProofLoading(false)
          if (paymentProofStatus === 200) {
            setSendProofModal(false)
            setSendProofLoading(false)
            setSendProofSent(true)
            showSnackbar(translate('giftShop:paymentSendProofSuccess'))
          }
        },
        onRequestFailed: () => {
          setSendProofLoading(false)
          showSnackbar(translate('giftShop:paymentSendProofFailed'))
        },
      })
    },
    [payment_invoice, translate],
  )

  useEffect(() => {
    handleCheckPaymentProofIsSent()
  }, [handleCheckPaymentProofIsSent])

  const handleRenderPaymentProofMessage = useMemo(
    () =>
      sendProofSent ? (
        <StyledMessageContainer>
          <Icon
            type="circular-check"
            size={16}
            style={{
              alignSelf: 'center',
              marginRight: convertUnit(16),
            }}
            color="success_5"
          />
          <Paragraph
            fontSize="s"
            fontWeight="medium"
            style={{alignSelf: 'center'}}>
            <Paragraph fontSize="s" fontWeight="bold" color="success_5">
              {translate('giftShop:paymentSendProofSuccess')}
              <br />
            </Paragraph>
            <Paragraph fontSize="s" fontWeight="medium" color="gray_5">
              {translate('giftShop:paymentProofAlreadySent')}
            </Paragraph>
          </Paragraph>
        </StyledMessageContainer>
      ) : (
        <StyledMessageContainer>
          <Icon
            type="info"
            size={16}
            style={{
              alignSelf: 'center',
              marginRight: convertUnit(16),
            }}
          />
          <Paragraph
            fontSize="s"
            fontWeight="medium"
            style={{alignSelf: 'center', lineHeight: convertUnit(21)}}>
            {translate('giftShop:paymentWaitOrSendProofFirst')}
            <Paragraph
              fontSize="s"
              fontWeight="medium"
              color="primary_5"
              style={{
                display: 'inline',
                cursor: 'pointer',
              }}
              onClick={() => setSendProofModal((prev) => !prev)}>
              {translate('giftShop:paymentWaitOrSendProofSecond')}
            </Paragraph>
            .
          </Paragraph>
        </StyledMessageContainer>
      ),
    [sendProofSent, translate],
  )

  const handleRenderPaymentProof = useMemo(
    () => (
      <GiftShopTemplatePaymentProofModal
        toggleModal={() => {
          setSendProofModal((prev) => !prev)
        }}
        visible={sendProofModal}
        loading={sendProofLoading}
        onSubmit={handleSubmitPaymentProof}
      />
    ),
    [handleSubmitPaymentProof, sendProofLoading, sendProofModal],
  )

  const copyInvoiceToClipboard = useCallback(() => {
    navigator.clipboard.writeText(invoice_id)
    showSnackbar(translate('global:invoiceCopied'))
  }, [invoice_id, translate])

  const renderContent = useMemo(
    () =>
      invoice_id && (
        <>
          <StyledContentSection
            width={
              transaction_type === 'Withdraw' ||
              transaction_type === 'Incentive'
                ? '100%'
                : '50%'
            }>
            <StyledHeaderContainer>
              <StyledTitleContainer>
                <StyledTitle fontSize="xl" fontWeight="bold">
                  {title}
                </StyledTitle>
                {isFirstBuy ? (
                  <IconFirstBuy text={translate('giftShop:firstPromo')} />
                ) : (
                  <></>
                )}
                {transaction_type === 'Incentive' ? (
                  <IconFirstBuy text={translate('giftShop:firstBuyer')} />
                ) : (
                  <></>
                )}
              </StyledTitleContainer>
              {handleRenderMessage}
            </StyledHeaderContainer>
            <StyledInfoContainer>
              {(transaction_type === 'Received' ||
                transaction_type === 'Refund_to') && (
                <>
                  <StyledInfoItem>
                    <Paragraph fontSize="m" fontWeight="medium">
                      {translate('giftShop:transactionYuserNickname')}
                    </Paragraph>
                  </StyledInfoItem>
                  <StyledInfoItem>
                    <StyledDetailText fontSize="m">
                      {data[0].collector_nickname}
                    </StyledDetailText>
                  </StyledInfoItem>
                </>
              )}
              {(transaction_type === 'Purchase' ||
                transaction_type === 'Refund' ||
                transaction_type === 'Make_it_free') && (
                <>
                  <StyledInfoItem>
                    <Paragraph fontSize="m" fontWeight="medium">
                      {translate('giftShop:transactionCreatorName')}
                    </Paragraph>
                  </StyledInfoItem>
                  <StyledInfoItem>
                    <StyledDetailText fontSize="m">
                      {data[0].creator_name}
                    </StyledDetailText>
                  </StyledInfoItem>
                </>
              )}
              <StyledInfoItem>
                <Paragraph fontSize="m" fontWeight="medium">
                  {translate('giftShop:status')}
                </Paragraph>
              </StyledInfoItem>
              <StyledInfoItem>{renderTransactionStatus}</StyledInfoItem>

              <StyledInfoItem>
                <Paragraph fontSize="m" fontWeight="medium">
                  {translate('giftShop:transactionType')}
                </Paragraph>
              </StyledInfoItem>
              <StyledInfoItem>
                <StyledDetailText fontSize="m">
                  {translate('giftShop:transactionType', {
                    context: transaction_type.toUpperCase(),
                  })}
                </StyledDetailText>
              </StyledInfoItem>
              {transaction_type !== 'Incentive' && (
                <>
                  <StyledInfoItem>
                    <Paragraph fontSize="m" fontWeight="medium">
                      {translate('giftShop:transactionTime')}
                    </Paragraph>
                  </StyledInfoItem>
                  <StyledInfoItem>
                    <StyledDetailText fontSize="m">
                      {(payment_at &&
                        parseDate(payment_at, 'MMMM D, YYYY, HH:mm')) ||
                        ''}
                    </StyledDetailText>
                  </StyledInfoItem>
                </>
              )}

              {transaction_type !== 'Withdraw' && payment_at && (
                <>
                  <StyledInfoItem>
                    <Paragraph fontSize="m" fontWeight="medium">
                      {translate('giftShop:transactionPaymentTime')}
                    </Paragraph>
                  </StyledInfoItem>
                  <StyledInfoItem>
                    <StyledDetailText fontSize="m">
                      {(payment_at &&
                        parseDate(payment_at, 'MMMM D, YYYY, HH:mm')) ||
                        ''}
                    </StyledDetailText>
                  </StyledInfoItem>
                </>
              )}

              <StyledInfoItem>
                <Paragraph fontSize="m" fontWeight="medium">
                  {translate('giftShop:invoiceID')}
                </Paragraph>
              </StyledInfoItem>
              <StyledInfoItem
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}>
                <StyledCopyIcon
                  type="copy"
                  onClick={copyInvoiceToClipboard}
                  color="primary_5"
                />
                <StyledDetailText fontSize="m">{invoice_id}</StyledDetailText>
              </StyledInfoItem>

              {transaction_type === 'Incentive' && (
                <>
                  <StyledInfoItem>
                    <Paragraph fontSize="m" fontWeight="medium">
                      {translate('giftShop:totalIncentive')}
                    </Paragraph>
                  </StyledInfoItem>
                  <StyledInfoItem>
                    <StyledDetailText
                      color="primary_5"
                      fontSize="m"
                      fontWeight="bold">
                      {(amount && getCurrencyValue(amount)) || ''}
                    </StyledDetailText>
                  </StyledInfoItem>
                </>
              )}

              {(transaction_type === 'Received' ||
                transaction_type === 'Refund' ||
                transaction_type === 'Refund_to' ||
                transaction_type === 'Make_it_free') &&
                transaction_method && (
                  <>
                    <StyledInfoItem>
                      <Paragraph fontSize="m" fontWeight="medium">
                        {translate('giftShop:paymentMethod')}
                      </Paragraph>
                    </StyledInfoItem>
                    <StyledInfoItem>
                      <StyledDetailText>
                        <Paragraph fontSize="m">
                          {handlePaymentMethod}
                        </Paragraph>
                      </StyledDetailText>
                    </StyledInfoItem>
                  </>
                )}
              {handleRenderTotalRefund}
              {handleRenderPricingDetails}
            </StyledInfoContainer>
            {enableHowToPayButton &&
              sendProofSent !== undefined &&
              handleRenderPaymentProofMessage}
            <StyledButtonsContainer>
              {transaction_type === 'Purchase' &&
                statusState === 'PROCESSED' && (
                  <StyledButtonOutline
                    borderColor="gray_5"
                    color="gray_5"
                    label={translate('global:cancel')}
                    onClick={() => setCancelModal(true)}
                  />
                )}
              {enableHowToPayButton && (
                <StyledButton
                  label={translate('giftShop:howToPay')}
                  onClick={handleHowToPay}
                />
              )}
            </StyledButtonsContainer>
          </StyledContentSection>
          {renderItemPurchased}
        </>
      ),
    [
      invoice_id,
      transaction_type,
      title,
      isFirstBuy,
      translate,
      handleRenderMessage,
      data,
      renderTransactionStatus,
      payment_at,
      copyInvoiceToClipboard,
      amount,
      transaction_method,
      handlePaymentMethod,
      handleRenderTotalRefund,
      handleRenderPricingDetails,
      enableHowToPayButton,
      sendProofSent,
      handleRenderPaymentProofMessage,
      statusState,
      handleHowToPay,
      renderItemPurchased,
    ],
  )

  return (
    <StyledContainer
      maxWidth={
        transaction_type === 'Withdraw' ? convertUnit(500) : convertUnit(2000)
      }>
      {handleRenderPaymentProof}
      {renderContent}
      {handleRenderCancelTransactionModal}
    </StyledContainer>
  )
}
