import React, {useCallback, useMemo, useRef, useState} from 'react'
import {translate} from 'i18n'
import {requestData} from 'services'
import {
  FORKYGRAM_FEED_QUERY_LIMIT,
  FORKYGRAM_FAVORITES_QUERY_LIMIT,
  FORKYGRAM_FOOTPRINTS_QUERY_LIMIT,
} from 'consts'
import {
  TreeFotoTreeLeafFavorite,
  TreeFotoTreeLeafFootprintData,
  TreeFotoTreeLeafFootprintTree,
} from 'types'
import {getContentURL, useHistory, useLocation} from 'utils'
import {useWindowMode} from 'windows'
import {ListNotLazy} from 'common/components'
import {useSelector} from 'lib/redux'
import {GiftShopRootScreen} from 'pages/gift-shop'
import {ForkygramFootprintPagination} from '../../footprints'
import {
  ForkygramListGridItem,
  ForkygramTemplateListGridContent,
} from '../containers'

export default function ForkygramFootprintDetailScreen() {
  const mode = useWindowMode()
  const history = useHistory()
  const {memberId, tagId, treeId, treeName, treeAlias} = useLocation(
    'forkygram_footprint_detail',
  ).state
  const {id} = useSelector('user') || {}
  const footprintLastPage = useRef(1)
  const favoriteLastPage = useRef({page: 1, isEnd: false})
  const stateData = useState<ReadonlyArray<TreeFotoTreeLeafFootprintData>>([])
  const statePage = useState(1)
  const data = stateData[0]
  const rawPage = statePage[0]
  const [count, setCount] = useState(0)
  const column = mode === 'website' ? 5 : 4

  const pageCount = useMemo(
    () => Math.ceil(count / FORKYGRAM_FOOTPRINTS_QUERY_LIMIT),
    [count],
  )

  const handleNavigateFotoTreeDetail = useCallback(
    (item: TreeFotoTreeLeafFootprintData) => {
      const index = data.findIndex((val) =>
        val.type === 'fotoyu' && item.type === 'fotoyu'
          ? val.content_id === item.content_id
          : val.type === 'footprint' && item.type === 'footprint'
          ? val.post_id === item.post_id
          : 1,
      )

      const basePage = Math.floor(index / FORKYGRAM_FEED_QUERY_LIMIT) + 1
      const currPage = basePage + (footprintLastPage.current - 1) * 2

      history.push(
        'tree_fototree_feed',
        {
          tagId,
          treeId,
          treeAlias,
          selectedItemId:
            item.type === 'fotoyu' ? item.content_id : item.post_id,
          memberId,
          isPastMemory: true,
          initialPage:
            item.type === 'fotoyu'
              ? footprintLastPage.current *
                (footprintLastPage.current === 1 ? 1 : 2)
              : currPage,
          initialPageFavorite:
            favoriteLastPage.current.page === footprintLastPage.current
              ? favoriteLastPage.current.page
              : undefined,
        },
        treeAlias,
      )
    },
    [data, history, memberId, tagId, treeAlias, treeId],
  )

  const handleClickHeader = useCallback(() => {
    history.push('tree_fototree_detail', {treeId, treeAlias}, treeAlias)
  }, [history, treeAlias, treeId])

  const handleRenderItem = useCallback(
    (item: TreeFotoTreeLeafFootprintData) =>
      item.type === 'fotoyu' ? (
        <ForkygramListGridItem
          borderColor="black"
          column={column}
          data={{
            contentType: item.content_type || 'photo',
            url: getContentURL({url: item.url, type: item.type}),
            thumbnail: getContentURL({url: item.url, type: item.type}),
            isHidden: true,
            isWishlist: true,
          }}
          onClickItem={() => handleNavigateFotoTreeDetail(item)}
        />
      ) : (
        <ForkygramListGridItem
          borderColor="black"
          column={column}
          data={{
            contentType: item.post.content_type,
            url: item.url,
            thumbnail: item.url,
            isHidden: item.is_hidden,
          }}
          onClickItem={() => handleNavigateFotoTreeDetail(item)}
        />
      ),
    [column, handleNavigateFotoTreeDetail],
  )

  const handleLoadFootprintData = useCallback(
    async (
      page: number,
      limit: number,
    ): Promise<TreeFotoTreeLeafFootprintTree[]> => {
      const response = await requestData('tree_get_leaf_footprints', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {page, limit, member_id: memberId, tree_id: treeId},
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) {
            setCount(result.count)
          }
        },
      })

      footprintLastPage.current = page

      return typeof response !== 'string' && response.status === 200
        ? response.data.result.footprints.map((item) => ({
            ...item,
            type: 'footprint',
          }))
        : []
    },
    [memberId, treeId],
  )

  const handleLoadFavoriteData = useCallback(
    async (
      page: number,
      limit: number,
    ): Promise<TreeFotoTreeLeafFavorite[]> => {
      if (id !== memberId) return []

      const {current} = favoriteLastPage

      if (page <= current.page) current.isEnd = false
      if (current.isEnd && current.page !== page) return []

      const response = await requestData('giftshop_get_favorite', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {page, limit, tag_id: tagId},
      })

      current.page = page
      current.isEnd =
        typeof response !== 'string' &&
        response.data.result.length < FORKYGRAM_FAVORITES_QUERY_LIMIT

      return typeof response !== 'string' && response.status === 200
        ? (response.data.result.filter(
            ({type}) => type === 'fotoyu',
          ) as TreeFotoTreeLeafFavorite[])
        : []
    },
    [id, memberId, tagId],
  )

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const [footprints, favorites] = await Promise.all<
        TreeFotoTreeLeafFootprintData[]
      >([
        handleLoadFootprintData(page, limit),
        handleLoadFavoriteData(page, FORKYGRAM_FAVORITES_QUERY_LIMIT),
      ])

      const favEndIndex = Math.floor(
        (footprints.length + Math.min(favorites.length, 1)) /
          FORKYGRAM_FAVORITES_QUERY_LIMIT,
      )

      return [
        ...footprints.slice(0, favEndIndex),
        ...favorites.slice(0, 1),
        ...footprints.slice(favEndIndex),
        ...favorites.slice(1),
      ]
    },
    [handleLoadFavoriteData, handleLoadFootprintData],
  )

  const handleRenderContent = useMemo(
    () => (
      <ForkygramTemplateListGridContent
        innerMargin={0}
        header={false}
        backgroundColor="black"
        containerHeight="100%">
        <ForkygramFootprintPagination
          pageCount={pageCount}
          statePage={statePage}
        />
        <ListNotLazy
          renderItem={handleRenderItem}
          stateData={stateData}
          loadData={handleLoadData}
          limitPerPage={FORKYGRAM_FOOTPRINTS_QUERY_LIMIT}
          defaultPage={rawPage}
          page={rawPage}
          pageCount={pageCount}
          keyExtractor={(item) =>
            item.type === 'fotoyu' ? item.content_id : item.url
          }
          primaryExtractor={(item) => item.type === 'footprint'}
          numColumns={column}
          scrollbar={false}
        />
      </ForkygramTemplateListGridContent>
    ),
    [
      column,
      handleLoadData,
      handleRenderItem,
      pageCount,
      rawPage,
      stateData,
      statePage,
    ],
  )

  return (
    <GiftShopRootScreen
      showBackIcon
      showBadgeIcon={false}
      darkBar
      darkHeader
      headerTitle={treeName}
      headerBorderColor="gray_8"
      headerSubtitle={translate('tree:fototreeFootprintsLeafCount', {count})}
      onClickHeader={handleClickHeader}>
      {handleRenderContent}
    </GiftShopRootScreen>
  )
}
