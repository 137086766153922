import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {useSelector} from 'lib/redux'
import {requestData, ServiceParam} from 'services'
import {TreeFotoTreeLeafFavorite} from 'types'
import {clearUserCache, showSnackbar, useHistory} from 'utils'
import {useWindowLayout} from 'windows'
import {FototreeWishlistContentItem} from '../item'
import {FototreeWhishlistContentProps} from './FototreeContentProps'

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
`

export default function FototreeWishlistContent({
  componentRef,
  data,
  index,
  scrollPosition,
  stateMute,
  statePause,
}: FototreeWhishlistContentProps) {
  const history = useHistory()
  const {height} = useWindowLayout()
  const {translate} = useTranslation()
  const {kycStatus} = useSelector('yuserActivationState')
  const [isCart, setIsCart] = useState(data.is_cart)
  const pause = statePause[0]
  const mute = stateMute[0]

  const handleFeatureDisabled = useCallback(() => {
    if (kycStatus === 'onhold') {
      return history.push('giftshop_similar_identity', {})
    }
    if (kycStatus === 'unregistered') {
      history.push('giftshop_robopet_activation_selfie', {})
      return
    }
    history.push('giftshop_robopet', {})
  }, [history, kycStatus])

  const handleOnBuyNow = useCallback(
    (item: TreeFotoTreeLeafFavorite) => {
      if (!(kycStatus === 'verified' || kycStatus === 'verified_check')) {
        handleFeatureDisabled()
        return
      }
      const {url, type, price} = item
      const par: TreeFotoTreeLeafFavorite = {
        ...item,
        url: type ? url[type] : url,
      }
      clearUserCache('cart')
      history.push('payment_checkout', {
        data: [par],
        firstPurchase: [],
        totalPrice: price,
      })
    },
    [handleFeatureDisabled, history, kycStatus],
  )

  const handleToggleCart = useCallback(() => {
    const action: keyof ServiceParam = isCart
      ? 'giftshop_delete_cart'
      : 'giftshop_post_cart'
    const context = isCart ? 'remove' : 'add'

    requestData(action, {
      useDefaultMessage: true,
      actionType: 'execute',
      data: {
        content_ids: [data.content_id],
      },
      onRequestFailed: () => {
        showSnackbar(translate('giftShop:snackbarFailedExecute'))
      },
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          setIsCart(!isCart)
          showSnackbar(
            translate('giftShop:snackbarSuccessExecute', {
              where: translate('global:cart'),
              context,
            }),
          )
        }
      },
    })
  }, [isCart, data.content_id, translate])

  const scrollPos = useMemo(() => scrollPosition + 0.2 * height, [
    height,
    scrollPosition,
  ])

  const focused = useMemo(() => Math.floor(scrollPos / height) === index, [
    height,
    index,
    scrollPos,
  ])

  const handleRenderContent = useMemo(
    () => (
      <FototreeWishlistContentItem
        active={focused && !pause}
        data={data}
        focused={focused}
        isCart={isCart}
        mute={mute}
        statePause={statePause}
        onBuyNow={handleOnBuyNow}
        onCart={handleToggleCart}
      />
    ),
    [
      data,
      focused,
      handleOnBuyNow,
      handleToggleCart,
      isCart,
      mute,
      pause,
      statePause,
    ],
  )

  return (
    <StyledContainer ref={componentRef}>{handleRenderContent}</StyledContainer>
  )
}
