import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  AUTH_LOGIN_MAX_ATTEMPT,
  SERVICE_CANCELLATION_POST_WITHDRAW,
} from 'consts'
import {
  GiftShopWithdrawValidationType,
  WindowModeType,
  WithdrawPasswordFormData,
} from 'types'
import {
  getCurrencyValue,
  getWithdrawInfoDate,
  parseDate,
  useDebounce,
  useHistory,
} from 'utils'
import {useWindowMode} from 'windows'
import {GiftShopCreatePasswordSSOModal} from 'pages'
import {Button, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TransactionWithdrawConfirmationItem} from '../ConfirmationItem'
import {TransactionWithdrawValidation} from '../ValidationModal'
import {TransactionWithdrawResultModalTemplate} from '../WithdrawResultModalTemplate'
import {TransactionWithdrawConfirmationProps} from './TransactionWithdrawConfirmationProps'

interface StyledContainerProps {
  mode: WindowModeType
}

interface StyledMessageContainerProps {
  purpose: 'disclaimer' | 'explanation'
}

const StyledScrollContainer = styled.div`
  overflow-y: scroll;
  height: 100%;

  ::-webkit-scrollbar {
    background-color: ${({theme}) => theme.white_3};
    width: ${convertUnit(25)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_3};
    border-radius: ${convertUnit(16)};
  }
`

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, mode}) => ({
    backgroundColor: mode === 'mobile' ? theme.white_1 : theme.white_2,
  })};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100%;
`

const StyledComponentContainer = styled.div<StyledContainerProps>`
  ${({theme, mode}) => ({
    backgroundColor: theme.white_1,
    width: mode !== 'mobile' ? convertUnit(451) : '100%',
    margin: mode !== 'mobile' ? convertUnit(25) : 0,
    padding: mode !== 'mobile' ? convertUnit(40) : convertUnit(25),
  })};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledMessageContainer = styled.div<StyledMessageContainerProps>`
  ${({theme, purpose}) => ({
    backgroundColor: theme.white_4,
    borderRadius: purpose === 'explanation' ? convertUnit(8) : 0,
  })}
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${convertUnit(8)} ${convertUnit(16)};
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
`

const StyledTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(12)};
`

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  margin-top: ${convertUnit(12)};
`

export default function TransactionWithdrawConfirmation({
  stateAmount,
  stateBankAccount,
  roletype,
}: TransactionWithdrawConfirmationProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const date = new Date().toISOString()
  const [withdrawAmount] = stateAmount
  const [account] = stateBankAccount
  const [fee, setFee] = useState(0)
  const [feeLoaded, setFeeloaded] = useState(false)
  const stateModal = useState<GiftShopWithdrawValidationType>('hidden')
  const [modal, setModal] = stateModal
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const user = useSelector('user')
  const lang = useMemo(() => (user ? user.lang : 'id'), [user])
  const transactionDateFormat = useMemo(
    () => (lang === 'en' ? 'MMMM D, YYYY' : 'D MMMM YYYY'),
    [lang],
  )
  const withdrawInfoDateFormat = useMemo(
    () =>
      lang === 'en'
        ? 'dddd, MMMM D, YYYY, hh:mm A'
        : 'dddd, D MMMM YYYY, HH:mm',
    [lang],
  )

  const {checkDate, transferDate} = getWithdrawInfoDate(date)

  const form = useForm<WithdrawPasswordFormData>({
    defaultValues: {password: ''},
    reValidateMode: 'onChange',
  })
  const {setError, watch} = form
  const {password} = watch()
  const [hasPassword, setHasPassword] = useState(false)

  const getBankName = useCallback(() => {
    if (account) {
      const bankName = account.bank_name

      return bankName
    }
    return ''
  }, [account])

  const getAccountName = useCallback(() => {
    if (account) {
      const accountName = account.account_name.toUpperCase()

      return accountName
    }
    return ''
  }, [account])

  const getWithdrawFee = useCallback(() => {
    if (!feeLoaded) {
      const amount = parseInt(withdrawAmount, 10)
      requestData('giftshop_get_withdraw_fee', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {amount},
        onRequestSuccess: ({status, data: dataResponse}) => {
          if (status === 200) {
            setFee(-dataResponse.result.total)
            setFeeloaded(true)
          }
        },
      })
    }
  }, [feeLoaded, withdrawAmount])

  const debounce = useDebounce()

  const handlePostWithdraw = useCallback(() => {
    setIsLoading(true)
    debounce(() => {
      if (account) {
        requestData('giftshop_withdraw', {
          data: {
            amount: withdrawAmount,
            bank_account_id: account?.id,
            password,
            is_host: roletype === 'host',
          },
          onRequestFailed: () => {
            setIsLoading(false)
            setModal('failed')
          },
          onRequestSuccess: ({status, data: {detail}}) => {
            setIsLoading(false)
            if (status === 200) {
              setModal('success')
            } else if (status === 400 || status === 401 || status === 404) {
              const attempt = (detail && Number(detail.attempt)) || 0
              setError('password', {
                type: 'manual',
                message: translate('giftShop:withdrawInvalidPassword', {
                  attempt: AUTH_LOGIN_MAX_ATTEMPT - attempt,
                }),
              })
            } else if (status === 422 || status === 423) {
              setModal('hidden')
            }
          },
          cancelId: SERVICE_CANCELLATION_POST_WITHDRAW,
        })
      }
    })
  }, [
    account,
    debounce,
    password,
    roletype,
    setError,
    setModal,
    translate,
    withdrawAmount,
  ])

  const handleHasPassword = useCallback(() => {
    requestData('auth_get_self', {
      useDefaultMessage: true,
      actionType: 'fetch',
      onRequestSuccess: ({data: response}) => {
        setHasPassword(response.result.has_password)
      },
    })
  }, [])

  const handlePatchPassword = useCallback(() => {
    setIsLoading(true)
    debounce(() =>
      requestData('auth_patch_passwordsso', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {
          password,
        },
        onRequestSuccess: ({status}) => {
          setIsLoading(false)
          if (status === 200) {
            if (account) {
              requestData('giftshop_withdraw', {
                data: {
                  amount: withdrawAmount,
                  bank_account_id: account?.id,
                  password,
                },
                onRequestFailed: () => {
                  setModal('failed')
                },
                onRequestSuccess: ({
                  status: withdrawStatus,
                  data: {detail},
                }) => {
                  if (withdrawStatus === 200) {
                    setModal('success')
                  } else if (withdrawStatus === 400) {
                    setError('password', {
                      type: 'manual',
                      message: translate('giftShop:withdrawInvalidPassword', {
                        attempt: (detail && Number(detail.attempt)) || 0,
                      }),
                    })
                  } else if (withdrawStatus === 422 || withdrawStatus === 423) {
                    setModal('hidden')
                  }
                },
              })
            }
          } else if (status === 422) {
            setModal('hidden')
          }
        },
        onRequestFailed: () => {
          setIsLoading(false)
          setModal('failed')
        },
      }),
    )
  }, [
    account,
    debounce,
    password,
    setError,
    setModal,
    translate,
    withdrawAmount,
  ])

  const handleRenderModal = useMemo(() => {
    if (account) {
      switch (modal) {
        case 'input':
          return hasPassword ? (
            <TransactionWithdrawValidation
              disabledButton={isLoading}
              isLoading={isLoading}
              withdrawForm={form}
              onClickConfirm={handlePostWithdraw}
              onClickCancel={() => setModal('hidden')}
              accountDetails={account}
              withdrawTotal={getCurrencyValue(
                parseInt(withdrawAmount, 10) + fee,
              )}
            />
          ) : (
            <GiftShopCreatePasswordSSOModal
              disabledButton={isLoading}
              isLoading={isLoading}
              onClickConfirm={handlePatchPassword}
              onClickBackDrop={() => setModal('hidden')}
              withdrawForm={form}
            />
          )
        case 'success':
          return (
            <TransactionWithdrawResultModalTemplate
              iconType="process"
              iconColor="warning_3"
              iconSize={41.7}
              title={translate('global:processed')}
              description={translate('giftShop:withdrawProcessSuccess')}
              buttonLabel={translate('global:ok')}
              onClickButton={() =>
                roletype === 'user'
                  ? history.replace('giftshop_balance', {})
                  : history.replace('tree_balance', {})
              }
            />
          )
        case 'failed':
          return (
            <TransactionWithdrawResultModalTemplate
              iconType="close"
              iconColor="gray_3"
              iconSize={36}
              title={translate('global:failed')}
              description={translate('giftShop:withdrawProcessFailed')}
              buttonLabel={translate('global:tryAgain')}
              onClickButton={() => setModal('input')}
            />
          )
        case 'limit':
        case 'hidden':
          return null
      }
    }
    return null
  }, [
    account,
    fee,
    form,
    handlePatchPassword,
    handlePostWithdraw,
    hasPassword,
    history,
    isLoading,
    modal,
    roletype,
    setModal,
    translate,
    withdrawAmount,
  ])

  useEffect(() => {
    handleHasPassword()
    getWithdrawFee()
  }, [getWithdrawFee, handleHasPassword])

  return (
    <StyledScrollContainer>
      <StyledMessageContainer purpose="disclaimer">
        <Paragraph fontSize="s" fontWeight="medium" color="gray_6">
          {roletype === 'user'
            ? translate('giftShop:withdrawConfirmationDisclaimer')
            : translate('tree:withdrawConfirmationDisclaimer')}
        </Paragraph>
        {roletype === 'user' && (
          <>
            <Paragraph fontSize="m" fontWeight="bold">
              {translate('giftShop:withdrawConfirmationDisclaimer', {
                context: 'time',
              })}
            </Paragraph>
            <Paragraph>
              {translate('giftShop:withdrawConfirmationDisclaimer', {
                context: 'desc',
              })}
            </Paragraph>
          </>
        )}
      </StyledMessageContainer>
      {handleRenderModal}
      <StyledContainer mode={mode}>
        <StyledComponentContainer mode={mode}>
          <StyledTitle fontSize="xl" fontWeight="bold">
            {translate('giftShop:withdrawDetailLabel')}
          </StyledTitle>
          {account && (
            <TransactionWithdrawConfirmationItem
              upperText={translate('giftShop:accountLabel')}
              lowerText={translate('giftShop:bankAccountDetail', {
                bankName: getBankName(),
                name: getAccountName(),
              })}
            />
          )}
          <StyledRow>
            {account && (
              <TransactionWithdrawConfirmationItem
                upperText={translate('giftShop:addBankInputAccountNumberLabel')}
                lowerText={account.account_number}
              />
            )}
            <TransactionWithdrawConfirmationItem
              upperText={translate('giftShop:transactionDate')}
              lowerText={parseDate(date, transactionDateFormat)}
            />
          </StyledRow>
          <StyledRow>
            <TransactionWithdrawConfirmationItem
              upperText={translate('giftShop:nominalLabel')}
              lowerText={getCurrencyValue(parseInt(withdrawAmount, 10))}
            />
            <TransactionWithdrawConfirmationItem
              upperText={translate('giftShop:withdrawFee')}
              lowerText={getCurrencyValue(fee)}
              lowerTextColor="danger_5"
            />
          </StyledRow>
          <StyledRow>
            <TransactionWithdrawConfirmationItem
              upperText={translate('giftShop:totalWithdraw')}
              lowerText={getCurrencyValue(parseInt(withdrawAmount, 10) + fee)}
              lowerFontWeight="bold"
              lowerTextColor="primary_5"
              marginBottom={20}
            />
          </StyledRow>
          <StyledMessageContainer purpose="explanation">
            <Paragraph
              fontSize="s"
              fontWeight="bold"
              style={{lineHeight: convertUnit(21)}}>
              {translate('giftShop:withdrawConfirmationExplanationFirst')}
            </Paragraph>
            <Paragraph
              fontSize="s"
              fontWeight="regular"
              style={{lineHeight: convertUnit(21)}}>
              {parseDate(checkDate, withdrawInfoDateFormat)}
            </Paragraph>
            <Paragraph
              fontSize="s"
              fontWeight="bold"
              style={{lineHeight: convertUnit(21)}}>
              {translate('giftShop:withdrawConfirmationExplanationSecond')}
            </Paragraph>
            <Paragraph
              fontSize="s"
              fontWeight="regular"
              style={{lineHeight: convertUnit(21)}}>
              {parseDate(transferDate, withdrawInfoDateFormat)}
            </Paragraph>
          </StyledMessageContainer>
          <StyledButton
            label={translate('global:confirm')}
            color="white_1"
            fontSize="m"
            fontWeight="medium"
            disabled={!feeLoaded}
            disableColor="gray_1"
            onClick={() => setModal('input')}
          />
        </StyledComponentContainer>
      </StyledContainer>
    </StyledScrollContainer>
  )
}
