import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {useSelector} from 'lib/redux'
import {requestData} from 'services'
import {
  convertFileSizeFromByte,
  showSnackbar,
  useDebounce,
  useDidMount,
} from 'utils'
import {WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {Button, Paragraph} from 'common/components'

interface StyledContainerProps {
  width: string
}

interface StyledWindoModeProps {
  mode: WindowModeType
}

interface StyledBonusBarContainerProps {
  right: string
  mode?: WindowModeType
}

const StyledContainerBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

const StyledStorageBarContainer = styled.div`
  padding-left: ${convertUnit(6)};
  padding-right: ${convertUnit(4)};
`

const StyledProgressBarContainer = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.white_4};
  height: ${convertUnit(8)};
  border-radius: ${convertUnit(5)};
  margin-bottom: ${convertUnit(10)};
`

const StyledProgressBar = styled.div<StyledContainerProps>`
  background-color: ${({theme}) => theme.primary_6};
  height: ${convertUnit(8)};
  width: ${({width}) => width};
  border-radius: ${convertUnit(5)};
`

const StyledBonusBarContainer = styled.div<StyledBonusBarContainerProps>`
  border-left: ${convertUnit(2)} dashed ${({theme}) => theme.info_5};
  position: absolute;
  right: ${({right}) => right};
  ${({mode}) => ({
    height: mode === 'mobile' ? convertUnit(49) : convertUnit(35),
  })};
  width: 0;
`

const StyledStorageSizeContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
`

const StyledStorageSize = styled(Paragraph)`
  margin-bottom: ${convertUnit(10)};
  margin-left: ${convertUnit(6)};
  margin-right: ${convertUnit(6)};
`

const StyledUsedStorageSize = styled.span`
  color: ${({theme}) => theme.primary_5};
`

const StyledLimitStorageSize = styled.span`
  color: ${({theme}) => theme.info_5};
`

const StyledTextContainer = styled.div`
  padding-left: ${convertUnit(6)};
  width: 100%;
`

const StyledBonusText = styled(Paragraph)`
  ${({theme}) => ({
    backgroundColor: theme.white_1,
  })};
  right: ${convertUnit(10)};
  top: ${convertUnit(18)};
  max-width: ${convertUnit(70)};
  position: absolute;
`

const StyledBonusParagraph = styled(Paragraph)<StyledWindoModeProps>`
  ${({mode}) => ({
    top: mode === 'mobile' ? convertUnit(32) : convertUnit(18),
  })};
  right: 0;
  width: ${convertUnit(50)};
  position: absolute;
`

const StyledStorageWarningContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: ${convertUnit(8)};
`

const StyledTotalFilesText = styled(Paragraph)`
  margin-left: ${convertUnit(6)};
  margin-bottom: ${convertUnit(10)};
`

const StyledButton = styled(Button)`
  height: ${convertUnit(28)};
  font-size: ${convertUnit(12)};
  margin-top: ${convertUnit(8)};
`

export default function GiftShopStorageItem() {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const [percentageBar, setPercentageBar] = useState<string>('0%')
  const [bonusPercentageBar, setBonusPercentageBar] = useState<string>('0%')
  const [lowStorage, setLowStorage] = useState(false)
  const [canRequestStorage, setCanRequestStorage] = useState(false)
  const [showRequestStorage, setShowRequestStorage] = useState(false)
  const [fullStorage, setFullStorage] = useState(false)
  const {creatorStatus} = useSelector('yuserActivationState') || {}
  const debounce = useDebounce()

  const loadStorageData = useCallback(async () => {
    const response = await requestData('giftshop_get_storage')
    if (typeof response === 'string' || response.status !== 200) {
      return
    }
    const {
      limit_storage,
      used_storage,
      bonus_storage,
      total_files,
      remaining_storage,
      is_storage_addition_requested,
    } = response.data.result
    localStorage.setItem(
      'limit',
      `${convertFileSizeFromByte(limit_storage, 'GB').toFixed(2)} GB`,
    )

    localStorage.setItem(
      'used',
      `${convertFileSizeFromByte(used_storage, 'GB').toFixed(2)} GB`,
    )

    localStorage.setItem(
      'bonus',
      `${Math.round(
        (convertFileSizeFromByte(bonus_storage, 'MB') / 10) * 10,
      )} MB`,
    )

    localStorage.setItem(
      'bonusGb',
      `${parseFloat(localStorage.getItem('bonus') || '0 GB') / 1000}`,
    )

    localStorage.setItem('totalFiles', `${total_files} files`)

    const calculatePercentageBar =
      (used_storage / (limit_storage + bonus_storage)) * 100
    setPercentageBar(
      calculatePercentageBar > 100 ? '100%' : `${calculatePercentageBar}%`,
    )
    if (calculatePercentageBar > 85) {
      if (calculatePercentageBar >= 100) {
        setFullStorage(true)
      } else {
        setLowStorage(true)
      }
    }
    const calculateBonusPercentageBar =
      (bonus_storage / (limit_storage + bonus_storage)) * 100
    if (calculateBonusPercentageBar > 75) {
      setBonusPercentageBar('75%')
    } else {
      setBonusPercentageBar(
        calculateBonusPercentageBar < 15
          ? '15%'
          : `${calculateBonusPercentageBar}%`,
      )
    }

    const remainingNumeric = Number(
      convertFileSizeFromByte(remaining_storage, 'GB').toFixed(2),
    )

    setCanRequestStorage(
      remainingNumeric <= 5 &&
        !is_storage_addition_requested &&
        creatorStatus === 'verified',
    )
    setShowRequestStorage(
      is_storage_addition_requested ||
        (creatorStatus === 'verified' && remainingNumeric <= 5),
    )
  }, [creatorStatus])

  const handleRequestStorage = useCallback(() => {
    debounce(() => {
      requestData('giftshop_post_storage_request', {
        onRequestSuccess: ({status}) => {
          setCanRequestStorage(false)
          status === 200 &&
            showSnackbar(translate('giftShop:storageRequestNotice'))
        },
      })
    })
  }, [debounce, translate])

  const handleWarningText = useMemo(() => {
    if (fullStorage) {
      return (
        <StyledStorageWarningContainer>
          <Paragraph fontWeight="bold" color="danger_5">
            {translate('giftShop:storageFullInfo')}
          </Paragraph>
        </StyledStorageWarningContainer>
      )
    }
    if (lowStorage) {
      return (
        <StyledStorageWarningContainer>
          <Paragraph fontWeight="bold" color="danger_5">
            {translate(
              creatorStatus === 'verified'
                ? 'giftShop:storageLowInfo'
                : 'giftShop:storageLowInfoNonVerified',
            )}
          </Paragraph>
        </StyledStorageWarningContainer>
      )
    }
    return <></>
  }, [creatorStatus, fullStorage, lowStorage, translate])

  const handleRenderStorage = useMemo(
    () => (
      <>
        <StyledStorageSizeContainer>
          <StyledStorageSize fontSize="m" fontWeight="medium">
            <StyledUsedStorageSize>
              {localStorage.getItem('used') || '0 GB'}
            </StyledUsedStorageSize>
            {translate('giftShop:ofLimitStorage')}
            <StyledLimitStorageSize>
              {` ${translate('giftShop:gbStorage', {
                limit: (
                  parseFloat(localStorage.getItem('limit') || '0 GB') +
                  parseFloat(localStorage.getItem('bonusGb') || '0 GB')
                ).toFixed(2),
              })}`}
            </StyledLimitStorageSize>
            {translate('giftShop:usedStorage')}
          </StyledStorageSize>
        </StyledStorageSizeContainer>
        <StyledTotalFilesText fontWeight="medium" color="gray_5">
          {localStorage.getItem('totalFiles') || '0 files'}
        </StyledTotalFilesText>
        <StyledContainerBar>
          <StyledBonusBarContainer mode={mode} right={bonusPercentageBar}>
            <StyledBonusParagraph mode={mode} color="gray_5">
              {localStorage.getItem('limit') || '0 GB'}
            </StyledBonusParagraph>
          </StyledBonusBarContainer>
          <StyledBonusText color="info_5">
            {` ${translate('giftShop:bonusStorage', {
              bonus: parseFloat(
                localStorage.getItem('bonusGb') || '0 GB',
              ).toFixed(2),
            })}`}
          </StyledBonusText>
          <StyledStorageBarContainer>
            <StyledProgressBarContainer>
              <StyledProgressBar width={percentageBar} />
            </StyledProgressBarContainer>
          </StyledStorageBarContainer>
          <StyledTextContainer>
            <Paragraph color="gray_5">
              {` ${translate('giftShop:totalStorage', {
                limit: (
                  parseFloat(localStorage.getItem('limit') || '0 GB') +
                  parseFloat(localStorage.getItem('bonusGb') || '0 GB')
                ).toFixed(2),
              })}`}
            </Paragraph>
            <StyledStorageWarningContainer>
              {handleWarningText}
              <StyledButton
                style={{
                  visibility: showRequestStorage ? 'visible' : 'hidden',
                  marginLeft: 'auto',
                }}
                onClick={(e) => {
                  handleRequestStorage()
                  e.stopPropagation()
                }}
                disabled={!showRequestStorage || !canRequestStorage}>
                <Paragraph fontSize="s" color="white_1" fontWeight="bold">
                  {translate('giftShop:storageRequest')}
                </Paragraph>
              </StyledButton>
            </StyledStorageWarningContainer>
          </StyledTextContainer>
        </StyledContainerBar>
      </>
    ),
    [
      bonusPercentageBar,
      canRequestStorage,
      handleRequestStorage,
      handleWarningText,
      mode,
      percentageBar,
      showRequestStorage,
      translate,
    ],
  )

  useDidMount(() => {
    loadStorageData()
  })

  return handleRenderStorage
}
