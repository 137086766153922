import {
  ForkygramAllFootprintDetailScreen,
  ForkygramFootprintDetailScreen,
  ForkygramSearchFriendsScreen,
  ForkygramUploadContentScreen,
} from 'pages'
import {RouteMap} from '../RouteMap'
import {RouteParamForkygram} from './RouteParamForkygram'

export const ROUTE_MAP_FORKYGRAM: RouteMap<RouteParamForkygram> = {
  forkygram_upload: {
    path: '/upload/post',
    component: ForkygramUploadContentScreen,
    defaultState: null,
    permission: 'user',
  },
  forkygram_footprint_all: {
    path: '/footprint/all',
    component: ForkygramAllFootprintDetailScreen,
    defaultState: {
      memberId: '',
    },
  },
  forkygram_footprint_detail: {
    path: '/footprint/detail',
    component: ForkygramFootprintDetailScreen,
    defaultState: {
      memberId: '',
      tagId: '',
      treeId: '',
      treeName: '',
      treeAlias: '',
    },
  },
  forkygram_search_friends: {
    path: '/search-friends',
    component: ForkygramSearchFriendsScreen,
    permission: 'user',
  },
}
