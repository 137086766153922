import React, {useCallback, useEffect, useMemo, useState} from 'react'
import AutoSizer, {Size} from 'react-virtualized-auto-sizer'
import {UseFormMethods} from 'react-hook-form'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  convertTagToSearchTreeItem,
  getAndSupplyFileMime,
  getLocationPermission,
  isDateMoreThan30Days,
  showSnackbar,
  useDidMount,
  useDidUpdate,
  useGiftShopVideoAutoPreview,
  useGiftShopVideoFaceHandler,
  useHistory,
  validateUnsupportedDevice,
} from 'utils'
import {requestData, requestDataPayload} from 'services'
import {
  GiftShopLastCreationResponse,
  GiftShopContentType,
  GiftShopUploadFormData,
  GiftShopUploadQuickFillData,
  GiftShopUploadSeriesData,
  LastUploadState,
  TransactionCurrencyData,
  AuthCredentialType,
} from 'types'
import {useTheme} from 'themes'
import {GIFT_SHOP_UPLOAD_TYPE, GIFT_SHOP_UPLOAD_VIDEO_TYPE} from 'consts'
import {Flatlist, Icon, Paragraph} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopTemplateModalCredential} from '../../../template'
import {GiftShopLocationModal} from '../Location'
import {
  GiftShopUploadContentItem,
  GiftShopUploadContentItemBulk,
} from '../ContentItem'
import {
  GiftShopUploadPublishBulk,
  GiftShopUploadPublishSingle,
} from '../Publish'
import {GiftShopUploadDropzone} from '../Dropzone'
import {GiftShopUploadContentProps} from './GiftShopUploadContentProps'
import {GiftShopUploadQuickFillModal} from '../QuickFillModal'
import {GiftShopUploadPublishReport} from '../Report'

interface StyledContentItemProps {
  index: number
}

const StyledContentItem = styled(
  GiftShopUploadContentItem,
)<StyledContentItemProps>`
  ${({index}) => index > 0 && {marginTop: convertUnit(25)}}
`

const StyledListContent = styled.div`
  display: flex;
  flex: 1;
`

const StyledQuickFillBannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${convertUnit(8)} ${convertUnit(20)};
  gap: ${convertUnit(20)};
  background-color: ${({theme}) => theme.primary_1};
  cursor: pointer;
`

export default function GiftShopUploadContent({
  data,
  total,
  mapForm,
  mapRef,
  stateUploadType,
  stateViewReport,
  stateModalStatus,
  stateVideoProcessStatus,
  onDeleteSeries,
  onDeleteVariant,
  onSetParent,
  onLoadFileSeries,
}: GiftShopUploadContentProps) {
  const theme = useTheme()
  const [currentMap, setCurrentMap] = useState<
    UseFormMethods<GiftShopUploadFormData>
  >()
  const [modal, setModal] = useState(true)
  const [currencyData, setCurrencyData] = useState<TransactionCurrencyData[]>()
  const {update} = useDispatch()
  const [locPermission, setLocPermission] = useState<boolean>(false)
  const [call, setCall] = useState(false)
  const [uploadType, setUploadType] = stateUploadType
  const stateSelected = useState<string[]>([])
  const {loadVideoFaceFile} = useGiftShopVideoFaceHandler()
  const {handlePreprocessVideos} = useGiftShopVideoAutoPreview({
    loadVideoFaceFile,
    onLoadFileSeries,
    stateModalStatus,
    stateUploadType,
    stateVideoProcessStatus,
  })
  const user = useSelector('user')
  const history = useHistory()
  const hostState = useSelector('hostState')
  const {translate} = useTranslation()
  const [quickFillModal, setQuickFillModal] = useState(false)
  const [latestData, setLatestData] = useState<GiftShopLastCreationResponse>()
  const stateQuickFill = useState<GiftShopUploadQuickFillData>({
    description: false,
    location: false,
    price: false,
  })
  const quickFillState = stateQuickFill[0]
  const imgs = Object.values(data)
  const [viewReport, setViewReport] = stateViewReport
  const {visible: showReport} = viewReport
  const loadData = useCallback(() => imgs, [imgs])
  const {mapForm: mapFormInRef} = mapRef.map.current
  const credential = useMemo<AuthCredentialType | undefined>(
    () => (!user?.email ? 'email' : !user?.phone_number ? 'phone' : undefined),
    [user?.email, user?.phone_number],
  )
  const stateModalCredential = useState(false)
  const [isAnyOldContent, setIsAnyOldContent] = useState(false)

  const handlePermission = useCallback((res: PermissionStatus) => {
    switch (res.state) {
      case 'granted':
        setLocPermission(true)
        break
      case 'prompt':
        navigator.geolocation.getCurrentPosition(
          () => setLocPermission(true),
          () => setLocPermission(false),
          {enableHighAccuracy: true},
        )
        break
      case 'denied':
        setLocPermission(false)
        break
    }
  }, [])

  const handleFormUpdate = useCallback(
    (id: string, values: GiftShopUploadFormData) => {
      mapForm.current[id] = values
    },
    [mapForm],
  )

  const handleLoadCurrencyData = useCallback(
    () =>
      requestDataPayload('giftshop_get_currency', {
        useDefaultMessage: true,
        actionType: 'fetch',
        onRequestSuccess: ({data: response, status}) => {
          if (status === 200) {
            setCurrencyData(response.result)
          }
        },
      }),
    [],
  )

  const handleUpdateUploadCache = useCallback(
    (type: GiftShopContentType) => {
      requestData('giftshop_get_latest_creation', {
        params: {type},
        onRequestSuccess: ({data: response, status}) => {
          if (status === 200) {
            const {result} = response

            if (result) {
              const {description, price, tags, location} = result

              const state: Partial<LastUploadState> = {}

              /** @todo <Following condition is to be re-implemented in near future, update directly to form> */
              // if (updateState) {
              state.description = description
              state.price = price.toString()
              state.tags = hostState.role
                ? tags.map((tag) => convertTagToSearchTreeItem(tag))
                : []
              // }

              update(
                type === 'photo'
                  ? 'lastUploadStatePhoto'
                  : 'lastUploadStateVideo',
                state,
              )
              if (type === 'photo' && (price || description || location.name)) {
                setLatestData(result)
              }
            }
          }
        },
      })
    },
    [hostState.role, update],
  )

  const handleKeyExtractor = useCallback(
    (item: GiftShopUploadSeriesData) => item.id,
    [],
  )

  const handleValidateFile = useCallback((file: File) => {
    const type = getAndSupplyFileMime(GIFT_SHOP_UPLOAD_TYPE, file)

    if (GIFT_SHOP_UPLOAD_TYPE.includes(type)) return null

    return {
      code: 'invalid-format',
      message: 'Invalid format',
    }
  }, [])

  const handleValidateVideo = useCallback((file: File) => {
    const type = getAndSupplyFileMime(GIFT_SHOP_UPLOAD_VIDEO_TYPE, file)

    if (GIFT_SHOP_UPLOAD_VIDEO_TYPE.includes(type)) return null

    return {
      code: 'invalid-format',
      message: 'Invalid format',
    }
  }, [])

  const handleNavigateAddCredential = useCallback(() => {
    stateModalCredential[1](false)
    history.push('giftshop_edit_profile', {
      menu: credential === 'email' ? 'email' : 'phone',
    })
  }, [credential, history, stateModalCredential])

  const handleRenderCredentialModal = useMemo(
    () => (
      <GiftShopTemplateModalCredential
        stateModal={stateModalCredential}
        credential={credential}
        onAgree={handleNavigateAddCredential}
      />
    ),
    [credential, handleNavigateAddCredential, stateModalCredential],
  )

  const handleRenderDropzone = useMemo(
    () => (
      <GiftShopUploadDropzone
        onUploadBulk={(fileList, seriesId) => {
          onLoadFileSeries(fileList, seriesId, 'bulk')
          setUploadType('bulk')
        }}
        onUploadSingle={(fileList, seriesId) => {
          onLoadFileSeries(fileList, seriesId, 'single')
          setUploadType('single')
        }}
        validator={handleValidateFile}
        onUploadVideo={async (fileList, seriesId) => {
          setUploadType('video')
          const validVideos = fileList.filter(
            (file) => !handleValidateVideo(file),
          )

          const isNotSupported = validateUnsupportedDevice()
          if (isNotSupported) {
            showSnackbar(translate('giftShop:videoUploadErrorInvalidDevice'))
            return
          }

          if (validVideos.length > 0)
            handlePreprocessVideos(validVideos, seriesId)
          else showSnackbar(translate('giftShop:videoUploadErrorInvalidFormat'))
        }}
        videoValidator={handleValidateVideo}
        onClickBulk={() => setUploadType('bulk')}
        onClickSingle={() => setUploadType('single')}
        onClickVideo={() => setUploadType('video')}
        onNonVerifiedCreatorUpload={() => {
          if (credential) {
            stateModalCredential[1](true)
          } else {
            history.push('giftshop_upgrade_creator', {})
          }
        }}
      />
    ),
    [
      credential,
      handlePreprocessVideos,
      handleValidateFile,
      handleValidateVideo,
      history,
      onLoadFileSeries,
      setUploadType,
      stateModalCredential,
      translate,
    ],
  )

  const handleRenderItem = useCallback(
    (item: GiftShopUploadSeriesData, index: number) => (
      <StyledContentItem
        key={item.id}
        index={index}
        data={item}
        total={total}
        currencyData={currencyData}
        defaultValues={mapForm.current[item.id]}
        uploadType={uploadType}
        onFormUpdate={(form) => handleFormUpdate(item.id, form)}
        onAddLocation={setCurrentMap}
        onDelete={() => {
          onDeleteSeries(item.id)
        }}
        onSetParent={(variant) => onSetParent(item, variant)}
        onDeleteVariant={onDeleteVariant}
        onLoadFileSeries={onLoadFileSeries}
        toggleModal={() => setModal(true)}
        latestData={latestData}
        quickFill={quickFillState}
      />
    ),
    [
      total,
      currencyData,
      mapForm,
      uploadType,
      onDeleteVariant,
      onLoadFileSeries,
      latestData,
      quickFillState,
      handleFormUpdate,
      onDeleteSeries,
      onSetParent,
    ],
  )

  const handleRenderLocation = useMemo(
    () => (
      <GiftShopLocationModal
        updateUploadState
        showField="giftshop"
        mapForm={currentMap}
        onSave={() => setCurrentMap(undefined)}
        toggleModal={() => setModal((prev) => !prev)}
        visible={modal}
        uploadType={uploadType}
      />
    ),
    [currentMap, modal, uploadType],
  )

  const handleRenderQuickFillBanner = useMemo(
    () =>
      latestData ? (
        <StyledQuickFillBannerContainer onClick={() => setQuickFillModal(true)}>
          <Icon type="pencil" size={20} color="primary_5" />
          <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
            {translate('giftShop:uploadQuickFillBanner')}
          </Paragraph>
        </StyledQuickFillBannerContainer>
      ) : (
        <></>
      ),
    [latestData, translate],
  )

  const handleRenderListContent = useCallback(
    ({height, width}: Size) => (
      <Flatlist
        height={height}
        width={width}
        data={Object.values(data)}
        keyExtractor={handleKeyExtractor}
        renderItem={handleRenderItem}
        contentContainerStyle={{
          padding: convertUnit(20),
          boxSizing: 'border-box',
          backgroundColor: theme.white_2,
        }}
      />
    ),
    [data, handleKeyExtractor, handleRenderItem, theme.white_2],
  )

  const handleRenderUploadSingleList = useMemo(
    () => (
      <>
        {handleRenderQuickFillBanner}
        <StyledListContent>
          <AutoSizer>{handleRenderListContent}</AutoSizer>
        </StyledListContent>
        <GiftShopUploadPublishSingle
          data={data}
          total={total}
          formRef={mapForm}
          mapRef={mapRef}
          uploadType={uploadType}
          onView={(defaultTab = 'failed') => {
            setViewReport((prev) => ({
              ...prev,
              tab: defaultTab,
              visible: true,
            }))
          }}
          hideModal={showReport}
        />
      </>
    ),
    [
      handleRenderQuickFillBanner,
      handleRenderListContent,
      data,
      total,
      mapForm,
      mapRef,
      uploadType,
      showReport,
      setViewReport,
    ],
  )

  const handleRenderBulkUploadImage = useMemo(
    () =>
      imgs[0] && (
        <>
          <GiftShopUploadContentItemBulk
            data={imgs}
            loadData={loadData}
            stateSelected={stateSelected}
            defaultValues={mapForm.current[imgs[0].id]}
            onAddLocation={setCurrentMap}
            onDelete={(ids) => {
              ids.forEach((id) => {
                onDeleteSeries(id)
              })
            }}
            onFormUpdate={(form) => {
              imgs.forEach((img) => {
                handleFormUpdate(img.id, form)
              })
            }}
            toggleModal={() => setModal(true)}
            uploadType={uploadType}
          />
          <GiftShopUploadPublishBulk
            data={data}
            total={total}
            formRef={mapForm}
            mapRef={mapRef}
            uploadType={uploadType}
            onView={(defaultTab = 'failed') => {
              setViewReport((prev) => ({
                ...prev,
                tab: defaultTab,
                visible: true,
              }))
            }}
            hideModal={showReport}
          />
        </>
      ),
    [
      data,
      handleFormUpdate,
      imgs,
      loadData,
      mapForm,
      mapRef,
      onDeleteSeries,
      setViewReport,
      showReport,
      stateSelected,
      total,
      uploadType,
    ],
  )

  const handleRenderUploadContentType = useMemo(() => {
    switch (uploadType) {
      case 'bulk':
        return handleRenderBulkUploadImage
      case 'single':
        return handleRenderUploadSingleList
      case 'video':
        return handleRenderBulkUploadImage
    }
    return <></>
  }, [handleRenderBulkUploadImage, handleRenderUploadSingleList, uploadType])

  const handleRenderContent = useMemo(
    () => (total ? handleRenderUploadContentType : handleRenderDropzone),
    [total, handleRenderUploadContentType, handleRenderDropzone],
  )

  const handleRenderQuickFillModal = useMemo(
    () =>
      latestData ? (
        <GiftShopUploadQuickFillModal
          data={latestData}
          visible={quickFillModal}
          toggleModal={() => setQuickFillModal(false)}
          stateQuickFill={stateQuickFill}
        />
      ) : (
        <></>
      ),
    [latestData, quickFillModal, stateQuickFill],
  )

  useDidMount(() => {
    handleLoadCurrencyData()
  })

  useDidUpdate(() => {
    if (!call && locPermission) {
      handleUpdateUploadCache('photo')
      handleUpdateUploadCache('video')
    }
    setCall(true)
  }, [call, handleUpdateUploadCache, locPermission])

  useDidMount(() => {
    getLocationPermission(handlePermission, () =>
      navigator.geolocation.getCurrentPosition(
        () => setLocPermission(true),
        () => setLocPermission(false),
        {enableHighAccuracy: true},
      ),
    )
  })

  const handleRenderReport = useMemo(
    () => (
      <GiftShopUploadPublishReport
        stateViewReport={stateViewReport}
        mapForm={mapFormInRef}
      />
    ),
    [mapFormInRef, stateViewReport],
  )

  const handleRenderPage = useMemo(
    () =>
      showReport ? (
        handleRenderReport
      ) : (
        <>
          {handleRenderCredentialModal}
          {handleRenderQuickFillModal}
          {handleRenderLocation}
          {handleRenderContent}
        </>
      ),
    [
      handleRenderContent,
      handleRenderCredentialModal,
      handleRenderLocation,
      handleRenderQuickFillModal,
      handleRenderReport,
      showReport,
    ],
  )

  useEffect(() => {
    for (const img of imgs) {
      const {id} = img
      if (
        isDateMoreThan30Days(mapForm.current[id].originalAt) &&
        !isAnyOldContent
      ) {
        showSnackbar(translate('giftShop:oldContentReminder'))
        setIsAnyOldContent(true)
      }
    }
  }, [imgs, isAnyOldContent, mapForm, translate])

  return handleRenderPage
}
