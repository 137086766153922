import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {Image, Icon, Paragraph, BannerReferralModal} from 'common/components'
import {GiftShopBannerReferralTemplateProps} from './GiftShopBannerReferralTemplateProps'

const StyledContentContainer = styled.div`
  height: 100%;
  display: flex;
  background-color: ${({theme}) => theme.info_1};
  border-radius: ${convertUnit(16)};
  padding: ${convertUnit(24)};
`

const StyledRobopetImageContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(88)};
  aspect-ratio: 1;
  object-fit: contain;
  margin-right: ${convertUnit(12)};
`

const StyledBlankContainer = styled.div`
  text-align: left;
`

const StyledClosedButton = styled.div`
  cursor: pointer;
  top: ${convertUnit(12)};
  right: ${convertUnit(12)};
`

const StyledReadMore = styled.a`
  cursor: pointer;
  font-family: Roboto-Bold;
  font-size: ${convertUnit(12)};
  color: ${({theme}) => theme.primary_5};
  margin-left: ${convertUnit(4)};
`

export default function GiftShopBannerReferralTemplate({
  page = 'setting',
}: GiftShopBannerReferralTemplateProps) {
  const {translate} = useTranslation()
  const img = IMAGE_ASSET('giftshop', 'referal-pic.png')
  const {update} = useDispatch()
  const bannerClosed = useSelector('bannerClosed')
  const [modalVisible, setModalVisible] = useState(false)
  const {creatorStatus} = useSelector('yuserActivationState')
  const isCreator = creatorStatus === 'verified'

  const handleCloseButton = useCallback(() => {
    if (page === 'setting') {
      update('bannerClosed', {setting: true})
    } else {
      update('bannerClosed', {roboyu: true})
    }
  }, [update, page])

  const handleReadMoreButton = useCallback(() => {
    setModalVisible(true)
  }, [])

  const handleBannerOk = useCallback(() => {
    setModalVisible(false)
  }, [])

  return (
    <>
      <BannerReferralModal
        isCreator={isCreator}
        visible={modalVisible}
        title={translate('giftShop:firstBuyReferralModalTitle')}
        messageOne={
          !isCreator
            ? translate('giftShop:firstBuyReferralModalDescription')
            : translate(
                'giftShop:firstBuyReferralVerifiedModalDescriptionPartOne',
              )
        }
        messageTwo={
          !isCreator
            ? ''
            : translate(
                'giftShop:firstBuyReferralVerifiedModalDescriptionPartTwo',
              )
        }
        footerOne={
          !isCreator
            ? translate('giftShop:firstBuyReferralModalFooterPartOne')
            : ''
        }
        footerTwo={
          !isCreator
            ? translate('giftShop:firstBuyReferralModalFooterPartTwo')
            : ''
        }
        onClickOk={handleBannerOk}
      />
      {(page === 'setting' ? !bannerClosed.setting : !bannerClosed.roboyu) && (
        <StyledContentContainer>
          <StyledRobopetImageContainer>
            <Image src={img} alt={img} />
          </StyledRobopetImageContainer>
          <StyledBlankContainer>
            <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
              {translate('giftShop:roboyuFirstBuyBannerTitle')}
            </Paragraph>
            <Paragraph fontSize="s" color="primary_5">
              {translate('giftShop:roboyuFirstBuyBannerDescription')}
              <span>
                <StyledReadMore onClick={handleReadMoreButton}>
                  {translate('giftShop:roboyuReadMore')}
                </StyledReadMore>
              </span>
            </Paragraph>
          </StyledBlankContainer>
          <StyledClosedButton onClick={handleCloseButton}>
            <Icon size={convertUnit(12)} type="close" color="primary_5" />
          </StyledClosedButton>
        </StyledContentContainer>
      )}
    </>
  )
}
