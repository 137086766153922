import React from 'react'
import {useListLazyAction} from 'utils'
import {ListLazyBase} from '../ListLazyBase'
import {ListLazyProps} from './ListLazyProps'

export default function ListLazy<ItemT, S>({
  data,
  initialPage,
  limitPerPage,
  search,
  refresh,
  stateData,
  cache,
  loadData,
  keyExtractor,
  anchorExtractor,
  primaryExtractor,
  ...props
}: ListLazyProps<ItemT, S>) {
  const {
    items,
    status,
    handleEndReached,
    handleLayout,
    handleContentSizeChange,
  } = useListLazyAction<ItemT, S>({
    data,
    cache,
    stateData,
    refresh,
    search,
    initialPage,
    limitPerPage,
    loadData,
    keyExtractor,
    anchorExtractor,
    primaryExtractor,
  })

  return (
    <ListLazyBase
      {...props}
      data={items}
      status={status}
      anchorExtractor={anchorExtractor}
      keyExtractor={keyExtractor}
      onEndReached={handleEndReached}
      onLayout={handleLayout}
      onContentSizeChange={handleContentSizeChange}
    />
  )
}
