import React, {useCallback, useMemo, useRef} from 'react'
import ReactHlsPlayer from '@gumlet/react-hls-player'
import styled, {CSSProperties} from 'styled-components'
import {GIFT_SHOP_ROOT_CONTENT_ITEM_PADDING, IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {useWindowMode} from 'windows'
import {getContentURL, getThemeColor, isDiscountPrice} from 'utils'
import convertUnit from 'lib/unit'
import {Icon, Image, Paragraph, RadioItem} from 'common/components'
import {GiftShopTemplateMasonryItemProps} from './GiftShopTemplateMasonryItemProps'
import {GiftShopTemplateFilterOverlay} from '../FilterOverlay'
import {
  GiftShopTemplateContentBadge,
  GiftShopTemplateContentRightBadge,
  GiftShopTemplateContentVideoBadge,
} from '../Badge'

interface StyledBaseContainerProps {
  cursor?: CSSProperties['cursor']
}

const StyledBaseContainer = styled.div<StyledBaseContainerProps>`
  ${({cursor}) => ({cursor})}
  position: relative;
  flex: 1;
`

const StyledItemContainer = styled.div`
  position: absolute;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: ${convertUnit(GIFT_SHOP_ROOT_CONTENT_ITEM_PADDING)};
`

const StyledItemContentContainer = styled.div`
  position: relative;
  flex: 1;
`

const StyledProgresiveImage = styled(Image)`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  object-fit: scale-down;
  background-color: ${({theme}) => theme.gray_2};
`

const StyledRadioItem = styled(RadioItem)`
  position: absolute;
  z-index: 2;
  top: ${convertUnit(16)};
  left: ${convertUnit(16)};
`
const StyledHiResBadgeContainer = styled.div`
  padding: ${convertUnit(2)} ${convertUnit(8)};
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 0 ${convertUnit(5)} 0;
  background-color: ${({theme}) => getThemeColor(theme, 'gray_7', 0.6)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

const StyledFoundConfirmedContainer = styled.div`
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.5)};
  display: flex;
  flex-direction: column;
  padding-left: ${convertUnit(1.6)};
  padding-top: ${convertUnit(2)};
  padding-bottom: ${convertUnit(2)};
  padding-right: ${convertUnit(4)};
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-top-right-radius: ${convertUnit(4)};
`

const StyledFoundConfirmedItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledVideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: inherit;
  height: 100%;
  width: 100%;
`

const StyledOverlay = styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

const StyledVideo = styled(ReactHlsPlayer)`
  box-sizing: border-box;
  height: inherit;
  width: inherit;
  object-fit: scale-down;
  background-color: ${({theme}) => theme.gray_2};
`

export default function GiftShopTemplateMasonryItem({
  item,
  toggleSelect = false,
  isSelected = false,
  showResBadge = false,
  showPurchaseStatusBadge = false,
  showUnwishlistBadge,
  showFoundConfirmed = false,
  onMouseHold = false,
  onClickItem,
  onSelectRule,
}: GiftShopTemplateMasonryItemProps) {
  const {
    url,
    title,
    is_valid = true,
    is_reported,
    purchase_status,
    is_deletable,
    price,
    initial_price,
    quality,
    found_count,
    found_count_low,
    confirmed_count,
    type,
    content_type,
    video_url,
    is_wishlist,
    is_robofind_done,
    upload_source,
  } = item
  const brokenImage = IMAGE_ASSET('giftshop', 'broken-image-explore.png')
  const mode = useWindowMode()
  const {translate} = useTranslation()
  const videoRef = useRef<HTMLVideoElement>(null)

  const isPriceDiscount = useMemo(() => isDiscountPrice(price, initial_price), [
    initial_price,
    price,
  ])

  const handleRenderFilter = useMemo(
    () => !is_valid && <GiftShopTemplateFilterOverlay />,
    [is_valid],
  )

  const handleRenderSelect = useMemo(
    () =>
      toggleSelect &&
      onSelectRule &&
      onSelectRule(item) && (
        <StyledRadioItem isSelected={isSelected} size={32} />
      ),
    [isSelected, item, onSelectRule, toggleSelect],
  )

  const handleRenderDiscountBadge = useMemo(
    () =>
      isPriceDiscount && (
        <GiftShopTemplateContentRightBadge
          icon="percentage"
          backgroundColor="success_5"
        />
      ),
    [isPriceDiscount],
  )

  const handleRenderPurchaseStatusBadge = useMemo(
    () =>
      (purchase_status === 'sold' ||
        purchase_status === 'resend' ||
        purchase_status === 'resendpology') &&
      showPurchaseStatusBadge && (
        <GiftShopTemplateContentBadge type={purchase_status} />
      ),
    [purchase_status, showPurchaseStatusBadge],
  )

  const handleRenderUnwishlistBadge = useMemo(
    () =>
      showUnwishlistBadge &&
      is_deletable && (
        <GiftShopTemplateContentBadge
          isDeletable={is_deletable}
          type={undefined}
        />
      ),
    [showUnwishlistBadge, is_deletable],
  )

  const handleRenderHiResBadge = useMemo(
    () =>
      showResBadge &&
      upload_source === 'sell' &&
      quality === 'hi-res' && (
        <StyledHiResBadgeContainer>
          <Paragraph
            fontSize={mode === 'website' ? 'm' : 's'}
            fontWeight="medium"
            color="white_1">
            {translate('giftShop:collectionHiResBadge')}
          </Paragraph>
        </StyledHiResBadgeContainer>
      ),
    [showResBadge, upload_source, quality, mode, translate],
  )

  const handleRenderFoundConfirmedBadge = useMemo(
    () =>
      showFoundConfirmed ? (
        <StyledFoundConfirmedContainer>
          <StyledFoundConfirmedItem>
            <Icon type="found-up" color="white_1" size={convertUnit(12)} />
            <Paragraph color="white_1" fontSize="s" fontWeight="medium">
              {is_robofind_done ? found_count : '-'}
            </Paragraph>
          </StyledFoundConfirmedItem>
          <StyledFoundConfirmedItem>
            <Icon type="found-down" color="white_1" size={convertUnit(12)} />
            <Paragraph color="white_1" fontSize="s" fontWeight="medium">
              {is_robofind_done ? found_count_low : '-'}
            </Paragraph>
          </StyledFoundConfirmedItem>
          <StyledFoundConfirmedItem>
            <Icon type="fotoyu" color="white_1" size={convertUnit(12)} />
            <Paragraph color="white_1" fontSize="s" fontWeight="medium">
              {is_robofind_done ? confirmed_count : '-'}
            </Paragraph>
          </StyledFoundConfirmedItem>
        </StyledFoundConfirmedContainer>
      ) : (
        <></>
      ),
    [
      confirmed_count,
      found_count,
      found_count_low,
      is_robofind_done,
      showFoundConfirmed,
    ],
  )

  const handleRenderVideoBadge = useMemo(
    () =>
      content_type === 'video' ? <GiftShopTemplateContentVideoBadge /> : <></>,
    [content_type],
  )

  const handleRenderMasonryImage = useMemo(
    () =>
      !is_reported && (
        <StyledItemContentContainer>
          {handleRenderFilter}
          {handleRenderSelect}
          {handleRenderDiscountBadge}
          {handleRenderPurchaseStatusBadge}
          {handleRenderUnwishlistBadge}
          {handleRenderHiResBadge}
          {handleRenderFoundConfirmedBadge}
          {handleRenderVideoBadge}
          {content_type === 'video' &&
          (is_wishlist || type !== 'is_this_you') ? (
            <StyledVideoContainer>
              <StyledOverlay />
              <StyledVideo
                playerRef={videoRef}
                src={getContentURL({url: video_url, type})}
              />
            </StyledVideoContainer>
          ) : (
            <StyledProgresiveImage
              useLazyLoad
              src={getContentURL({url, type})}
              height="100%"
              width="100%"
              alt={title}
              onError={(e) => {
                e.currentTarget.src = brokenImage
              }}
            />
          )}
        </StyledItemContentContainer>
      ),
    [
      is_reported,
      handleRenderFilter,
      handleRenderSelect,
      handleRenderDiscountBadge,
      handleRenderPurchaseStatusBadge,
      handleRenderUnwishlistBadge,
      handleRenderHiResBadge,
      handleRenderFoundConfirmedBadge,
      handleRenderVideoBadge,
      content_type,
      is_wishlist,
      type,
      video_url,
      url,
      title,
      brokenImage,
    ],
  )

  const handleClick = useCallback(() => {
    if (toggleSelect && onSelectRule && !onSelectRule(item)) return undefined
    onClickItem && onClickItem(item)
    return undefined
  }, [item, onClickItem, onSelectRule, toggleSelect])

  return (
    <StyledBaseContainer cursor={!toggleSelect ? 'pointer' : undefined}>
      <StyledItemContainer
        style={{userSelect: 'none'}}
        onMouseDown={handleClick}
        onMouseEnter={() => onMouseHold && handleClick()}>
        {!is_reported && handleRenderMasonryImage}
      </StyledItemContainer>
    </StyledBaseContainer>
  )
}
