import React, {useMemo, useState, useRef, useEffect, useCallback} from 'react'
import styled from 'styled-components'
import {
  FORKYGRAM_CAPTION_LINE_HEIGHT,
  IMAGE_ASSET,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {ForkygramTemplatePostButtons, TemplateAuthAccessModal} from 'pages'
import {
  fototreeLocationName,
  getBorder,
  getTextShadow,
  getThemeColor,
  useDidUpdate,
  useHistory,
} from 'utils'
import {useWindowLayout} from 'windows'
import {Icon, Image, IconVerifiedBadge, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {AssetImageFotoTree} from 'types'
import {ForkygramTemplatePostProgressBar} from '../../../../forkygram'
import {FototreeMemoryContentItemProps} from './FototreeContentItemProps'
import FototreeMemoryContentItemVideo from './FototreeMemoryContentItemVideo'

interface StyledContentProps {
  height: number
}

interface StyledParagraphProps {
  collapse: boolean
}

interface StyledProfileProps {
  shouldUpdateGap: boolean
}

const StyledContentImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  box-sizing: border-box;
`

const StyledMobileCaptionContainer = styled.div`
  position: absolute;
  left: ${convertUnit(12)};
  bottom: ${convertUnit(12)};
  display: flex;
  flex-direction: column;
  width: calc(100% - (${convertUnit(70)}));
  max-width: ${WINDOW_MODE_MOBILE_WIDTH}px;
`

const StyledCaptionContainer = styled.div`
  display: flex;
  flex: 1;
  width: fill-available;
  flex-direction: column;
`

const StyledUsernameContainer = styled.div`
  display: flex-end;
`

const StyledCaptionContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
`

const StyledParagraphContainer = styled.div<StyledParagraphProps>`
  height: fit-content;
  max-height: ${({collapse}) => (collapse ? convertUnit(30) : 'fit-content')};
  overflow: hidden;
`

const StyledParagraph = styled(Paragraph)`
  text-shadow: ${({theme}) => getTextShadow(theme)};
`
const StyledIconWrapper = styled.div`
  display: inline;
`

const StyledCollapsibleParagraph = styled(Paragraph)<StyledParagraphProps>`
  ${({collapse}) =>
    collapse && {
      overflow: 'hidden',
    }};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: auto;
  word-break: break-word;
  white-space: pre-wrap;
  text-shadow: ${({theme}) => getTextShadow(theme)};
`
const StyledAvatarContainer = styled.div`
  position: relative;
  cursor: pointer;
`
const StyledAvatar = styled(Image)`
  border: ${({theme}) => getBorder(1, 'solid', theme.white_1)};
  border-radius: ${convertUnit(5)};
  object-fit: contain;
  width: ${convertUnit(45)};
  height: ${convertUnit(60)};
  background-color: ${({theme}) => theme.black};
  box-sizing: border-box;
`

const StyledContentContainer = styled.div<StyledContentProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({height}) => convertUnit(height)};
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  max-width: ${convertUnit(600)};
  margin: auto;
`

const StyledProfileContainer = styled.div<StyledProfileProps>`
  ${({shouldUpdateGap}) => ({gap: convertUnit(shouldUpdateGap ? 16 : 8)})};
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: fit-content;
`

const StyledIconVerifiedBadge = styled(IconVerifiedBadge)`
  display: inline-block;
  margin-right: ${convertUnit(4)};
  line-height: ${convertUnit(16)};
  margin-bottom: ${convertUnit(-4)};
`

const StyledInvisibleContainer = styled.div<StyledParagraphProps>`
  display: flex;
  column-gap: ${convertUnit(4)};
  background-color: ${({theme, collapse}) =>
    getThemeColor(theme, 'black', collapse ? 0.4 : 0.5)};
  width: fit-content;
`

const StyledFollowButton = styled.div`
  position: absolute;
  z-index: 5;
  top: ${convertUnit(4)};
  left: ${convertUnit(36)};
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(4)};
  padding: ${convertUnit(2)};
  padding-top: 0;
  cursor: pointer;
`

const StyledInlineParagraph = styled(StyledParagraph)`
  display: inline;
  word-break: break-word;
  margin-right: ${convertUnit(4)};
`

const StyledFotoTreeTag = styled.div`
  display: flex;
  height: fit-content;
  width: fit-content;
  gap: ${convertUnit(4)};
  margin-top: ${convertUnit(4)};
`

const StyledFotoTreePinContainer = styled.div`
  width: fit-content;
`

const StyledFotoTreePin = styled(Image)`
  height: ${convertUnit(16)};
`

export default function FototreeMemoryContentItem({
  data,
  dataTag,
  active,
  focused,
  stateMute,
  statePause,
  onClickMore,
  onFollow,
  stateFollowButton,
}: FototreeMemoryContentItemProps) {
  const {translate} = useTranslation()
  const {
    id,
    username,
    photo,
    creator_id,
    creator_status,
    is_followed,
  } = data.member
  const {access_token, id: userId} = useSelector('user') || {}
  const [mute, setMute] = stateMute
  const history = useHistory()
  const {height} = useWindowLayout()
  const ref = useRef<HTMLDivElement>(null)
  const stateProgress = useState(0)
  const [collapse, setCollapse] = useState(true)
  const [captionClientHeight, setClientHeight] = useState(0)
  const [captionScrollHeight, setScrollHeight] = useState(0)
  const [loginModal, setLoginModal] = useState(false)
  const followButton = stateFollowButton[0]
  const [shouldUpdateGap, setShouldUpdateGap] = useState(false)
  const usernameRef = useRef<HTMLDivElement>(null)

  const isOverflow = useMemo(() => captionScrollHeight > captionClientHeight, [
    captionClientHeight,
    captionScrollHeight,
  ])

  const locationName = useMemo(
    () => fototreeLocationName(dataTag?.location?.name),
    [dataTag?.location?.name],
  )

  const handleClickMore = useCallback(() => {
    if (!access_token) {
      setLoginModal(true)
      return
    }
    onClickMore()
  }, [access_token, onClickMore])

  const handleFollow = useCallback(() => {
    if (!access_token) {
      setLoginModal(true)
      return
    }
    onFollow()
  }, [access_token, onFollow])

  const handleNavigateProfile = useCallback(() => {
    if (!access_token) {
      setLoginModal(true)
      return
    }
    if (id === userId) {
      history.push('giftshop_profile', {self: true}, username)
    } else {
      history.pushQuery({
        path: 'giftshop_search_by_creator_creation_content',
        state: {
          photo,
          allowNonVerified: true,
        },
        queryObject: {
          creator_id,
          username,
        },
      })
    }
  }, [access_token, creator_id, history, id, photo, userId, username])

  const handleRenderContent = useMemo(
    () =>
      data.content_type === 'photo' ? (
        <StyledContentImage src={data.url} alt="" height="100%" width="100%" />
      ) : (
        <FototreeMemoryContentItemVideo
          active={active}
          focused={focused}
          mute={mute}
          src={data.url}
          statePause={statePause}
          stateProgress={stateProgress}
        />
      ),
    [
      active,
      data.content_type,
      data.url,
      focused,
      mute,
      statePause,
      stateProgress,
    ],
  )

  const handleRenderProgressBar = useMemo(
    () => <ForkygramTemplatePostProgressBar progress={stateProgress[0]} />,
    [stateProgress],
  )

  const handleRenderBadge = useMemo(
    () => creator_status === 'verified' && <StyledIconVerifiedBadge />,
    [creator_status],
  )

  const handleRenderUsername = useMemo(
    () => (
      <StyledUsernameContainer ref={usernameRef}>
        <StyledInlineParagraph
          color="white_1"
          className="pressable"
          fontSize="m"
          fontWeight="bold"
          onClick={handleNavigateProfile}>
          @{username}
        </StyledInlineParagraph>
        <StyledIconWrapper>{handleRenderBadge}</StyledIconWrapper>
      </StyledUsernameContainer>
    ),
    [handleNavigateProfile, handleRenderBadge, username],
  )

  const handleRenderAvatar = useMemo(
    () => (
      <StyledProfileContainer shouldUpdateGap={shouldUpdateGap}>
        {!is_followed && followButton && id !== userId && (
          <StyledFollowButton onClick={handleFollow}>
            <Icon type="plus" size={12} color="primary_5" />
          </StyledFollowButton>
        )}
        <StyledAvatarContainer onClick={handleNavigateProfile}>
          <StyledAvatar alt={username} src={photo} />
        </StyledAvatarContainer>
        {handleRenderUsername}
      </StyledProfileContainer>
    ),
    [
      followButton,
      handleFollow,
      handleNavigateProfile,
      handleRenderUsername,
      id,
      is_followed,
      photo,
      shouldUpdateGap,
      userId,
      username,
    ],
  )

  const handleRenderFotoTreeTag = useMemo(
    () =>
      dataTag?.name &&
      dataTag?.icon && (
        <StyledFotoTreeTag>
          <StyledFotoTreePinContainer>
            <StyledFotoTreePin
              src={IMAGE_ASSET(
                'tree',
                `icons/pinpoint-${
                  dataTag.icon.split('/icons/')[1]
                }` as AssetImageFotoTree,
              )}
              alt={dataTag.name}
            />
          </StyledFotoTreePinContainer>
          <StyledInlineParagraph
            fontWeight="medium"
            color="white_1"
            lineHeight={14}>
            {`${dataTag.name}${locationName ? ` • ${locationName}` : ''}`}
          </StyledInlineParagraph>
        </StyledFotoTreeTag>
      ),
    [dataTag?.icon, dataTag?.name, locationName],
  )

  const handleRenderMobileCaption = useMemo(
    () => (
      <StyledMobileCaptionContainer>
        {handleRenderAvatar}
        {handleRenderFotoTreeTag}
        <StyledCaptionContainer>
          {data.is_hidden && (
            <StyledInvisibleContainer collapse={collapse} id="test">
              <Icon
                type="invisible"
                size={convertUnit(12)}
                color="white_1"
                hasOpacity
                hasShadow
              />
              <Paragraph color="white_1" fontWeight="medium">
                {translate('tree:invisible')}
              </Paragraph>
            </StyledInvisibleContainer>
          )}
          {data.caption ? (
            <StyledCaptionContentContainer
              onClick={() => setCollapse((prev) => !prev)}>
              <StyledParagraphContainer collapse={collapse} ref={ref}>
                <StyledCollapsibleParagraph
                  lineHeight={14}
                  color="white_1"
                  collapse={collapse}>
                  {data.caption.replace(/\\n/g, '\n')}
                </StyledCollapsibleParagraph>
              </StyledParagraphContainer>
              {isOverflow && (
                <StyledParagraph
                  lineHeight={14}
                  color="white_1"
                  fontWeight="bold">
                  {'...'}
                  {collapse
                    ? translate('global:more')
                    : translate('global:less')}
                </StyledParagraph>
              )}
            </StyledCaptionContentContainer>
          ) : (
            <></>
          )}
        </StyledCaptionContainer>
      </StyledMobileCaptionContainer>
    ),
    [
      collapse,
      data.caption,
      data.is_hidden,
      handleRenderAvatar,
      handleRenderFotoTreeTag,
      isOverflow,
      translate,
    ],
  )

  const handleRenderRightButtons = useMemo(
    () => (
      <ForkygramTemplatePostButtons
        ownerId={data.member.id}
        postId={data.post_id}
        photo={data.member.photo}
        username={data.member.username}
        member_id={data.member.id}
        creator_status={data.member.creator_status}
        isFeed
        data={data}
        mute={mute}
        handleShowModal={() => setLoginModal(true)}
        onPressMute={() => setMute((prev) => !prev)}
        onClickMore={handleClickMore}
      />
    ),
    [data, handleClickMore, mute, setMute],
  )

  const handleRenderModalLogin = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => setLoginModal((prev) => !prev)}
        visible={loginModal}
      />
    ),
    [loginModal, setLoginModal],
  )

  useEffect(() => {
    ref.current?.clientHeight && setClientHeight(ref.current.clientHeight)
    ref.current?.scrollHeight && setScrollHeight(ref.current.scrollHeight)
  }, [])

  useDidUpdate(() => {
    setShouldUpdateGap(
      !is_followed && followButton && usernameRef.current?.offsetHeight
        ? usernameRef.current.offsetHeight > FORKYGRAM_CAPTION_LINE_HEIGHT
        : false,
    )
  }, [followButton, is_followed, usernameRef.current?.offsetHeight])

  return (
    <StyledContentContainer height={height}>
      {handleRenderModalLogin}
      {handleRenderContent}
      {handleRenderMobileCaption}
      {handleRenderRightButtons}
      {handleRenderProgressBar}
    </StyledContentContainer>
  )
}
