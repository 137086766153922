import React, {useEffect, useState, useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {translate} from 'i18n'
import {GiftShopSettingSupport} from 'pages'
import {requestData} from 'services'
import {FetchDataType, Role, WindowModeType} from 'types'
import {getCurrencyValue, useHistory} from 'utils'
import {useWindowMode} from 'windows'
import {Button, Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch} from 'lib/redux'
import {TemplateMyBalanceContentProps} from './TemplateMyBalanceContentProps'

interface StyledContainerProps {
  mode: WindowModeType
  roletype?: Role
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, mode}) => ({
    backgroundColor: mode === 'mobile' ? theme.white_1 : theme.white_2,
  })};
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const StyledIconBackground = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.warning_5,
  })};
  display: flex;
  border-radius: ${convertUnit(8)};
  justify-content: center;
  width: ${convertUnit(36)};
  height: ${convertUnit(36)};
`

const StyledPaymentListContainer = styled.div`
  display: flex;
  padding-top: ${convertUnit(10)};
  padding-bottom: ${convertUnit(10)};
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const StyledInnerContainer = styled.div<StyledContainerProps>`
  ${({mode}) => ({
    marginTop: mode === 'mobile' ? 0 : convertUnit(20),
    maxWidth: mode !== 'mobile' ? convertUnit(561) : '100%',
  })}
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({theme}) => theme.white_1};
`

const StyledAvailableBalance = styled.div<StyledContainerProps>`
  display: flex;
  box-sizing: border-box;
  padding: ${convertUnit(16)} ${convertUnit(20)};
  width: 100%;
  ${({theme, roletype, mode}) => ({
    backgroundColor: roletype === 'user' ? theme.info_1 : theme.success_3,
    borderTopLeftRadius: mode === 'mobile' ? 0 : convertUnit(16),
    borderTopRightRadius: mode === 'mobile' ? 0 : convertUnit(16),
  })};
`

const StyledAvailableBalanceHost = styled(StyledAvailableBalance)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledAvailableBalanceUser = styled(StyledAvailableBalance)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const StyledUserButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  margin-top: ${convertUnit(8)};
  width: 100%;
`

const StyledUserButtons = styled(Button)`
  border-radius: ${convertUnit(8)};
  width: 100%;
  height: ${convertUnit(34)};
`

const StyledHostButton = styled(Button)`
  display: flex;
  flex-direction: column;
  border-radius: ${convertUnit(8)};
  margin-left: ${convertUnit(8)};
  max-width: ${convertUnit(80)};
  justify-content: center;
  align-items: center;
`

const StyledTransactionHistoryTitleContainer = styled.div`
  width: 100%;
`

const StyledWaitingForPaymentContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_4,
  })};
  width: 100%;
`

const StyledSeperator = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.warning_5,
  })};
  height: ${convertUnit(2)};
  width: 100%;
`

const StyledWaitingForPaymentTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(12)};
  padding: ${convertUnit(8)} ${convertUnit(20)};
  justify-content: flex-start;
  align-items: center;
`

const StyledTransactionHistoryTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(12)};
  padding: ${convertUnit(8)} ${convertUnit(20)};
  justify-content: flex-start;
  align-items: center;
`

export default function TemplateMyBalanceContent({
  roletype,
  lastUpdate,
}: TemplateMyBalanceContentProps) {
  const [balanceData, setBalanceData] = useState<number>()
  const {update} = useDispatch()
  const mode = useWindowMode()
  const history = useHistory()
  const [balanceStatus, setBalanceStatus] = useState<FetchDataType>('Loading')
  const [lastUpdateLocal, setLastUpdateLocal] = useState<number>(
    Math.floor(new Date().getTime() / 1000),
  )
  const [waitingCount, setWaitingCount] = useState(0)

  const handleLoadUserBalance = useCallback(async () => {
    const response = await requestData('giftshop_get_balance', {
      useDefaultMessage: true,
      actionType: 'fetch',
      onRequestSuccess: ({data: dataResponse}) => {
        setBalanceData(dataResponse.result.balance)
      },
    })

    if (typeof response !== 'string') {
      setBalanceStatus('Success')
    } else {
      setBalanceStatus('Failed')
    }
  }, [setBalanceStatus])

  const handleLoadWaitingCount = useCallback(() => {
    requestData('giftshop_get_transaction_count', {
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          setWaitingCount(result.checkout_count)
        }
      },
    })
  }, [])

  const handleLoadHostBalance = useCallback(async () => {
    const responseHost = await requestData('tree_fetch_hosts', {
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          const {balance} = result
          update('hostState', {
            balance,
          })
          setBalanceData(balance.valueOf())
        }
      },
    })

    if (typeof responseHost !== 'string') {
      setBalanceStatus('Success')
    } else {
      setBalanceStatus('Failed')
    }
  }, [update])

  const handleRenderAvailableBalanceHost = useMemo(
    () => (
      <StyledAvailableBalanceHost mode={mode} roletype={roletype}>
        <div>
          <Paragraph fontSize="m" fontWeight="bold" color="success_9">
            {translate('giftShop:totalBalance')}
          </Paragraph>
          {balanceStatus === 'Success' ? (
            balanceData !== undefined && (
              <Paragraph fontSize="xl" color="success_9">
                {getCurrencyValue(balanceData)}
              </Paragraph>
            )
          ) : balanceStatus === 'Loading' ? (
            <Paragraph fontSize="s" fontWeight="medium" color="success_7">
              {translate('global:pleaseWait')}
            </Paragraph>
          ) : (
            <Paragraph fontSize="s" fontWeight="medium" color="warning_7">
              {translate('global:connectionInterrupted')}
            </Paragraph>
          )}
        </div>
        <StyledHostButton
          backgroundColor="warning_4"
          onClick={() => history.push('tree_withdraw', {})}>
          <Icon type="withdraw" color="success_9" size={convertUnit(16)} />
          <Paragraph fontSize="xs" fontWeight="bold" color="success_9">
            {translate('tree:withdraw')}
          </Paragraph>
        </StyledHostButton>
      </StyledAvailableBalanceHost>
    ),
    [balanceData, balanceStatus, history, mode, roletype],
  )

  const handleRenderAvailableBalanceUser = useMemo(
    () => (
      <StyledAvailableBalanceUser mode={mode} roletype={roletype}>
        <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
          {translate('giftShop:totalBalance')}
        </Paragraph>
        {balanceStatus === 'Success' ? (
          balanceData !== undefined && (
            <Paragraph fontSize="xl" color="primary_5">
              {getCurrencyValue(balanceData)}
            </Paragraph>
          )
        ) : balanceStatus === 'Loading' ? (
          <Paragraph fontSize="s" fontWeight="medium" color="primary_3">
            {translate('global:pleaseWait')}
          </Paragraph>
        ) : (
          <Paragraph fontSize="s" fontWeight="medium" color="warning_7">
            {translate('global:connectionInterrupted')}
          </Paragraph>
        )}
        <StyledUserButtonsContainer>
          <StyledUserButtons
            label={translate('giftShop:bankAccount')}
            backgroundColor="primary_1"
            color="primary_5"
            leftIcon={
              <Icon
                type="bank"
                size={convertUnit(16)}
                color="primary_5"
                style={{marginRight: convertUnit(12)}}
              />
            }
            onClick={() => history.push('giftshop_user_bank_list', {})}
          />
          <StyledUserButtons
            label={translate('giftShop:withdraw')}
            backgroundColor="primary_1"
            color="primary_5"
            leftIcon={
              <Icon
                type="withdraw"
                size={convertUnit(16)}
                color="primary_5"
                style={{
                  marginRight: convertUnit(12),
                }}
              />
            }
            onClick={() => history.push('giftshop_withdraw', {})}
          />
        </StyledUserButtonsContainer>
      </StyledAvailableBalanceUser>
    ),
    [balanceData, balanceStatus, history, mode, roletype],
  )

  const handleRenderAvailableBalance = useMemo(
    () =>
      roletype === 'host'
        ? handleRenderAvailableBalanceHost
        : handleRenderAvailableBalanceUser,
    [
      handleRenderAvailableBalanceHost,
      handleRenderAvailableBalanceUser,
      roletype,
    ],
  )

  const handleRenderWaitingForPayment = useMemo(
    () => (
      <>
        <StyledWaitingForPaymentContainer>
          <StyledWaitingForPaymentTitle>
            <StyledIconBackground>
              <Icon type="clock" size={convertUnit(20)} color="white_1" />
            </StyledIconBackground>
            <StyledPaymentListContainer>
              <div>
                <Paragraph color="warning_7" fontSize="m" fontWeight="bold">
                  {translate('giftShop:waitingForPayment')}
                </Paragraph>
                <Paragraph color="gray_5" fontWeight="medium">
                  {translate('giftShop:waitingForPayment_desc', {
                    count: waitingCount,
                  })}
                </Paragraph>
              </div>
              <Button
                label="Open"
                type="small"
                fontSize="s"
                onClick={() => history.push('giftshop_waiting_for_payment', {})}
              />
            </StyledPaymentListContainer>
          </StyledWaitingForPaymentTitle>
        </StyledWaitingForPaymentContainer>
        <StyledSeperator />
      </>
    ),
    [history, waitingCount],
  )

  const handleRenderTransactionHistory = useMemo(
    () => (
      <>
        <StyledTransactionHistoryTitleContainer>
          <StyledTransactionHistoryTitle>
            <Icon type="list" size={convertUnit(16)} color="gray_6" />
            <Paragraph color="gray_6" fontSize="m" fontWeight="medium">
              {translate('giftShop:transactionHistory')}
            </Paragraph>
          </StyledTransactionHistoryTitle>
        </StyledTransactionHistoryTitleContainer>
        <GiftShopSettingSupport isHorizontal />
      </>
    ),
    [],
  )

  const handleLoadData = useCallback(() => {
    if (
      balanceStatus === 'Loading' ||
      (lastUpdate && lastUpdate > lastUpdateLocal)
    ) {
      lastUpdate && setLastUpdateLocal(lastUpdate)
      roletype === 'user' ? handleLoadUserBalance() : handleLoadHostBalance()
    }
  }, [
    balanceStatus,
    lastUpdate,
    lastUpdateLocal,
    setLastUpdateLocal,
    roletype,
    handleLoadUserBalance,
    handleLoadHostBalance,
  ])

  useEffect(() => {
    handleLoadWaitingCount()
    handleLoadData()
  }, [lastUpdate, handleLoadData, handleLoadWaitingCount])

  return (
    <StyledContainer mode={mode}>
      <StyledInnerContainer mode={mode}>
        {handleRenderAvailableBalance}
        {waitingCount > 0 && handleRenderWaitingForPayment}
        {handleRenderTransactionHistory}
      </StyledInnerContainer>
    </StyledContainer>
  )
}
