import {TranslationKeyGiftShop} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_GIFT_SHOP_EN: TranslationResource<TranslationKeyGiftShop> = {
  translation: {
    'giftShop:about': 'About',
    'giftShop:aboutContent': 'About Content',
    'giftShop:accessPermissionButton': 'Give Permission',
    'giftShop:accessPermissionDisclaimer':
      '(Only a few key engineers have access for maintenance purposes)',
    'giftShop:accessPermissionFirstDescription':
      "To increase search accuracy, FOTOYU needs your consent to turn on your device's location services.",
    'giftShop:accessPermissionFirstDescription_camera':
      'To activate account and search your photo, Fotoyu needs your consent to turn on your camera access.',
    'giftShop:accessPermissionHeader': 'Access Location Permission',
    'giftShop:accessPermissionHeader_camera': 'Access Camera Permission',
    'giftShop:accessPermissionSecondDescription':
      'Your location data is encrypted by RoboYu, so most of the FOTOYU staff cannot read the data.',
    'giftShop:accessPermissionSecondDescription_camera':
      'Your data is encrypted by RoboYu, so most of the Fotoyu staff cannot read the data.',
    'giftShop:account': 'Account',
    'giftShop:accountDetailHeader': 'Account Detail',
    'giftShop:accountLabel': 'Account Name',
    'giftShop:accountName': 'Account Name',
    'giftShop:accountNumberAlreadyExist': 'Account number already exists!',
    'giftshop:acountNumberCooldown': 'Please try again in {{timer}}',
    'giftShop:addAccountFailed': "There's a problem when sending your data",
    'giftShop:addAccountSuccess': 'Your new bank account has been added.',
    'giftShop:addBankAccount': 'Add bank account',
    'giftShop:addBankAccountHeader': 'Add Bank Account',
    'giftShop:addBankAccountCreatePasswordInfo':
      'You must create a password for your account before addding bank account',
    'giftShop:addBankInputAccountNameErrorMustAlphabet':
      'Account name must be alphabet',
    'giftShop:addBankInputAccountNameLabel': 'Full Name',
    'giftShop:addBankInputAccountNamePlaceholder':
      'Full name according to bank account',
    'giftShop:addBankInputAccountNumberErrorMinimalCharacter':
      'Minimum account number is {{number}} characters',
    'giftShop:addBankInputAccountNumberErrorNumberOnly':
      'Account number only contain numeric',
    'giftShop:addBankInputAccountNumberLabel': 'Account Number',
    'giftShop:addBankInputAccountNumberPlaceholder': 'Add account number',
    'giftShop:addBankInputBankBranchLabel': 'Branch Name (Optional)',
    'giftShop:addBankInputBankBranchPlaceholder': 'Add branch name',
    'giftShop:addBankInputBankNameLabel': 'Bank Name',
    'giftShop:addBankInputBankNamePlaceholder': 'Add bank name',
    'giftShop:addBioHeader': 'Add Bio',
    'giftShop:addCart': 'Add to Cart',
    'giftShop:addCartSuccessMessage': 'Content successfully added to cart.',
    'giftShop:addEmailErrorExist':
      'Sorry! Looks like this email address has been registered. Let’s use another email and try again.',
    'giftShop:addEmailHeader': 'Add Email',
    'giftShop:addEmailPlaceholder': 'Someone@domain.com',
    'giftShop:addEmailVerifyDescription':
      'Account confirmation is required. Please check your email for confirmation link.',
    'giftShop:addEmailVerifyDidNotGet': "Didn't get the email?",
    'giftShop:addEmailVerifyExpiration':
      'Your verification link expires in 1 hour',
    'giftShop:addEmailVerifyResend': 'Resend email {{countdown}}',
    'giftShop:addEmailVerifySubtitle': 'We have sent you a verification email.',
    'giftShop:addEmailVerifyTitle': 'Verify Your Email',
    'giftShop:addPhoneHeader': 'Add Mobile Number',
    'giftShop:addPhoneVerifyTitle': 'Verify Your Phone Number',
    'giftShop:addMainAccountFailed': 'Failed to set account as main account',
    'giftShop:addMainAccountSuccess':
      'Account succesfully set as main account!',
    'giftShop:addPaymentAccount': 'Add Payment Account',
    'giftShop:addPhoto': 'Add Photo',
    'giftShop:addSelfieTooltip': 'Please add selfie',
    'giftShop:addSelfieTooltipDesc': '(Can wear sunglasses)',
    'giftShop:addSocialLinkInputPlaceholder': 'Input your {{socialType}} link',
    'giftShop:addSocialLinkInputPlaceholder_username':
      'Input your {{socialType}} username',
    'giftShop:addSocialLinkInputPlaceholder_number':
      'Input your {{socialType}} number',
    'giftShop:addSocialLinkInputPlaceholder_title': 'Input the title',
    'giftShop:addSocialLinkInputPlaceholder_whatsapp': 'e.g. 62877xxxx',
    'giftshop:addSocialLinkTypePlaceholder': 'Select the type',
    'giftShop:addWishlistSuccessMessage':
      'Content successfully added to FotoYu.',
    'giftShop:additionalStorage': '+{{bonus}} MB',
    'giftShop:allContent': 'All your content will be shown here.',
    'giftShop:allDiscounted': 'All Discounted',
    'giftShop:alltransaction':
      'All your transaction history will be shown here.',
    'giftShop:alreadyExists': '{{thing}} already exists.',
    'giftShop:archive': 'Archive',
    'giftShop:archiveEmptySubtitle':
      'All the archived contents will be shown here.',
    'giftShop:archiveEmptyTitle':
      "It looks like you haven't archive any content yet.",
    'giftShop:archiveHeader': 'Archived Content',
    'giftShop:archiveHeaderSubtitle': 'You can unarchive content at any time',
    'giftShop:archiveSuccessMessage':
      'Content Archived. You can unarchive from ⋮ menu',
    'giftShop:archiveUnarchive': 'Unarchive',
    'giftShop:archiveUnarchiveSuccessMessage':
      'Content successfully unarchived.',
    'giftShop:availableBalanceLabel': 'Available Balance',
    'giftShop:backTo': 'Back to {{where}}',
    'giftShop:balance': 'My Balance',
    'giftShop:bankAccount': 'Bank Account',
    'giftShop:bankAccountDescription':
      'See all of your registered bank account, or add a new one',
    'giftShop:bankAccountDetail': '{{bankName}} Account ({{name}})',
    'giftShop:bankAccountListHeader': 'Bank Account List',
    'giftShop:bankAccountMain': 'MAIN',
    'giftShop:bankAccountMaxMessage': 'You can only add {{max}} bank account.',
    'giftShop:bankAccountNotFound': 'No Bank Account',
    'giftShop:bankAccountVerified': 'Verified',
    'giftShop:bankAccountNotVerified': 'Unverified',
    'giftShop:batchResend': 'Batch Resend',
    'giftShop:beforeDeleteAccount':
      'Before deleting your account, you need to make sure:',
    'giftShop:beforeDeleteAccountListOne':
      'Spend/Withdraw money in your Balance. Remaining money in your balance will be lost when you delete your account. Furthermore, your balance cannot be negative.',
    'giftShop:beforeDeleteAccountListTwo':
      'Not associated with any Host, either as a Host Internal Creator, Host leader, Host admin or other Host collaborations.',
    'giftShop:beforeDeleteAccountListThree':
      'Do not have any Resend HiRes requirement for other yusers.',
    'giftShop:beforeDeleteAccountListFour':
      'Already downloaded all your collections.',
    'giftShop:bio': 'Bio',
    'giftShop:bioMaxMessage': 'Maximum {{count}} characters',
    'giftShop:blockedAccounts': 'Blocked Accounts',
    'giftShop:emptyBlockedAccountsTitle': "You haven't blocked anyone yet",
    'giftShop:emptyBlockedAccountsDesc':
      'When you blocked someone, their username will appear here.',
    'giftShop:branchName': 'Branch Name',
    'giftShop:buy': 'Buy',
    'giftShop:buyNow': 'Buy Now',
    'giftShop:cancelPayment': 'Cancel Payment',
    'giftShop:cartEmptyInformationDescription':
      'All the added contents will be shown here.',
    'giftShop:cartEmptyInformationTitle':
      'It looks like you haven’t added any content to your cart yet...',
    'giftShop:cartHeader': 'My Cart',
    'giftShop:cartMaximumErrorMessage':
      'The cart has already reached its limit',
    'giftShop:cartSummaryTitle': 'Order Summary',
    'giftShop:cartSummaryTotalPriceTitle': 'Total Price ({{count}} item)',
    'giftShop:cartSummaryTotalPriceTitle_plural':
      'Total Price ({{count}} items)',
    'giftShop:changeBioHeader': 'Change Bio',
    'giftShop:changeBioPlaceholder': 'Describe your self',
    'giftShop:changeDateOfBirthHeader': 'Change Date of Birth',
    'giftShop:changeDateOfBirthMessage':
      'You can only change this data once. Please be careful and double check before you save the change.',
    'giftShop:changeEmailHeader': 'Change Email',
    'giftShop:changeFullnameHeader': 'Change Fullname',
    'giftShop:changeLanguage': 'Change Language',
    'giftShop:changeNickname': 'Change Nickname',
    'giftShop:changeNicknameSuccess': 'Nickname successfully changed.',
    'giftShop:changeNicknameWarning':
      'Your nickname can only be changed once every {{limit}} days.',
    'giftShop:changePasswordLimitDesc':
      'Sorry, we were unable to process your request because of too many failed attempts. Please try again in 1 hour.',
    'giftShop:changePasswordLimitTitle': 'Too Many Failed Attempts',
    'giftShop:changePasswordSuccessSnackbar':
      'Please check your inbox and follow the instructions.',
    'giftShop:changePasswordWarning':
      'Changing your password will log you out of all other sessions except this one, to keep your account secure.',
    'giftShop:changePhoneHeader': 'Change Mobile Number',
    'giftShop:changePhotoButton': 'Change Photo',
    'giftShop:changePicture': 'Change Picture',
    'giftShop:changeUsername': 'Change Username',
    'giftShop:changeUsernameSuccess': 'Username successfully changed.',
    'giftShop:changeUsernameWarning':
      'Your username can only be changed once every {{limit}} days.',
    'giftShop:checkoutDurationEnds': 'Payment Duration Ends',
    'giftShop:checkboxDeleteAccountPlaceholder':
      'I have read and agreed to terms of MY ACCOUNT DELETION',
    'giftShop:checkoutTimeLeft': 'Time Left:',
    'giftShop:checkoutHeader': 'Checkout',
    'giftShop:checkoutTransactionDate': 'Transaction Date',
    'giftShop:checkoutRefundMessage':
      'Creators need to resend Hi-Res in 48 hours, or you will get a refund (excluding bank fee).',
    'giftShop:choosePicture': 'Choose a Picture',
    'giftShop:chooseYuser': 'Choose a Yuser',
    'giftShop:chooseYuserDesc':
      'The list of yusers below are those who have purchased this content.',
    'giftShop:codeExpired': 'The code has expired. Please try again.',
    'giftShop:codeExpiresIn': 'Code expires in: ',
    'giftShop:collection': 'Collections',
    'giftShop:collectionEmptyDesc':
      'Your purchased contents will be shown here.',
    'giftShop:collectionEmptyTitle':
      "It looks like you haven't bought any contents...",
    'giftShop:collectionHeader': 'Collections',
    'giftShop:collectionHiResBadge': 'Hi-res',
    'giftShop:collectionPreviewBadge': 'Not Hi-Res',
    'giftShop:collectorPay': 'Selling Price ',
    'giftShop:commingSoon': "We're still working on the web version.",
    'giftShop:commingSoonDesc': 'You can use the mobile app for messaging.',
    'giftShop:compressAndResend': 'Compress & Resend',
    'giftShop:compressActivated': 'Auto-compress has been activated',
    'giftShop:compressDeactivated': 'Auto-compress has been disabled',
    'giftShop:compressHeader': 'Always Compress image on Upload Sell Content',
    'giftShop:compressInfoLabel': 'By activating the Compress image tool:',
    'giftShop:compressInfoMessage1':
      'Each content you uploaded will be compressed, take less storage space, and marked “Not Hi-Res”.',
    'giftShop:compressInfoMessage2':
      'Each time a “Not Hi-Res” content was sold, you will need to resend the Hi-Res content in 48 hours.',
    'giftShop:compressInfoMessage3':
      'This way you will only need to edit / retouch the sold contents, to resend the Hi-Res version. Efficient.',
    'giftShop:compressInfoMessage4':
      "Passing the 48 hours time limit will trigger the refund process. 100% of your content's Basic Price will be refunded to the buyer.",
    'giftShop:compressModalTitle': 'Compressing...',
    'giftShop:compressModalDescription':
      '{{success}} of {{total}} Contents Compressed.',
    'giftShop:compressStatus': 'Compress: ',
    'giftShop:confirmNewPassword': 'Confirm New Password',
    'giftShop:contentType': 'Content Type',
    'giftShop:newNickname': 'New Nickname',
    'giftShop:newNicknamePlaceholder': 'Input new nickname',
    'giftShop:newPasswordPlaceholder': 'Input your new password again',
    'giftShop:newUsername': 'New Username',
    'giftShop:newUsernamePlaceholder': 'Input new username',
    'giftShop:newYuser': 'New Yuser (@{{yuser}})',
    'giftShop:newYuserNoticeDescriptionFirst':
      'Welcome to FotoYu Tab, you will find your photos here.',
    'giftShop:newYuserNoticeDescriptionSecond':
      'However, there is a possibility that your photographer has not uploaded your photos to the server.',
    'giftShop:newYuserNoticeDescriptionThird':
      "If your photo isn't here yet, please check periodically after a few hours.",
    'giftShop:newYuserNoticeTitle': 'Hello Yuser!',
    'giftShop:contentCount': '{{count}}/{{max}} Content',
    'giftShop:contentCount_plural': '{{count}}/{{max}} Contents',
    'giftShop:contentDeleted': 'Content deleted.',
    'giftShop:contentDetail': 'Content Detail',
    'giftShop:contentDetailDiscountInformation':
      'Buy {{content_count}} from {{creator_username}}, get {{discount}}% off',
    'giftShop:contentDetailEditDeleteDescription':
      'Are you sure to delete this content? This action can not be undone.',
    'giftShop:contentDetailEditHeader': 'Edit Content',
    'giftShop:contentDetailEditModalMessage':
      'Are you sure want to cancel? Any changes will not be saved',
    'giftShop:contentDetailFilterDescription':
      'This content may violate the FotoYu Community Guidelines.',
    'giftShop:contentDetailFilterTitle': 'Failed to Upload',
    'giftShop:contentDetailReportContent': 'Report Content',
    'giftShop:contentModalLoadingHeader': 'Loading Contents...',
    'giftShop:contentModalLoadingDescription':
      '{{success}} of {{total}} Contents Loaded.',
    'giftShop:contentModalLoadingProcessingDescription':
      'Creating {{success}} of {{total}} Contents Preview.',
    'giftShop:contentModalLoadingUploadingDescription':
      'Uploading {{success}} of {{total}} Contents Preview.',
    'giftShop:createPassword': 'Create Password',
    'giftShop:creation': 'Creation',
    'giftShop:creationBadge': 'Available',
    'giftShop:creationBadge_resend': 'Resend Hi-res',
    'giftShop:creationBadge_resendpology': 'Resendpology',
    'giftShop:creationBadge_sold': 'Sold',
    'giftShop:creationBadge_unwishlist': 'Removed by yuser',
    'giftShop:creationChip': 'All',
    'giftShop:creationChip_all': 'All',
    'giftShop:creationChip_available': 'Available',
    'giftShop:creationChip_resend': 'Resend',
    'giftShop:creationChip_sold': 'Sold',
    'giftShop:creationFilterModalTitle': 'Filter Selection',
    'giftShop:creationFilteredEmpty': 'No Content Found',
    'giftShop:creationFilteredEmptyDesc':
      'Please update the filter to discover relevant content.',
    'giftShop:creationFilterEmpty':
      'Upload your creation, your related content will appear here',
    'giftShop:creationFilterEmptyAll':
      'Upload your photo creations to the FotoYu server, so that your clients or friends can find them automatically.',
    'giftShop:creationFilterEmptyAllButton': 'Upload Sell',
    'giftShop:creationGroupDeleteDescription':
      'Are you sure you want to remove these folders & the contents in it?',
    'giftShop:creationGroupDeleteSubDescription':
      'Sold or Checkout content cannot be deleted.',
    'giftShop:creationGroupDeleteHeader': 'Delete Folders',
    'giftShop:creationGroupDeleteItemsDescription':
      'Are you sure you want to delete these contents? This action cannot be undone',
    'giftShop:creationGroupDeleteItemsHeader': 'Delete Item Content(s)',
    'giftShop:creationGroupDeleteMessageFailed': 'Failed to delete folder.',
    'giftShop:creationGroupDeleteMessageSuccess':
      'Folder successfully deleted.',
    'giftShop:creationGroupEditMessageFailed': 'Failed to update the content.',
    'giftShop:creationGroupEditMessageSuccess':
      'Contents are successfully updated.',
    'giftShop:creationSingleEditMessageSuccess':
      'Content is successfully edited.',
    'giftShop:creationSortByTitle': 'Sort Resend Content by',
    'giftShop:creationSortBy': 'File Name (Default)',
    'giftShop:creationSortBy_purchasedAt': 'Earliest Due Date',
    'giftShop:creationSortBy_title': 'File Name (Default)',
    'giftShop:creator': 'Creator',
    'giftShop:creatorPriceLimitErrorMessage': 'Max. IDR 9.000.',
    'giftShop:creatorPriceLimitErrorMessageContinue':
      'Upgrade your account for higher price.',
    'giftShop:creatorPriceLimitModalDescription':
      'Creator price limit is IDR 9.000/content. Please upgrade to be able to set the price up to 1 million rupiah. ',
    'giftShop:creatorPriceLimitUpgradeModalDescription':
      'These content will not be saved',
    'giftShop:creatorPriceLimitModalTitle': 'Creator Price Limit',
    'giftShop:creatorPriceLimitUpgradeModalTitle':
      'Upgrade to Verified Creator',
    'giftShop:currentPassword': 'Current Password',
    'giftShop:deleteAccount': 'Delete Account',
    'giftshop:deleteAccountFailed': 'There was a problem deleting your account',
    'giftshop:deleteAccountPrompt':
      'Are you sure you want to delete your account?',
    'giftshop:deleteAccountSuccess': 'Your account has been deleted',
    'giftshop:deleteAccountWarning':
      'After 7 days, you will not be able to recover your data.',
    'giftShop:deleteMyAccount': 'Delete My Account',
    'giftShop:deleteAccountConfirmation': 'Delete Account Confirmation',
    'giftShop:deleteAccountConfirmationDesc':
      'To delete your account press continue.',
    'giftShop:deleteAccountDetail': 'Delete Account Detail',
    'giftShop:currencyPrice': 'IDR 0,00',
    'giftShop:deleteBankAccount': 'Delete Bank Account',
    'giftShop:deleteContentConfirmationMessage':
      'Are you sure to delete the contents? This action can not be undone.',
    'giftShop:deleteContentConfirmationTitle': 'Delete Contents',
    'giftShop:deleteItem': 'Remove {{thing}}',
    'giftShop:deleteParentSeriesMessage':
      'You need to replace the parent content with a new one before removing it.',
    'giftShop:deleteResendpologyNotification': 'Notification deleted',
    'giftShop:deleteResendpologyConfirmationModalDescription':
      'Are you sure want to delete this Resendpology notification? This action cannot be undone.',
    'giftShop:deleteResendpologyConfirmationModalTitle':
      'Delete Notification Item',
    'giftShop:didNotReceiveTheCode': 'Did not receive the code?',
    'giftShop:discount': 'Discount',
    'giftShop:discountPercent': 'Discount {{count}}%',
    'giftShop:discountText': 'Buy {{count}} from {{username}}',
    'giftShop:editContentBulkDescription':
      'Your changes will be applied to all selected contents.',
    'giftShop:editContentBulkHeader': 'Edit Contents',
    'giftShop:editContentBulkLimitMessage':
      'This data can only be changed 3x per day.',
    'giftShop:editContentBulkSuccess':
      'Content queued. Please wait for the changes.',
    'giftShop:editProfile': 'Edit Profile',
    'giftShop:editProfileDescription':
      'Edit your full name, nickname, email, etc.',
    'giftShop:editProfileDisclaimer':
      "We don't show your other data above beside username, nickname, and picture on your public profile",
    'giftShop:editProfileInputPasswordLimit': 'Exceed Input Password Limit',
    'giftShop:editProfileInvalidFormatData': 'Invalid format',
    'giftShop:editProfileMaxFileSize': 'Maximum file size is 10 MB',
    'giftShop:editProfileMaxResolution': 'Maximum pixel is 16 MP',
    'giftShop:editProfileBackgroundSize':
      'Minimum size is {{height}} x {{width}} pixels',
    'giftShop:editProfileNotConfirmed': '(Not Confirmed Yet)',
    'giftShop:editProfileUnverifiedCaption':
      "You added this new {{item}} before, but it hasn't been verified.",
    'giftShop:editProfileVerifyPasswordCaption':
      'We found that you have not created a password.',
    'giftShop:editProfileVerifyPasswordDescription':
      'You must create a password first to add an email.',
    'giftShop:editProfileVerifyPasswordDescription_nickname':
      'You must create a password first to change nickname.',
    'giftShop:editProfileVerifyPasswordDescription_phone':
      'You must create a password first to add a mobile number.',
    'giftShop:editProfileVerifyPasswordDescription_username':
      'You must create a password first to change a username.',
    'giftShop:emptyDefaultMessage': "Let's interact with your friends!",
    'giftshop:emptyLocation': 'Please fill location data',
    'giftShop:emailTo': 'Email to',
    'giftShop:enterTheOTPSentAddBank': 'Enter the OTP sent to {{number}}',
    'giftShop:enterTheOTPSentSetting': 'Enter the OTP sent to ',
    'giftShop:eWalletQRInstruction':
      'Please scan the QR code below using the {{method}} scan feature in the {{app}} application.',
    'giftShop:eWalletQRInstruction_second':
      'When the process is complete, the payment will be processed automatically.',
    'giftShop:explore': 'You?',
    'giftShop:exploreChangeAccuracyTooltip': "You can change RoboYu's accuracy",
    'giftShop:exploreEmptySubtitle1':
      'You can also chat the fotographer to upload your photo.',
    'giftShop:exploreEmptySubtitle2': "Don't create a second account.",
    'giftShop:exploreEmptyTitle':
      "If your photo hasn't been found, try adding a selfie in the RoboYu menu ",
    'giftShop:exploreFilterClear': 'Clear Filter',
    'giftShop:exploreFilterCreatorLabel': 'Creator (Optional)',
    'giftShop:exploreFilterDateTimeLabel': 'Date and Time (Optional)',
    'giftShop:exploreFilterLocationLabel': 'Location (Optional)',
    'giftShop:exploreFilterPictureLabel': 'Match with picture',
    'giftShop:exploreFilterTagsLabel': 'FotoTree (Optional)',
    'giftShop:exploreFilterCreatorPlaceholder': 'Type a Creator username',
    'giftShop:exploreFilterDatePlaceholder': 'Select date',
    'giftShop:exploreFilterTimePlaceholder': 'Select time',
    'giftShop:exploreFilterLocationPlaceholder': 'Select a location',
    'giftShop:exploreFilterPicturePlaceholder': 'Add a picture',
    'giftShop:exploreFilterTagsPlaceholder': 'Type a FotoTree name',
    'giftShop:exploreFloatingButtonTitle': 'RoboYu found new content',
    'giftShop:exploreFloatingButtonSubtitle': 'Tap to see',
    'giftShop:exploreHeader': 'Is this you?',
    'giftShop:exploreSearchTemporary': 'This feature is coming soon!',
    'giftShop:exploreSearchTitle': 'Search',
    'giftShop:externalLink': 'External Link',
    'giftShop:externalLinkDesc':
      'Are you sure you want to leave this app and go to that link?',
    'giftShop:facebook': 'Facebook',
    'giftShop:failedTransaction': 'Failed Transaction',
    'giftShop:favorite': 'Favorites',
    'giftShop:favoriteButtonRemoveBulk': 'Remove from Favorites',
    'giftShop:favoriteEmptySubtitle':
      'All the added contents will be shown here.',
    'giftShop:favoriteEmptyTitle':
      "It looks like you haven't added any content to your Favorites yet...",
    'giftShop:favoriteErrorAddFavorite': 'Error while adding to favorites',
    'giftShop:favoriteErrorAddFavoriteAlreadyExist':
      'Content already added in favorites',
    'giftShop:favoriteErrorRemoveFavorite':
      'Error while removing from favorites',
    'giftShop:favoriteSuccessAdd': 'Content added to Favorites Tab.',
    'giftShop:favoriteSuccessRemove': 'Content removed from Favorites Tab.',
    'giftShop:favoriteSuccessRemoveCount':
      '{{count}} content removed from Favorites Tab.',
    'giftShop:favoriteSuccessRemoveCount_plural':
      '{{count}} contents removed from Favorites Tab.',
    'giftShop:fillTheDateError': 'Fill the date',
    'giftShop:fillTheTimeError': 'Fill the time',
    'giftShop:firstPromo': 'First Promo',
    'giftShop:fototreeCannotAddMoreThanOneSnackbar':
      'Sorry! You can only add one FotoTree.',
    'giftShop:followerTitle': 'Follower',
    'giftShop:followingTitle': 'Following',
    'giftShop:forGiftshopPurchaseOnly': '(for Giftshop purchase only)',
    'giftShop:forgotPasswordDidNotGetEmail': "Didn't get the email? ",
    'giftShop:forgotPasswordEdit': 'I Forgot My Current Password',
    'giftShop:forgotPasswordPhoneHeader':
      'A link has been sent to your mobile number to continue the process.',
    'giftShop:forgotPasswordSuccessBottomText':
      'Now you can log in using your new password. Enjoy!',
    'giftShop:forgotPasswordSuccessDescription':
      'Your password has been changed.',
    'giftShop:fotoyuEmptyDesc': 'All the added contents will be shown here.',
    'giftShop:fotoyuEmptyTitle':
      "It looks like you haven't added any content to FotoYu yet...",
    'giftShop:fotoyuFinishedLoadingSnackbar':
      'Current finding result. RoboYu will keep searching new content uploaded by photographer.',
    'giftShop:fotoyuIsNotLabel': 'Nope',
    'giftShop:fotoyuIsYesLabel': 'Yeah',
    'giftShop:foundByLabel': 'Found by:',
    'giftShop:freeContentInfoBox':
      'You have free voucher(s), for first photo purchase at ',
    'giftShop:finalPrice': 'Final Price',
    'giftShop:firstBuyer': 'First Buyer',
    'giftShop:firstBuyReferralVerifiedModalDescriptionPartOne': 'As a creator',
    'giftShop:firstBuyReferralVerifiedModalDescriptionPartTwo':
      ', you get 5 GB of free storage. Get additional 50 MB storage / new yuser that purchased for the first time at FotoYu from you. Increase it up to 10 GB of maximum storage.',
    'giftShop:firstBuyReferralModalDescription':
      'As a creator, you get 500 MB of free storage. Get additional 50 MB storage / new yuser that purchased for the first time at FotoYu from you. Increase it up to 1 GB of maximum storage.',
    'giftShop:firstBuyReferralModalFooterPartOne':
      'You can upgrade your account to Creator ',
    'giftShop:firstBuyReferralModalFooterPartTwo': ' for bigger storage.',
    'giftShop:firstBuyReferralModalTitle': 'Creator',
    'giftShop:helpAndSupport': 'Help & Support',
    'giftshop:hiddenContent': 'Hidden Content',
    'giftShop:hiresConfirmationCompleteTime':
      'The transaction will be completed automatically after:',
    'giftShop:hiresConfirmationEmptyInfo':
      'All content that requires confirmation will be shown here.',
    'giftShop:hiresConfirmationEmptyTitle':
      'It looks like you have confirmed all content!',
    'giftShop:hiresConfirmationHeader': 'Hi-Res Confirmation',
    'giftShop:hiresConfirmationSuccessSnackbar':
      'Content successfully confirmed.',
    'giftShop:hiresConfirmationWaitingSubtitle':
      'Please confirm these hi-res contents.',
    'giftShop:hiresConfirmationWaitingTitle': 'Waiting for Confirmation',
    'giftShop:history': 'History',
    'giftShop:historyDescription':
      'Check all of your transaction history in one place',
    'giftShop:host': 'Host',
    'giftShop:hostNotification': 'Host Notification',
    'giftShop:howToPay': 'How to pay',
    'giftShop:ifDeleteAccount': 'If you delete your account: ',
    'giftShop:ifDeleteAccountListOne':
      'FotoYu will permanently delete facial biometric identification, location data, content, posts, profile, storage and all other data from your account.',
    'giftShop:ifDeleteAccountListTwo':
      'For past activities that connected with other yusers, such as chat, comments, sold photos, likes, follows, and other digital footprints, not all of them can be deleted, since they are integrated with each other.',
    'giftShop:ifDeleteAccountListThreePartOne':
      'Your account will be deactivated and hidden from public for ',
    'giftShop:ifDeleteAccountListThreePartTwo':
      '. You can recover your account during the deactivation period. After ',
    'giftShop:ifDeleteAccountListThreePartThree':
      ', your data will be deleted permanently.',
    'giftShop:ifDeleteAccountListFour':
      'You will not be able to login and use FotoYu services with this account.',
    'giftShop:ifDeleteAccountListFive':
      'You cannot re-register with the same nickname again.',
    'giftShop:incorrectOTP': 'Incorrect OTP.',
    'giftShop:incorrectOTPPleaseEnterCorrect': 'Please enter the correct OTP.',
    'giftShop:instagram': 'Instagram',
    'giftShop:insufficientBalance':
      'Sorry, your balance is not enough to do a withdraw',
    'giftShop:invoiceID': 'Invoice ID',
    'giftShop:invalidFormatData': 'Invalid file format',
    'giftShop:isThisYou': 'Is This You?',
    'giftShop:isThisYouBulkConfirmSuccess': 'Content successfully confirmed.',
    'giftShop:isThisYouEmptyDesc':
      'You can help by having a clear selfie in the RoboYu menu ',
    'giftShop:isThisYouEmptyDesc_Alt':
      'You can also chat the fotographer to upload your photo.',
    'giftShop:isThisYouFilterEmptyMessage':
      'RoboYu haven’t found what you’re looking for. Don’t worry, RoboYu will keep searching.',
    'giftShop:isThisYouFilterEmptyTitle': 'Filter is activated! But...',
    'giftShop:isThisYouFilterEmptyButton': 'Reset Filter',
    'giftShop:isThisYouEmptyTitle': 'RoboYu has not found new content yet.',
    'giftShop:itemPurchased': 'Item Purchased',
    'giftShop:kycAgreement':
      'I give my consent to PT. Super Giga Generasi to collect or disclose my Facial Biometric Identifiers and my Device Location Data.',
    'giftShop:kycCancelMessage':
      'Are you sure you want to cancel? Any changes will not be saved.',
    'giftShop:kycExampleBad': 'Bad Example:',
    'giftShop:kycExampleGood': 'Good Example:',
    'giftShop:kycFollowInstruction': 'Follow the instructions when you start',
    'giftShop:kycFollowInstructionFull':
      'Selfie Quality Affects Photo Search Accuracy',
    'giftShop:kycRecord': 'Look straight forward',
    'giftShop:kycRecord_left': 'Look to the left',
    'giftShop:kycRecord_right': 'Look to the right',
    'giftShop:kycRecord_side': 'Look to the side',
    'giftShop:kycSatisfiedMessage':
      'Please wait while we checking your information. We will notify you after the process is done.',
    'giftShop:kycSelfieReviewMessage':
      'Attention! Poor lighting and blurry faces will make it hard to find your photos.',
    'giftShop:kycSelfieReviewTitle': 'Are you satisfied with the result?',
    'giftShop:kycSelfie': 'Take a selfie',
    'giftShop:kycSelfieRetake': 'Retake',
    'giftShop:kycSelfieWarning':
      'Please remove your glasses and make sure your face is clean and not covered by accessories and hair',
    'giftShop:kycSelfieWarning_1': "Please don't use any accessories",
    'giftShop:kycSelfieWarning_2':
      'Make sure your face is clean and not covered by hair',
    'giftShop:kycSelfieWarning_3':
      'Adjust the lighting so that your face is visible in the camera',
    'giftShop:kycTapToStart': 'Press record to start',
    'giftShop:kycUploading': 'Uploading {{current}} of {{total}}',
    'giftShop:kycUploading_preparing': 'Preparing',
    'giftShop:kycWarningUnsupported':
      '(Currently, our web registration works best on Safari.)',
    'giftShop:languageEmpty': 'Sorry! Language Not Found.',
    'giftShop:languageEmptyDescription':
      'Please try again using another keyword.',
    'giftShop:makeItFree': 'Make It Free',
    'giftShop:makeItFreeDescription':
      'Are you sure you want to make this content free?',
    'giftShop:makeItFreeSnackBarSuccess':
      'Done! Check your transaction history for more detail.',
    'giftShop:managePasswordHeader': 'Manage Password',
    'giftShop:manageAccount': 'Manage Account',
    'giftShop:manageAccountResendDescription1':
      'All uploaded selling contents will be marked as a "Not Hi-Res" version.',
    'giftShop:manageAccountResendDescription2':
      'Thus,  for the Yuser preview, you can upload un-edited content and compress it to save your account storage.',
    'giftShop:manageAccountResendDescription3':
      'Each time a content was sold, you will need to resend the Hi-Res version in 48 hours.',
    'giftShop:manageAccountResendDescription4':
      'You can use the allocated time to edit and Resend the Hi-Res to the buyer.',
    'giftShop:manageAccountResendDescription5':
      "Surpassing the time limit will trigger 100% of your content's Basic Price refunded to the buyer.",
    'giftShop:manageAccountResendSubtitle': 'By activating Always Resend:',
    'giftShop:manageAccountResendTitle': 'Always Resend Hi-Res on Sold Content',
    'giftShop:maps': 'Maps',
    'giftShop:messageBackToEditProfile': 'Back to Edit Profile',
    'giftShop:modalDeleteBankAccount': 'Are you sure to delete this account?',
    'giftShop:modalLoadingDescription':
      "Please wait a moment, and don't close this page.",
    'giftShop:modalRemoveConfirmationMessage': 'This action cannot be undone',
    'giftShop:modalRemoveFromMain':
      'Are you sure to unset this account from your main account?',
    'giftShop:modalRemovePicture':
      'Are you sure to remove {{count}} picture(s)? This action cannot be undone',
    'giftShop:modalSetAsMain':
      'Are you sure to set this account as your main bank account?',
    'giftShop:modalTitleDeleteBankAccount': 'Delete Bank Account',
    'giftShop:modalTitleRemoveFromMain': 'Remove from Main',
    'giftShop:modalTitleSetAsMain': 'Set as Main',
    'giftShop:moreDetail': 'More Detail',
    'giftShop:myBalance': 'My Balance',
    'giftShop:myCollection': 'My Collection',
    'giftShop:myCreation': 'My Creation',
    'giftShop:myCreationEmptyTitle':
      "It looks like you haven't uploaded any content...",
    'giftShop:hostCreationEmptyTitle':
      "It looks like the creators haven't uploaded any content...",
    'giftShop:myCreationEmptyTitle_available': 'Congratulations!',
    'giftShop:myCreationEmptyTitle_sold': 'No content has been sold yet',
    'giftShop:myCreationEmptyTitle_resend': 'Congratulations!',
    'giftShop:hostCreationEmptyDesc':
      'Ask the creators to upload their content, and it will be shown here.',
    'giftShop:myCreationEmptyDesc':
      'Upload your first content, and it will be shown here.',
    'giftShop:myCreationEmptyDesc_available':
      "All of your content are sold. I'm happy for you!",
    'giftShop:myCreationEmptyDesc_sold':
      "Don't worry, your contents are amazing. Keep the spirit!",
    'giftShop:myCreationEmptyDesc_resend':
      'You have resend all content. Good job!',
    'giftShop:myCreationDownloadActivateDesc':
      'Activate your creator account through FOTOYU mobile apps.',
    'giftShop:myCreationDownloadDesc':
      'Impress the world with your creation, interact with your fans, while earning money!',
    'giftShop:myCreationDownloadTitle': 'Be a Creator',
    'giftShop:myCreationNotCompletedDesc':
      'Thank you for submitting your verification data, unfortunately the process is not completed. Please make sure that all requirements are correct and try again.',
    'giftShop:myCreationNotCompletedTitle':
      'Creator Verification Not Completed',
    'giftShop:myCreationProcessedDesc':
      'Thank you for submitting your verification data, unfortunately we are not able to process it for the moment.',
    'giftShop:myCreationProcessedTitle':
      'Creator Verification Cannot Be Processed',
    'giftShop:myCreationRecievedDesc':
      'Thank you for submitting your verification data, we still reviewing and will update you within 24 hours.',
    'giftShop:myCreationRecievedTitle': 'Creator Verification Recieved',
    'giftShop:myProfile': 'My Profile',
    'giftShop:myWishlist': 'My Wishlist',
    'giftShop:newBankAccount': 'New Bank Account',
    'giftShop:newPassword': 'New Password',
    'giftShop:confirmNewPasswordPlaceholder': 'Input your new password',
    'giftShop:nicknameRecentlyChanged':
      'Nickname is recently changed. Please try again later.',
    'giftShop:noBankAccountSubtitle': 'You can add {{max}} bank account here.',
    'giftShop:noBankAccountTitle': "You haven't added a bank account yet",
    'giftShop:noBio': 'No Bio',
    'giftShop:nominal': 'Nominal',
    'giftShop:nominalLabel': 'Nominal',
    'giftShop:noContent': "It looks like you don't have any content yet...",
    'giftShop:notHires': 'Not Hi-Res',
    'giftShop:notificationUnread': '{{count}} Unread',
    'giftShop:noTransaction':
      "It looks like you haven't made any transaction yet...",
    'giftShop:noTransactionSub': 'Your purchase history will be shown here.',
    'giftShop:noWebsite': 'No Website',
    'giftShop:OTPVerification': 'OTP Verification',
    'giftShop:OTPVerificationDescription': 'Enter the OTP sent to\n{{number}}',
    'giftShop:OTPVerificationDescription_whatsapp':
      'Enter the OTP sent to\nWhatsApp {{number}}',
    'giftShop:pageNotFoundTitle': 'Whoops! Page not found',
    'giftShop:pageNotFoundDescription':
      "We're sorry the page you requested could not be found.",
    'giftShop:partnership': 'Partnership',
    'giftShop:partnershipSuccess':
      'Thank you for your interest in partnering with us! We will get back to you shortly.',
    'giftShop:partnershipNamePlaceholder': 'Type in full name',
    'giftShop:partnershipMessagePlaceholder': 'Write a message here',
    'giftshop:passwordConfirmation': 'Password Confirmation',
    'giftShop:paymentInstruction': 'Payment Instruction',
    'giftShop:paymentFee': 'Payment Fee',
    'giftShop:paymentMethod': 'Payment Method',
    'giftShop:paymentProofTitle': 'Send Payment Proof',
    'giftShop:paymentProofAlreadySent': 'Please wait for verification.',
    'giftShop:paymentSendProofFailed':
      'Failed to send the receipt. Please try again.',
    'giftShop:paymentSendProofSuccess': 'Receipt sent.',
    'giftShop:paymentWaitOrSendProofFirst':
      'If you have already paid, please wait 2 minutes for processing, or ',
    'giftShop:paymentWaitOrSendProofSecond': 'send a receipt',
    'giftShop:paymentWaitOrSendProofUploadSubtitle':
      '.png or .jpeg file, maximum size is 2MB',
    'giftShop:pendingTransaction': 'Pending Transaction',
    'giftShop:photoCompress': 'Photo Compress: ',
    'giftShop:photoResend': 'Photo Resend: ',
    'giftShop:pinpointTheLocation': 'Scroll the map to Pinpoint the location',
    'giftShop:playRobopet': 'RoboYu',
    'giftShop:point': '{{count}} Point',
    'giftShop:point_plural': '{{count}} Points',
    'giftShop:premium': 'Premium',
    'giftShop:preparingContent': 'Preparing the best content for you...',
    'giftShop:previewInfoLabel':
      'This is a preview version. Creator will resend the Hi-Res file in 2 days. You can also chat with the creator through the message feature.',
    'giftShop:previewInfoLabel_photo':
      'This is a preview version. Creator will resend the Hi-Res file in 2 days. You can also chat with the creator through the message feature.',
    'giftShop:previewInfoLabel_video':
      'This is a short preview (4 seconds). Creator will resend the full version up to 30 seconds, within 2 days. You can also chat with the Creator through the message feature.',
    'giftShop:price': 'Price',
    'giftShop:priceBreakdown': 'Price Breakdown',
    'giftShop:priceBreakdownRefund': 'Refund',
    'giftShop:priceBreakdownRefund_percentage':
      '({{percentage}}% form basic price)',
    'giftShop:priceBreakdownRefundCreator': 'Refund Creator ({{percentage}}%)',
    'giftShop:priceBreakdownRefundFotoyu': 'Refund Fotoyu ({{percentage}}%)',
    'giftShop:priceBreakdownRefundHost': 'Refund Host ({{percentage}}%)',
    'giftShop:priceEarn': 'Your earnings: ',
    'giftShop:pricefee': 'Basic Price ({{count}}%)',
    'giftShop:pricefee_forky': 'FotoYu fee ({{count}}%)',
    'giftShop:pricefee_host': 'Host fee ({{count}}%)',
    'giftShop:pricefee_paymentservice': 'Payment & services fee',
    'giftShop:pricefee_paymentservicepercentage':
      '({{count}}% from selling price)',
    'giftShop:priceNet': 'Net creator ({{count}}%)',
    'giftshop:pricingDetail': 'Pricing Details',
    'giftShop:problemWhenProcessingRequest':
      "There's a problem when processing your request.",
    'giftshop:problemRequestAttemptLimit':
      'You have exceeded the limit of edit attempt. Please try again later.',
    'giftShop:processImage': 'Processing images: {{count}}/{{total}}',
    'giftShop:profileAddCredential':
      'For ease of support, please add your WhatsApp number.',
    'giftShop:profileAddCredential_email':
      'For ease of support, please add your Email.',
    'giftShop:profileHeader': 'My Creation',
    'giftShop:profilePhoto': 'Profile Photo',
    'giftShop:profileRemoveContentsMessage':
      'Are you sure you want to remove these contents? This action cannot be undone',
    'giftShop:purchase': 'Purchase',
    'giftShop:purchaseDetail': 'Purchase Detail',
    'giftshop:received': 'Received',
    'giftshop:receivedDetail': 'Received Detail',
    'giftshop:receivedPayment': 'Received Payment',
    'giftShop:receivedtransaction':
      'Your received payment history will be shown here.',
    'giftShop:recoverContentButton': 'Recover',
    'giftShop:recoverContentButtonLabel': 'Recover Content?',
    'giftShop:recoverContentSuccessMessage': 'Content successfully recovered.',
    'giftShop:removeAllSeriesMessage':
      'Are you sure to remove all series? This action can not be undone.',
    'giftShop:removeCart': 'Remove from Cart',
    'giftShop:removeCartSuccessMessage':
      'Content successfully removed from cart.',
    'giftShop:removeContents': 'Remove Contents',
    'giftShop:removeContentsMessage':
      'Are you sure to remove {{count}} content? This action cannot be undone',
    'giftShop:removeContentsMessage_plural':
      'Are you sure to remove {{count}} contents? This action cannot be undone',
    'giftShop:removeContentsSnackbarFailed': 'Failed to remove content.',
    'giftShop:removeContentsSnackbarFailed_autopost':
      "Sorry you can't delete content shared with more than one user.",
    'giftShop:removeContentsSnackbarSuccess': 'Content successfully removed.',
    'giftShop:removedContentEmptySubtitle':
      'All the removed contents will be shown here.',
    'giftShop:removedContentEmptyTitle':
      "It looks like you haven't removed any content yet.",
    'giftShop:removedContentSubtitle': 'Contents will be deleted after 7 days',
    'giftShop:removedContentTitle': 'Removed Content',
    'giftShop:removeMainAccountFailed':
      'Failed to remove account from main account',
    'giftShop:removeMainAccountSuccess':
      'Account successfully removed from main account!',
    'giftShop:removePicture': 'Remove Picture',
    'giftShop:removeProfilePictureModalDesc':
      'Are you sure to remove the picture?',
    'giftShop:removeWishlistModalDesc':
      'Are you sure you want to remove {{count}} picture(s)? This action cannot be undone.',
    'giftShop:reportReason': 'Unknown',
    'giftShop:reportReason_FAKE ACCOUNT': "It's pretending to be someone else",
    'giftShop:reportReason_INAPPROPRIATE':
      'One of the content posted is not appropriate',
    'giftShop:reportReason_OTHER': 'Others',
    'giftShop:reportReason_SPAM': 'Spam content',
    'giftShop:resendBatchFailedDescription':
      "There's an error, please try again.",
    'giftShop:resendBatchFailedTitle': 'Failed to Upload',
    'giftShop:resendBatchNoDate': 'No Date',
    'giftShop:resendBatchNoFileName': 'No File Name',
    'giftShop:resendBatchNoFileSize': 'No File Size',
    'giftShop:resendBatchNoResolution': 'No Resolution',
    'giftShop:resendBatchPhotoPlaceholder': 'Add the Hi-res version',
    'giftShop:resendContentCount': '{{count}} Content',
    'giftShop:resendContentCount_plural': '{{count}} Contents',
    'giftShop:resendFloatingButtonTitle': 'Now resend request',
    'giftShop:resendFloatingButtonSubtitle': 'Tap to see',
    'giftShop:resend': 'Resend',
    'giftShop:resendEmail': 'Resend email',
    'giftShop:resendMinSize': 'Minimum Image file is {{min}}',
    'giftShop:resendOTP': 'Resend OTP ',
    'giftShop:resendRevisionButton': 'Resend Revision',
    'giftShop:resendRevisionChatExpired':
      'Sorry, the time for confirming hi-res content has expired.',
    'giftShop:resendRevisionErrorAlreadyRevisedMessage':
      'Sorry! You have revised this content before.',
    'giftShop:resendRevisionImagePlaceholder': 'Add the hi-res version',
    'giftShop:resendRevisionModalEmpty':
      'There is no Yuser you can send a revision to.',
    'giftShop:resendRevisionModalSubtitle':
      'The list of yusers below are those who have purchased this content.',
    'giftShop:resendRevisionModalMessage':
      '(Revision only available for 24 hours after Hi-Res)',
    'giftShop:resendRevisionModalTitle': 'Choose a Yuser',
    'giftShop:resendStatus': 'Resend: ',
    'giftShop:resendToYuser': 'Resend to Yuser',
    'giftShop:resetPasswordPromptMessage':
      'Are you sure want to cancel? The data will not be saved',
    'giftShop:resolution': 'Resolutions:',
    'giftShop:retailDescription':
      '1.  Tell the Alfamart/Alfamidi cashier to make payments to merchants PT. Super Giga Generasi (SUPER GIGA).',
    'giftShop:retailDescription_fourth':
      '4. Your order will be verified automatically in the FOTOYU App after successful payment',
    'giftShop:retailDescription_second':
      '2.  Show the payment code to the cashier and make a payment of {{Amount}}.',
    'giftShop:retailDescription_third':
      '3. After the transaction is successful, you will get proof of payment. Please keep proof of payment as collateral in case further verification is required.',
    'giftShop:retakeKYC': 'Add Selfie',
    'giftShop:retakeKYCExample': 'Good & Bad Examples:',
    'giftShop:retakeKYCLimitInfo': 'Add Selfie is limited to 3 times per day.',
    'giftShop:retakeKYCInstruction': 'Tap the screen to continue',
    'giftShop:retakeKYCMessage':
      'The selfie you registered is not clear. Please add a selfie by following the tips given.',
    'giftShop:retakeKYCPlaceholder':
      'You can add 1 more selfie.\nYou may wear glasses.',
    'giftShop:retakeKYCPlaceholder_processing':
      'RoboYu learning the new selfie.\nPlease wait...',
    'giftShop:retakeKYCPlaceholder_rejected':
      'RoboYu needs a better selfie, please retake by following the tips given',
    'giftShop:retakeKYCRoboyuMessage':
      'Hello Boss, RoboYu searches your photos based on these selfies.',
    'giftShop:retakeKYCWarning':
      'Make sure your face is clean and not covered by accessories (other than glasses) and hair',
    'giftShop:robopet': 'RoboYu',
    'giftShop:robopetHeader': '',
    'giftShop:roboyuAccuracyHighDesc': 'Less content',
    'giftShop:roboyuAccuracyMediumDesc': 'More content',
    'giftShop:roboyuAccuracySuccessSnackbar': 'RoboYu Accuracy adjusted.',
    'giftShop:roboyuAccuracySuccessSnackbar_content': 'Showing more content...',
    'giftShop:roboyuAccuracyTitle': 'RoboYu Search Accuracy',
    'giftShop:roboyuAccuracyPlus': '5+ accuracy',
    'giftShop:roboyuAccuracyMinus': '5- accuracy',
    'giftShop:roboyuActivationIncompleteDescription':
      'Sorry! Looks like the process is not completed. Please make sure that all requirements are correct, and try again.',
    'giftShop:roboyuActivationIncompleteHeader':
      'Verification is \n Not Completed',
    'giftShop:roboyuActivationProcessingDescription':
      'Thank you! RoboYu will inform you as soon as possible.',
    'giftShop:roboyuActivationProcessingHeader': 'Waiting For Approval...',
    'giftShop:roboyuActivationRejectedDescription':
      'Thank you for the submission! Unfortunately, we are not able process it for the moment.',
    'giftShop:roboyuActivationRejectedHeader':
      'Verification Cannot Be Processed',
    'giftShop:roboyuActivationUnregisteredDescription':
      'Activate RoboYu through FOTOYU mobile app.',
    'giftShop:roboyuActivationUnregisteredDescription_search':
      'You can activate RoboYu as your private robo searcher.',
    'giftShop:roboyuActivationUnregisteredDescription_robopet':
      'Utilize Face Recognition technology on RoboYu to find your contents faster.',
    'giftShop:roboyuActivationUnregisteredDescription_filter-ol':
      'Filter your searches based on your location and time history.',
    'giftShop:roboyuActivationUnregisteredDescription_image-search':
      'Also, you can search by image too, RoboYu can recognize any items on the image.',
    'giftShop:roboyuActivationUnregisteredHeader': 'Hi! I am RoboYu!',
    'giftShop:roboyuActivationVerifiedDescription':
      'RoboYu starts searching right away and will send you DMs if it finds something. For the first search, RoboYu needs verification time. Your contents are shown on "FotoYu" tab.',
    'giftShop:roboyuActivationVerifiedHeader': 'RoboYu Activated!',
    'giftShop:roboyuActivationUploadPhotoDescription':
      'Upload your photo creations to the FotoYu server so that your friends or family can find it.',
    'giftShop:roboyuFirstBuyBannerTitle': 'First Buy Referral Program',
    'giftShop:roboyuFirstBuyBannerDescription':
      'Refer your friends and families to get additional 50 MB storage/new yuser that purchased for the first time at FotoYu from you.',
    'giftShop:roboyuIncompleteDesc':
      'Please make sure that all requirements are correct, and try again.',
    'giftShop:roboyuIncompleteSubtitle':
      'Your KYC Verification process is Not Completed.',
    'giftShop:roboyuReadMore': 'Read More',
    'giftShop:roboyuUploadInvitation':
      'Upload your photo creations to the FotoYu server so that your friends or family can find it.',
    'giftShop:roboyuUploadNotActivatedDesc':
      'You need to activate your RoboYu first before uploading your first content.',
    'giftShop:roboyuUploadNotActivatedInfo':
      'When your RoboYu is activated, you can come back here to start uploading your content.',
    'giftShop:searchByCreatorEmptyInfo':
      'Please try again using another keyword.',
    'giftShop:searchByCreatorEmptyTitle':
      'Sorry! We cannot find the Creator you are looking for',
    'giftShop:searchByCreatorNotFoundButton': 'Open FotoYu Tab',
    'giftShop:searchByCreatorNotFoundDescription1':
      'Please ask the link owner to update their link,',
    'giftShop:searchByCreatorNotFoundDescription2':
      ' or try using search button in the FotoYu tab.',
    'giftShop:searchByCreatorNotFoundTitle': "Sorry! We can't find the creator",
    'giftShop:searchByCreatorPlaceholder': 'Search by creator username',
    'giftShop:searchCityPlaceholder': 'Type in city name',
    'giftShop:searchCreation': 'Search Content',
    'giftShop:searchCreationNotFoundTitle':
      'Sorry! We cannot find the content you are looking for',
    'giftShop:searchCreationNotFoundMessage':
      'Please try again using another keyword.',
    'giftShop:searchCreationPlaceholder':
      'Input content title (Min. 3 characters)',
    'giftShop:searchCreatorAndFotoTreeNotFound':
      'Sorry! We cannot find the results you are looking for. Please try again using another keyword.',
    'giftShop:searchCreatorAndFotoTreePlaceholder': 'FotoTree or Creator',
    'giftShop:searchInputMinThreeChar': 'Type (Min. 3 characters)',
    'giftShop:searchLocationInputPlaceholder': 'Search for Location',
    'giftShop:searchResultDisclaimerTitle': 'Your RoboYu findings',
    'giftShop:searchResultDisclaimer1':
      '**Keep in mind that FotoYu never searches photos for yuser. Yuser are the ones that control each Roboyu to search photos of themselves.** ',
    'giftShop:searchResultDisclaimer2':
      'RoboYu is a smart and diligent Robo. However, **you are the controller.** Thus, for every photo found, RoboYu will ask for your confirmation, **is this you?** You just need to answer: **Yeah** or **Nope**.',
    'giftShop:searchResultDisclaimer3':
      "From millions of photos at the server, RoboYu will only offer yuser, photos that it considers match **the face and location algorithm**. This will drastically reduce the chances of other people's photos appearing on your findings.",
    'giftShop:searchResultDisclaimer4':
      "Sometimes RoboYu will offer a photo that it considers similar **algorithmically**, but it may not be you. Ethically, you only confirm when the photo is really you, and reject the photo that isn't you. By correctly answering **Yeah** or **Nope**, you train RoboYu to be **smarter and more efficient** on finding your photos.",
    'giftShop:searchResultDisclaimer5':
      "**You may not buy other people's photos on FotoYu, if there is no you in the photo, we strongly emphasize.**",
    'giftShop:searchResultDisclaimer6':
      "All transactions will leave digital footprints where FotoYu will maximally enforce in order to protect everyone's privacy.",
    'giftShop:searchResultDisclaimer7':
      "Many other websites allow you to buy other people's photos, but we forbid this on FotoYu.",
    'giftShop:searchResultDisclaimer8':
      "FotoYu tries to make a real difference in privacy, so that the process of selling personal photos/content in this world, doesn't need to display everyone's photos for everyone to see.",
    'giftShop:searchResultDisclaimerCloser': "Let's FotoYu!",
    'giftShop:searchTransaction': 'Search Transaction',
    'giftShop:searchTransactionInputPlaceholder':
      'Bank, invoice ID, or creator (Min. 3 characters)',
    'giftShop:selectFile': 'Select File',
    'giftShop:selectItems': 'Selected ({{count}})',
    'giftShop:selectVerificationMethod': 'Select Verification Method',
    'giftShop:sell': 'Sell',
    'giftShop:sendToFace': 'Send to Face',
    'giftShop:sendToFaceDescriptionPlaceholder': 'Write a description',
    'giftShop:sendToFaceInfo': 'Automatically send photo to the face owner',
    'giftShop:setAsMainBankAccount': 'Set as the main bank account',
    'giftShop:setting': 'Account Settings',
    'giftShop:settingHeader': 'Account Settings',
    'giftShop:settingWholesale': 'Discount Settings',
    'giftShop:settingWholesaleDisclaimer':
      "The discount will be applied to the content's basic price.",
    'giftShop:shareCollectionTextTemplateTwitterAndFacebook':
      "Hi, check out this cool content that I found from FotoYu's Website!%0AFind out more at https://www.fotoyu.com %0A",
    'giftShop:shareCollectionTextTemplateWhatsapp':
      "Hi, check out this cool content that I found from FotoYu's Website!%0AFind out more at https://www.fotoyu.com %0A%0A",
    'giftShop:shareProfile': 'Share Profile',
    'giftShop:shareProfileFotoyu': 'Share FotoYu Profile',
    'giftShop:shareProfileFotoyuDescription':
      'So yusers can see your portfolios.',
    'giftShop:shareProfileSearchLink': 'Share Search Link',
    'giftShop:shareProfileSearchLinkCreatorOnlySnackbar':
      'Please upgrade your account into verified creator.',
    'giftShop:shareProfileSearchLinkDescription':
      'So yusers can see the contents only from you.',
    'giftShop:shareTo': 'Share to...',
    'giftShop:similarAccountDeleteOthersDesc':
      'Please delete your other accounts to help speed up your account verification. Undeleted double accounts will need extra time for admin manual verification:',
    'giftShop:similarAccountDescSelf': 'Only 1 account per person is allowed.',
    'giftShop:similarAccountDeleteSuccess': 'Account successfully deleted.',
    'giftShop:similarAccountNoOtherAccount': "I don't have other accounts",
    'giftShop:similarAccountSelfConfirm': 'Is this your account?',
    'giftShop:similarAccountSelfVerified':
      'Your account has been successfully activated and is now ready for use. Welcome aboard!',
    'giftShop:similarChooseAccountDesc':
      'Only 1 account per person is allowed. Please choose one account you wish to activate:',
    'giftShop:similarChooseAccountSelfText':
      'Please wait while we check your information. We will notify you after the process is done.',
    'giftShop:similarCurrentAccount': 'Current',
    'giftShop:similarIdentityDescription':
      'As a precaution, we will need to do a manual verification to re-confirm.',
    'giftShop:similarIdentitySubtitle':
      'RoboYu detects a similarity with another account.',
    'giftShop:similarMultiAccountChooseDesc':
      'Only 1 account per person is allowed.\nPlease choose carefully which account you want to activate.\nThe other accounts will be banned:',
    'giftShop:similarMultiAccountCollectionCount':
      'Collections: <count>{{photo_count}} photos | {{video_count}} videos</count>',
    'giftShop:similarMultiAccountConfirmation':
      'Are you sure with your choice? The other accounts will be banned and inaccessible.',
    'giftShop:similarMultiAccountDescFirst':
      'We took privacy issues seriously and strictly against any illegal attempts to impersonate others or spoofing.',
    'giftShop:similarMultiAccountDescSecond':
      'To continue using our app, you need to choose only one account.',
    'giftShop:similarMultiAccountFinishedOther':
      'You will be logged out. Please login using the account you selected earlier.',
    'giftShop:similarMultiAccountFinishedSelf':
      'Your account has been activated. You can now use this account.',
    'giftShop:similarMultiAccountSubtitle': 'RoboYu detects double accounts.',
    'giftShop:similarMultiAccountTitle': 'Double Accounts',
    'giftShop:similarOtherAccountFailedMessage':
      'Invalid email, please input the correct one.',
    'giftShop:similarOtherAccountFailedMessage_phone':
      'Invalid mobile number, please input the correct one.',
    'giftShop:similarOtherAccountFailedMessage_username':
      'Invalid username, please input the correct one.',
    'giftShop:similarOtherAccountFinish':
      'You will be logged out, and this account will be banned. Please login using the account you selected earlier.',
    'giftShop:similarOtherAccountVerif': 'Please input the email to verify.',
    'giftShop:similarOtherAccountVerif_phone':
      'Please input the mobile number to verify.',
    'giftShop:similarOtherAccountVerif_username':
      'Please input the username to verify.',
    'giftShop:similarOtherAccountVerifUsernamePlaceholder': 'Input username',
    'giftShop:similarWaitingApprovalDesc':
      'We will notify you after the process is done.',
    'giftShop:similarWaitingApprovalSubtitle':
      "Roboyu's activation is pending for manual verification. Please refrain from creating a second account.",
    'giftShop:snackbarFailedDownloadMyCollection':
      'Download failed. Please try again later',
    'giftShop:snackbarFailedExecute':
      'Error occured while executing action. Please try again.',
    'giftShop:snackbarFailedFetch':
      'Error occured while fetching data. Please try again.',
    'giftShop:snackbarSuccessExecute': 'Successfully execute action',
    'giftShop:snackbarSuccessExecute_add':
      'Item successfully added to {{where}}',
    'giftShop:successAddPassword': 'Your password has been added.',
    'giftShop:successUnblockedAccount': 'Account successfully unblocked',
    'giftShop:snackbarSuccessExecute_remove':
      'Item successfully removed from {{where}}',
    'giftShop:socialLink': 'Social Link',
    'giftShop:socialLink_add': 'Add Social Link',
    'giftShop:socialLink_edit': 'Edit Social Link',
    'giftShop:socialLinkDescription': 'You can add up to 6 links',
    'giftShop:socialLinkDescriptionEmpty': "You haven't added any links yet",
    'giftshop:socialLinkResponseSuccess':
      'Your link has been successfully added.',
    'giftshop:socialLinkResponseSuccess_edit':
      'Your link has been successfully edited.',
    'giftshop:socialLinkResponseSuccess_delete':
      'Your link has been successfully deleted.',
    'giftshop:socialLinkValidation': 'Field cannot be empty',
    'giftshop:socialLinkValidation_minimumLength':
      'Username must be at least {{length}} characters long.',
    'giftshop:socialLinkValidation_minimumNumberLength':
      'Mobile number must be at least {{length}} characters long.',
    'giftshop:socialLinkValidation_includeKeyword':
      'Link must include {{keyword}}',
    'giftShop:sold': 'Sold',
    'giftShop:status': 'Status',
    'giftShop:storage': 'Storage',
    'giftShop:storageInfo': 'Storage Info',
    'giftShop:storageFullInfo': 'Your storage is full.',
    'giftShop:storageLowInfo': 'Your storage is almost full.',
    'giftShop:storageFullTitle': 'Your storage is almost full',
    'giftShop:storageFullMessage':
      'You can contact Admin for additional storage.',
    'giftShop:storageLowInfoNonVerified':
      'Your storage is almost full. Upgrade your account to a verified creator and enjoy expanded storage.',
    'giftShop:storageRequest': 'Request Storage',
    'giftShop:storageRequestNotice':
      'We have received your request. Please wait for notification from us.',
    'giftShop:successfullyAddedItem': 'Succesfully added item',
    'giftShop:supportTitle': 'Got questions?',
    'giftShop:supportSubTitle': 'You can chat with FotoYu support team',
    'giftShop:successDownloadMessageHires':
      'Downloaded. Check "FotoYu" folder in your gallery.',
    'giftShop:successDownloadMessageNotHires':
      '"Not Hi-Res" version downloaded. Check "FotoYu" folder in your gallery.',
    'giftShop:successfulTransaction': 'Successful Transaction',
    'giftShop:tag': 'FotoTree:',
    'giftShop:textTo': 'Text to',
    'giftShop:totalBalance': 'Total Balance',
    'giftShop:totalIncentive': 'Total Incentive',
    'giftShop:totalItems': '{{count}} contents',
    'giftShop:totalPayment': 'Total Payment',
    'giftShop:totalPrice': 'Total Price',
    'giftShop:totalRefund': 'Total Refund',
    'giftShop:totalRefundAmount': '{{price}} ({{percent}}%)',
    'giftShop:totalWithdraw': 'Total Withdrawal',
    'giftShop:transactionCreatorName': "Creator's name",
    'giftShop:transactionCreatorNameHost': 'Host name',
    'giftShop:transactionDate': 'Transaction Date',
    'giftShop:transactionDetail': 'Transaction Detail',
    'giftShop:transactionDetailSubtitle': 'This page is not proof of payment.',
    'giftShop:transactionHistory': 'Transaction History',
    'giftShop:transactionHistoryNotFoundDescription':
      'Please try again using another keyword.',
    'giftShop:transactionHistoryNotFoundTitle':
      'Sorry! We cannot find the transactions you are looking for',
    'giftShop:transactionPaymentTime': 'Payment Time',
    'giftShop:transactionStatus': 'Unknown',
    'giftShop:transactionStatus_COMPLETED': 'Completed',
    'giftShop:transactionStatus_FAILED': 'Failed',
    'giftShop:transactionStatus_HALF_COMPLETED': 'Half Completed',
    'giftShop:transactionStatus_PROCESSED': 'Waiting Payment',
    'giftShop:transactionStatus_WAITING_HIRES': 'Waiting Hi-Res',
    'giftShop:transactionTime': 'Transaction Time',
    'giftShop:transactionType': 'Transaction Type',
    'giftShop:transactionType_INCENTIVE': 'Incentive',
    'giftShop:transactionType_PURCHASE': 'Payment to',
    'giftShop:transactionType_RECEIVED': 'Payment from',
    'giftShop:transactionType_REFUND': 'Refund from',
    'giftShop:transactionType_REFUND_TO': 'Refund to',
    'giftShop:transactionType_MAKE_IT_FREE': 'Make It Free',
    'giftShop:transactionType_WITHDRAW': 'Withdraw',
    'giftShop:transactionVANumberReminder':
      'This is a one-time use only VA number. Always use the matching bank service for the payment.',
    'giftShop:transactionYuserNickname': "Yuser's Nickname",
    'giftShop:twitter': 'Twitter',
    'giftShop:ofLimitStorage': ' of ',
    'giftShop:oldContentReminder': 'Some content is over 30 days old.',
    'giftShop:gbStorage': '{{limit}} GB',
    'giftShop:usedStorage': ' used',
    'giftShop:totalStorage': 'Total: {{limit}} GB',
    'giftShop:bonusStorage': '+{{bonus}} GB',
    'giftShop:unblock': 'Unblock',
    'giftShop:unsatisfiedUploadFormat':
      'Image must be under 50MB and 25MP resolution',
    'giftShop:unsatisfiedUploadFormat_SIZE_ONLY': 'Image must be under 50MB ',
    'giftShop:unwishlistModalConfirmText': 'Nope, this is not me',
    'giftShop:unSuccessUnblockedAccount': 'Failed unblocked Account',
    'giftShop:unwishlistModalDesc':
      'RoboYu will keep training to serve you better.',
    'giftShop:unwishlistModalQuestion': 'Are you sure this is not you?',
    'giftShop:unwishlistModalTitle': 'Oops...',
    'giftShop:upgrade': 'Upgrade Premium',
    'giftShop:upgradeCreator': 'Upgrade to Creator',
    'giftShop:upgradeCreatorDescription':
      'Upgrade to a verified creator through the fotoyu mobile app',
    'giftShop:upgradeCreatorPrice':
      'Set the price of your photos up to 1 million rupiah',
    'giftShop:upgradeCreatorStorage':
      'You will get additional storage up to 10 gb',
    'giftShop:upgradeCreatorVideo': 'You will be able to sell videos',
    'giftShop:upgradeDescription':
      'Upgrade your account to get all extra perks',
    'giftShop:upgradeToPremium': 'Upgrade to Premium',
    'giftShop:uploadAddSeriesButton': '+ Same Person',
    'giftShop:uploadAddSeriesToolTip':
      'Add person with same information as the “parent” content',
    'giftShop:uploadAnImage': 'Upload an Image',
    'giftShop:uploadBulkDeleteConfirmationDescription':
      'Are you sure you want to remove this content? This action cannot be undone',
    'giftShop:uploadBulkDeleteConfirmationHeader': 'Remove Content',
    'giftShop:uploadContentHeader': 'Upload Content',
    'giftShop:uploadContentHere': 'Upload your images here',
    'giftShop:uploadCriteria':
      'JPG, JPEG, PNG, HEIF, or HEIC Image and no larger than 50MB',
    'giftShop:uploadDeleteContent': 'Delete Content',
    'giftShop:uploadDescriptionLabel': 'Description (Optional)',
    'giftShop:uploadDragAndDrop': 'Drop your masterpiece here to make it fly',
    'giftShop:uploadDroptheImage': 'Drop the image to upload it',
    'giftShop:uploadedContentCount': '{{count}} Content',
    'giftShop:uploadedContentCount_plural': '{{count}} Contents',
    'giftShop:uploadedPicture': '{{count}} content(s) successfully added',
    'giftShop:uploadEmptyDesc':
      'Start uploading and let the world know your work!',
    'giftShop:uploadEmptyTitle': 'This place is so lonely...',
    'giftShop:uploadFailed':
      "There's some problem occured when uploading your images",
    'giftShop:uploadFailed_storage':
      'Exceeded maximum storage limit {{limit}} GB',
    'giftShop:uploadIconMaxSnackbar': 'Maximum upload is {{max}} images',
    'giftShop:uploadIconMaxVideoSnackbar': 'Maximum upload is {{max}} videos',
    'giftShop:uploadInputDateLabel': 'Date (Optional)',
    'giftShop:uploadInputLocationLabel': 'Location (Optional)',
    'giftShop:uploadInputDescriptionPlaceholder': 'Tell us about your content',
    'giftShop:uploadInputLocationPlaceholder': 'Add Location',
    'giftShop:uploadInputPeopleTagPlaceholder': 'Max. 10 Nicknames',
    'giftShop:uploadInputPricePlaceholder': 'How much?',
    'giftShop:uploadInputTagsNotFound': 'We cannot find FotoTree ',
    'giftShop:uploadInputTagsNotSelected':
      'Please select a FotoTree from the list.',
    'giftShop:uploadInputTagsPlaceholder': 'Type a FotoTree name',
    'giftShop:uploadInputTimeLabel': 'Time (Optional)',
    'giftShop:uploadInputTitlePlaceholder': 'The name of this masterpiece',
    'giftShop:uploadMaximumMessage': 'Cannot upload more than {{max}} images',
    'giftShop:uploadMaximumVideoMessage':
      'Cannot upload more than {{max}} videos',
    'giftShop:uploadModalMessage':
      'Are you sure you want to cancel? The content will not be saved',
    'giftShop:uploadModalCompressDescriptionEnabled':
      'Compress & Resend is Activated',
    'giftShop:uploadModalPostDescription':
      'Share your moment and it will show up publicly in Home feed.',
    'giftShop:uploadModalSellDescription':
      "Upload your creations to Fotoyu server, so Yuser's RoboYu can find them.",
    'giftShop:uploadModalTitle': 'Choose Content Type',
    'giftShop:uploadModeBulkDescription':
      'One metadata for all files (Max. {{max}} files)',
    'giftShop:uploadModeBulkHeader': 'Photo - Bulk Mode',
    'giftShop:uploadModeSingleDescription':
      'Custom metadata for each file (Max. {{max}} files)',
    'giftShop:uploadModeSingleHeader': 'Photo - Single Mode',
    'giftShop:uploadPeopleTagLabel': "Tag Yuser's Nickname (Optional) ",
    'giftShop:uploadModeVideoDescription':
      'One metadata for all files (Max. {{max}} files)',
    'giftShop:uploadModeVideoHeader': 'Video Preview',
    'giftShop:uploadModeVideoSubdescription':
      'Use a video editor to adjust the file to the criteria.',
    'giftShop:uploadNeedLocationOrFototreeErrorMessage':
      'Either FotoTree or Location needed.',
    'giftShop:uploadPhoto': 'Upload Photo',
    'giftShop:uploadPriceEmpty': 'Please fill price data',
    'giftShop:uploadPriceZeroModalButtonCancel': 'Edit Price',
    'giftShop:uploadPriceZeroModalDescription':
      'Some content has zero price tag, are you sure?',
    'giftShop:uploadPriceZeroModalTitle': 'Zero Price Tag',
    'giftShop:uploadPublishFinishedDescription':
      'Content has been uploaded successfully!',
    'giftShop:uploadPublishFinishedDescription_plural':
      'All content has been uploaded successfully!',
    'giftShop:uploadPublishFinishedTitle': "It's Done!",
    'giftShop:uploadPublishLoadingDescription':
      '{{success}} of {{total}} Contents Uploaded.',
    'giftShop:uploadPublishLoadingNote': 'Please wait a moment',
    'giftShop:uploadPublishLoadingTitle': 'Uploading...',
    'giftShop:uploadPublishReportTitle': 'Upload Report',
    'giftShop:uploadPublishReportDescription': 'You just uploaded:',
    'giftShop:uploadPublishRetryCountFail':
      '{{count}} contents failed to upload.',
    'giftShop:uploadPublishRetryCountDuplicate':
      '{{count}} contents detected as duplicates.',
    'giftShop:uploadPublishRetryCountUploaded':
      '{{count}} contents uploaded successfully.',
    'giftShop:uploadPublishRetryCountUploadedDuplicate':
      'Only {{count}} contents uploaded.',
    'giftShop:uploadPublishRetryTitle': 'Uploaded! But...,',
    'giftShop:uploadPublishRetryTryAgainAndViewReport':
      'Try Again, then View Report',
    'giftShop:uploadPublishRetryViewReport': 'View Report',
    'giftShop:uploadQuickFillBanner': 'Click Here to fill form faster.',
    'giftShop:uploadQuickFillModalDescription': 'Description:',
    'giftShop:uploadQuickFillModalLocation': 'Location:',
    'giftShop:uploadQuickFillModalPrice': 'Basic Price:',
    'giftShop:uploadQuickFillModalSuccessSnackbar': 'Data filled successfully.',
    'giftShop:uploadQuickFillModalTitle':
      'Fill all content data with recently used data:',
    'giftShop:uploadSeriesModalTitle': 'All Content of the Series ({{count}})',
    'giftShop:uploadSeriesModalTitle_plural':
      'All Contents of the Series ({{count}})',
    'giftShop:uploadSeriesSetAsParent': 'Set as Parent',
    'giftShop:uploadTagHostFee': 'Host Fee: {{hostFee}}%',
    'giftShop:uploadTagHostFeeEditFotoTree': 'Please change the FotoTree.',
    'giftShop:uploadTagHostFeeFototreeDesc': '({{hostFee}}% Fee) {{location}}',
    'giftShop:uploadTagHostFeeModalDescription':
      'There is a Host Fee from the FotoTree you pick, that will be deducted from your earnings. Are you sure?',
    'giftShop:uploadTagHostFeeModalEditButton': 'Edit FotoTree',
    'giftShop:uploadTagHostFeeModalTitle': 'Host Fee',
    'giftShop:uploadTagLabel': 'FotoTree (Optional)',
    'giftShop:uploadTagNicknameConfirmationDescription':
      'Are you sure you want to tag this nickname for all contents? Make sure to not tag the nickname for an incorrect photo.',
    'giftShop:uploadTagNicknameConfirmationTitle': 'Tag Yuser’s Nickname',
    'giftShop:uploadTitleLabel': 'File Name',
    'giftShop:uploadTotal': '{{count}}/{{max}}',
    'giftShop:uploadTotal_plural': '{{count}} Contents',
    'giftShop:validateKYCModalDescription':
      'Please wait for account approval to use this feature',
    'giftShop:validateKYCModalSubTitle':
      'It seems that your account is still being verified',
    'giftShop:validationAccountNumber': 'Please enter an account number',
    'giftShop:validationAccountNumberNumeric': 'Account number must be numeric',
    'giftShop:validationAccountNumberRegistered':
      'Account number has been registered',
    'giftShop:validationBankName': 'Please enter bank name',
    'giftShop:validationBranchName': 'Please enter bank branch name',
    'giftShop:validationDateOfBirth': 'Please enter your date of birth',
    'giftShop:validationEmail': 'Please enter an email address',
    'giftShop:validationFullname': 'Please enter a full name',
    'giftShop:validationPriceNumeric': 'Price must be numeric',
    'giftShop:verifiedAccount': 'Verified Account',
    'giftShop:videoConfirmBeforePlayMessage':
      'Please confirm the content first.',
    'giftShop:videoEditorTitle': 'Edit Preview Video',
    'giftShop:videoEditorProcessing': 'Processing...',
    'giftShop:videoPreview': 'Video Preview',
    'giftShop:videoResend': 'Video Resend: ',
    'giftShop:videoResendAddButton': 'Add Video',
    'giftShop:videoResendChangeButton': 'Change Video',
    'giftShop:videoResendNoDuration': 'No Duration',
    'giftShop:videoResendNoFileSize': 'No File Size',
    'giftShop:videoResendPlaceholder': 'Add the hi-res version',
    'giftShop:videoResendRequirementInfo':
      'Hi-Res Video: Max 30 seconds, 70MB 1080p 30fps MP4 (MPEG-4)',
    'giftShop:videoUploadErrorInvalidDevice':
      "Sorry, you can't upload video previews on iPhone browsers for now",
    'giftShop:videoUploadErrorInvalidFormat': 'Video format must be MP4',
    'giftShop:videoUploadErrorMaxDuration':
      'Maximum duration is {{duration}} seconds',
    'giftShop:videoUploadErrorMaxResolution':
      'Maximum width & height is {{maxRes}}',
    'giftShop:videoUploadErrorMaxSize': 'Maximum file size is {{maxSize}} MB',
    'giftShop:videoUploadSendToFaceErrorInvalidFormat':
      'Image format must be JPEG, PNG, or HEIC',
    'giftShop:videoUploadSendToFaceErrorMaxSize':
      'Maximum file size is {{maxSize}} MB',
    'giftShop:videoUploadSendToFacePlaceholder':
      'Photo with clear face of the yuser(s).',
    'giftShop:virtualAccountMethod': '{{method}} Virtual Account',
    'giftShop:virtualAtmDescription': '',
    'giftShop:virtualAtmDescription_BCA':
      '1. Select Other Transactions > Transfer > To BCA Virtual Account. \n2. Enter company code 7007015 / 7007115 / 7007215 and select Correct. \n3. Enter Virtual Account number {{Account}} and select True. \n4. Check the information shown on the screen. Make sure the Merchant is PT. Super Giga Generasi (SUPER GIGA), the total bill is correct. If correct, select Yes.',
    'giftShop:virtualAtmDescription_BNI':
      '1. Select Transfer. \n2. Select Current/Savings. \n3. Select Virtual Account Billing. \n4. Enter Virtual Account number {{Account}} and select True. \n5. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If so, press the number 1 and select Yes. \n6. Check the confirmation screen and select Yes.',
    'giftShop:virtualAtmDescription_BRI':
      '1. Select Other Transactions > Payments > Others > BRIVA. \n2. Enter BRIVA Number {{Account}} then select True. \n3. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If so, select Yes.',
    'giftShop:virtualAtmDescription_MANDIRI':
      '1. Select Pay/Buy. \n2. Select More > More > Multi Payment. \n3. Enter company code 88608 / 88908 and select Correct. \n4. Enter Virtual Account number {{Account}} and select True. \n5. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If so, press the number 1 and select Yes. \n6. Check the confirmation screen and select Yes.',
    'giftShop:virtualAtmDescription_PERMATA':
      '1. Select Other Transactions. \n2. Select Payments > Other Payments. \n3. Select Virtual Accounts. \n4. Enter the pay code {{Account}}, then select Correct. \n5. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If true, select True.',
    'giftShop:virtualiBankingDescription': '',
    'giftShop:virtualiBankingDescription_BCA':
      '1. Select Fund Transfer > Transfer to BCA Virtual Account. \n2. Check the Virtual Account number and enter {{Account}} and select Continue. \n3. Check the information shown on the screen. Make sure the Merchant is PT. Super Giga Generasi (SUPER GIGA) and the total bill is correct. If correct, select Continue. \n4. Enter your KeyBCA response and click Submit.',
    'giftShop:virtualiBankingDescription_BNI':
      '1. Login to your i-Banking. Select Transfer, then select Virtual Account Billing. \n2. Enter Virtual Account number {{Account}}, then select Continue. \n3. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If correct, enter your PIN and select OK.',
    'giftShop:virtualiBankingDescription_BRI':
      '1. Select the Payment menu. \n2. In the left navigation column, in the Payments menu, select BRIVA. \n3. Select the original account, then select Fill in the Pay Code and enter the Virtual Account number {{Account}} then select Send. \n4. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If correct, enter your iBanking and mToken passwords, then click Submit.',
    'giftShop:virtualiBankingDescription_MANDIRI':
      '1. Select Pay > Multi Payment \n2. Select From Account: Your Account and Service Provider: XENDIT, then click Continue. \n3. Enter Virtual Account number {{Account}} and select Continue. \n4. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If correct, tick the bill and click Continue. \n5. Enter your token PIN and click Submit.',
    'giftShop:virtualiBankingDescription_PERMATA':
      '1. Select Pay Bill Payment. \n2. Select Virtual Accounts. \n3. Enter the pay code {{Account}}, then select OK. \n4. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If so, select Next. \n5. Enter your Response Code, then select Continue.',
    'giftShop:virtualmBankingHeader': 'm-Banking',
    'giftShop:virtualmBankingHeader_MANDIRI': 'm-Banking (Livin by Mandiri)',
    'giftShop:virtualmBankingDescription': '',
    'giftShop:virtualmBankingDescription_BCA':
      '1. Select m-Transfer > BCA Virtual Account. \n2. Enter Virtual Account number {{Account}} and select Send. \n3. Check the information shown on the screen. Make sure the Merchant is PT. Super Giga Generasi (SUPER GIGA) and the total bill is correct. If it’s already correct, select yes. \n4. Enter your m-BCA PIN and select OK.',
    'giftShop:virtualmBankingDescription_BNI':
      '1. Login to your m-Banking. Select Transfer, then select Virtual Account Billing. \n2. Enter Virtual Account number {{Account}}, then select Continue. \n3. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If correct, enter your PIN and select OK.',
    'giftShop:virtualmBankingDescription_BRI':
      '1. Enter the BRI Mobile Banking menu. Then, select Payments > BRIVA. \n2. Enter BRIVA Number {{Account}} \n3. Enter your PIN then select Send. When a confirmation message for transactions using SMS appears, select OK. Transaction status will be sent via SMS and can be used as proof of payment.',
    'giftShop:virtualmBankingDescription_MANDIRI':
      '1. Login to your m-Banking. Select IDR Transfer, then select Transfer to new recipient. \n2. Enter your Virtual Account number {{Account}}, then select Continue. \n3. Check the information shown on the screen. Make sure the total bill is correct. If correct, enter your PIN and select OK.',
    'giftShop:virtualmBankingDescription_PERMATA':
      '1. Select Payment Pay Bill \n2. Select Virtual Accounts. \n3. Enter the pay code {{Account}}, then select OK. \n4. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If so, select Next. \n5. Select the account number you will use. \n6. Enter your PIN, then select Next.',
    'giftShop:virtualMiniAtmDescription_BRI':
      '1. Select Mini ATM > Payments > BRIVA. \n2. Swipe your BRI debit card. \n3. Enter BRIVA Number {{Account}} then press OK. \n4. Enter your PIN then press OK. \n5. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct, then select Continue. If the transaction is successful, a proof of transfer will be printed.',
    'giftShop:waitingForPayment': 'Waiting for Payment',
    'giftShop:waitingForPayment_desc': '{{count}} transaction is pending.',
    'giftShop:wechat': 'WeChat',
    'giftShop:website': 'Website',
    'giftShop:websiteLinkPlaceholder': 'Input your link',
    'giftShop:whatsapp': 'WhatsApp',
    'giftShop:whatsappTo': 'WhatsApp to',
    'giftShop:whereYourContentIsMade': 'Where your content is made?',
    'giftShop:wholesaleChangesFailed': 'Changes failed to save.',
    'giftShop:wholesaleChangesSuccess': 'Changes have been saved.',
    'giftShop:wholesaleDiscountHigherMessage':
      'The discount value cannot be higher than 50%.',
    'giftShop:wholesaleDiscountLowerMessage':
      'The discount value cannot be same or lower than the previous discount value.',
    'giftShop:wholesaleMaximumHigherMessage':
      'The maximum amount cannot be higher than 100.',
    'giftShop:wholesaleMaximumLowerMessage':
      'The maximum amount cannot be lower than the minimum amount.',
    'giftShop:wholesaleModalAllDiscountedItem': 'Discount {{discount}}%',
    'giftShop:wholesaleModalContentAmount': 'Content Amount',
    'giftShop:wholesaleModalContentAmountItem': '{{contents}} contents',
    'giftShop:wholesaleModalTitle': 'Discount Prices',
    'giftShop:wishlist': 'FotoYu',
    'giftShop:wishlistHeader': 'Photo of You',
    'giftShop:withdraw': 'Withdraw',
    'giftShop:withdrawHeader': 'Withdraw',
    'giftShop:withdrawal': 'Your withdrawal history will be shown here.',
    'giftShop:withdrawAll': 'Withdraw all',
    'giftShop:withdrawAmmount': 'Withdraw Amount',
    'giftShop:withdrawAmountLabel': 'Withdrawal Amount:',
    'giftShop:withdrawCancelConfirmation':
      'Are you sure to cancel this transaction?',
    'giftshop:withdrawChooseBank': 'Please select a bank account',
    'giftShop:withdrawConfirmationDisclaimer':
      'Weekly withdrawal cut off time : Monday & Thursday at 12.00 noon WIB. ',
    'giftShop:withdrawConfirmationDisclaimer_time':
      'Monday & Thursday at 12.00 noon WIB.',
    'giftShop:withdrawConfirmationDisclaimer_desc':
      'Withdrawals after the cut-off time will be processed next cycle.',
    'giftShop:withdrawConfirmationExplanationFirst':
      'Checking transactions processed at:',
    'giftShop:withdrawConfirmationExplanationSecond':
      'Funds will be transferred before:',
    'giftShop:withdrawDescription':
      'Withdraw your balance to your bank account',
    'giftShop:withdrawDestinationLabel': 'Bank Account:',
    'giftShop:withdrawDetailLabel': 'Withdraw Detail',
    'giftShop:withdrawDisclaimer': 'Please select a bank account',
    'giftShop:withdrawConfirmation': 'Withdrawal Confirmation',
    'giftShop:withdrawEnterPasswordPlaceholder': 'Input your password',
    'giftShop:withdrawFee': 'Withdraw Fee',
    'giftShop:withdrawInvalidPassword':
      'Incorrect password. Please enter the correct password. You have {{attempt}} chances left.',
    'giftShop:withdrawInvalidPassword_limit':
      'Exceed input password limit. Please Try Again Later',
    'giftShop:withdrawButtonConfirm': 'Withdraw {{price}}',
    'giftShop:withdrawErrorMessage': 'An error occured while withdrawing.',
    'giftShop:withdrawErrorMessage_BANK_ACCOUNT_LIMIT':
      'You cannot have more than 3 bank accounts.',
    'giftShop:withdrawErrorMessage_NO_BANK_ACCOUNT':
      'Please select a bank account.',
    'giftShop:withdrawErrorMessage_INSUFFICIENT_BALANCE':
      'Sorry, your balance is not enough to do a withdraw.',
    'giftShop:withdrawErrorMessage_WITHDRAW_NO_AMOUNT':
      'Please input a valid amount of withdrawal.',
    'giftShop:withdrawErrorMessage_WITHDRAW_ZERO_AMOUNT':
      'Invalid withdraw amount.',
    'giftShop:withdrawErrorMessage_WITHDRAW_MIN_AMOUNT':
      'Minimum withdraw amount is IDR 50.000,00',
    'giftShop:withdrawErrorMessage_WITHDRAW_MAX_AMOUNT':
      'Maximum withdraw amount is IDR 100.000.000,00',
    'giftShop:withdrawErrorMessage_WITHDRAW_MAX_HOST':
      'You can only withdraw 1x/day.',
    'giftShop:withdrawErrorMessage_WITHDRAW_FEE_ERROR':
      'An error occured. Please try again later.',
    'giftShop:withdrawProcessFailed':
      "There's a problem when processing your request.",
    'giftShop:withdrawProcessSuccess':
      'Your withdrawal request is being processed.',
    'giftShop:xOfy': '{{x}} of {{y}}',
    'giftShop:yourPhotos': 'Your Photos',
  },
}
