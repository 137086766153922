import {TranslationKeyGlobal} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_GLOBAL_ID: TranslationResource<TranslationKeyGlobal> = {
  translation: {
    'global:accept': 'Terima',
    'global:accepted': 'Diterima',
    'global:activate': 'Aktifkan',
    'global:activated': 'Diaktifkan',
    'global:add': 'Tambah',
    'global:addNew': 'Tambah Baru',
    'global:addNow': 'Tambahkan',
    'global:addCart': 'Tambahkan ke Keranjang',
    'global:additionalDataModalButton': 'Pergi ke Ubah Profil',
    'global:additionalDataModalDescription':
      'Sebelum melanjutkan, mohon daftarkan alamat email kamu pada halaman ubah profil.',
    'global:additionalDataModalTitle': 'Data Tambahan Diperlukan',
    'global:addWishlist': 'Tambahkan ke FotoYu',
    'global:all': 'Semua',
    'global:allContents': 'Semua Konten',
    'global:allDone': 'Selesai!',
    'global:amount': 'Jumlah',
    'global:and': 'dan',
    'global:apply': 'Terapkan',
    'global:back': 'Kembali',
    'global:backgroundMessage': 'Konten Terbaik ',
    'global:backgroundMessage_second': 'Hanya Untuk kamu',
    'global:backTo': 'Kembali ke {{page}}',
    'global:bad': 'Salah',
    'global:basicPrice': 'Harga Dasar',
    'global:beta': 'Beta',
    'global:buy': 'Beli',
    'global:by': 'Oleh',
    'global:cancel': 'Batal',
    'global:cancelConfirmation':
      'Apakah kamu yakin ingin membatalkan? Laporan tidak akan ditindaklanjuti',
    'global:cart': 'Keranjang',
    'global:cashDeposit': 'Setor Tunai',
    'global:change': 'Ubah',
    'global:changesSaved': 'Perubahan telah disimpan',
    'global:charCount': '{{count}}/{{max}}',
    'global:chat': 'Obrolan',
    'global:chatCreator': 'Berbincang dengan Creator',
    'global:chatTheCreator': 'Chat Kreator',
    'global:checkoutPayment': 'Pembayaran',
    'global:chosen': 'Dipilih',
    'global:city': 'Kota',
    'global:clear': 'Bersihkan',
    'global:close': 'Tutup',
    'global:closeContent': 'Tutup Konten',
    'global:communities': 'Komunitas',
    'global:complete': 'Selesai',
    'global:confirm': 'Konfirmasi',
    'global:confirmation': 'Konfirmasi',
    'global:confirmed': 'Konfirm',
    'global:congratulations': 'Selamat!',
    'global:connectionInterrupted': 'Koneksi terputus',
    'global:content': 'Konten',
    'global:contentNotFound': 'Konten Tidak Ditemukan',
    'global:continue': 'Lanjut',
    'global:copy': 'Salin',
    'global:copyLink': 'Bagikan Tautan',
    'global:live': 'Live',
    'global:createAccount': 'Buat Akun',
    'global:createnickname': 'Buat Nickname',
    'global:createnicknameDesc':
      'Buat nickname untuk melindungi privasimu. Kamu dapat menggantinya nanti.',
    'global:createnicknameFail': 'Terjadi masalah ketika mengirimkan data kamu',
    'global:createnicknamePlaceholder': 'Masukan nickname baru',
    'global:createnicknameSuccess': 'Nama panggilan kamu berhasil dibuat.',
    'global:creators': 'Kreator',
    'global:currency': 'Mata Uang',
    'global:current': 'Terbaru',
    'global:date': 'Tanggal',
    'global:dateFormat': '{{hour}}j {{minute}}m',
    'global:dateOfBirth': 'Tanggal Lahir',
    'global:day': 'Hari',
    'global:days': '{{day}} hari',
    'global:day_friday': 'Jumat',
    'global:day_monday': 'Senin',
    'global:day_saturday': 'Sabtu',
    'global:day_sunday': 'Minggu',
    'global:day_thursday': 'Kamis',
    'global:day_tuesday': 'Selasa',
    'global:day_wednesday': 'Rabu',
    'global:delete': 'Hapus',
    'global:deleteContent': 'Hapus Konten',
    'global:deleted': 'Dihapus',
    'global:description': 'Deskripsi',
    'global:download': 'Unduh',
    'global:duplicate': 'Duplikat',
    'global:iBanking': 'i-Banking',
    'global:edit': 'Edit',
    'global:editContent': 'Edit Konten',
    'global:email': 'Email',
    'global:emailNew': 'Email Baru',
    'global:events': 'Acara',
    'global:expandContent': 'Buka Content',
    'global:expandMenu': 'Perluas Menu',
    'global:faceRecognition': 'Pengenalan Wajah',
    'global:fail': 'Gagal',
    'global:failed': 'Gagal',
    'global:failedLoad': 'Gagal Untuk Memuat',
    'global:faq': 'FAQ',
    'global:fileName': 'Tampilan Nama File',
    'global:filter': 'Filter',
    'global:forUser': 'Untuk {{nickname}}',
    'global:fotoyu': 'FotoYu',
    'global:fotoyuAdmin': 'Admin Fotoyu',
    'global:fototree': 'FotoTree',
    'global:fotoyuSupportTeam': 'Tim dukungan FotoYu:',
    'global:free': 'Gratis',
    'global:from': 'Dari',
    'global:fullname': 'Nama Lengkap',
    'global:generateThumbnail':
      'Membuat thumbnail... Silakan kembali lagi nanti.',
    'global:good': 'Benar',
    'global:hashtag': 'Tagar',
    'global:here': 'di sini',
    'global:hide': 'Sembunyikan',
    'global:high': 'Tinggi',
    'global:hires': 'Hi-Res',
    'global:history': 'Riwayat',
    'global:hour': 'Jam',
    'global:host': 'Host',
    'global:howItWorks': 'Cara Kerja',
    'global:iAgree': 'Saya Setuju',
    'global:iChooseThis': 'Saya memilih ini',
    'global:invoiceCopied': 'Invoice berhasil disalin',
    'global:idr': 'IDR',
    'global:kycModalInfo': 'Langkah selanjutnya akan menyalakan kamera kamu.',
    'global:kycModalSubtitle':
      'Aktifkan Roboyu untuk mencari foto-foto kamu dengan teknologi pengenalan wajah.',
    'global:language': 'Bahasa',
    'global:later': 'Nanti Saja',
    'global:learnMore': 'Pelajari lebih lanjut.',
    'global:less': 'Lebih sedikit',
    'global:limitedAccess': 'Akses Terbatas',
    'global:limitedAccessDesc': 'Belum memenuhi persyaratan umur.',
    'global:link': 'Tautan',
    'global:linkCopied': 'Tautan berhasil disalin',
    'global:location': 'Lokasi',
    'global:login': 'Masuk',
    'global:loading': 'Memuat...',
    'global:logout': 'Keluar',
    'global:logoutDescription': 'Keluar dari akun ini',
    'global:mBanking': 'm-Banking',
    'global:main': 'Utama',
    'global:maintenanceEnd': 'hingga <date>{{date}}</date>',
    'global:maintenanceStart': 'pada <date>{{date}}</date>',
    'global:masonryView': 'Tampilan Masonry',
    'global:medium': 'Sedang',
    'global:menu': 'Menu',
    'global:messageError': 'Ups... terjadi sesuatu yang tidak terduga',
    'global:message': 'Pesan',
    'global:messageError_code-400': 'Format data yang dikirim tidak valid',
    'global:messageError_code-401':
      'Akses token yang digunakan sudah tidak valid',
    'global:messageError_code-403': 'Kamu dilarang mengakses fitur ini',
    'global:messageError_code-404': 'Halaman tidak ditemukan',
    'global:messageError_code-408': '$t(global:messageError_timeout)',
    'global:messageError_code-409': 'Data Konflik: ID telah digunakan',
    'global:messageError_no-connection':
      'Nyalakan koneksi internet untuk melanjutkan',
    'global:messageError_timeout': 'Koneksi Terputus',
    'global:messageError-execute':
      'Terjadi kesalahan saat mengeksekusi tindakan. Silakan coba lagi.',
    'global:messageError-fetch':
      'Terjadi kesalahan saat menarik data. Silakan coba lagi.',
    'global:minute': 'Menit',
    'global:mobileNumber': 'Nomor Ponsel',
    'global:mobileNumberNew': 'Nomor Ponsel Baru',
    'global:month': 'Bulan',
    'global:month_april': 'April',
    'global:month_august': 'Agustus',
    'global:month_december': 'Desember',
    'global:month_february': 'Februari',
    'global:month_january': 'Januari',
    'global:month_july': 'Juli',
    'global:month_june': 'Juni',
    'global:month_march': 'Maret',
    'global:month_may': 'May',
    'global:month_november': 'November',
    'global:month_october': 'Oktober',
    'global:month_september': 'September',
    'global:monthly': 'Bulanan',
    'global:more': 'Lebih banyak',
    'global:name': 'Name',
    'global:needHelp': 'Butuh Bantuan?',
    'global:new': 'baru',
    'global:next': 'Lanjut',
    'global:nickname': 'Nickname',
    'global:no': 'Tidak',
    'global:noData': 'Tidak ada data',
    'global:notes': 'Catatan',
    'global:notification': 'Notifikasi',
    'global:notInterest': 'Tidak Tertarik',
    'global:notInterested': 'Tidak Tertarik',
    'global:notMe': 'Bukan Saya',
    'global:numberCopied': 'Nomor berhasil disalin',
    'global:notRegistered': 'Tidak Terdaftar',
    'global:off': 'Nonaktif',
    'global:ok': 'OK',
    'global:okay': 'Oke',
    'global:old': 'lama',
    'global:on': 'Aktif',
    'global:options': 'Pilihan',
    'global:other': 'Lainnya',
    'global:password': 'Kata Sandi',
    'global:passwordConfirm': 'Konfirmasi Kata Sandi',
    'global:phoneNumber': 'Nomor Ponsel',
    'global:photo': 'Foto',
    'global:places': 'Tempat',
    'global:play': 'Mainkan',
    'global:pleaseTryAgain': 'Mohon coba kembali dalam waktu {{countdown}}.',
    'global:pleaseWait': 'Harap tunggu...',
    'global:price': 'Harga',
    'global:processed': 'Diproses',
    'global:profile': 'Profil',
    'global:publish': 'Unggah',
    'global:purchaseDate': 'Tanggal pembelian: {{purchased_at}}',
    'global:refresh': 'Undi',
    'global:refreshPage': 'Segarkan',
    'global:register': 'Daftar',
    'global:reject': 'Tolak',
    'global:rejected': 'Ditolak',
    'global:remove': 'Buang',
    'global:removeAll': 'Buang Semua',
    'global:removeCart': 'Buang dari Keranjang',
    'global:removeWishlist': 'Buang dari Daftar Keinginan',
    'global:report': 'Laporkan Konten',
    'global:reportCancel': 'Laporan tidak akan ditindaklanjutkan',
    'global:reportContent': 'Laporkan',
    'global:reportFail': 'Laporan Gagal',
    'global:reportFailMessage':
      'Kesalahan terjadi ketika mengirimkan laporan. Mohon ulangi lagi.',
    'global:reportReason': 'Pilih alasan:',
    'global:reportSuccess': 'Laporan Berhasil!',
    'global:reportSuccessMessage':
      'Terima kasih atas partisipasinya. Kami akan memproses laporan kamu.',
    'global:retry': 'Ulangi',
    'global:revision': 'Revisi',
    'global:rp': 'Rp',
    'global:save': 'Simpan',
    'global:saveChanges': 'Simpan Perubahan',
    'global:search': 'Cari',
    'global:seeMore': 'Lihat Lebih Banyak',
    'global:select': 'Pilih',
    'global:selectAll': 'Pilih Semua',
    'global:selectedCount': '{{count}} Terpilih',
    'global:selectThing': 'Pilih {{thing}}',
    'global:selectVideo': 'Pilih video untuk diunggah',
    'global:send': 'Kirim',
    'global:settings': 'Pengaturan',
    'global:share': 'Bagikan',
    'global:shareContent': 'Bagikan Konten',
    'global:shareContentFailed':
      'Tidak dapat membagikan {{type}} saat ini. Silakan coba lagi nanti.',
    'global:show': 'Tampilkan',
    'global:signUp': 'Daftar',
    'global:similarAccount': 'Kemiripan Akun',
    'global:skip': 'Lewati',
    'global:sorry': 'Maaf!',
    'global:sort': 'Urutkan',
    'global:standard': 'Standar',
    'global:start': 'Mulai',
    'global:stop': 'Berhenti',
    'global:stay': 'Tetap di sini',
    'global:submit': 'Simpan',
    'global:success': 'Berhasil!',
    'global:sure': 'Ya, saya yakin',
    'global:tags': 'Tags',
    'global:tapToRetry': 'Ketuk untuk Mencoba Lagi',
    'global:time': 'Waktu',
    'global:title': 'Judul',
    'global:today': 'Hari ini',
    'global:tomorrow': 'Besok',
    'global:to': 'Sampai',
    'global:transferATM': 'Transfer ATM',
    'global:transferMiniATM': 'Transfer Mini ATM',
    'global:trees': 'Pohon',
    'global:tryAgain': 'Coba Lagi',
    'global:tryRefresh': 'Coba segarkan halaman ini.',
    'global:type': 'Tipe',
    'global:upgrade': 'Tingkatkan',
    'global:upgradeStatus': 'Status Peningkatan',
    'global:upload': 'Unggah',
    'global:uploadedImage': 'Gambar Terunggah',
    'global:uploadVideo': 'Unggah Video',
    'global:uploadVideoOptional': 'Unggah Video (Opsional)',
    'global:username': 'Username',
    'global:validationMaxLength':
      'Formulir ini membutuhkan maksimal {{count}} karakter',
    'global:validationMaxLength_plural':
      'Formulir ini membutuhkan maksimal {{count}} karakter',
    'global:validationMinLength':
      'Formulir ini membutuhkan setidaknya {{count}} karakter',
    'global:validationMinLength_plural':
      'Formulir ini membutuhkan setidaknya {{count}} karakter',
    'global:validationMaxPrice': 'Harga maksimum konten adalah {{price}}',
    'global:validationMinPrice': 'Harga minimum konten adalah {{price}}',
    'global:validationRequired': 'Formulir ini wajib diisi',
    'global:verified': 'Terverifikasi',
    'global:verify': 'Verifikasi',
    'global:video': 'Video',
    'global:view': 'Tampilan',
    'global:visit': 'Kunjungi',
    'global:waitingAdmin': 'Menunggu Persetujuan Admin',
    'global:weekly': 'Mingguan',
    'global:whatsapp': 'WhatsApp',
    'global:with': 'Dengan',
    'global:yes': 'Ya',
    'global:yesDelete': 'Ya, Hapus',
    'global:yesImSure': 'Saya yakin',
    'global:yesterday': 'Kemarin',
    'global:yuser': 'Yuser',
    'global:yuser_plural': 'Yuser',
  },
}
