import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Icon, Image, Paragraph, ParsedText} from 'common/components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {parseShape} from 'types'
import {
  fototreeLocationName,
  getCurrencyValue,
  getFontFamily,
  getTextShadow,
} from 'utils'
import {FototreWishlistContentItemWishlistProps} from './FototreeContentItemProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(2)};
`

const StyledTagContainer = styled.div`
  display: flex;
  gap: ${convertUnit(4)};
`

const StyledTreeContainer = styled.div`
  width: fit-content;
`

const StyledTreeImage = styled(Image)`
  height: ${convertUnit(14)};
`
const StyledParagraph = styled(Paragraph)`
  text-shadow: ${({theme}) => getTextShadow(theme)};
`

export default function FototreeWishlistContentItemDescription({
  data,
}: FototreWishlistContentItemWishlistProps) {
  const {translate} = useTranslation()
  const {username} = useSelector('user') || {}

  const locationName = useMemo(
    () => fototreeLocationName(data.tags[0].location?.name),
    [data.tags],
  )

  const patterns: parseShape[] = useMemo(
    () => [
      {
        pattern: /<username>.*?<\/username>/g,
        style: {fontFamily: getFontFamily('bold')},
        renderText: (text: string) =>
          text.replace(/<username>|<\/username>/g, ''),
      },
    ],
    [],
  )

  const handleRenderContent = useMemo(
    () => (
      <StyledContainer>
        <StyledTagContainer>
          <Icon type="invisible" size={14} hasShadow color="white_1" />
          <StyledParagraph color="white_1">
            {translate('tree:invisible')}
          </StyledParagraph>
        </StyledTagContainer>
        <StyledTagContainer>
          <StyledTreeContainer>
            <StyledTreeImage
              src={IMAGE_ASSET('tree', 'icons/pinpoint-event.png')}
              alt={data.tags[0].name}
            />
          </StyledTreeContainer>
          <StyledParagraph color="white_1">
            {`${data.tags[0].name}${locationName ? ` • ${locationName}` : ''}`}
          </StyledParagraph>
        </StyledTagContainer>
        <StyledParagraph color="white_1">
          <ParsedText parse={patterns}>
            {translate('tree:wishlistAddCollection', {username})}
          </ParsedText>
        </StyledParagraph>
        <StyledTagContainer>
          <Icon hasShadow size={16} type="price-tags" color="white_1" />
          <StyledParagraph color="white_1" fontSize="l" fontWeight="bold">
            {getCurrencyValue(data.price, data.currency_code || undefined)}
          </StyledParagraph>
        </StyledTagContainer>
      </StyledContainer>
    ),
    [
      data.currency_code,
      data.price,
      data.tags,
      locationName,
      patterns,
      translate,
      username,
    ],
  )

  return handleRenderContent
}
