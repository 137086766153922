import React, {useCallback, useState} from 'react'
import AutoSizer, {Size} from 'react-virtualized-auto-sizer'
import {GiftShopContentData} from 'types'
import {GIFT_SHOP_ROOT_CONTENT_PADDING} from 'consts'
import {ListMasonryLazy} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopTemplateMasonryProps} from './GiftShopTemplateMasonryProps'
import {GiftShopTemplateMasonryItem} from '../MasonryItem'

export default function GiftShopTemplateMasonry({
  stateData,
  toggleSelect = false,
  isDragToSelect = false,
  showResBadge = false,
  selected,
  cache,
  refresh,
  onClickItem,
  search,
  scrollbar,
  showPurchaseStatusBadge,
  showUnwishlistBadge,
  showFoundConfirmed,
  isSelected,
  onSelectRule,
  ...props
}: GiftShopTemplateMasonryProps) {
  const [onMouseHold, setOnMouseHold] = useState(false)

  const handleLoadLayout = useCallback(
    (item: GiftShopContentData) => item.resolution,
    [],
  )

  const handleKeyExtractor = useCallback(
    (item: GiftShopContentData) => item.content_id + item.quality,
    [],
  )

  const handleMouseDown = useCallback(() => {
    isDragToSelect && setOnMouseHold((prev) => !prev)
  }, [isDragToSelect])

  const handleMouseUp = useCallback(() => {
    isDragToSelect && setOnMouseHold((prev) => !prev)
  }, [isDragToSelect])

  const handleRenderItem = useCallback(
    (item: GiftShopContentData) => (
      <GiftShopTemplateMasonryItem
        showResBadge={showResBadge}
        item={item}
        toggleSelect={toggleSelect}
        isSelected={
          isSelected ? isSelected(item.content_id) : selected?.includes(item)
        }
        onMouseHold={onMouseHold}
        onClickItem={onClickItem}
        showPurchaseStatusBadge={showPurchaseStatusBadge}
        showUnwishlistBadge={showUnwishlistBadge}
        showFoundConfirmed={showFoundConfirmed}
        onSelectRule={onSelectRule}
      />
    ),
    [
      showResBadge,
      toggleSelect,
      isSelected,
      selected,
      onMouseHold,
      onClickItem,
      showPurchaseStatusBadge,
      showUnwishlistBadge,
      showFoundConfirmed,
      onSelectRule,
    ],
  )

  const handleRenderMasonry = useCallback(
    ({height, width}: Size) => (
      <div onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
        <ListMasonryLazy
          {...props}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          refresh={refresh}
          height={height}
          width={width}
          cache={cache}
          stateData={stateData}
          loadLayout={handleLoadLayout}
          keyExtractor={handleKeyExtractor}
          renderItem={handleRenderItem}
          style={{
            padding: convertUnit(GIFT_SHOP_ROOT_CONTENT_PADDING),
          }}
          scrollbar={scrollbar}
          search={search}
        />
      </div>
    ),
    [
      props,
      handleMouseDown,
      handleMouseUp,
      refresh,
      cache,
      stateData,
      handleLoadLayout,
      handleKeyExtractor,
      handleRenderItem,
      scrollbar,
      search,
    ],
  )

  return <AutoSizer>{handleRenderMasonry}</AutoSizer>
}
