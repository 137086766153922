import React from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET, WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {useHistory} from 'utils'
import {Button, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledOuterContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: inherit;
  max-width: ${convertUnit(375)};
  text-align: center;
  align-self: center;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    max-width: 100%;
    width: fit-content;
    padding: ${convertUnit(20)};
  }
`

const StyledImage = styled(Image)`
  width: ${convertUnit(129)};
  height: ${convertUnit(129)};
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
  width: fit-content;
`

export default function GiftShopSearchByCreatorNotFound() {
  const {translate} = useTranslation()
  const history = useHistory()
  const imgAsset = IMAGE_ASSET('giftshop', 'robopet-confused.png')

  return (
    <StyledOuterContainer>
      <StyledContainer>
        <StyledImage
          src={imgAsset}
          alt={translate('giftShop:searchByCreatorNotFoundTitle')}
        />
        <Paragraph color="gray_5" fontSize="l" fontWeight="bold">
          {translate('giftShop:searchByCreatorNotFoundTitle')}
        </Paragraph>
        <Paragraph color="gray_5">
          {translate('giftShop:searchByCreatorNotFoundDescription1')}
        </Paragraph>
        <Paragraph color="gray_5">
          {translate('giftShop:searchByCreatorNotFoundDescription2')}
        </Paragraph>
        <StyledButton
          label={translate('giftShop:searchByCreatorNotFoundButton')}
          onClick={() => history.push('giftshop_explore', {})}
        />
      </StyledContainer>
    </StyledOuterContainer>
  )
}
