import {TranslationKeyAuth} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_AUTH_ID: TranslationResource<TranslationKeyAuth> = {
  translation: {
    'auth:askParentalConsent': 'Minta Persetujuan Orang Tua',
    'auth:banModalDescription':
      'Maaf, akun kamu telah diblokir karena melanggar pedoman komunitas FotoYu. Kamu dapat membaca pedoman komunitas kami lagi <guideline>di sini</guideline>.',
    'auth:banModalUnderageDescription':
      'Maaf, akun kamu telah diblokir karena usia kamu yang tidak mencukupi sebagaimana ditentukan dalam Syarat Penggunaan. Kamu dapat membaca Syarat Penggunaan kami lagi.',
    'auth:banModalTitle': 'Akun kamu telah diblokir',
    'auth:confirmationPassword': 'Kata sandi baru tidak cocok',
    'auth:createPasswordSSOConfirmationPlaceholder':
      'Konfirmasi kata sandi kamu',
    'auth:createPasswordSSODesc':
      'Sebelum kita melanjutkan, mohon membuat sebuah password untuk kebutuhan transaksi.',
    'auth:createPasswordSSOTitle': 'Membuat Password',
    'auth:deleteAccountAssociateHost':
      'Akun kamu masih terasosiasi dengan host, silakan demote akun kamu dari host.',
    'auth:deleteAccountOweResend':
      'Kamu masih belum mengirimkan file Hi-Res kepada user, tolong kirimkan file tersebut terlebih dahulu.',
    'auth:filterDateValidation':
      'Tanggal mulai tidak boleh lebih besar dari tanggal akhir',
    'auth:forgotPasswordEmailDescription':
      'Tautan telah dikirim ke email kamu untuk melanjutkan proses. Silakan periksa inbox email kamu.',
    'auth:forgotPasswordHeader': 'Lupa Kata Sandi',
    'auth:forgotPasswordHeaderDescription': 'Dimana kami bisa hubungi kamu?',
    'auth:forgotPasswordInputLabel': 'Email atau Nomor Ponsel',
    'auth:forgotPasswordInputPlaceholder': 'Ketik disini',
    'auth:forgotPasswordPhoneDescription':
      'Tautan verifikasi dikirim ke nomor ponsel kamu. Silakan periksa SMS kamu.',
    'auth:forgotPasswordPhoneDescription_whatsapp':
      'Tautan verifikasi dikirim ke nomor ponsel kamu. Silakan periksa WhatsApp kamu.',
    'auth:forgotPasswordPhoneDidNotGet': 'Tidak mendapatkan tautan?',
    'auth:forgotPasswordPhoneResend': 'Kirim ulang tautan',
    'auth:forgotPasswordPhoneResendCountdown':
      'Kirim ulang tautan ({{countdown}}d)',
    'auth:forgotPasswordResendMessage':
      'Maaf! Mohon tunggu sekitar {{cooldown}} menit sebelum melanjutkan kembali.',
    'auth:forgotPasswordResendMessageLimit':
      'Maaf! Mohon tunggu <hourStamp>{{hour}}j</hourStamp><minuteStamp>{{minute}}m</minuteStamp><secondStamp>{{second}}d</secondStamp> sebelum melanjutkan kembali.',
    'auth:generalValidationAlphanumeric': 'Terdiri dari huruf dan angka',
    'auth:generalValidationMinLength':
      'Terdiri dari minimal {{length}} karakter',
    'auth:inputPasswordConfirmLabel': 'Konfirmasi Kata Sandi Baru',
    'auth:inputPasswordConfirmPlaceholder':
      'Masukkan kata sandi baru kamu lagi',
    'auth:identifierOneAccount': 'Setiap orang hanya dapat memiliki 1 akun.',
    'auth:linkExpiredHeader': 'Tautan Kedaluwarsa',
    'auth:linkExpiredHeaderDescription':
      'Tautan yang dikirim sudah kedaluwarsa.',
    'auth:linkExpiredInformation': 'Tidak mendapatkan tautan?',
    'auth:linkExpiredResend': 'Kirim ulang tautan',
    'auth:linkSuccessHeader': 'Berhasil!',
    'auth:loginAs': 'Masuk sebagai {{username}}',
    'auth:loginButtonAppleAuthAccess': 'Masuk dengan Apple',
    'auth:loginButtonAppleTitle': 'Masuk dengan Apple',
    'auth:loginButtonGoogleAuthAccess': 'Masuk dengan Google',
    'auth:loginButtonGoogleTitle': 'Masuk dengan Google',
    'auth:loginForgotPassword': 'Lupa / Buat Kata Sandi',
    'auth:validationLoginNoPasswordMissmatch':
      "Belum ada kata sandi untuk akun ini. Tekan 'Lupa Kata Sandi' untuk menambahkan.",
    'auth:loginHeader': 'Selamat Datang Kembali',
    'auth:loginHeaderDescription': 'Masuk ke akun kamu.',
    'auth:loginInputAccountLabel': 'Nama Pengguna, Email, atau Nomor Ponsel',
    'auth:loginInputAccountPlaceholder':
      'Masukkan nama pengguna, email, atau nomor ponsel kamu',
    'auth:loginInputPasswordLabel': 'Kata Sandi',
    'auth:loginInputPasswordPlaceholder': 'Masukkan kata sandi kamu',
    'auth:loginNotHaveAccount': 'Belum punya akun?',
    'auth:loginUnderage': 'Butuh Persetujuan Orang Tua',
    'auth:loginUnderageHeader':
      'Kamu berusia di bawah {{age}} tahun dan teridentifikasi sebagai Yuser di bawah umur.',
    'auth:loginUnderageDescription': 'Kamu butuh persetujuan orang tua',
    'auth:logoutModalExpiredTokenDescription':
      'Jangan khawatir, kamu hanya perlu login kembali.',
    'auth:logoutModalTitle': 'Sesi kamu telah kedaluwarsa',
    'auth:logoutSaveLoginInfo': 'Simpan informasi login',
    'auth:messageBackToLogin': 'Kembali ke Login',
    'auth:messageBackToProfile': 'Kembali ke Profil',
    'auth:nicknameAllowedCharacters': 'Nickname harus alphanumerik',
    'auth:nicknameCreated': 'Nickname berhasil dibuat.',
    'auth:nicknameLabelDescription': 'Untuk pembelian konten',
    'auth:nicknameSublabel':
      'Masukkan nickname yang berbeda dengan username kamu',
    'auth:registerDateOfBirthInputPlaceholder': 'DD/MM/YYYY',
    'auth:registerAppleButtonLabel': 'Daftar dengan Apple',
    'auth:registerGoogleButtonLabel': 'Daftar dengan Google',
    'auth:registerHeader': 'Daftar',
    'auth:registerHeaderDateOfBirth': 'Kapan Kamu Lahir?',
    'auth:registerHeaderPassword': 'Buat Kata Sandi',
    'auth:registerHeaderAccount': 'Buat Akun',
    'auth:registerCreateAccountThroughMobileApp':
      'Buat akun kamu melalui aplikasi mobile Fotoyu.',
    'auth:registerDoBDescription':
      'Di bawah {{age}} tahun butuh persetujuan orang tua',
    'auth:registerEasySignupChildDescription_1':
      'Orang tua perlu mengambil selfie dengan yuser anak.',
    'auth:registerEasySignupChildDescription_2':
      'Langkah selanjutnya akan menghidupkan dan merekam kamera kamu.',
    'auth:registerEasySignupChildHeader': 'Wefie Orang Tua dan Anak',
    'auth:registerEasySignupChildHeader_selfie': 'Selfie Anak',
    'auth:registerEasySignupChildReapplyDesc':
      'Maaf! Sepertinya proses belum selesai.',
    'auth:registerEasySignupChildReapplyTitle': 'Verifikasi Belum Selesai',
    'auth:registerEasySignupChildWefieDesc': 'Selfie orang tua dan anak',
    'auth:registerEasySignupChildWefieDesc_selfie': 'Ambil selfie anak saja',
    'auth:registerEasySignupCreatePassword':
      'Kamu harus membuat kata sandi untuk akun kamu',
    'auth:registerEasySignupCredentialConfirm':
      'Mohon pastikan nomor WhatsApp kamu sudah benar. Kami akan mengirimkan link verifikasi ke nomor WhatsApp kamu.',
    'auth:registerEasySignupCredentialConfirm_email':
      'Mohon pastikan Email kamu sudah benar. Kami akan mengirimkan link verifikasi ke Email kamu.',
    'auth:registerEasySignupDuplicateConfirmation':
      'Saya perlu membuat akun bisnis.',
    'auth:registerEasySignupDuplicateDesc':
      'Akun duplikat dapat menyebabkan penangguhan akun.',
    'auth:registerEasySignupDuplicateTitle':
      'Kamu sudah memiliki akun.\nKamu tidak dapat membuat akun kedua.',
    'auth:registerEasySignupResendLinkCooldownSnackbar':
      'Mohon cek WhatsApp atau Email kamu. <timestamp><hourStamp>{{hour}} jam </hourStamp><minuteStamp>{{minute}} menit </minuteStamp><secondStamp>{{second}} detik</secondStamp></timestamp> untuk pengiriman kembali.',
    'auth:registerEasySignupResendLinkLimitSnackbar':
      'Batas pengiriman ulang telah tercapai.<timestamp><hourStamp>{{hour}} jam </hourStamp><minuteStamp>{{minute}} menit </minuteStamp><secondStamp>{{second}} detik</secondStamp></timestamp> untuk pengiriman kembali.',
    'auth:registerEasySignupSendLink':
      'Kami akan mengirim tautan ke email ini.',
    'auth:registerEasySignupSendLink_phone':
      'Kami akan mengirim tautan ke nomor WhatsApp ini.',
    'auth:registerEasySignupParentDob': 'Tanggal Lahir Orang Tua',
    'auth:registerEasySignupParentDobRestriction':
      'Harus berusia {{age}} tahun atau lebih',
    'auth:registerEasySignupParentInputDob':
      'Sebelum memulai, silakan masukkan tanggal lahir orang tua kamu.',
    'auth:registerEasySignupUpdateCredentialDescription':
      'Mohon masukkan email yang valid untuk verifikasi.',
    'auth:registerEasySignupUpdateCredentialDescription_whatsapp':
      'Mohon masukkan nomor WhatsApp yang valid untuk verifikasi.',
    'auth:registerEasySignupUpdateCredentialLabel': 'Email Baru',
    'auth:registerEasySignupUpdateCredentialLabel_whatsapp':
      'Nomor WhatsApp Baru',
    'auth:registerEasySignupUpdateCredentialSnackbar':
      'Tautan verifikasi telah dikirim ke email baru.',
    'auth:registerEasySignupUpdateCredentialSnackbar_whatsapp':
      'Tautan verifikasi telah dikirim ke nomor WhatsApp baru.',
    'auth:registerEasySignupUpdateCredentialTitle': 'Ubah Email',
    'auth:registerEasySignupUpdateCredentialTitle_whatsapp':
      'Ubah Nomor WhatsApp',
    'auth:registerEasySignupUploadWarning':
      'Mohon tunggu sebentar, dan jangan tutup halaman ini.',
    'auth:registerEasySignupUsernameNotMatch': 'Username tidak sesuai',
    'auth:registerEasySignupVerifMobileHeaderDescription':
      'Kami telah mengirimkan pesan verifikasi kepada kamu. Silakan periksa SMS kamu untuk link konfirmasi.',
    'auth:registerEasySignupVerifMobileHeaderDescription_whatsapp':
      'Kami telah mengirimkan pesan verifikasi kepada kamu. Silakan periksa WhatsApp kamu untuk link konfirmasi.',
    'auth:registerEasySignupWelcomeLinkExpired':
      'Tautan login ini telah kadaluarsa. Silakan masuk menggunakan kata sandi Anda.',
    'auth:registerEasySignupWelcomeMessage':
      'Silakan masukkan username kamu untuk memverifikasi.',
    'auth:registerEasySignupWelcomePlaceholder': 'Masukkan username kamu',
    'auth:registerEasySignupWelcomeTitle': 'Selamat Datang di FotoYu',
    'auth:registerIdentifierAlreadyHaveAnAccount': 'Sudah memiliki akun?',
    'auth:registerIdentifierCancelModalMessage':
      'Apakah kamu yakin ingin membatalkan? Data tidak akan disimpan',
    'auth:registerIdentifierInputEmailLabel': 'Email',
    'auth:registerIdentifierInputEmailPlaceholder': 'nama@domain.com',
    'auth:registerIdentifierInputPhoneLabel': 'Nomor Ponsel',
    'auth:registerIdentifierInputPhonePlaceholder': 'contoh: 878761234',
    'auth:registerIdentifierSearchCountryEmpty':
      'Oh tidak! Kami tidak dapat menemukan negara "{{search}}"',
    'auth:registerIdentifierTabEmail': 'Email',
    'auth:registerIdentifierTabPhone': 'Nomor Ponsel',
    'auth:registerLimitReached': 'Silakan coba lagi dalam 24 jam',
    'auth:registerMinimumAgeErrorMessage': 'Minimal umur {{minAge}} tahun',
    'auth:registerPasswordConfirmationMessage': 'Masukkan kata sandimu kembali',
    'auth:registerPasswordInputPlaceholder': 'Buat kata sandi kamu',
    'auth:registerTermsOfService':
      'Dengan mendaftar, kamu menyetujui <tos>Persyaratan Penggunaan</tos> dan <privacy>Kebijakan Privasi</privacy>, termasuk <cookie>Kebijakan Cookie</cookie>. Kamu mungkin menerima notifikasi email dari kami dan dapat memilih untuk tidak ikut kapan saja.',
    'auth:registerTakeSelfie': 'Ambil Selfie',
    'auth:registerUsernameInputPlaceholderEasySignup':
      'Buat dan ingat username kamu',
    'auth:resendLimitReached': 'Silakan coba lagi dalam {{duration}}',
    'auth:registerTermsOfService_phone':
      'Dengan mendaftar, kamu menyetujui <tos>Persyaratan Penggunaan</tos> dan <privacy>Kebijakan Privasi</privacy>, termasuk <cookie>Kebijakan Cookie</cookie>. Kamu mungkin menerima notifikasi SMS dari kami dan dapat memilih untuk tidak ikut kapan saja.',
    'auth:registerTermsOfService_whatsapp':
      'Dengan mendaftar, kamu menyetujui <tos>Persyaratan Penggunaan</tos> dan <privacy>Kebijakan Privasi</privacy>, termasuk <cookie>Kebijakan Cookie</cookie>. Kamu mungkin menerima notifikasi WhatsApp dari kami dan dapat memilih untuk tidak ikut kapan saja.',
    'auth:registerUnderage':
      'Maaf, kamu masih dibawah umur dan butuh persetujuan orangtua. Mohon download aplikasi FotoYu.',
    'auth:registerUsernameInputPlaceholder': 'Buat username mu',
    'auth:resetPasswordButtonTitle': 'Setel Ulang Kata Sandi',
    'auth:resetPasswordHeader': 'Setel Ulang Kata Sandi',
    'auth:resetPasswordHeaderDescription':
      'Ingat kata sandi kamu untuk menikmati Fotoyu dengan mulus!',
    'auth:resetPasswordSuccessDescription':
      'Sekarang kamu dapat masuk menggunakan kata sandi baru kamu. Selamat datang!',
    'auth:resetPasswordSuccessSubtitle': 'Kata sandi kamu telah diubah.',
    'auth:serverUnderMaintenance': 'Server Dalam Pemeliharaan',
    'auth:serverUnderMaintenanceDescFirst':
      'Pelayanan akan aktif kembali sesaat lagi.',
    'auth:serverUnderMaintenanceDescGeneral':
      'FotoYu sedang dalam pemeliharaan, mohon maaf atas ketidaknyamanannya.',
    'auth:serverUnderMaintenanceDescSecond':
      'Mohon maaf atas ketidaknyamanannya.',
    'auth:similarIdentityButton': 'Pilih Akun',
    'auth:similarIdentityDescFirst':
      'Kami menganggap serius permasalahan privasi dan dengan tegas menentang segala percobaan ilegal untuk menyamar atau berpura-pura sebagai orang lain. Kami selalu berusaha untuk melindungi keamanan pengguna dari pencurian identitas.',
    'auth:similarIdentityDescSecond':
      'Untuk melanjutkan penggunaan aplikasi, pilih satu akun yang ingin kamu aktifkan.',
    'auth:similarIdentitySnackbar':
      'Terima kasih! Kami akan segera menghubungi kamu.',
    'auth:successCreatePassword': 'Kata sandi berhasil dibuat.',
    'auth:suspendLoginModalDescription':
      'Kamu telah melampaui batas upaya login. Silakan coba lagi dalam 5 menit.',
    'auth:suspendModalDescription':
      'Maaf, Berdasarkan aktivitas terakhir kamu, akun kamu telah ditangguhkan sementara karena melanggar pedoman komunitas kami.',
    'auth:suspendModalTitle': 'Akun Kamu Telah Ditangguhkan',
    'auth:unverifiedEmailSnackbar': 'Silakan periksa inbox email kamu.',
    'auth:validationCreatePasswordSSO':
      'Password cannot contain spaces, non-Roman letter, or emojis',
    'auth:validationEmail': 'Mohon masukkan format email yang benar',
    'auth:validationEmailEmpty': 'Email tidak boleh kosong',
    'auth:validationEmailExpired': 'Link verifikasi telah expired',
    'auth:validationEmailFormat': 'Email harus memiliki karakter @',
    'auth:validationEmailHasBeenTaken': 'Email telah digunakan',
    'auth:validationLoginMissMatch':
      'Maaf! Kami tidak dapat menemukan akun apa pun yang cocok dengan kredensial dan sandi kamu. Harap periksa kredensial kamu dan coba lagi.',
    'auth:validationLoginSSOMissMatch':
      'Akun kamu terdaftar menggunakan Google/Apple, coba masuk dengan tekan masuk dengan Google atau Apple.',
    'auth:validationForgotPasswordMissmatch':
      'Kami tidak dapat menemukan akun yang cocok dengan kredensial. Harap periksa dan coba lagi.',
    'auth:validationForgotPasswordSSOMissMatch':
      'Akun kamu sudah terdaftar menggunakan akun Google/Apple.',
    'auth:validationLoginAttempt':
      'Maaf! Kata sandi salah. Kamu punya {{attempt}} kesempatan tersisa.',
    'auth:validationMandatoryField': 'Mandatory field harus diisi',
    'auth:validationNicknameContainsFotoyu':
      'Nickname tidak boleh mengandung kata FotoYu',
    'auth:validationNicknameHasBeenTaken':
      'Nickname ini sudah diambil oleh pengguna lain.',
    'auth:validationNicknameHasBeenUsed':
      'Nickname ini sudah diambil oleh pengguna lain',
    'auth:validationNicknameIsEmpty': 'Nickname harus diisi',
    'auth:validationNicknameMatchPrevious': 'Mohon tambahkan nickname baru',
    'auth:validationNicknameMatchUsername':
      'Nickname kamu sama dengan username. Silakan ubah nickname kamu',
    'auth:validationNicknameMaxLength': 'Maximal {{count}} kata',
    'auth:validationNicknameMinLength':
      'Nickname terlalu pendek. Minimal {{count}} karakter',
    'auth:validationNicknameNoSpace': 'Nickname tidak boleh memiliki spasi',
    'auth:validationNicknameOneLetterRequired':
      'Nickname harus memiliki setidaknya satu huruf',
    'auth:validationNicknameSingleDash':
      'Nickname hanya boleh memiliki satu garis (-)',
    'auth:validationNicknameSinglePeriod':
      'Nickname hanya boleh memiliki satu titik (.)',
    'auth:validationNicknameSingleUnderscore':
      'Nickname hanya boleh memiliki satu garis bawah (_)',
    'auth:validationNicknameStartCharacter':
      'Nickname harus dimulai dengan huruf',
    'auth:validationNicknameStartEndAlphanumeric':
      'Nickname tidak boleh dimulai atau diakhiri dengan garis bawah (_), tanda pisah (-), atau titik (.)',
    'auth:validationParentMinimumAge':
      'Orang tua harus berusia {{age}} tahun atau lebih untuk memberikan persetujuan. Mohon masukkan usia yang valid',
    'auth:validationPassword': 'Termasuk campuran antara huruf dan angka',
    'auth:validationPasswordConfirmation':
      'Untuk konfirmasi menggunakan password',
    'auth:validationPasswordError':
      'Password harus terdiri dari abjad dan numerik',
    'auth:validationPasswordLength':
      'Terdiri dari setidaknya {{minLength}} karakter',
    'auth:validationPasswordMaxLength': 'Maximum character is {{count}}',
    'auth:validationPasswordMinLength':
      'Kata sandi harus terdiri dari setidaknya {{minLength}} karakter',
    'auth:validationPasswordMinLength_plural':
      'Kata sandi harus terdiri dari setidaknya {{minLength}} karakter',
    'auth:validationPasswordMix':
      'Kata sandi harus terdiri dari alfabet dan angka',
    'auth:validationPasswordNoDashUnderscorePeriod':
      'Kata sandi tidak boleh memiliki garis bawah (_), tanda pisah (-), atau titik (.)',
    'auth:validationPasswordNoNonRomanCharacters':
      'Kata sandi tidak boleh memiliki huruf non-Romawi atau emoji',
    'auth:validationPasswordNoSpace': 'Kata sandi tidak dapat mengandung spasi',
    'auth:validationPhone':
      'Harus berupa angka, tidak boleh terdiri dari huruf / karakter',
    'auth:validationPhoneEmpty': 'Tidak boleh kosong',
    'auth:validationPhoneHasBeenTaken': 'Nomor ponsel telah digunakan',
    'auth:validationPhoneMinLength':
      'Nomor ponsel minimal terdiri dari {{count}} karakter (tanpa angka 0 di depan)',
    'auth:validationPhoneMinLengthWithoutLeadingZero':
      'Nomor ponsel minimal terdiri dari {{count}} karakter',
    'auth:validationPhoneRegistered':
      'Maaf! Sepertinya nomor ponsel ini telah terdaftar. Gunakan nomor ponsel lain dan coba lagi.',
    'auth:validationRequired': 'Bagian ini wajib diisi',
    'auth:validationResetPasswordMustBeDifferent':
      'Harus berbeda dari sandi lama',
    'auth:validationResetPasswordNotMatch': 'Kata sandi baru tidak cocok',
    'auth:validationUsername': 'Username harus alphanumerik',
    'auth:validationUsernameContainsFotoyu':
      'Username tidak boleh mengandung kata FotoYu',
    'auth:validationUsernameHasBeenRegistered': 'Username sudah teregistrasi',
    'auth:validationUsernameHasBeenTaken': 'Username telah digunakan',
    'auth:validationUsernameHasBeenUsed': 'Username sudah digunakan',
    'auth:validationUsernameMatchPrevious':
      'Username baru harus berbeda dengan yang sebelumnya.',
    'auth:validationUsernameLength':
      'Username setidaknya harus {{count}} karakter',
    'auth:validationUsernameMinLength':
      'Nama pengguna minimal terdiri dari {{count}} karakter',
    'auth:validationUsernameMinLength_plural':
      'Nama pengguna minimal terdiri dari {{count}} karakter',
    'auth:validationUsernameNoSpaceAndSpecialCharacter':
      'Username tidak boleh mengandung spasi atau special karakter',
    'auth:validationUsernameNotOnlyNumbers':
      'Username tidak boleh berisi hanya angka',
    'auth:validationUsernameSinglePeriod':
      'Username hanya dapat berisi satu garis bawah (_), tanda pisah (-)',
    'auth:validationUsernameStartEndAlphanumeric':
      'Username tidak boleh dimulai atau diakhiri dengan garis bawah (_) atau tanda pisah (-)',
    'auth:validationUsernameStartEndNoSpecial':
      'Username tidak boleh memiliki spasi',
    'auth:validationUsernameStartWithLetter':
      'Username harus dimulai dengan huruf',
    'auth:validationVerificationOTPInvalid':
      'Kami tidak dapat mengautentikasi OTP. Silakan coba lagi.',
    'auth:validationWesbite':
      "Situs Web harus diawali dengan 'http://' atau 'https://'",
    'auth:validationWesbiteURL':
      'Tautan tidak valid. Mohon periksa format tautan anda.',
    'auth:verificationChangeCredential':
      ' atau <change>Ubah nomor WhatsApp</change>',
    'auth:verificationChangeCredential_email':
      ' atau <change>Ubah email</change>',
    'auth:verificationCheckBox':
      'Dengan mendaftar, kamu menyetujui Ketentuan Penggunaan dan Kebijakan Privasi, termasuk Kebijakan Cookie. Kamu mungkin menerima pemberitahuan SMS dari kami dan dapat memilih keluar kapan saja.',
    'auth:verificationEmailCaution': 'Tautan verifikasi kamu kedaluwarsa dalam',
    'auth:verificationEmailCautionTime': '1 jam.',
    'auth:verificationEmailConfirmHeaderDescription':
      'Akun kamu telah diverifikasi.',
    'auth:verificationEmailConfirmInformation':
      'Sekarang kamu dapat masuk menggunakan email terdaftar kamu. Selamat datang!',
    'auth:verificationEmailDidNotGet': 'Tidak mendapatkan email?',
    'auth:verificationEmailHeader': '{{email}}',
    'auth:verificationEmailHeaderDescription':
      'Kami telah mengirimkan email verifikasi kepada kamu. Silakan periksa email kamu untuk link konfirmasi.',
    'auth:verificationEmailInformation':
      'Periksa email kamu dan klik tautan verifikasi atau tombol untuk melanjutkan',
    'auth:verificationEmailResend': 'Kirim ulang email',
    'auth:verificationEmailResendCountdown':
      'Kirim ulang email ({{countdown}}d)',
    'auth:verificationEmailSent': 'Konfirmasi email telah dikirimkan ke email',
    'auth:verificationPhoneCaution': 'OTP kamu kedaluwarsa dalam',
    'auth:verificationPhoneCautionTime': ' 5 menit.',
    'auth:verificationPhoneConfirmHeader': 'Email verified',
    'auth:verificationPhoneConfirmHeaderDescription':
      'Nomor ponsel kamu telah diverifikasi.',
    'auth:verificationPhoneConfirmHeaderInformation':
      'Sekarang kamu dapat masuk menggunakan nomor ponsel kamu yang terdaftar',
    'auth:verificationPhoneDidNotGet': 'Tidak mendapatkan OTP?',
    'auth:verificationPhoneHeaderDescription':
      'OTP dikirim ke nomor ponsel kamu. Silakan periksa SMS kamu.',
    'auth:verificationPhoneHeaderDescription_whatsapp':
      'OTP dikirim ke nomor ponsel kamu. Silakan periksa WhatsApp kamu.',
    'auth:verificationPhoneInputPlaceholder': 'OTP 4 digit kamu',
    'auth:verificationPhoneOTP': 'OTP telah kedaluwarsa.',
    'auth:verificationPhoneResend': 'Kirim ulang OTP',
    'auth:verificationPhoneResendCountdown': 'Kirim ulang OTP ({{countdown}}d)',
    'auth:verificationWhatsappDidNotGet': 'Tidak mendapatkan pesan?',
    'auth:verificationWhatsappResend': 'Kirim ulang pesan',
    'auth:verificationWhatsappResendCountdown':
      'Kirim ulang pesan ({{countdown}}d)',
    'auth:verifyAccount': 'Verifikasi akun kamu untuk melanjutkan',
    'auth:waitingModalInfo':
      'Karena permintaan yang banyak, kami sedang menaikan kapasitas server kami.',
    'auth:waitingModalDescription':
      'Saat ini kamu sedang ada di daftar tunggu. Kami akan informasikan ketika kapasitas kami telah bertambah. Terima kasih atas kesabaran kamu.',
    'auth:waitingModalTitle': 'Maaf!',
    'auth:wefieHeader': 'Wefie',
    'auth:wefieThroughMobileApp':
      'Masuk ke akun kamu melalui aplikasi seluler FotoYu, tambahkan anak-anakmu, dan lakukan wefie dengan anak-anakmu.',
    'auth:wefieReapplyModalDescription':
      'Pastikan semua persyaratan sudah benar, dan coba lagi.',
    'auth:wefieReapplyModalTitle':
      'Kamu belum bisa login, karena kami menemukan wefie Anda tidak memenuhi persyaratan.',
    'auth:wefieWaitingModalDescription':
      'RoboYu akan memberi tahu kamu sesegera mungkin setelah proses nya selesai.',
    'auth:wefieWaitingModalTitle':
      'Kamu belum bisa login, karena wefie kamu belum disetujui oleh RoboYu.',
  },
}
