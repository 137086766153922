import {
  FontColor,
  GiftShopCreationBadgeTypeConfig,
  GiftshopUploadContentData,
  GiftShopTransactionStatusConfig,
  IconType,
  GiftShopTransactionDetailResponse,
  GiftshopProfileSocialMediaType,
  GiftShopTransactionWaitingDetailResponse,
} from 'types'
import {ThemeColor} from 'themes'
import {INITIAL_PRICING_DETAIL} from '../pricing'

export const GIFT_SHOP_MAX_BIO_CHARACTER = 80
export const GIFT_SHOP_MAX_DESC_CHARACTER = 300

export const GIFT_SHOP_HEADER_HEIGHT = 64
export const GIFT_SHOP_HEADER_TITLE_WIDTH = 250
export const GIFT_SHOP_HEADER_PROFILE_ICON = 32
export const GIFT_SHOP_NAVBAR_ICON_SIZE = 24
export const GIFT_SHOP_NAVBAR_ITEM_PADDING_HORIZONTAL = 12
export const GIFT_SHOP_NAVBAR_ITEM_LABEL_WIDTH = 150

export const GIFT_SHOP_PROFILE_ROUTE = '/profile'
export const GIFT_SHOP_PROFILE_CHANGE_USERNAME_LIMIT = 14

export const GIFT_SHOP_ROOT_MENU_HEIGHT = 75
export const GIFT_SHOP_ROOT_MENU_WIDTH = 80
export const GIFT_SHOP_ROOT_HEADER_PADDING = 16
export const GIFT_SHOP_ROOT_CONTENT_EMPTY_PADDING = 25
export const GIFT_SHOP_ROOT_CONTENT_EMPTY_SPACE = 20
export const GIFT_SHOP_ROOT_CONTENT_EMPTY_IMAGE_HEIGHT = 200
export const GIFT_SHOP_ROOT_CONTENT_PADDING = 1
export const GIFT_SHOP_ROOT_CONTENT_ITEM_PADDING = 1
export const GIFT_SHOP_ROOT_BOTTOM_NAVBAR_HEIGHT = 52

export const GIFT_SHOP_CONTENT_CONTAINER_MAX_WIDTH = 850

export const GIFT_SHOP_EXPLORE_EMPTY_MAX_WIDTH = 400
export const GIFT_SHOP_EXPLORE_EMPTY_CONTAINER_PADDING = 20

export const GIFT_SHOP_COLLECTION_DOWNLOAD_BUNDLE_NAME = 'collection'

export const GIFTSHOP_UPLOAD_SINGLE_LIMIT = 100
export const GIFTSHOP_UPLOAD_BULK_LIMIT = 2000
export const GIFTSHOP_UPLOAD_VIDEO_LIMIT = 50

export const GIFT_SHOP_UPLOAD_ITEM_IMAGE_SIZE = 260
export const GIFT_SHOP_UPLOAD_MIN_PRICE = 0
export const GIFT_SHOP_UPLOAD_MAX_PRICE = 1000000
export const GIFT_SHOP_UPLOAD_YUSER_MAX_PRICE = 9000

export const GIFT_SHOP_ROBOPET_SETTINGS_DEFAULT = 6
export const GIFT_SHOP_ROBOPET_SETTINGS_OPTIONS = [1, 3, 6]

export const GIFT_SHOP_PROFILE_HEIGHT = 140
export const GIFT_SHOP_TAB_HEIGHT = 55

export const GIFT_SHOP_MAX_FILE_NAME = 100
export const GIFT_SHOP_FILTER_MAX_DATE = '9999-12-31T23:59'

export const GIFT_SHOP_CREATION_BADGE_BACKGROUND_COLORS: GiftShopCreationBadgeTypeConfig<ThemeColor> = {
  resend: 'danger_6',
  resendpology: 'warning_6',
  sold: 'success_5',
  unwishlist: 'black',
}

export const GIFT_SHOP_CREATION_BADGE_ICONS: GiftShopCreationBadgeTypeConfig<IconType> = {
  resend: 'exclamation-lg',
  resendpology: 'exclamation-lg',
  sold: 'basket',
  unwishlist: 'not-interested',
}

export const GIFT_SHOP_TRANSACTION_DETAIL_DEFAULT_VALUES: GiftShopTransactionDetailResponse = {
  data: [],
  checkout_at: '',
  payment_at: '',
  transaction_type: 'Purchase',
  transaction_method: '',
  transaction_total: 0,
  total_item: 0,
  status: 'PROCESSED',
  invoice_id: '',
  amount: 0,
  device: 'web',
  price_detail: {
    tax_fee: INITIAL_PRICING_DETAIL,
    service_fee: INITIAL_PRICING_DETAIL,
    creator: INITIAL_PRICING_DETAIL,
    fotoyu_fee: INITIAL_PRICING_DETAIL,
    exclude_tax: INITIAL_PRICING_DETAIL,
    final: INITIAL_PRICING_DETAIL,
    host_fee: INITIAL_PRICING_DETAIL,
    net_creator: INITIAL_PRICING_DETAIL,
    platform_fee: INITIAL_PRICING_DETAIL,
    refund: INITIAL_PRICING_DETAIL,
    refund_collector: INITIAL_PRICING_DETAIL,
    refund_creator: INITIAL_PRICING_DETAIL,
    refund_fotoyu: INITIAL_PRICING_DETAIL,
    refund_host: INITIAL_PRICING_DETAIL,
    discount: INITIAL_PRICING_DETAIL,
  },
  payment_invoice: '',
  payment_type: '',
}

export const GIFT_SHOP_WAITING_TRANSACTION_DETAIL_DEFAULT_VALUES: GiftShopTransactionWaitingDetailResponse = {
  data: [],
  transaction_method: '',
  transaction_total: 0,
  invoice_id: '',
  transaction_date: '',
  payment_invoice: '',
  payment_type: '',
}

export const GIFT_SHOP_TRANSACTION_STATUS_COLORS: GiftShopTransactionStatusConfig<FontColor> = {
  PROCESSED: 'gray_5',
  COMPLETED: 'success_4',
  FAILED: 'danger_5',
  HALF_COMPLETED: 'info_5',
  WAITING_HIRES: 'black',
}
export const GIFT_SHOP_TRANSACTION_STATUS_ICONS: GiftShopTransactionStatusConfig<IconType> = {
  PROCESSED: 'process',
  COMPLETED: 'read',
  FAILED: 'close',
  HALF_COMPLETED: 'check',
  WAITING_HIRES: 'image',
}

export const GIFT_SHOP_TRANSACTION_ERROR_REFRESH_ICON_SIZE = 16

export const GIFT_SHOP_TRANSACTION_BUILT_IN_KEY_WORD = 'BUILT_IN'

export const GIFT_SHOP_MIN_WITHDRAW_AMOUNT = 50000
export const GIFT_SHOP_MAX_WITHDRAW_AMOUNT = 10000000

export const GIFT_SHOP_MIN_ACCOUNT_NUMBER = 9
export const GIFT_SHOP_MAX_ACCOUNT_NUMBER = 18

export const GIFT_SHOP_CACHE_EXPIRATION_TIME = 1

export const GIFT_SHOP_UPLOAD_DEFAULT_LOCATION = `{"name":"Monumen Nasional, Jalan Silang Merdeka, Gambir, DKI Jakarta, 10110, IDN", "longitude":106.82584000000008, "latitude":-6.175269999999955}`
export const GIFT_SHOP_UPLOAD_MAX_SIZE = 50 * 2 ** 20
export const GIFT_SHOP_UPLOAD_MAX_RESOLUTION = 25 * 10 ** 6

export const GIFT_SHOP_UPLOAD_COMPRESS_QUALITY = 80
export const GIFT_SHOP_UPLOAD_COMPRESS_LIMIT = 1440

export const GIFT_SHOP_UPLOAD_TYPE = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/heic',
  'image/heif',
]

export const GIFT_SHOP_SPECIAL_IMAGE_UPLOAD_TYPE = ['image/heic', 'image/heif']
export const GIFT_SHOP_CONVERTED_SPECIAL_IMAGE_UPLOAD_TYPE = 'image/jpeg'

export const GIFT_SHOP_CACHE_EXPIRATION_DAYS = 60

export const GIFT_SHOP_UPLOAD_PROFILE_MAX_SIZE = 10485760
export const GIFT_SHOP_MIN_RESEND_SIZE = 1

export const GIFT_SHOP_UPLOAD_PROFILE_BACKGROUND_TYPE = [
  'image/jpg',
  'image/jpeg',
  'image/png',
]

export const GIFT_SHOP_UPLOAD_PROFILE_MAX_RESOLUTION = 16000000

export const GIFT_SHOP_UPLOAD_BACKGROUND_MIN_WIDTH = 1024
export const GIFT_SHOP_UPLOAD_BACKGROUND_MIN_HEIGHT = 512

export const MAP_NAME = 'explore.map'
export const MAP_LAT_LNG_PRECISION = 6

export const INITIAL_DATA_IMAGE: GiftshopUploadContentData = {
  id: '',
  src: '',
  file: ('' as unknown) as File,
  size: 0,
  height: 0,
  width: 0,
  extension: 'jpg',
}

export const GIFT_SHOP_SEND_TO_FACE_MAX_CONTENT = 30
export const GIFT_SHOP_SEND_TO_FACE_MAX_DESCRIPTION = 300

export const GIFT_SHOP_WHOLESALE_DISCOUNT_INCREMENT = 5
export const GIFT_SHOP_WHOLESALE_MAX_CONTENT = 100
export const GIFT_SHOP_WHOLESALE_MAX_DISCOUNT = 50
export const GIFT_SHOP_WHOLESALE_MAX_TIER = 5

export const GIFT_SHOP_RESEND_DURATION = 2
export const GIFT_SHOP_RESENDPOLOGY_DURATION = 7

export const GIFT_SHOP_SOCIAL_MEDIA: GiftshopProfileSocialMediaType[] = [
  'facebook',
  'instagram',
  'twitter',
  'linktree',
  'linkedin',
  'whatsapp',
  'other',
]

export const GIFT_SHOP_NOT_LINK_BASED_SOCIAL_MEDIA: GiftshopProfileSocialMediaType[] = [
  'facebook',
  'instagram',
  'twitter',
  'whatsapp',
]

export const GIFT_SHOP_MIN_FACEBOOK_USERNAME_LENGTH = 5
export const GIFT_SHOP_MAX_FACEBOOK_USERNAME_LENGTH = 50
export const GIFT_SHOP_MIN_INSTAGRAM_USERNAME_LENGTH = 1
export const GIFT_SHOP_MAX_INSTAGRAM_USERNAME_LENGTH = 30
export const GIFT_SHOP_MIN_TWITTER_USERNAME_LENGTH = 4
export const GIFT_SHOP_MAX_TWITTER_USERNAME_LENGTH = 15
export const GIFT_SHOP_MAX_LINKTREE_LINK_LENGTH = 80
export const GIFT_SHOP_MAX_LINKEDIN_LINK_LENGTH = 80
export const GIFT_SHOP_MIN_WHATSAPP_NUMBER_LENGTH = 10
export const GIFT_SHOP_MAX_WHATSAPP_NUMBER_LENGTH = 15
export const GIFT_SHOP_MAX_TITLE_LENGTH = 20
export const GIFT_SHOP_MAX_LINK_LENGTH = 80
export const GIFT_SHOP_SOCIAL_LINKEDIN_KEYWORD = 'www.linkedin.com'
export const GIFT_SHOP_SOCIAL_LINKTREE_KEYWORD = 'linktr.ee'

export const GIFT_SHOP_NEW_YUSER_NOTICE_MODAL_MINIMUM_SCREEN_HEIGHT = 849 + 64

export const GIFT_SHOP_UPLOAD_VIDEO_MAX_FILE = 1
export const GIFT_SHOP_UPLOAD_VIDEO_MAX_FPS = 24
export const GIFT_SHOP_UPLOAD_VIDEO_MAX_RESOLUTION = 1280
export const GIFT_SHOP_UPLOAD_VIDEO_MAX_SECONDS = 6.9
export const GIFT_SHOP_UPLOAD_VIDEO_MAX_SIZE = 15 * 2 ** 20
export const GIFT_SHOP_UPLOAD_VIDEO_TYPE = ['video/mp4']
export const GIFT_SHOP_UPLOAD_VIDEO_SEND_TO_FACE_MAX_SIZE = 10 * 2 ** 20
export const GIFT_SHOP_UPLOAD_VIDEO_SEND_TO_FACE_TYPE = [
  'image/png',
  'image/heic',
  'image/jpeg',
]
export const GIFT_SHOP_RESEND_VIDEO_MAX_RESOLUTION = 1920
export const GIFT_SHOP_RESEND_VIDEO_MAX_SIZE = 70 * 2 ** 20
export const GIFT_SHOP_RESEND_VIDEO_MAX_DURATION = 30.9
export const GIFT_SHOP_RESEND_VIDEO_TYPE = ['video/mp4']
export const GIFT_SHOP_UPLOAD_VIDEO_PREVIEW_SECONDS = 4
export const GIFT_SHOP_UPLOAD_VIDEO_PREVIEW_EXTENSION = 'zip'
export const GIFT_SHOP_UPLOAD_VIDEO_PREVIEW_MIME = 'application/zip'
export const GIFT_SHOP_UPLOAD_VIDEO_ZIP_TYPE = [
  GIFT_SHOP_UPLOAD_VIDEO_PREVIEW_MIME,
]
export const GIFT_SHOP_UPLOAD_VIDEO_UNSUPPORTED_OS = ['iOS']

export const GIFT_SHOP_USER_BANK_ACCOUNT_LIMIT = 1

export const GIFT_SHOP_UPLOAD_PAYMENT_PROOF_TYPE = [
  'image/png',
  'image/jpeg',
  'image/jpg',
]
export const GIFT_SHOP_MAX_UPLOAD_PAYMENT_PROOF_NOTES_CHARACTER = 200

export const GIFTSHOP_SEARCH_LIMIT = 10
export const GIFTSHOP_SEARCH_DEBOUNCE_TIMEOUT = 300
