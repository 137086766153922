import React from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {Button, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopPermissionType} from 'types'

const StyledContainer = styled.div`
  width: fill-available;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${convertUnit(25)};
`

const StyledContentContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(561)};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${convertUnit(10)};
`

const StyledImage = styled(Image)`
  margin: ${convertUnit(10)};
  width: ${convertUnit(136)};
`

const StyledButton = styled(Button)`
  margin-bottom: ${convertUnit(10)};
`

interface GiftShopTemplatePermissionDeniedProps {
  type: GiftShopPermissionType
}

export default function GiftShopTemplatePermissionDenied({
  type = 'location',
}: GiftShopTemplatePermissionDeniedProps) {
  const {translate} = useTranslation()
  const roboImg = IMAGE_ASSET('giftshop', 'robopet-offer.png')
  return (
    <StyledContainer>
      <StyledContentContainer>
        <StyledParagraph fontSize="l" fontWeight="bold">
          {translate('giftShop:accessPermissionHeader', {context: type})}
        </StyledParagraph>
        <StyledParagraph fontSize="m">
          {translate('giftShop:accessPermissionFirstDescription', {
            context: type,
          })}
        </StyledParagraph>
        {type === 'location' ? (
          <StyledImage src={roboImg} alt={roboImg} />
        ) : (
          <StyledButton
            label={translate('giftShop:accessPermissionButton')}
            onClick={() =>
              window.open('https://support.google.com/chrome/answer/2693767')
            }
          />
        )}
        <StyledParagraph fontSize="m">
          {translate('giftShop:accessPermissionSecondDescription', {
            context: type,
          })}
        </StyledParagraph>
        <StyledParagraph fontSize="s" color="gray_6">
          {translate('giftShop:accessPermissionDisclaimer')}
        </StyledParagraph>
        {type === 'location' && (
          <StyledButton
            label={translate('giftShop:accessPermissionButton')}
            onClick={() =>
              window.open('https://support.google.com/maps/answer/2839911')
            }
          />
        )}
      </StyledContentContainer>
    </StyledContainer>
  )
}
