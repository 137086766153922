import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET, TREE_LEADERBOARD_LOAD_LIMIT} from 'consts'
import {Icon, ListLazy, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {TreeLeaderboardData, TreeLeaderboardView} from 'types'
import {
  getBorder,
  getBoxShadow,
  getFontFamily,
  useDidUpdate,
  useHistory,
} from 'utils'
import {useDispatch} from 'lib/redux'
import {FototreeTallestTreeProps} from './FototreeTallestTreeProps'
import FototreeTallestTreeItem from './FototreeTallestTreeItem'

interface StyledTabItemProps {
  isSelected: boolean
}

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  width: fill-available;
  background-color: ${({theme}) => theme.primary_7};
  padding: ${convertUnit(12)} ${convertUnit(20)};
  gap: ${convertUnit(20)};
  box-shadow: ${({theme}) =>
    getBoxShadow(theme, {
      verticalOffset: 2,
      blurRadius: 4,
      opacity: 0.24,
    })};
`

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75%;
  max-width: ${convertUnit(480)};
  max-height: ${convertUnit(677)};
  margin: 0 ${convertUnit(20)};
  box-sizing: border-box;
  overflow: hidden;
  background: no-repeat center/cover
    url(${IMAGE_ASSET('giftshop', 'bg-tree.png')});
  background-color: ${({theme}) => theme.primary_7};
`

const StyledTabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  min-height: ${convertUnit(27)};
  gap: ${convertUnit(20)};
  padding: 0 ${convertUnit(20)};
  background-color: ${({theme}) => theme.primary_7};
`

const StyledTabItem = styled.div<StyledTabItemProps>`
  ${({theme, isSelected}) => ({
    color: isSelected ? theme.primary_2 : theme.gray_2,
    borderBottom: isSelected
      ? getBorder(3, 'solid', theme.primary_2)
      : undefined,
  })}
  width: calc(33% - (${convertUnit(40)}));
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

const StyledTabItemLabel = styled(Paragraph)<StyledTabItemProps>`
  ${({isSelected}) => ({
    fontFamily: getFontFamily(isSelected ? 'quicksandBold' : 'quicksandMedium'),
  })}
  color: inherit;
  text-align: center;
`

export default function FototreeTallestTree({
  stateVisible,
}: FototreeTallestTreeProps) {
  const history = useHistory()
  const {update} = useDispatch()
  const {translate} = useTranslation()
  const [visible, setVisible] = stateVisible
  const [tabView, setTabView] = useState<TreeLeaderboardView>('ALL')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refreshDate = useMemo(() => new Date(), [tabView])

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData(
        tabView === 'ALL'
          ? 'tree_get_fototree_leaderboard'
          : 'forkygram_get_fototree_leaderboard',
        {
          useDefaultMessage: true,
          params: {
            page,
            limit,
            filter_type: tabView !== 'ALL' ? tabView : undefined,
          },
        },
      )

      return typeof response !== 'string' && response?.status === 200
        ? response.data.result.map((item: TreeLeaderboardData) => ({
            ...item,
            type: tabView,
          }))
        : []
    },
    [tabView],
  )

  const handleNavigateFotoTreeDetail = useCallback(
    (item: TreeLeaderboardData) => {
      update('lastTreeState', {videoIntro: true})
      history.push(
        'tree_fototree_detail',
        {
          treeId: item.type === 'ALL' ? item.id : item.tree_id,
          treeAlias: item.type === 'ALL' ? item.alias : item.tree_alias,
        },
        item.type === 'ALL' ? item.alias : item.tree_alias,
      )
    },
    [history, update],
  )

  const handleRenderTab = useMemo(
    () => (
      <StyledTabContainer>
        <StyledTabItem
          isSelected={tabView === 'ALL'}
          onClick={() => setTabView('ALL')}>
          <StyledTabItemLabel isSelected={tabView === 'ALL'}>
            {translate('global:all')}
          </StyledTabItemLabel>
        </StyledTabItem>
        <StyledTabItem
          isSelected={tabView === 'MONTHLY'}
          onClick={() => setTabView('MONTHLY')}>
          <StyledTabItemLabel isSelected={tabView === 'MONTHLY'}>
            {translate('global:monthly')}
          </StyledTabItemLabel>
        </StyledTabItem>
        <StyledTabItem
          isSelected={tabView === 'WEEKLY'}
          onClick={() => setTabView('WEEKLY')}>
          <StyledTabItemLabel isSelected={tabView === 'WEEKLY'}>
            {translate('global:weekly')}
          </StyledTabItemLabel>
        </StyledTabItem>
      </StyledTabContainer>
    ),
    [tabView, translate],
  )

  const handleRenderHeader = useMemo(
    () => (
      <StyledHeader>
        <Icon
          type="close"
          color="white_1"
          style={{cursor: 'pointer'}}
          onClick={() => setVisible((prev) => !prev)}
        />
        <Paragraph fontSize="l" fontWeight="medium" color="white_1">
          {translate('tree:tallestTree')}
        </Paragraph>
      </StyledHeader>
    ),
    [setVisible, translate],
  )

  const handleRenderListItem = useCallback(
    (item: TreeLeaderboardData, index) => (
      <FototreeTallestTreeItem
        data={item}
        rank={index + 1}
        onClickItem={handleNavigateFotoTreeDetail}
      />
    ),
    [handleNavigateFotoTreeDetail],
  )

  const handleRenderList = useMemo(
    () => (
      <ListLazy
        keyExtractor={(item: TreeLeaderboardData) =>
          item.type === 'ALL' ? item.id : item.tree_id
        }
        refresh={refreshDate}
        loadData={handleLoadData}
        renderItem={handleRenderListItem}
        limitPerPage={TREE_LEADERBOARD_LOAD_LIMIT}
        scrollbar={false}
        contentContainerStyle={{
          padding: `${convertUnit(12)} ${convertUnit(16)}`,
        }}
      />
    ),
    [handleLoadData, handleRenderListItem, refreshDate],
  )

  useDidUpdate(() => {
    handleLoadData(1, TREE_LEADERBOARD_LOAD_LIMIT)
  }, [tabView])

  return (
    <StyledModal visible={visible}>
      {handleRenderHeader}
      {handleRenderTab}
      {handleRenderList}
    </StyledModal>
  )
}
