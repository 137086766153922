import React, {useCallback} from 'react'
import styled from 'styled-components'
import {Paragraph, Image} from 'common/components'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {FirebaseMessageRoomItemPayload} from 'types'
import {getFontFamily, useHistory} from 'utils'

export interface GiftShopMessageRoomContentItemNotificationLikeGroupProps {
  payload: FirebaseMessageRoomItemPayload['notification_like_group']
}

const StyledBold = styled.span`
  font-family: ${getFontFamily('bold')};
`

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledImageContainer = styled.div`
  margin-left: ${convertUnit(16)};
  cursor: pointer;
`

const StyledImage = styled(Image)`
  width: ${convertUnit(56)};
  height: ${convertUnit(56)};
  border-radius: ${convertUnit(4)};
`

export default function GiftShopMessageRoomContentItemNotificationLikeGroup({
  payload: {count, item, username},
}: GiftShopMessageRoomContentItemNotificationLikeGroupProps) {
  const {translate} = useTranslation()
  const history = useHistory()

  const handleNavigatePost = useCallback(() => {
    history.pushQuery({
      path: 'giftshop_profile_post_detail',
      state: {
        self: true,
        data: [],
        selectedItemId: item.id,
        memberId: item.member_id,
        loadSpecificData: true,
      },
      queryObject: {id: item.id},
      recursivePath: username,
    })
  }, [history, item.id, item.member_id, username])

  return (
    <StyledContainer>
      <Paragraph fontSize="m">
        <StyledBold>
          {count} {translate('global:yuser')}
        </StyledBold>
        {translate('messages:notificationLike')}
      </Paragraph>
      <StyledImageContainer onClick={handleNavigatePost}>
        <StyledImage src={item.url} alt="image" />
      </StyledImageContainer>
    </StyledContainer>
  )
}
