import React, {useState, useMemo, useCallback} from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_MAX_FACEBOOK_USERNAME_LENGTH,
  GIFT_SHOP_MAX_INSTAGRAM_USERNAME_LENGTH,
  GIFT_SHOP_MAX_LINKEDIN_LINK_LENGTH,
  GIFT_SHOP_MAX_LINKTREE_LINK_LENGTH,
  GIFT_SHOP_MAX_LINK_LENGTH,
  GIFT_SHOP_MAX_TITLE_LENGTH,
  GIFT_SHOP_MAX_TWITTER_USERNAME_LENGTH,
  GIFT_SHOP_MAX_WHATSAPP_NUMBER_LENGTH,
  GIFT_SHOP_MIN_FACEBOOK_USERNAME_LENGTH,
  GIFT_SHOP_MIN_INSTAGRAM_USERNAME_LENGTH,
  GIFT_SHOP_MIN_TWITTER_USERNAME_LENGTH,
  GIFT_SHOP_MIN_WHATSAPP_NUMBER_LENGTH,
  GIFT_SHOP_NOT_LINK_BASED_SOCIAL_MEDIA,
  GIFT_SHOP_SOCIAL_LINKEDIN_KEYWORD,
  GIFT_SHOP_SOCIAL_LINKTREE_KEYWORD,
  GIFT_SHOP_SOCIAL_MEDIA,
  REGEX_ALLOWED_USERNAME,
  REGEX_ALPHANUMERIC_SLASH_AND_DOT,
  REGEX_NUMBER,
} from 'consts'
import {translate} from 'i18n'
import {requestData} from 'services'
import {GiftshopProfileSocialMediaType} from 'types'
import {showSnackbar, useDefaultState} from 'utils'
import {
  Modal,
  Input,
  ListItemSelect,
  Menu,
  Button,
  Paragraph,
  DropdownItem,
  ButtonIcon,
} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {GiftshopEditProfileAddSocialLinkModalProps} from './GiftShopEditProfileModalProps'

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  gap: ${convertUnit(20)};
  align-items: center;
`

const StyledButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: ${convertUnit(20)};
`

const StyledMenuContainer = styled.div`
  display: flex;
  width: 100%;
`

const StyledInputContainer = styled.div`
  display: flex;
  width: 100%;
`

const StyledButton = styled(Button)`
  display: flex;
  flex: 1;
`

export default function GiftshopEditProfileAddSocialLinkModal({
  isEdit = false,
  onClickClose,
  selectedItemRef,
  stateShouldUpdate,
}: GiftshopEditProfileAddSocialLinkModalProps) {
  const {biodata} = useSelector('user') || {}
  const selectedItem = selectedItemRef?.current
  const socialTypeDefaultValue = selectedItem
    ? GIFT_SHOP_SOCIAL_MEDIA.includes(selectedItem.social_type)
      ? (selectedItem.social_type as GiftshopProfileSocialMediaType)
      : 'other'
    : undefined
  const socialTitleDefaultValue = selectedItem
    ? GIFT_SHOP_SOCIAL_MEDIA.includes(selectedItem.social_type)
      ? undefined
      : selectedItem.social_type
    : undefined
  const socialValueDefaultValue = selectedItem
    ? GIFT_SHOP_NOT_LINK_BASED_SOCIAL_MEDIA.includes(selectedItem.social_type)
      ? selectedItem.social_value.substring(
          selectedItem.social_value.lastIndexOf('/') + 1,
        )
      : selectedItem.social_value
    : undefined

  const stateToggle = useState(false)

  const [socialType, setSocialType] = useDefaultState<
    GiftshopProfileSocialMediaType | undefined
  >(socialTypeDefaultValue, undefined)
  const [socialValue, setSocialValue] = useDefaultState<string | undefined>(
    socialValueDefaultValue,
    undefined,
  )
  const [socialTitle, setSocialTitle] = useDefaultState<string | undefined>(
    socialTitleDefaultValue,
    undefined,
  )

  const [toggle, setToggle] = stateToggle
  const setShouldUpdate = stateShouldUpdate[1]

  const handleOnClickDropdownItem = useCallback(
    (value: GiftshopProfileSocialMediaType) => {
      setSocialType(value)
      setToggle((previous) => !previous)
    },
    [setSocialType, setToggle],
  )

  const handleInputMinLength = useMemo(() => {
    switch (socialType as GiftshopProfileSocialMediaType) {
      case 'facebook':
        return GIFT_SHOP_MIN_FACEBOOK_USERNAME_LENGTH
      case 'instagram':
        return GIFT_SHOP_MIN_INSTAGRAM_USERNAME_LENGTH
      case 'twitter':
        return GIFT_SHOP_MIN_TWITTER_USERNAME_LENGTH
      case 'whatsapp':
        return GIFT_SHOP_MIN_WHATSAPP_NUMBER_LENGTH
      case 'linktree':
      case 'linkedin':
      default:
        return 1
    }
  }, [socialType])

  const isInputValid = useCallback(() => {
    const minLength = handleInputMinLength
    if (!socialValue || (socialType === 'other' && !socialTitle)) {
      showSnackbar(translate('giftshop:socialLinkValidation'))
      return false
    }
    if (socialValue?.length < minLength) {
      showSnackbar(
        translate('giftshop:socialLinkValidation', {
          context:
            socialType === 'whatsapp' ? 'minimumNumberLength' : 'minimumLength',
          length: minLength,
        }),
      )
      return false
    }
    if (
      socialType === 'linktree' &&
      !socialValue?.includes(GIFT_SHOP_SOCIAL_LINKTREE_KEYWORD)
    ) {
      showSnackbar(
        translate('giftshop:socialLinkValidation', {
          context: 'includeKeyword',
          keyword: GIFT_SHOP_SOCIAL_LINKTREE_KEYWORD,
        }),
      )
      return false
    }
    if (
      socialType === 'linkedin' &&
      !socialValue?.includes(GIFT_SHOP_SOCIAL_LINKEDIN_KEYWORD)
    ) {
      showSnackbar(
        translate('giftshop:socialLinkValidation', {
          context: 'includeKeyword',
          keyword: GIFT_SHOP_SOCIAL_LINKEDIN_KEYWORD,
        }),
      )
      return false
    }
    return true
  }, [handleInputMinLength, socialTitle, socialType, socialValue])

  const handlePatchProfile = useCallback(() => {
    requestData('giftshop_patch_profile', {
      useDefaultMessage: true,
      actionType: 'execute',
      headerContentType: 'multipart/form-data',
      dataType: 'form-data',
      data: {
        bio: biodata,
        facebook: socialType === 'facebook' ? socialValue : '',
        instagram: socialType === 'instagram' ? socialValue : '',
        twitter: socialType === 'twitter' ? socialValue : '',
        linktree: socialType === 'linktree' ? socialValue : '',
        linkedin: socialType === 'linkedin' ? socialValue : '',
        whatsapp: socialType === 'whatsapp' ? socialValue : '',
        other_social:
          socialType === 'other' ? `${socialTitle},${socialValue}` : '',
      },
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          isEdit
            ? showSnackbar(
                translate('giftshop:socialLinkResponseSuccess', {
                  context: 'edit',
                }),
              )
            : showSnackbar(translate('giftshop:socialLinkResponseSuccess'))
          setShouldUpdate({})
        }
      },
    })
  }, [biodata, isEdit, setShouldUpdate, socialTitle, socialType, socialValue])

  const handleDeleteProfile = useCallback(() => {
    requestData('giftshop_patch_profile', {
      useDefaultMessage: true,
      actionType: 'execute',
      headerContentType: 'multipart/form-data',
      dataType: 'form-data',
      data: {
        bio: biodata,
        delete_social: socialType === 'other' ? socialTitle : socialType,
      },
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          showSnackbar(
            translate('giftshop:socialLinkResponseSuccess', {
              context: 'delete',
            }),
          )
          onClickClose()
          setShouldUpdate({})
        }
      },
    })
  }, [biodata, onClickClose, setShouldUpdate, socialTitle, socialType])

  const handleOnClickSubmit = useCallback(() => {
    if (isInputValid()) {
      handlePatchProfile()
      onClickClose()
    }
  }, [handlePatchProfile, isInputValid, onClickClose])

  const handleAllowedCharacters = useMemo(() => {
    switch (socialType) {
      case 'facebook':
      case 'instagram':
      case 'twitter':
        return REGEX_ALLOWED_USERNAME
      case 'whatsapp':
        return REGEX_NUMBER
      default:
        return REGEX_ALPHANUMERIC_SLASH_AND_DOT
    }
  }, [socialType])

  const handleInputMaxLength = useMemo(() => {
    switch (socialType) {
      case 'facebook':
        return GIFT_SHOP_MAX_FACEBOOK_USERNAME_LENGTH
      case 'instagram':
        return GIFT_SHOP_MAX_INSTAGRAM_USERNAME_LENGTH
      case 'twitter':
        return GIFT_SHOP_MAX_TWITTER_USERNAME_LENGTH
      case 'linktree':
        return GIFT_SHOP_MAX_LINKTREE_LINK_LENGTH
      case 'linkedin':
        return GIFT_SHOP_MAX_LINKEDIN_LINK_LENGTH
      case 'whatsapp':
        return GIFT_SHOP_MAX_WHATSAPP_NUMBER_LENGTH
      default:
        return GIFT_SHOP_MAX_LINK_LENGTH
    }
  }, [socialType])

  const handlePlaceholder = useMemo(() => {
    switch (socialType) {
      case 'facebook':
      case 'instagram':
      case 'twitter':
        return translate('giftShop:addSocialLinkInputPlaceholder', {
          socialType,
          context: 'username',
        })
      case 'linktree':
      case 'linkedin':
        return translate('giftShop:addSocialLinkInputPlaceholder', {
          socialType,
        })
      case 'whatsapp':
        return translate('giftShop:addSocialLinkInputPlaceholder', {
          context: 'whatsapp',
        })
      default:
        return translate('giftShop:addSocialLinkInputPlaceholder', {
          socialType: '',
        })
    }
  }, [socialType])

  const handleRenderButtons = useMemo(
    () => (
      <StyledButtonContainer>
        {isEdit && (
          <ButtonIcon
            backgroundColor="white_3"
            hasShadow={false}
            iconType="trash"
            style={{maxWidth: convertUnit(42), maxHeight: convertUnit(42)}}
            iconColor="primary_5"
            onClick={() => handleDeleteProfile()}
          />
        )}
        <StyledButton
          backgroundColor="white_3"
          color="primary_5"
          label={translate('global:cancel')}
          onClick={onClickClose}
        />
        <StyledButton
          label={isEdit ? translate('global:save') : translate('global:add')}
          onClick={() => socialType && socialValue && handleOnClickSubmit()}
          disabled={
            !(socialType && socialValue) ||
            (socialType === 'other' && !socialTitle)
          }
        />
      </StyledButtonContainer>
    ),
    [
      handleDeleteProfile,
      handleOnClickSubmit,
      isEdit,
      onClickClose,
      socialTitle,
      socialType,
      socialValue,
    ],
  )

  return (
    <StyledModal
      visible
      title={translate('giftShop:socialLink', {context: 'add'})}>
      <Paragraph fontSize={'l'} fontWeight="bold">
        {isEdit
          ? translate('giftShop:socialLink', {context: 'edit'})
          : translate('giftShop:socialLink', {context: 'add'})}
      </Paragraph>

      <StyledMenuContainer>
        <Menu
          stateToggle={stateToggle}
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            gap: convertUnit(4),
          }}
          renderContent={
            <>
              {GIFT_SHOP_SOCIAL_MEDIA.map((item) => (
                <ListItemSelect
                  title={
                    item === 'other'
                      ? translate('global:other')
                      : item.charAt(0).toUpperCase() + item.substring(1)
                  }
                  onClick={() =>
                    handleOnClickDropdownItem(
                      item.toLowerCase() as GiftshopProfileSocialMediaType,
                    )
                  }
                />
              ))}
            </>
          }>
          <Paragraph fontSize={'m'} fontWeight="medium">
            {translate('global:type')}
          </Paragraph>
          <DropdownItem
            label={
              (socialType === 'other'
                ? translate('global:other')
                : socialType) ||
              translate('giftshop:addSocialLinkTypePlaceholder')
            }
            style={{
              width: '100%',
              textTransform: socialType ? 'capitalize' : 'none',
            }}
            itemColor={isEdit ? 'gray_2' : socialType ? 'black' : 'gray_5'}
            fontWeight="regular"
            toggle={toggle}
            onClick={(e) => {
              e.preventDefault()
              if (!isEdit) {
                setSocialValue(undefined)
                setToggle((previous) => !previous)
              }
            }}
          />
        </Menu>
      </StyledMenuContainer>

      {socialType === 'other' && (
        <StyledInputContainer>
          <Input
            value={socialTitle || ''}
            name="social_title"
            label={translate('global:title')}
            maxLength={GIFT_SHOP_MAX_TITLE_LENGTH}
            autoComplete={false}
            placeholder={translate('giftShop:addSocialLinkInputPlaceholder', {
              context: 'title',
            })}
            onRightIconClick={() => setSocialTitle(undefined)}
            containerStyle={{flex: 1}}
            rightIcon={isEdit ? undefined : 'delete'}
            disabled={isEdit}
            onChangeText={setSocialTitle}
          />
        </StyledInputContainer>
      )}

      <StyledInputContainer>
        <Input
          required
          value={socialValue || ''}
          disabled={!socialType}
          name="social_value"
          label={
            (socialType &&
              socialType !== 'other' &&
              socialType.charAt(0).toUpperCase() + socialType.substring(1)) ||
            translate('global:link')
          }
          allowedCharacters={handleAllowedCharacters}
          autoComplete={false}
          maxLength={handleInputMaxLength}
          placeholder={handlePlaceholder}
          containerStyle={{flex: 1}}
          rightIcon={'delete'}
          onRightIconClick={() => setSocialValue(undefined)}
          onChangeText={setSocialValue}
        />
      </StyledInputContainer>

      {handleRenderButtons}
    </StyledModal>
  )
}
