import React, {useMemo} from 'react'
import styled from 'styled-components'
import {ForkygramListGridItemType} from 'types'
import {getBorder, getThemeColor} from 'utils'
import {useWindowLayout, useWindowMode} from 'windows'
import {Icon, Image, Paragraph, Tooltip} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {ThemeColor} from 'themes'
import {GiftShopTemplateContentVideoBadge} from '../../../../../gift-shop'
import {ForkygramListGridItemProps} from './ForkygramListGridItemProps'

interface StyledContentContainerProps {
  width: number
  borderColor?: ThemeColor
}

const StyledContentContainer = styled.div<StyledContentContainerProps>`
  ${({theme, width, borderColor = 'white_1'}) => ({
    width: convertUnit(width),
    height: convertUnit(width),
    border: getBorder(1, 'solid', theme[borderColor]),
  })}
  position: relative;
  max-width: fill-available;
  box-sizing: border-box;
`

const StyledImage = styled(Image)<StyledContentContainerProps>`
  ${({width}) => ({
    width: convertUnit(width),
    height: convertUnit(width),
  })}
  background-color: ${({theme}) => theme.gray_2};
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const StyledCaptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${convertUnit(8)};
  height: ${convertUnit(28)};
`

const StyledExtraBottom = styled.div`
  display: flex;
  background-color: ${({theme}) => getThemeColor(theme, 'primary_5', 0.6)};
  border-radius: ${convertUnit(20)};
  padding: 0 ${convertUnit(4)};
`

const StyledExtraBottomContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  bottom: ${convertUnit(4)};
  left: ${convertUnit(4)};
  gap: ${convertUnit(4)};
`

export default function ForkygramListGridItem<
  ItemT extends ForkygramListGridItemType
>({data, column, borderColor, onClickItem}: ForkygramListGridItemProps<ItemT>) {
  const {translate} = useTranslation()
  const {width} = useWindowLayout()
  const mode = useWindowMode()

  const handleRenderExtra = useMemo(
    () =>
      (data.isHidden || data.isWishlist) && (
        <StyledExtraBottomContainer>
          {data.isHidden && (
            <StyledExtraBottom
              data-tooltip-id={`tooltip-invisible-${data.url}`}>
              <Icon type="invisible" size={convertUnit(12)} color="white_1" />
              <Tooltip
                id={`tooltip-invisible-${data.url}`}
                content={translate('tree:invisible')}
              />
            </StyledExtraBottom>
          )}
          {data.isWishlist && (
            <StyledExtraBottom>
              <Icon type="price-tags" size={convertUnit(10)} color="white_1" />
            </StyledExtraBottom>
          )}
        </StyledExtraBottomContainer>
      ),
    [data.isHidden, data.isWishlist, data.url, translate],
  )

  return (
    <StyledContainer onClick={() => onClickItem(data)}>
      <StyledContentContainer
        borderColor={borderColor}
        width={mode === 'website' ? (width - 97) / column : width / column}>
        <>
          {data.contentType === 'video' && (
            <GiftShopTemplateContentVideoBadge />
          )}
          <StyledImage
            useLazyLoad
            width={mode === 'website' ? (width - 97) / column : width / column}
            src={data.contentType === 'video' ? data.thumbnail : data.url}
            alt="thumbnail"
          />
        </>
        {handleRenderExtra}
      </StyledContentContainer>
      {data.caption && (
        <StyledCaptionContainer>
          <Paragraph fontSize="xs" fontWeight="medium" color="white_1">
            {data.caption}
          </Paragraph>
        </StyledCaptionContainer>
      )}
    </StyledContainer>
  )
}
